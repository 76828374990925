<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">

                    <div class="col-md-6 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-currency-usd  "></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ $t('supplier.history.title', [supplier.name]) }} </h3>
                                <p class="opacity-75">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 text-white p-b-30 ml-auto">
                        <div class="rounded text-white bg-white-translucent">
                            <div class="p-all-15">
                                <div class="row text-center">
                                    <div class="col-md-6 my-2 m-md-0">
                                        <div class="text-overline opacity-75">{{ $t('supplier.received') }}</div>
                                        <transition name="slide-fades">
                                            <h4 v-if="animation" class="m-0 text-success">
                                                {{ formatPrice(parseInt(supplier.payments_count)) }}</h4>
                                        </transition>
                                    </div>
                                    <div class="col-md-6 my-2 m-md-0">
                                        <div class="text-overline    opacity-75">{{ $t('supplier.pending') }}</div>
                                        <transition name="slide-fades">
                                            <h5 v-if="animation" class="m-0 text-danger">{{ formatPrice
                                                (supplier.amt_owed) }}</h5>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link :to="{name: 'supplier-history'}"
                                         class="el-button el-button--success el-button--medium"><i
                                    class="mdi mdi-contactless-payment"></i> {{ $t('supplier.button.supplies') }}
                            </router-link>
                            <router-link :to="{name: 'supplier-payments'}"
                                         class="el-button el-button--primary el-button--medium"><i
                                    class="mdi mdi-subtitles"></i> {{ $t('supplier.button.payment') }}
                            </router-link>
                            <!--<el-button type="default" size="medium" icon="el-icon-s-finance" @click="cashIn">
                                Cash-in
                            </el-button>-->
                            <router-link :to="{name: 'suppliers'}"
                                         class="el-button el-button--warning el-button--medium"><i
                                    class="mdi mdi-format-list-bulleted"></i> {{ $t('supplier.button.all') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <router-view :supplier="supplier" @refresh-supplier="refreshSupplier"/>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'History',
  data () {
    return {
      supplier: {},
      animation: false
    }
  },
  methods: {
    animateAmount () {
      this.animation = false
      setTimeout(() => {
        this.animation = true
      }, 600)
    },
    refreshSupplier () {
      this.$supplierService.findById(this.$route.params.id)
        .then(data => {
          this.animateAmount()
          this.supplier = data
        })
    }
  },
  mounted () {
    this.refreshSupplier()
  }
}
</script>

<style scoped>
    .slide-fades-enter-active {
        transition: all .3s ease;
    }

    .slide-fades-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fades-enter, .slide-fades-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
