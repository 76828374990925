<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <el-card class="box-card text-center">
                    <div slot="header" class="clearfix">
                        <h3>{{ $t('report.employee_debt.name') }}</h3>
                    </div>
                    <el-form :inline="true" :model="form" class="demo-form-inline">
                        <el-form-item label="Start">
                            <el-date-picker v-model="form.start_date" format="dd MMM, yyyy"
                                            value-format="yyyy-MM-dd"
                                            placeholder="Select a start date"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="End">
                            <el-date-picker v-model="form.end_date" format="dd MMM, yyyy"
                                            value-format="yyyy-MM-dd"
                                            placeholder="Select an end date"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="Employee">
                            <el-select collapse-tags multiple v-model="form.employee" placeholder="All employees">
                                <el-option v-for="(sup, ind) in employees" :key="ind" :label="sup.name"
                                           :value="sup.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Debt Types">
                            <el-select collapse-tags multiple v-model="form.types" placeholder="All Debt Categories">
                                <el-option v-for="(type, ind) in types" :key="ind" :label="type.name"
                                           :value="type.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="">
                            <el-checkbox v-model="form.dl">Download</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button :loading="loading" type="primary" @click="onReportSubmit">Query</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
        </div>
        <br>
        <el-row>
            <div class="card m-b-30">
                <div class="card-header">
                    <h5 class="m-b-0">
                        <i class="mdi mdi-checkbox-intermediate"></i> Employee Debts Report
                    </h5>

                </div>
                <div class="card-body">
                    <div class="table-responsive">

                        <table class="table table-hover ">
                            <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Paid</th>
                                <th>Balance</th>
                                <th>Date</th>
                            </tr>
                            </thead>
                            <tbody v-for="(emp, idx) in data" :key="idx">
                            <tr v-for="(debt, x) in emp.debts" :key="x">
                                <td>{{ emp.name }}</td>
                                <td class="currency">{{ debt.amount | currency('XAF') }}</td>
                                <td class="currency">{{ debt.type.name }}</td>
                                <td>{{ debt.description }}</td>
                                <td class="currency">{{ debt.amount - debt.balance | currency('XAF')}}</td>
                                <td class="currency">{{ debt.balance | currency('XAF') }}</td>
                                <td>{{  shortDate(debt.date) }}</td>
                            </tr>
                            <tr style="background-color: moccasin;font-size: 15px;font-weight: bold;">
                                <td colspan="1">{{ emp.name }} Total</td>
                                <td colspan="">{{ getTotal(emp.debts, 'amount') | currency('XAF') }}</td>
                                <td></td>
                                <td></td>
                                <td colspan="1">{{ getTotal(emp.debts, 'left') | currency('XAF') }}</td>
                                <td colspan="1">{{ getTotal(emp.debts, 'balance') | currency('XAF') }}</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'EmployeeDebtReport',
  data () {
    return {
      form: {
        employee: [],
        types: [],
        start_date: null,
        end_date: null,
        dl: false
      },
      data: [],
      employees: [],
      types: [],
      loading: false
    }
  },
  methods: {
    getTotal (row, key) {
      if (key === 'left') {
        return row.map((d) => (d.amount - d.balance)).reduce((acc, curr) => (acc + curr), 0)
      }
      return row.map(d => d[key]).reduce((acc, curr) => (acc + curr), 0)
    },
    onReportSubmit () {
      const query = Object.assign({}, this.form)
      if (!query.dl) delete query.dl
      this.loading = true
      this.$reportService.getEmployeeDebts(query)
        .then(data => {
          this.data = data.data
          if (this.form.dl && data.url) {
            this.downloadFile(data.url)
          }
        })
        .catch(error => {
          this.displayMessage(error.message, () => {
          }, 'error')
        })
        .then(() => {
          this.loading = false
        })
    },
    downloadFile (url) {
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('report.employee_debt.name'))
    this.$employeeService.getEmployees()
      .then(data => (this.employees = data))
    this.$debtService.getDebtTypes()
      .then(data => (this.types = data.data))
  }
}
</script>

<style scoped>

</style>
