<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row type="flex" justify="end">
                            <el-col>
                                <div class="m-b-0 h5">
                                    <i class="mdi mdi-store-24-hour"></i> {{ $t('warehouse.button.all') }}
                                </div>
                            </el-col>
                            <el-col align="end">
                                <el-button v-can="'warehouse.create'" @click="showCreateDialog" icon="el-icon-plus"
                                           type="primary" plain round>
                                    {{ $t('warehouse.button.create') }}
                                </el-button>
                            </el-col>
                        </el-row>

                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="warehouses"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }"
                                         center-align>
                                <el-table-column label="S/N" type="index" sortable></el-table-column>
                                <el-table-column prop="name" :label="$t('label.name')">
                                    <template slot-scope="scope">
                                        {{ scope.row.name }}
                                        <el-tag v-if="scope.row.default" size="small">
                                            {{ $t('label.default') }}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="user.name" :label="$t('label.creator')"></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate"
                                                 :label="$t('label.created_at')"></el-table-column>
                                <el-table-column label="Actions" min-width="100px">
                                    <template slot-scope="scope">
                                        <el-dropdown v-can.event="'warehouse.manage'"
                                                     @click="$router.push({name: 'manage-warehouse', params: {id: scope.row.id}})"
                                                     split-button type="primary" size="small" trigger="click">
                                            <i class="el-icon-s-operation"></i> {{ $t('button.manage') }}
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-can.disable="'warehouse.edit'"
                                                                  @click.native="showEditDialog(scope.row.id)">
                                                    <i class="el-icon-edit"></i> {{ $t('button.edit') }}
                                                </el-dropdown-item>
                                                <el-dropdown-item v-can.disable="'warehouse.default'"
                                                                  :disabled="!!scope.row.default"
                                                                  @click.native="changeDefault(scope.row.id)">
                                                    <i class="el-icon-medal-1"></i> {{ $t('warehouse.button.default') }}
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-edit-warehouse @created="newWarehouse" :display="displayEditDialog" @close="closeEditDialog"
                               @updated="departmentUpdate"
                               :warehouse="warehouse"></create-edit-warehouse>
    </div>
</template>

<script>
import CreateEditWarehouse from './Modal/CreateWarehouse'

export default {
  name: 'Index',
  components: {
    CreateEditWarehouse
  },
  data () {
    return {
      warehouses: [],
      warehouse: {},
      displayEditDialog: false
    }
  },
  methods: {
    changeDefault (id) {
      this.askQuestion(this.$t('warehouse.confirm.default'), () => {
        this.$warehouseService.changeDefault(id).then(_ => {
          this.notify(this.$t('warehouse.message.default'))
          this.fetchWarehouses()
        })
      })
    },
    showCreateDialog () {
      this.warehouse = {}
      this.displayEditDialog = true
    },
    showEditDialog (id) {
      this.warehouse = this.warehouses.find(d => d.id === id)
      this.displayEditDialog = true
    },
    closeEditDialog () {
      this.warehouse = {}
      this.displayEditDialog = false
    },
    newWarehouse (data) {
      this.warehouses.push(data)
    },
    departmentUpdate (data) {
      this.warehouses.splice(this.warehouses.indexOf(this.warehouses.find(d => d.id === data.id)), 1, data)
    },
    showUsersDialog (id) {
    },
    beforeArchive (id) {
      this.askQuestion(this.$t('warehouse.message.archive'), () => {
        this.$departmentService.archiveDepartment(id).then(() => {
          this.notify(this.$t('warehouse.message.archived'))
          this.warehouses.splice(this.warehouses.indexOf(this.warehouses.find(d => d.id === id)), 1)
        })
      })
    },
    fetchWarehouses () {
      this.$warehouseService.getWarehouses().then(data => {
        this.warehouses = data
      })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('warehouse.title.warehouse'))
    this.fetchWarehouses()
  },
  created () {
    this.$parent.$on('new-warehouse', this.newWarehouse)
  }
}
</script>

<style scoped>

</style>
