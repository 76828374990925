<template>
    <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="visible"
               @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
        <slot name="title">
            <h3 class="text-center">
                {{ $t(`account.subcategory.title.${edit ? 'update' : 'create'}`, {name: category.name}) }}
            </h3>
            <hr>
        </slot>
        <el-row>
            <el-col :span="12" :offset="6">
                <el-form :model="form" size="medium" :rules="rules" ref="subcategoryForm">
                    <el-form-item label="Category name" label-width="100" prop="name">
                        <el-input placeholder="Enter subcategory name" @keyup.enter.native="submitForm" ref="input"
                                  v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close', false)">Cancel</el-button>
                <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
                    {{ $t(`account.subcategory.button.${edit ? 'update' : 'create'}`) }}
                </el-button>
            </el-col>
        </el-row>

    </el-dialog>
</template>

<script>
export default {
  name: 'CreateEditSubcategory',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    category: {
      type: Object,
      required: true,
      default: () => ({})
    },
    subcategory: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: this.subcategory.name || ''
      },
      rules: {
        name: [
          { required: true, message: 'Please, enter the subcategory name', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: {
    submittable () {
      return !!this.form.name.length
    },
    edit () {
      return this.subcategory && this.subcategory.id
    }
  },
  methods: {
    opened () {
      if (this.subcategory && this.subcategory.id) {
        this.form.name = this.subcategory.name
      }
      this.$refs.input.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = ''
      })
      this.loading = false
    },
    submitForm () {
      this.$refs.subcategoryForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`account.subcategory.confirm.${this.edit ? 'update' : 'create'}`,
            { name: this.category.name }), () => {
            this.loading = true
            if (this.edit) {
              this.$accountService.updateSubcategory(this.subcategory.id, this.form)
                .then(data => {
                  this.notify(this.$t('account.subcategory.message.updated', { name: this.category.name }), this.$t('status.success'))
                  this.$emit('subcategory-updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$accountService.createSubcategory(this.category.id, this.form)
                .then(data => {
                  this.notify(this.$t('account.subcategory.message.created', { name: this.category.name }), this.$t('status.success'))
                  this.$emit('subcategory-created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
