<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
             @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center">{{ $t(`modifier.title.${edit ? 'update' : 'create'}`, {name: form.name}) }}</h3>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form ref="modForm" :model="form" :rules="rules" label-width="100px" size="large" status-icon>
          <el-form-item label="Name" prop="name">
            <el-input v-model="form.name" ref="input"></el-input>
          </el-form-item>
          <el-form-item class="ml-2">
            <el-card class="box-card">
              <div v-for="(option, index) in form.options" :key="index" class="text item"
                   @dragstart="handleDragStart(index, $event)" @drop="handleDrop(index, $event)" @dragover.prevent>
                <el-row :gutter="5" draggable="true">
                  <el-col :span="1">
                    <el-button icon="el-icon-move" type="text" draggable="true"/>
                  </el-col>
                  <el-col :span="3">
                    <el-tooltip class="item" effect="light" content="Drag to reorganise" placement="bottom-start"
                                :open-delay="300" :disabled="form.options.length <= 1">
                      <el-button icon="el-icon-sort" :disabled="form.options.length <= 1"></el-button>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="10">
                    <el-input :ref="'option-' + index" v-model="option.name"
                              :placeholder="$t('modifier.label.option_name')"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input v-model="option.price" :placeholder="$t('modifier.label.price')" type="number"></el-input>
                  </el-col>
                  <el-col :span="1" class="ml-3" align="end">
                    <el-button type="danger" @click="removeOption(index)" icon="el-icon-delete"
                    ></el-button>
                  </el-col>
                </el-row>
              </div>
              <el-button type="success" @click="addOption" class="mt-3 ml-2" plain>
                {{ $t(`modifier.button.add_option`) }}
                <i class="el-icon-plus"></i>
              </el-button>
            </el-card>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" :offset="6">
        <el-button @click="$emit('close', false)">Cancel</el-button>
        <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
          {{ $t(`modifier.button.${edit ? 'update' : 'create'}`) }}
        </el-button>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
export default {
  name: 'createModifier',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    modifier: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: '',
        options: []
      },
      rules: {
        name: [
          { required: true, message: 'Please specify the modifier name', trigger: ['change', 'blur'] },
          { min: 3, message: 'Length should be at least 3', trigger: ['change', 'blur'] }
        ]
      },
      loading: false,
      draggedIndex: null
    }
  },
  computed: {
    submittable () {
      return !Object.keys(this.form).filter(k => (typeof this.form[k] !== 'boolean') && !this.form[k]).length
    },
    edit () {
      return this.modifier && this.modifier.id
    }
  },
  methods: {
    async opened () {
      if (this.modifier && this.modifier.id) {
        this.form.name = this.modifier.name
        await this.$modifierService.getModifierOptions(this.modifier.id)
          .then(data => {
            if (data.length > 0) {
              const options = []
              for (const option of data) {
                options.push({
                  id: option.id,
                  name: option.name,
                  price: option.price,
                  modifier_id: option.modifier_id
                })
              }
              this.form.options = options
            }
          })
      } else {
        this.form.options = [{ name: '', price: null }]
      }
      this.$refs.input.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = ''
      })
      this.loading = false
    },
    addOption () {
      this.form.options.push({
        name: '',
        price: ''
      })
      this.$nextTick(_ => {
        this.$refs[`option-${this.form.options.length - 1}`][0].$refs.input.focus()
      })
    },
    removeOption (index) {
      this.form.options.splice(index, 1)
    },
    handleDragStart (index, event) {
      this.draggedIndex = index
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('text/plain', index)
    },
    handleDrop (index, event) {
      event.preventDefault()
      const draggedIndex = event.dataTransfer.getData('text/plain')
      if (draggedIndex !== null && draggedIndex !== index) {
        const draggedItem = this.form.options.splice(parseInt(draggedIndex), 1)[0]
        this.form.options.splice(index, 0, draggedItem)
        this.draggedIndex = null
      }
    },
    submitForm () {
      this.$refs.modForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`modifier.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$modifierService.updateModifier(this.modifier.id, this.form)
                .then(async data => {
                  if (this.form.options.length > 0) {
                    for (const option of this.form.options) {
                      if (option.name !== '') {
                        option.modifier_id = data.id
                        option.price = option.price !== '' ? option.price : 0
                        if (Object.prototype.hasOwnProperty.call(option, 'id')) {
                          await this.$modifierService.updateModifierOption(option.id, option)
                            .then(res => {
                            })
                        } else {
                          await this.$modifierService.createModifierOption(option)
                            .then(res => {
                            })
                        }
                      }
                    }
                  }
                  this.notify(this.$t('modifier.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              const data = {
                name: this.form.name,
                options: this.form.options.filter(option => (!!option.name))
              }
              this.$modifierService.createModifier(data)
                .then(async data => {
                  /* if (this.form.options.length > 0) {
                    for (const option of this.form.options) {
                      if (option.name !== '') {
                        option.modifier_id = data.id
                        option.price = option.price !== '' ? option.price : 0
                        await this.$modifierService.createModifierOption(option)
                          .then(res => {
                          })
                      }
                    }
                  } */
                  this.notify(this.$t('modifier.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
