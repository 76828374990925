export default class SessionService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getAllSessions (with_users = false) {
    return new Promise((resolve, reject) => {
      this.$http.get('sessions', { params: { with_users } })
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getSessionableUsers () {
    return new Promise((resolve, reject) => {
      this.$http.get('sessions/sessionable-users')
        .then(res => res.data)
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
  }

  getSessions (dep, perPage, page) {
    return new Promise((resolve, reject) => {
      this.$http.get(`sessions/${dep}`, { params: { perPage, page } })
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createSession (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('sessions/store', data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  closeUserSession ($id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`sessions/close/${$id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  generateReport (data) {
    return new Promise((resolve, reject) => {
      this.$http.get('sessions/report/generate', { params: { sessions: data } })
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
  }
}
