<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <el-card class="box-card text-center">
          <div slot="header" class="clearfix">
            <h3>{{ $t('sale.name') }}</h3>
          </div>
          <el-form inline label-position="top" size="mini" :model="query" class="">
            <el-form-item label="Session">
              <el-select clearable collapse-tags v-model="query.session" placeholder="">
                <el-option v-for="(sess, ind) in sessions" :key="sess.id" :value="sess.id"
                           :label="sess.user.name + ' (' + ind + ')'">
                  <span style="float: left">
                     <span :class="sess.status ? 'text-success':'text-danger'">
                      <i class="mdi mdi-circle-small"></i>
                    </span> {{ sess.user.username }}
                  </span>&nbsp;&nbsp;
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                      sess.created_at | date(true, 'Do/MMM')
                    }} -
                    <span v-if="sess.closed_on">{{ sess.closed_on | date(true, 'Do/MMM') }}</span></span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Start Date">
              <el-date-picker v-model="query.start_date"></el-date-picker>
            </el-form-item>
            <el-form-item label="End Date">
              <el-date-picker v-model="query.end_date"></el-date-picker>
            </el-form-item>
            <el-form-item label="Cashiers">
              <el-select clearable collapse-tags multiple v-model="query.cashiers" placeholder="">
                <el-option v-for="(csh, ind) in cashiers" :key="csh.id" :label="csh.name"
                           :value="csh.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Customers">
              <el-select clearable collapse-tags multiple v-model="query.customers" placeholder="">
                <el-option v-for="(cus, ind) in customers" :key="cus.id" :label="cus.name"
                           :value="cus.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Items">
              <el-select clearable collapse-tags multiple v-model="query.items" placeholder="">
                <el-option v-for="(item, ind) in items" :key="item.id" :label="item.name"
                           :value="item.id">
                  <span style="float: left">{{ item.code }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Categories">
              <el-select clearable collapse-tags multiple v-model="query.categories">
                <el-option v-for="(category, ind) in categories" :key="category.id" :label="category.name"
                           :value="category.id">{{ category.name }}
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Has Debts?">
              <el-checkbox v-model="query.has_debt"></el-checkbox>
            </el-form-item>

            <el-form-item label="Has Discount?">
              <el-checkbox v-model="query.has_discount"></el-checkbox>
            </el-form-item>

            <el-form-item label="Print?">
              <el-checkbox v-model="query.print"></el-checkbox>
            </el-form-item>

            <el-form-item class="">
              <el-button @click="fetchSales" :loading="loading" type="primary" click="">Query</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <el-row type="flex" justify="space-between">
              <el-col :span="20">
                <h5 class="m-b-0">
                  <i class="mdi mdi-room-service"></i> {{ $t('sale.name') }}
                </h5>
              </el-col>
              <el-col :span="4">
                <el-button v-if="selections.length" @click="printSale(selections.map(s => s.id))" icon="mdi mdi-printer"
                           type="primary" size="mini">Print Selected ({{ selections.length }})</el-button>
              </el-col>
            </el-row>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <el-table @selection-change="setSelection" :data="sales" style="width: 100%" size="mini" show-summary :summary-method="getSummaries">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="code" label="Ref" width="75px">
                  <template slot-scope="scope">
                    <small>{{ scope.row.code }}</small>
                  </template>
                </el-table-column>
                <el-table-column v-if="query.customers.length" show-overflow-tooltip prop="customer.name"
                                 label="Cust"></el-table-column>
                <el-table-column align="center" prop="items_count" label="#" width="40px">
                  <template slot="header" slot-scope="scope">
                    <el-tooltip class="item" effect="dark" content="Article count" placement="top">
                      <span>#</span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">{{ scope.row.items_count | currency('') }}</template>
                </el-table-column>
                <el-table-column align="left" prop="items" label="Art" min-width="120px">
                  <template slot-scope="scope">
                    <span>{{ scope.row.items.map(i => i.item.name + `(${i.quantity})`).join(', ') }}</span>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="cashier.name" label="Cashier"></el-table-column>
                <el-table-column show-overflow-tooltip prop="service.name" label="Service"></el-table-column>
                <el-table-column prop="total_price" align="center" label="Total">
                  <template slot-scope="scope">{{ scope.row.total_price | currency('') }}</template>
                </el-table-column>
                <el-table-column prop="paid" align="center" label="Paid">
                  <template slot-scope="scope">{{ scope.row.paid | currency('') }}</template>
                </el-table-column>
                <el-table-column prop="debt" align="center" label="Bal">
                  <template slot-scope="scope">{{ scope.row.debt | currency('') }}</template>
                </el-table-column>
                <el-table-column prop="discount" align="center" label="Disc">
                  <template slot-scope="scope">{{ scope.row.discount | currency('') }}</template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="Date">
                  <template slot-scope="scope">{{ scope.row.created_at | datetime(true) }}</template>
                </el-table-column>
                <el-table-column :label="$t('label.action')">
                  <template slot-scope="scope">
                    <el-button @click="printSale(scope.row.id)" size="mini" icon="mdi mdi-printer"/>
                  </template>
                </el-table-column>
              </el-table>
              <div class="text-center pt-3">
                <el-pagination :current-page.sync="query.page"
                               background :page-size.sync="query.per_page"
                               layout="prev, pager, next, sizes"
                               :total="total" @current-change="fetchSales" @size-change="fetchSales">
                </el-pagination>
                <!--                <span style="float: right;">
                                  <el-button :loading="loading" type="primary" @click="fetchSales(true)" :disabled="!sales.length"
                                             icon="el-icon-printer">{{ $t('button.print') }}
                                  </el-button>
                                </span>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      query: {
        page: 1,
        session: [],
        cashiers: [],
        customers: [],
        items: [],
        categories: [],
        per_page: 10,
        has_debt: false,
        has_discount: false,
        start_date: null,
        end_date: null,
        print: false
      },
      sales: [],
      total: 0,
      cashiers: [],
      sessions: [],
      items: [],
      categories: [],
      customers: [],
      loading: false,
      selections: []
    }
  },
  methods: {
    fetchSales () {
      this.loading = true
      this.$saleService.getSales(this.query)
        .then(data => {
          if (data.url) {
            this.openUrlInBrowser(data.url)
          }
          return data.data
        })
        .then(data => {
          this.total = data.total
          this.sales = data.data
        })
        .catch(this.globalErrHandle)
        .then(() => (this.loading = false))
    },
    fetchSessions () {
      this.$sessionService.getAllSessions(true)
        .then(data => {
          this.sessions = data
        })
    },
    fetchCustomers () {
      this.$customerService.getCustomers()
        .then(data => {
          this.customers = data
        })
    },
    fetchCashiers () {
      return new Promise((resolve, reject) => {
        this.$userService.getCashiers()
          .then(data => {
            this.cashiers = data
            resolve()
          })
      })
    },
    fetchItems () {
      this.$itemService.getItems()
        .then(data => {
          this.items = data
        })
    },
    fetchCategories () {
      this.$categoryService.getCategories()
        .then(data => {
          this.categories = data
        })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Totals'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = this.$currency(values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0))
        } else {
          sums[index] = 'N/A'
        }
      })
      return sums
    },
    printSale (saleIds) {
      if (!Array.isArray(saleIds)) saleIds = [saleIds]
      this.loading = true
      this.$saleService.printSalesRecord(saleIds)
        .then(data => {
          if (data.url) {
            this.openUrlInBrowser(data.url)
          }
        })
        .catch(this.globalErrHandle)
        .then(() => (this.loading = false))
    },
    setSelection (selections) {
      this.selections = selections
    }
  },
  async mounted () {
    this.$emit('parent-title', this.$t('sale.name'))
    this.fetchCashiers()
      .then(async () => {
        await this.fetchCustomers()
        await this.fetchSessions()
        await this.fetchItems()
        await this.fetchCategories()
        await this.fetchSales()
      })
  },
  created () {
  }
}
</script>

<style scoped>

</style>
