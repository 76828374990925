<template>
  <div class="tab-pane fade active show" id="tab-reg" role="tabpanel"
       aria-labelledby="home-tab">
    <strong class="p-b-2 mb-5 h5">{{ $t('receipt.name') }}</strong>
    <div class="mt-4">
      <el-input v-model="data.title" placeholder="Enter a receipt label">
        <el-button slot="append" type="primary" size="mini" :loading="loading" :disabled="loading" @click.prevent="createReceipt"
                   icon="el-icon-plus">
          {{ $t('receipt.button.create') }}
        </el-button>
      </el-input>
    </div>
    <hr>
    <h4 class="line-head">Manage Receipts</h4>
    <el-row :gutter="12">
      <el-col :span="6" v-for="receipt in receipts" :key="receipt.id">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ receipt.title }}</span>
          </div>
          <el-button size="mini" type="text" @click.prevent="deleteReceipt(receipt.id)" :disabled="receipt.system">
            <i class="mdi mdi-delete"></i> {{ $t('button.delete') }}
          </el-button>
          <el-button size="mini" type="text" :class="receipt.active ? 'text-warning':'text-success'"
                     @click.prevent="changeStatus(receipt.id)" :icon="receipt.active ? 'el-icon-close':'el-icon-check'">
            <span v-if="parseInt(receipt.active)">{{ $t('button.deactivate') }}</span>
            <span v-else>{{ $t('button.activate') }}</span>
          </el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'SalesReceipts',
  data () {
    return {
      data: {
        title: ''
      },
      receipts: [],
      loading: false
    }
  },
  methods: {
    getReceipts () {
      this.$receiptService.getReceipts()
        .then(data => {
          this.receipts = data
        })
    },
    changeStatus (id) {
      this.$receiptService.changeStatus(id)
        .then(data => {
          this.notify(this.$t('receipt.message.status'))
          this.getReceipts()
        })
    },
    deleteReceipt (id) {
      this.askQuestion(this.$t('receipt.confirm.delete'), () => {
        this.$receiptService.deleteReceipt(id)
          .then(data => {
            this.notify(this.$t('receipt.message.deleted'))
            this.getReceipts()
          })
          .catch(this.globalErrHandle)
      })
    },
    createReceipt () {
      this.askQuestion(this.$t('receipt.confirm.delete'), () => {
        this.loading = true
        this.$receiptService.saveReceipt(this.data)
          .then(data => {
            this.notify(this.$t('receipt.message.created'))
            this.getReceipts()
            this.data.title = ''
          })
          .catch(this.globalErrHandle)
          .then(() => {
            this.loading = false
          })
      })
    }
  },
  mounted () {
    this.getReceipts()
  }
}
</script>

<style scoped>

</style>
