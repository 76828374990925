<template>
  <aside class="admin-sidebar">
    <div class="admin-sidebar-brand">
      <!-- begin sidebar branding-->
      <img class="admin-brand-logo" src="@/assets/logo/icon-geebung.png" width="40" alt="slm Logo">
      <span class="admin-brand-content font-secondary">
                <router-link :to="{name: 'dashboard'}">  Salamanda</router-link>
            </span>
      <!-- end sidebar branding-->
      <div class="ml-auto">
        <!-- sidebar pin-->
        <a href="#" class="admin-pin-sidebar btn-ghost btn btn-rounded-circle"></a>
        <!-- sidebar close for mobile device-->
        <a href="#" class="admin-close-sidebar"></a>
      </div>
    </div>
    <div class="admin-sidebar-wrapper js-scrollbar">
      <ul class="menu">
        <li class="menu-item">
          <router-link :to="{name: 'dashboard'}" class=' menu-link' active-class="link-is-active">
            <span class="menu-label">
                <span class="menu-name">Dashboard</span>
            </span>
            <span class="menu-icon">
                 <i class="icon-placeholder mdi mdi-monitor-dashboard "></i>
            </span>
          </router-link>
        </li>
        <li class="menu-item ">
          <a href="" class="open-dropdown menu-link">
            <span class="menu-label">
                <span class="menu-name">{{ $t('link.structure') }}
                    <span class="menu-arrow"></span>
                </span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder mdi mdi-finance "></i>
            </span>
          </a>
          <!--submenu-->
          <ul class="sub-menu">
            <li class="menu-item" v-can="'item.read'">
              <router-link :to="{name: 'items'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.article') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-shopping"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'warehouse.read'">
              <router-link :to="{name: 'warehouses'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.warehouse') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-store-24-hour"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'category.read'">
              <router-link :to="{name: 'categories'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.family') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-group"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'department.read'">
              <router-link :to="{name: 'departments'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.department') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder el-icon-office-building"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'supply.read'">
              <router-link :to="{name: 'supplies'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.supply') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-account-network"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'account.read-category'">
              <router-link :to="{name: 'category'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.acc_category') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-cart-plus"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'modifier.read'">
              <router-link :to="{name: 'modifiers'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.modifier') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-needle"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'variant.read'">
              <router-link :to="{name: 'variants'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.variant') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-nfc-variant"></i>
                </span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="menu-item ">
          <a href="" class="open-dropdown menu-link">
            <span class="menu-label">
                <span class="menu-name">{{ $t('link.management') }}
                    <span class="menu-arrow"></span>
                </span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder mdi mdi-briefcase-account"></i>
            </span>
          </a>
          <!--submenu-->
          <ul class="sub-menu">
            <li class="menu-item" v-can="'user.read'">
              <router-link :to="{name: 'users'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.user') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-shopping"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'session.read'">
              <router-link :to="{name: 'sessions'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.session') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-timer"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'employee.read'">
              <router-link :to="{name: 'employees'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.employee') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-account-group"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'customer.read'">
              <router-link :to="{name: 'customers'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.customer') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-account-supervisor"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'supplier.read'">
              <router-link :to="{name: 'suppliers'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.supplier') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-account-network"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'service.read'">
              <router-link :to="{name: 'services'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.service') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-room-service"></i>
                </span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="menu-item ">
          <a href="" class="open-dropdown menu-link">
            <span class="menu-label">
                <span class="menu-name">{{ $t('link.sale') }}
                    <span class="menu-arrow"></span>
                </span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder mdi mdi-shopping"></i>
            </span>
          </a>
          <!--submenu-->
          <ul class="sub-menu">
            <li class="menu-item" v-can="'invoice.read'">
              <router-link :to="{name: 'sales'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.sale') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-cart-arrow-right"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link :to="{name: 'sales'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.sales_order') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-orbit"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link :to="{name: 'payment-methods'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.payment_method') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-square-inc-cash"></i>
                </span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="menu-item ">
          <a href="" class="open-dropdown menu-link">
            <span class="menu-label">
                <span class="menu-name">{{ $t('link.accounting') }}
                    <span class="menu-arrow"></span>
                </span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder mdi mdi-finance"></i>
            </span>
          </a>
          <!--submenu-->
          <ul class="sub-menu">
            <li class="menu-item" v-can="'deposit.read'">
              <router-link :to="{name: 'deposit'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.deposit') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-account-plus"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'withdrawal.read'">
              <router-link :to="{name: 'withdrawal'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.withdrawal') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-account-minus"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'debt.read'">
              <router-link :to="{name: 'debts'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.debt') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-cash-register"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'payroll.read'">
              <router-link :to="{name: 'salaries'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.salary') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-square-inc-cash"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'discount.read'">
              <router-link :to="{name: 'discounts'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.discount') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-percent"></i>
                </span>
              </router-link>
            </li>
            <li class="menu-item" v-can="'tax.read'">
              <router-link :to="{name: 'taxes'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.tax') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-cash-multiple"></i>
                </span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="menu-item ">
          <a href="" class="open-dropdown menu-link">
            <span class="menu-label">
                <span class="menu-name">{{ $t('link.authorisation') }}
                    <span class="menu-arrow"></span>
                </span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder mdi mdi-server-security"></i>
            </span>
          </a>
          <!--submenu-->
          <ul class="sub-menu">
            <li class="menu-item" v-can="'role.read'">
              <router-link :to="{name: 'roles'}" class=' menu-link'>
                <span class="menu-label">
                    <span class="menu-name">{{ $t('link.role') }}</span>
                </span>
                <span class="menu-icon">
                    <i class="icon-placeholder mdi mdi-security"></i>
                </span>
              </router-link>
            </li>
          </ul>
        </li>

        <li class="menu-item" v-can="'report.read'">
          <router-link :to="{name: 'reports'}" class=' menu-link' active-class="link-is-active">
            <span class="menu-label">
              <span class="menu-name">{{ $t('link.report') }}</span>
            </span>
            <span class="menu-icon">
              <i class="icon-placeholder mdi mdi-chart-bell-curve"></i>
            </span>
          </router-link>
        </li>

        <li class="menu-item" v-can="'setting.read'">
            <router-link :to="{name: 'config_org'}" class=' menu-link' active-class="link-is-active">
                <span class="menu-label">
                    <span class="menu-name">{{ $t('setting.name') }}</span>
                </span>
                <span class="menu-icon">
                     <i class="icon-placeholder mdi mdi-settings"></i>
                </span>
            </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'Sidebar',
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  watch: {
    $route (route) {
      this.routerManager()
      // if (!window.$('li.menu-item').hasClass('active')) {
      //   window.$('.link-is-active').parent('li').addClass('active')
      // }
    }
  },
  methods: {
    routerManager () {
    }
  },
  mounted () {
    // Only allow one expanded tab at a time (collapse all others)
    window.$('.menu-item > a.open-dropdown').click(function (evt) {
      for (const elt of window.$('li.menu-item.opened > a.open-dropdown').toArray()) {
        if (!window.$(elt).is(window.$(this))) window.$(elt).click()
      }
    })
    // Activate tab if child link is active
    if (!window.$('li.menu-item.opened > a.open-dropdown').toArray().length &&
        window.$('a.menu-link').hasClass('open-dropdown')) {
      if (window.$('a.router-link-active').length) {
        window.$('a.router-link-active')
          .parents('ul.sub-menu:first').css({ display: 'block' })
          .parents('li:first').addClass('opened')
      }
    }
    // if (!window.$('li.menu-item').hasClass('active')) {
    //   window.$('.link-is-active').parent('li').addClass('active')
    // }
    for (const smo of window.$('ul.sub-menu')) {
      if (!window.$(smo).find('li.menu-item').length) {
        window.$(smo).parent('li:first').remove()
      }
    }
  }
}
</script>

<style scoped>
  .link-is-active {}
</style>
