export default class DepartmentService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getDepartments () {
    return new Promise((resolve, reject) => {
      this.$http.get('departments')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getDepartmentsWithWarehouseItem (itemId) {
    return new Promise((resolve, reject) => {
      this.$http.get(`departments/contains-warehouse-item${itemId ? ('/' + itemId) : ''}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getAllDepartments () {
    return new Promise((resolve, reject) => {
      this.$http.get('departments/all')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createDepartment (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('departments/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          else resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateDepartment (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`departments/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          else resolve(data.data)
        })
        .catch(reject)
    })
  }

  setWarehouse (whID, deptId) {
    return new Promise((resolve, reject) => {
      this.$http.post(`departments/${deptId}/set-warehouse/${whID}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          else resolve(data.data)
        })
        .catch(reject)
    })
  }

  archiveDepartment (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`departments/${id}/archive`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          else resolve(data.data)
        })
        .catch(reject)
    })
  }

  changeWarehouse (deptId, whId) {
    return new Promise((resolve, reject) => {
      this.$http.post(`departments/${deptId}/warehouse/${whId}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          else resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateDepartmentSettings (deptId, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`departments/${deptId}/settings`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          else resolve(data.data)
        })
        .catch(reject)
    })
  }
}
