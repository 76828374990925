<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('supplier.payments') }}
                                </h5>
                            </el-col>
                            <el-col :span="12" class="text-right">
                                <el-button @click.prevent="displayPayment = true" icon="el-icon-s-finance" size="medium">
                                    {{$t('supplier.button.make_payment')}}
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-center">
                            <el-table :data="payments" class="text-center" style="width: 100%">
                                <el-table-column
                                        type="index"
                                        label="S/N">
                                </el-table-column>
                                <el-table-column
                                        prop="amount"
                                        :label="$t('supplier.table.amount')"
                                        width="180">
                                    <template slot-scope="scope">
                                        <span class="currency">
                                            {{ scope.row.amount | currency('XAF')}}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="user.name"
                                        :label="$t('supplier.table.user')">
                                </el-table-column>
                                <el-table-column width="220"
                                        prop="message"
                                        :label="$t('supplier.table.message')">
                                </el-table-column>
                                <el-table-column
                                        prop="created_at"
                                        :label="$t('supplier.table.date')"
                                        :formatter="$shortDate"
                                        width="180">
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <make-payment-modal :supplier-id="supplierId" :display="displayPayment"
                            @updated="paymentCreated" @close="closePaymentDialog"></make-payment-modal>
    </div>

</template>

<script>
import MakePaymentModal from './MakePaymentModal'

export default {
  name: 'Payments',
  components: { MakePaymentModal },
  data () {
    return {
      payments: [],
      displayPayment: false
    }
  },
  computed: {
    supplierId () {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    makePayment (index) {
    },
    paymentCreated (data) {
      this.payments.splice(0, 0, data)
      this.$emit('refresh-supplier')
    },
    closePaymentDialog (status = false) {
      this.displayPayment = status
    }
  },
  mounted () {
    this.$supplierService.getSupplierPayment(this.$route.params.id)
      .then(data => {
        this.payments = data
      })
  }
}
</script>

<style scoped>

</style>
