<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('item.button.item') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="items.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.code.toLowerCase().includes(search.toLowerCase()))"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                              <!--<el-table-column label="S/N" type="index" sortable fixed></el-table-column>-->
                              <el-table-column label="Photo" align="center" min-width="50" fixed>
                                <template slot-scope="scope">
                                  <div v-if="scope.row.visual_type === 'colour'" style="background-color: #00a513; height: 36px; width: 36px; border-radius: 5px">
                                  </div>
                                  <el-avatar fit="cover" v-else size="medium" :src="scope.row.image"></el-avatar>
                                </template>
                              </el-table-column>
                              <el-table-column show-overflow-tooltip prop="code" :label="$t('label.ref')" fixed>
                                <template slot-scope="scope"><strong>{{ scope.row.code }}</strong></template>
                              </el-table-column>
                              <el-table-column show-overflow-tooltip prop="name" :label="$t('label.name')" fixed>
                              </el-table-column>
                              <el-table-column show-overflow-tooltip prop="category.name" label="Cat">
                              </el-table-column>
                              <el-table-column label="U.P" min-width="100" fixed>
                                  <template slot-scope="scope">
                                      <span class="currency">{{ scope.row.unit_price | currency(' XAF') }}</span>
                                  </template>
                              </el-table-column>
                              <el-table-column label="P.P">
                                <template slot-scope="scope">
                                  <span class="currency">{{ scope.row.purchase_price | currency(' XAF') }}</span>
                                </template>
                              </el-table-column>
                              <el-table-column prop="unit_type" label="Unit"></el-table-column>
                                <el-table-column show-overflow-tooltip prop="item_type" label="Type">
                                  <slot slot-scope="scope">
                                    <el-tag type="success" size="mini">{{ scope.row.item_type.toUpperCase() }}</el-tag>
                                  </slot>
                                </el-table-column>
                                <el-table-column :label="$t('label.action')" min-width="100px">
                                    <template v-slot:header="scope">
                                        <el-input ref="searchField" v-model="search" size="mini" autofocus placeholder="Type to search"/>
                                    </template>
                                    <template v-slot:default="scope">
                                        <el-dropdown v-can.event="'item.update'" @click="showEditDialog(scope.row.id)" split-button type="primary" size="small" trigger="click">
                                            <i class="el-icon-edit"></i> {{ $t('button.edit') }}
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-can.disable="'item.archive'" @click.native="beforeArchive(scope.row.id)">
                                                    <i class="mdi mdi-archive"></i> {{ $t('button.archive') }}
                                                </el-dropdown-item>
                                                <el-dropdown-item v-can.disable="'item.stock'" @click.native="$router.push({name: 'stock-item', params: {item_id: scope.row.id}})">
                                                    <i class="mdi mdi-information"></i> {{ $t('item.button.stock') }}
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-edit-item :display="displayEditDialog" @close="closeEditDialog" @created="newItem"
                          @updated="itemUpdated" :item="item"></create-edit-item>
    </div>
</template>

<script>
import CreateEditItem from './Modal/CreateEditItem'
export default {
  name: 'Index',
  components: { CreateEditItem },
  data () {
    return {
      items: [],
      item: {},
      displayEditDialog: false,
      search: ''
    }
  },
  methods: {
    showEditDialog (id) {
      this.item = this.items.find(d => d.id === id)
      this.displayEditDialog = true
    },
    closeEditDialog () {
      this.item = {}
      this.displayEditDialog = false
    },
    newItem (data) {
      this.items.splice(0, 0, data)
    },
    itemUpdated (data) {
      this.items.splice(this.items.indexOf(this.items.find(d => d.id === data.id)), 1, data)
    },
    beforeArchive (id) {
      this.askQuestion(this.$t('item.confirm.archive'), () => {
        this.$itemService.archiveItem(id)
          .then(() => {
            this.notify(this.$t('item.message.archived'))
            this.items.splice(this.items.indexOf(this.items.find(d => d.id === id)), 1)
          })
      })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('item.title.item'))
    this.$itemService.getItems()
      .then(data => {
        this.items = data
      })
    setTimeout(() => this.$nextTick(this.$refs.searchField.focus), 200)
  },
  created () {
    this.$parent.$on('new-item', this.newItem)
  }
}
</script>

<style scoped>

</style>
