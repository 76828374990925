<template>
    <el-dialog label-width="120px" fullscreen :visible="display"
               @closed="closed" @close="$emit('close')" @opened="opened" :destroy-on-close="true">
        <slot name="title">
            <h3 class="text-center">{{ $t(`payment_method.title.${edit ? 'edit' : 'create'}`, [payment_method.name]) }}</h3>
            <hr>
        </slot>
        <el-row>
            <el-col :span="12" :offset="6">
                <el-form label-position="top" :model="form" size="medium" :rules="rules" ref="methodForm">
                    <el-form-item :label="$t('payment_method.label.name')" label-width="100" prop="name">
                        <el-input @keyup.enter.prevent="submitForm" ref="input" v-model="form.name" autocomplete="off"
                          @focus="$event.target.select()"/>
                    </el-form-item>
                </el-form>
            </el-col>

        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close')">Cancel</el-button>
                <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
                    {{ $t(`payment_method.button.${edit ? 'update' : 'create'}`) }}
                </el-button>
            </el-col>
        </el-row>

    </el-dialog>
</template>

<script>
export default {
  name: 'CreateEditPaymentMethod',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    payment_method: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: this.$t('validation.required'), trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: {
    submittable () {
      return !!this.form.name.length
    },
    edit () {
      return !!this.payment_method.id
    }
  },
  methods: {
    opened () {
      if (this.payment_method.id) {
        this.form.name = this.payment_method.name
      }
      this.$nextTick(() => this.$refs.input.focus())
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = ''
      })
      this.loading = false
    },
    submitForm () {
      this.$refs.methodForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`payment_method.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$paymentMethodService.updateMethod(this.payment_method.id, this.form)
                .then(data => {
                  this.notify(this.$t('payment_method.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$paymentMethodService.createMethod(this.form)
                .then(data => {
                  this.notify(this.$t('payment_method.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
