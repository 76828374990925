<template>
    <el-dialog ref="debtTypeForm"
               :visible="visible" @closed="closed"
               lock-scroll :close-on-click-modal="false" :show-close="false" @open="open" fullscreen>
        <template slot="title">
            <h3 v-if="debt.employee" class="text-center">Payment History of {{ this.debt.amount | currency('XAF') }} by
                {{ debt.employee.name }} On {{ debt.date | date }}</h3>
            <hr>
        </template>
        <el-row>
            <el-col :span="12" :offset="6">
                <div class="table-responsive">
                    <data-tables
                            :data="data"
                            :pagination-props="{ pageSizes: [20, 30, 40, 100], pageSize: 20 }"
                            center-align>
                        <template slot="empty" class="is-warning">
                            <h4><strong>{{ $t('message.no_data') }}</strong> </h4>
                        </template>
                        <el-table-column in label="S/N" type="index" fixed></el-table-column>
                        <el-table-column prop="amount" label="Amount" min-width="100" fixed>
                            <template slot-scope="scope">
                                <span class="currency">{{ scope.row.amount | currency('XAF') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="date" :formatter="$shortDate" label="Date"
                                         min-width="100" fixed></el-table-column>
                        <el-table-column prop="initial" label="Initial" min-width="100" fixed>
                            <template slot-scope="scope">
                                <span class="currency">{{ scope.row.initial | currency('XAF') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="balance" label="Balance" min-width="100" fixed>
                            <template slot-scope="scope">
                                <span class="currency">{{ scope.row.balance | currency('XAF') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="user" label="User" min-width="100" fixed>
                            <template slot-scope="scope">
                                <span class="currency">{{ scope.row.user.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="message" label="Message" min-width="100" fixed>
                            <template slot-scope="scope">
                                {{ scope.row.message }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" label="Created" min-width="100" :formatter="$shortDate"
                                         fixed></el-table-column>
                    </data-tables>
                </div>
            </el-col>
        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close')">Close</el-button>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
export default {
  name: 'PaymentHistory',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    debt: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      data: []
    }
  },
  watch: {},
  methods: {
    closed () {
    },
    open () {
      this.$debtService.getPaymentHistory(this.debt.id)
        .then(data => (this.data = data.data))
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
