<template>
    <div>
        <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
                   @closed="closed" @close="$emit('close', false)" :show-close="false">
            <slot name="title">
                <h3 class="text-center">
                    {{ $t('text.supplier_debit_title', {name: supplier.name}) }}
                </h3>
            </slot>
            <el-row>
                <el-col :span="12" :offset="6">
                    <el-form :model="form" :rules="rules">
                        <el-form-item label="Cash-in amount" label-width="100" prop="amount">
                            <el-input autofocus v-model.number="form.amount" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="Message" label-width="100" prop="message">
                            <el-input type="textarea" v-model="form.message"></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-row slot="footer" class="dialog-footer">
                <el-col :span="12" :offset="6">
                    <el-button @click="$emit('close', false)">Cancel</el-button>
                    <el-button :loading="loading" type="primary" @click="makePayment">Confirm
                    </el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'SupplierCashIn',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    supplierId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      form: {
        amount: null,
        message: ''
      },
      supplier: {},
      loading: false,
      rules: {
        amount: [
          { type: 'integer', required: true, trigger: 'blur' }
        ],
        message: [
          { required: true, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    makePayment () {
      this.askQuestion(this.$t('supplier.confirm.direct_debit', { supplier: this.supplier.name }), () => {
        this.$supplierService.cashIn(this.supplier.id, this.form)
          .then(data => {
            this.$emit('updated')
            this.$emit('close')
          })
          .catch(error => {
            this.displayMessage(error.response && error.response.data ? error.response.data.message : error.message,
              () => {
              }, 'error')
          })
          .then(() => (this.loading = false))
      })
    },
    closed () {
      this.form.amount = 0
      this.form.supplier = null
      this.form.date = null
    }
  },
  mounted () {
    this.$supplierService.findById(this.supplierId)
      .then(data => (this.supplier = data))
  }
}
</script>

<style scoped>

</style>
