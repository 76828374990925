<template>
  <div class="tab-pane fade active show" id="tab-reg" role="tabpanel"
       aria-labelledby="home-tab">
    <strong class="p-b-2 mb-5 h5">{{ $t('price_list.name') }}</strong>
    <el-table stripe :data="price_lists" style="width: 100%" header-cell-class-name="bg-secondary">
      <el-table-column :label="$t('price_list.label.description')"
                       prop="name" label-class-name="text-white" class-name="text-dark">
      </el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-tooltip :content="$t('price_list.button.add')">
            <el-button type="success" icon="el-icon-plus" @click.prevent="addPriceList(scope.$index+1)" size="small"
                       class="ml-2 mr-2" circle>
            </el-button>
          </el-tooltip>
          <el-tooltip :open-delay="300" class="item" effect="dark"
                      :content="$t('price_list.button.remove')"
                      placement="bottom">
            <el-button type="danger" @click.prevent="removePriceList(scope.row.id, scope.$index)"
                       :disabled="!!scope.row.system"
                       class="ml-2 mr-2" size="small" icon="el-icon-minus" circle>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <add-price-list :position="add_position" :display="show_add_dialog" @close="hideAddDialog"
                    @created="priceListCreated"></add-price-list>
  </div>
</template>

<script>
import AddPriceList from '../Dialogs/AddPriceList'

export default {
  name: 'PriceLists',
  components: { AddPriceList },
  data () {
    return {
      price_lists: [],
      loading: false,
      add_position: null,
      show_add_dialog: false
    }
  },
  methods: {
    addPriceList (position) {
      this.add_position = position
      this.showAddDialog()
    },
    showAddDialog () {
      this.show_add_dialog = true
    },
    hideAddDialog () {
      this.show_add_dialog = false
    },
    priceListCreated (data) {
      this.price_lists.splice(this.add_position, 0, data)
    },
    removePriceList (id, index) {
      this.askQuestion(this.$t('price_list.confirm.remove'), () => {
        this.$configService.removePriceList(id)
          .then(data => {
            if (data.status === 'success' || data.status === 200) {
              this.displayMessage(this.$t('price_list.message.removed'), () => {
                this.price_lists.splice(index, 1)
              }, 'success', this.$t('status.success'))
            } else {
              this.displayMessage(data.message, () => {
              }, data.status)
            }
          })
          .catch(this.globalErrHandle)
      })
    },
    update () {
      this.loading = true
      this.$configService.getPriceLists()
        .then(data => {
          console.log(data)
          if (data.status === 'success' || data.status === 200) {
            this.displayMessage(this.$t('status.success'))
          } else {
            this.displayMessage(data.message, () => {
            }, data.status)
          }
        })
        .catch(this.globalErrHandle)
        .then(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.$configService.getPriceLists()
      .then(data => {
        this.price_lists = data
      })
  }
}
</script>

<style scoped>

</style>
