export default class ConfigService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getConfig () {
    return new Promise((resolve, reject) => {
      this.$http.get('config')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  setConfig (data, mode) {
    return new Promise((resolve, reject) => {
      this.$http.post('config', { data, mode })
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getPriceLists () {
    return new Promise((resolve, reject) => {
      this.$http.get('price_lists')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  addPriceList (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('price_lists/create', data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  removePriceList (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`price_lists/remove/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  getDefaultMessages () {
    return new Promise((resolve, reject) => {
      this.$http.get('configs/default-messages')
        .then(res => res.data)
        .then(data => resolve(data.data))
        .catch(reject)
    })
  }

  setDefaultMessage (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`configs/default-messages/${id}`, data)
        .then(res => res.data)
        .then(data => resolve(data.data))
        .catch(reject)
    })
  }

  changeStatus (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`users/${id}/status`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  resetPassword (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`users/${id}/password-reset`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
