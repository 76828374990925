<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
            </h5>
          </div>
          <div class="card-body">
            <el-steps :active="active" finish-status="success" align-center>
              <el-step v-for="step in steps" :title="step.name" :icon="'el-icon-'+step.icon"
                       :key="step.slug"></el-step>
            </el-steps>
          </div>
        </div>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <!--<span v-if="payable" class="currency">Payable: {{ payable | currency('XAF') }}</span>-->
            <el-button :disabled="steps[active].slug !== 'items'" style="float: right; padding: 3px 0"
                       type="text" icon="el-icon-arrow-right el-icon-right" @click="nextStep">
              Next Step
            </el-button>
          </div>
          <div v-if="steps[active].slug === 'supplier'">
            <el-select v-model="data.supplier" filterable :placeholder="$t('supply.title.suppliers')" class="f-w"
            @change="nextStep">
              <el-option v-for="supplier in suppliers"
                  :key="supplier.id" :label="supplier.name" :value="supplier.id">
              </el-option>
            </el-select>
          </div>
          <div v-if="steps[active].slug === 'items'">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead class="">
                <tr>
                  <th scope="col" class="text-center">#</th>
                  <th scope="col" class="text-center">{{ $t('supply.table.title.item') }}</th>
                  <th scope="col" class="text-center">{{ $t('supply.table.title.quantity') }}</th>
                  <th scope="col" class="text-center">{{ $t('supply.table.title.purchase_price') }}</th>
                  <th scope="col" class="text-center">{{ $t('supply.table.title.total') }}</th>
                  <th scope="col" class="text-center">{{ $t('supply.table.title.action') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">#</td>
                  <td class="text-center">
                    <el-select @change="setItemPurchasePrice" v-model="item.id" filterable
                               :placeholder="$t('supply.title.items')" class="f-w">
                      <el-option v-for="item in uniqueItems" :key="item.id" :label="item.name"
                                 :value="item.id">
                        <span style="float: left">{{ item.name }}</span> &nbsp;
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.code }}</span>
                      </el-option>
                    </el-select>
                  </td>
                  <td class="text-center">
                    <el-input :placeholder="$t('supply.title.quantity')" v-model="item.quantity"></el-input>
                  </td>
                  <td class="text-center">
                    <el-input @keyup.enter.native="appendItem" :placeholder="$t('supply.table.title.purchase_price')"
                              v-model="item.unit_price">
                    </el-input>
                  </td>
                  <td class="text-center currency">
                    {{ item.unit_price * item.quantity | currency }}
                  </td>
                  <td class="text-center">
                    <el-button @click="appendItem" icon="el-icon-plus" size="mini" type="info">
                      {{ $t('supply.button.add') }}
                    </el-button>
                  </td>
                </tr>
                <tr v-for="(it, key) in data.items" :key="key">
                  <td class="text-center">{{ key + 1 }}</td>
                  <td class="text-center">{{ items.find(i => i.id === it.id).name }}</td>
                  <td class="text-center">{{ it.quantity | currency }}</td>
                  <td class="text-center">{{ it.unit_price | currency }}</td>
                  <td class="text-center">{{ it.unit_price * it.quantity | currency('XAF') }}</td>
                  <td class="text-center">
                    <el-popconfirm title="Are you sure to remove this?" :cancel-button-text="$t('button.cancel')"
                                   :confirm-button-text="$t('button.confirm')" @onConfirm="removeItem(key)">
                      <el-button slot="reference" icon="el-icon-minus" size="mini"> {{ $t('supply.button.remove') }}
                      </el-button>
                    </el-popconfirm>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="steps[active].slug === 'invoice'">
            <el-row class="p-b-25 p-t-25">
              <h2 class="text-center text-uppercase">{{ $t('supply.table.invoice') }}</h2>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead class="">
                  <tr>
                    <th class="text-center" scope="col">#</th>
                    <th class="text-center" scope="col">{{ $t('supply.table.title.item') }}</th>
                    <th class="text-center" scope="col">{{ $t('supply.table.title.quantity') }}</th>
                    <th class="text-center" scope="col">{{ $t('supply.table.title.unit_price') }}</th>
                    <th class="text-center" scope="col">{{ $t('supply.table.title.total_cost') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(it, key) in data.items" :key="key">
                    <td class="text-center">{{ key + 1 }}</td>
                    <td class="text-center">{{ items.find(i => i.id === it.id).name }}</td>
                    <td class="text-center">{{ it.quantity }}</td>
                    <td class="text-center">{{ it.unit_price | currency }}</td>
                    <td class="text-center">{{ (it.unit_price * it.quantity) | currency }}</td>
                  </tr>
                  <tr class="bg-light p-t-5">
                    <td colspan="4" class="f-18">
                      {{ $t('supply.table.title.total') }}
                    </td>
                    <td colspan="1" class="f-18 currency">
                      {{ payable | currency('XAF') }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </el-row>
          </div>
          <div v-if="steps[active].slug === 'payment'">
            <el-row :gutter="50" class="p-t-15 p-b-15">
              <el-col :span="8">
                <el-input class="f-w f-8" v-model="data.paid" placeholder=""
                          size="medium">
                  <template slot="prepend">{{ $t('supply.title.paid') }}</template>
                </el-input>
              </el-col>
              <el-col :span="8">
                <el-date-picker type="date" class="f-w f-8" v-model="data.date" :placeholder="$t('title.date')"
                          size="medium">
                </el-date-picker>
              </el-col>
              <el-col :span="8">
                <el-select class="f-w" v-model="data.warehouse" filterable
                           :placeholder="$t('supply.title.warehouse')">
                  <!--<template slot="prepend">{{ $t('supply.label.warehouse')}}</template>-->
                  <el-option v-for="warehouse in warehouses" :key="warehouse.id"
                             :label="warehouse.name" :value="warehouse.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="24">
                <el-row class="text-center">
                  <el-col :span="4" class="f-4">
                    {{ $t('supply.title.total') }}:
                    <span class="currency f-4">{{ payable | currency('XAF') }}</span>
                  </el-col>
                  <el-col :span="4" class="f-4">
                    {{ $t('supply.title.balance') }}:
                    <span class="currency f-4" v-if="data.paid">{{ temp.owed | currency('XAF') }}</span>
                    <span class="currency f-4" v-else>{{ payable | currency('XAF') }}</span>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
          <div class="bottom p-t-50">
            <el-row :gutter="20">
              <hr>
              <el-col :span="6">
                <el-button v-if="previousable" class="f-w" type="primary" icon="el-icon-arrow-left"
                           @click="prevStep">
                  {{ $t('supply.button.prev') }}
                </el-button>
              </el-col>
              <el-col :span="6" :offset="12" style="float: right;">
                <el-button v-if="nextable" class="f-w" type="primary" @click="nextStep">
                  {{ $t('supply.button.next') }} <i class="el-icon-arrow-right el-icon-right"></i>
                </el-button>
                <el-button :loading="loading" @click.prevent="addSupply" v-if="!nextable" class="f-w"
                           type="success"
                           icon="el-icon-check">
                  {{ $t('supply.button.confirm') }}
                </el-button>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddSupply',
  data () {
    return {
      active: 0,
      steps: [
        { name: 'Supplier', slug: 'supplier', icon: 'user' },
        { name: 'Items', slug: 'items', icon: 's-operation' },
        { name: 'Invoice', slug: 'invoice', icon: 'shopping-cart-full' },
        { name: 'Payment', slug: 'payment', icon: 's-finance' }
      ],
      suppliers: [],
      items: [],
      warehouses: [],
      item: {
        id: null,
        quantity: null,
        unit_price: null
      },
      data: {
        supplier: null,
        items: [],
        paid: null,
        warehouse: null,
        date: null
      },
      temp: {
        owed: null
      },
      loading: false
    }
  },
  watch: {
    'data.paid' (value) {
      this.temp.owed = this.data.items.map(i => i.unit_price * i.quantity).reduce((v1, v2) => v1 + v2) - value
    }
  },
  computed: {
    previousable () {
      return this.active > 0
    },
    nextable () {
      return this.active < (this.steps.length - 1)
    },
    uniqueItems () {
      return this.items.map(i => i.id)
        .filter(i => !this.data.items.map(ii => ii.id).includes(i))
        .map(ii => this.items.find(i => i.id === ii))
    },
    payable () {
      const prices = this.data.items.map(i => i.unit_price * i.quantity)
      return prices.length ? prices.reduce((v1, v2) => v1 + v2) : 0
    }
  },
  methods: {
    setItemPurchasePrice (id) {
      this.item.unit_price = this.items.find(i => i.id === id).purchase_price
    },
    addSupply () {
      this.askQuestion(this.$t('supply.create.confirm'), () => {
        this.loading = true
        this.$supplyService.createSupply(Object.assign({}, this.data))
          .then(data => {
            this.displayMessage(this.$t('supply.create.success'), () => {
              this.$router.push({ name: 'supplies' })
              this.loading = false
            })
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 403) {
                this.displayMessage(error.response.data.message, () => {
                }, 'error', this.$t('status.error'))
              } else {
                this.displayMessage(error.message, () => {
                }, 'error', this.$t('status.error'))
              }
            }
          })
          .then(_ => {
            this.loading = false
          })
      })
    },
    appendItem () {
      if (this.item.id && this.item.quantity && this.item.unit_price &&
          (!isNaN(parseInt(this.item.quantity))) &&
          (!isNaN(parseInt(this.item.unit_price)))) {
        const item = {
          id: this.item.id,
          quantity: parseInt(this.item.quantity),
          unit_price: parseInt(this.item.unit_price)
        }
        this.data.items.push(item)
        this.item.id = null
        this.item.quantity = null
        this.item.unit_price = null
      }
    },
    removeItem (index) {
      this.data.items.splice(index, 1)
    },
    nextStep () {
      if (++this.active > (this.steps.length - 1)) --this.active
    },
    prevStep () {
      if (--this.active < 0) ++this.active
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('supply.title.new_supply'))
    this.$supplierService.getSuppliers()
      .then(data => {
        this.suppliers = data
      })
    this.$itemService.getItems()
      .then(data => {
        this.items = data
      })
      .catch(this.globalErrHandle)
    this.$warehouseService.getWarehouses()
      .then(data => {
        this.warehouses = data
        this.data.warehouse = data.find(w => !!w.default).id
      })
  }
}
</script>

<style scoped>

</style>
