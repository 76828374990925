<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
<!--                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('employee.visualize.title') }}-->
                                </h5>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <h3 class="text-center">{{ $t('message.coming') }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Visualize',
  props: {
    employee: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
    setTimeout(() => {
      this.$emit('parent-title', this.$t('employee.visualize.title', { name: this.employee.name }))
    }, 1000)
  }
}
</script>

<style>
</style>
