<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <el-card class="box-card text-center">
          <div slot="header" class="clearfix">
            <h3>{{ $t('report.inventory.name') }}</h3>
          </div>
          <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="Warehouse">
              <el-select collapse-tags multiple v-model="form.warehouses" placeholder="Choose Warehouse">
                <el-option v-for="(wh, ind) in warehouses" :key="ind" :label="wh.name"
                           :value="wh.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="form.zero_items">Zero Items?</el-checkbox>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="form.expected_sales">Sales?</el-checkbox>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="form.dl">Download</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" type="primary" @click="onReportSubmit">Query</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
    <br>
    <el-row>
      <el-card class="box-card">
        <div class="table-responsive">

          <table class="table table-hover ">
            <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Purchase Price</th>
              <th>T. Purchase</th>
              <th v-if="form.expected_sales">Selling Price</th>
              <th v-if="form.expected_sales">T. Sales</th>
              <th v-if="form.expected_sales" class="h6">N. Profit</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(items, category) in inventories">
              <tr>
                <td align="center" class="text-dark bg-black-translucent" :colspan="form.expected_sales? 8:5">
                  {{ category }}
                </td>
              </tr>
              <tr v-for="(item, index) in items">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity | currency }}</td>
                <td>{{ item.purchase_price | currency(' XAF') }}</td>
                <td>{{ item.purchase_price * item.quantity | currency(' XAF') }}</td>
                <td v-if="form.expected_sales">{{ item.selling_price | currency(' XAF') }}</td>
                <td v-if="form.expected_sales">{{ item.selling_price * item.quantity | currency(' XAF') }}</td>
                <td v-if="form.expected_sales" class="h6">
                  {{ ((item.selling_price * item.quantity) - (item.purchase_price * item.quantity)) | currency(' XAF') }}
                </td>
              </tr>
              <tr class="h6">
                <td colspan="4" align="center" class="text-dark">{{ category }} Total</td>
                <td colspan="1">{{ sumColumn(category, 'purchase_subtotal') | currency(' XAF') }}</td>
                <td v-if="form.expected_sales" colspan="1"></td>
                <td v-if="form.expected_sales" colspan="1">{{ sumColumn(category, 'sales_subtotal') | currency(' XAF') }}</td>
                <td v-if="form.expected_sales" colspan="1">
                  {{ (sumColumn(category, 'sales_subtotal') - sumColumn(category, 'purchase_subtotal')) | currency(' XAF') }}
                </td>
              </tr>
            </template>
            <tr class="bg-soft-dark text-white h5">
              <td colspan="4" align="center" class="text-dark">Total</td>
              <td colspan="1">{{ sumColumn('_total_purchase', 'purchase_price') | currency(' XAF') }}</td>
              <td v-if="form.expected_sales" colspan="1"></td>
              <td v-if="form.expected_sales" colspan="1">{{ sumColumn('_total_sales', 'selling_price') | currency(' XAF') }}</td>
              <td v-if="form.expected_sales" colspan="1">
                {{( sumColumn('_total_sales', 'selling_price') - sumColumn('_total_purchase', 'purchase_price')) | currency(' XAF') }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Inventories',
  data () {
    return {
      form: {
        warehouse: [],
        expected_sales: false,
        dl: false,
        zero_items: false
      },
      warehouses: [],
      inventories: [],
      loading: false
    }
  },
  methods: {
    sumColumn (category, column) {
      if (column === 'purchase_subtotal') {
        return this.inventories[category].reduce((acc, item) => acc + (item.purchase_price * item.quantity), 0)
      }
      if (column === 'sales_subtotal') {
        return this.inventories[category].reduce((acc, item) => acc + (item.selling_price * item.quantity), 0)
      }
      if (category.startsWith('_total')) {
        return Object.values(this.inventories).reduce((acc, inv) => acc.concat(inv), [])
          .reduce((acc, item) => acc + (item[column] * item.quantity), 0)
      }
      return this.inventories[category].reduce((acc, item) => acc + item[column], 0)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('title.totals')
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = this.formatPrice(sums[index])
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getItemNames (row) {
      return (row.items.map((item) => item.item.name + `(<span class="currency">${item.quantity}</span>)`).join(', '))
    },
    onReportSubmit () {
      const query = Object.assign({}, this.form)
      if (!query.dl) delete query.dl
      if (!query.zero_items) delete query.zero_items
      if (!query.expected_sales) delete query.expected_sales
      this.loading = true
      this.$reportService.queryInventory(query)
        .then(data => {
          this.inventories = data.data
          if (this.form.dl && data.url) {
            this.$electron.shell.openExternal(data.url, {}, (err) => {
              if (err) {
                this.displayMessage(err, () => {
                }, 'error')
                return false
              }
              this.displayMessage(this.$t('report.message.downloaded'))
            })
          }
        })
        .catch(error => {
          this.displayMessage(error.message, () => {
          }, 'error')
        })
        .then(() => {
          this.loading = false
        })
    },
    downloadFile (url) {
    },
    showProgress (received, total) {
      const percentage = (received * 100) / total
      console.log(percentage + '% | ' + received + ' bytes out of ' + total + ' bytes.')
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('report.inventory.name'))
    this.$warehouseService.getWarehouses()
      .then(data => (this.warehouses = data))
  }
}
</script>

<style scoped>

</style>
