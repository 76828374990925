<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-body">
            <el-menu size="mini" :default-active="activeIndex" class="el-menu-demo" router mode="horizontal"
                     background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
              <el-menu-item v-can.disable="'setting.organisation'" :route="{name: 'config_org'}" index="config_org">Organisation</el-menu-item>
              <el-submenu index="2">
                <template slot="title">{{ $t('setting.tabs.products_and_services') }}</template>
<!--                <el-menu-item index="price_lists" :route="{name: 'price_lists'}">Price Lists</el-menu-item>-->
                <el-submenu index="2-2">
                  <template slot="title">{{ $t('setting.tabs.articles') }}</template>
                  <el-menu-item v-can.disable="'setting.price-list'" index="price_lists" :route="{name: 'price_lists'}">
                    {{ $t('setting.tabs.price_lists') }}
                  </el-menu-item>
                  <el-menu-item v-can.disable="'setting.default-message'" index="default_message" :route="{name: 'default_messages'}">
                    {{ $t('setting.tabs.default_messages') }}
                  </el-menu-item>
                </el-submenu>
                <el-submenu index="2-3">
                  <template slot="title">{{ $t('sale.title.sales') }}</template>
                  <el-menu-item v-can.disable="'setting.receipt'" index="receipts" :route="{name: 'receipts'}">
                    {{ $t('setting.tabs.receipts') }}
                  </el-menu-item>
                  <el-menu-item index="print_terminals" :route="{name: 'print_terminals'}"> <!--v-can.disable="'setting.receipt'"-->
                    {{ $t('setting.tabs.sales_print_terminals') }}
                  </el-menu-item>
                </el-submenu>
                <el-submenu index="2-4">
                  <template slot="title">{{ $t('setting.tabs.pos') }}</template>
                  <el-menu-item v-can.disable="'setting.pos'" index="pos" :route="{name: 'pos_settings'}">
                    {{ $t('setting.tabs.department') }}
                  </el-menu-item>
                </el-submenu>
              </el-submenu>
              <el-menu-item v-can.disable="'setting.read'" index="config_reg" :route="{name: 'config_reg'}">
                Registration
              </el-menu-item>
            </el-menu>
            <hr>
            <div class="tab-content" id="myTabContent2">
              <router-view/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Config',
  data () {
    return {
      pageTitle: 'Configuration',
      activeIndex: 'config_org'
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      this.$router.push(key)
      console.log(key, keyPath)
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('setting.name'))
  }
}
</script>

<style scoped>

</style>
