<template>
  <div>
    <el-dialog top="5vh" :visible.sync="display" width="60%"
               :close-on-click-modal="false"
               :close-on-press-escape="false" @closed="closedHandler" center>
      <el-form ref="form" :model="data" :rules="rules" label-width="200px" size="mini" status-icon>
        <h3 class="card-title text-center">{{ $t('role.button.edit_role') }}
          <small>(Fields with <span class="required">*</span> are required)</small>
        </h3>
        <hr>
        <el-form-item label="Name" prop="name">
          <el-input v-model="data.name"></el-input>
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input type="textarea" v-model="data.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="$emit('close')">{{ $t('button.cancel') }}</el-button>
            <el-button @click="resetForm()">Reset</el-button>
            <el-button type="primary" :loading="loading" @click.prevent="confirmUpdate">{{ $t('button.save') }}</el-button>
        </span>
    </el-dialog>
    <el-dialog title="Warning" :visible.sync="confirmation" width="30%" center append-to-body>
      <span><i class="el-icon-warning"></i>{{$t('role.confirm.update')}}</span>
      <span slot="footer" class="dialog-footer">
                <el-button @click="confirmation = false">{{ $t('button.cancel') }}</el-button>
                <el-button type="primary" @click="updateCategory">{{ $t('button.confirm') }}</el-button>
            </span>
    </el-dialog>
  </div>
</template>
<script>
// import moment from 'moment'
export default {
  name: 'role-edit-modal',
  props: ['role'],
  data () {
    return {
      confirmation: false,
      display: true,
      data: {
        id: this.role.id,
        name: this.role.name,
        description: this.role.description
      },
      rules: {
        name: [
          { required: true, message: this.$t('role.validation.name.required'), trigger: ['change', 'blur'] },
          { min: 3, message: this.$t('role.validation.name.length'), trigger: ['change', 'blur'] }
        ],
        description: [
          { required: true, message: this.$t('role.validation.description.required'), trigger: ['change', 'blur'] },
          { min: 5, message: this.$t('role.validation.description.length'), trigger: ['blur', 'change'] }
        ]
      },
      loading: false
    }
  },
  computed: {
  },
  methods: {
    closedHandler () {
      this.$emit('close')
    },
    confirmUpdate () {
      this.confirmation = true
    },
    updateCategory () {
      this.confirmation = false
      this.$refs.form.validate((valid) => {
        if (valid) {
          const info = Object.assign({}, this.data)
          this.loading = true
          this.$roleService.updateRole(info.id, info)
            .then(res => {
              this.$notify.success(this.$t('role.message.update').toString())
              this.$emit('updated', res.data)
              this.closedHandler()
              this.loading = false
            })
            .catch(this.globalErrHandle)
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.$refs.form.resetFields()
    }
  },
  mounted () {
  }
}
</script>
<style scoped>
.required {
  color: #ef1900;
  font-weight: bold;
}
</style>
