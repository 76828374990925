<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
             @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center">{{ $t(`discount.title.${edit ? 'update' : 'create'}`, { name: form.name }) }}</h3>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form ref="disForm" :model="form" :rules="rules" label-width="120px" status-icon>
          <el-form-item :label="$t('discount.form.name')" prop="name">
            <el-input v-model="form.name" ref="input"></el-input>
          </el-form-item>
          <el-form-item :label="$t('discount.form.type')" prop="type">
            <el-radio-group v-model="form.type" size="medium">
              <el-radio border v-for="type in types" :key="type.value" :label="type.value">
                {{ type.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('discount.form.value')" prop="value">
            <el-input-number ref="valueField" @focus="focusedValueField" v-model="form.value" controls-position="right"
                             @change="handleValueChange" :min="minValue" :max="maxValue"></el-input-number>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>
    <template #footer>
      <el-row class="dialog-footer">
        <el-col :span="12" :offset="6">
          <el-button @click="$emit('close', false)">Cancel</el-button>
          <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
            {{ $t(`discount.button.${edit ? 'update' : 'create'}`) }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'createDiscount',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    discount: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: '',
        type: 'percentage',
        value: ''
      },
      types: [
        {
          label: this.$t('discount.label.amount'),
          value: 'amount'
        },
        {
          label: this.$t('discount.label.percentage'),
          value: 'percentage'
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: 'Please specify the discount name',
            trigger: ['change', 'blur']
          },
          {
            min: 3,
            message: 'Length should be at least 3',
            trigger: ['change', 'blur']
          }
        ],
        value: [
          {
            required: true,
            message: 'Please enter the discount value',
            trigger: ['change', 'blur']
          }
        ],
        type: [
          {
            required: true,
            message: 'Please select the discount type',
            trigger: ['change', 'blur']
          }
        ]
      },
      loading: false
    }
  },
  watch: {
    'form.type' (_) {
      this.form.value = this.minValue
    }
  },
  computed: {
    submittable () {
      return !Object.keys(this.form).filter(k => (typeof this.form[k] !== 'boolean') && !this.form[k]).length
    },
    edit () {
      return this.discount && this.discount.id
    },
    maxValue () {
      return this.form.type === 'amount' ? 99999999999999 : 100
    },
    minValue () {
      return this.form.type === 'percentage' ? 0.01 : 0
    }
  },
  methods: {
    handleValueChange (_) {
    },
    focusedValueField (_) {
      if (this.form.value <= 0) {
        this.$refs.valueField.select()
      }
    },
    opened () {
      if (this.edit) {
        this.form.name = this.discount.name
        this.form.value = this.discount.value
        this.form.type = this.discount.type
      }
      this.$refs.input.focus()
    },
    closed () {
      this.form.name = ''
      this.form.value = 0.01
      this.form.type = 'percentage'
      this.loading = false
    },
    submitForm () {
      this.$refs.disForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`discount.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$discountService.updateDiscount(this.discount.id, this.form)
                .then(data => {
                  this.notify(this.$t('discount.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$discountService.createDiscount(this.form)
                .then(data => {
                  this.notify(this.$t('discount.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
