<template>
    <el-dialog ref="debtTypeForm"
               :visible="visible" @closed="closed"
               lock-scroll :close-on-click-modal="false" :show-close="false" @open="open" fullscreen>
        <template slot="title">
            <h3 v-if="debt.employee" class="text-center">Payment of Debt by {{ debt.employee.name }} (Max: <span
                    class="currency">{{ this.debt.balance | currency('XAF')}}</span>)</h3>
            <hr>
        </template>
        <el-row>
            <el-col :span="12" :offset="6">
                <el-form :model="form" :rules="rules" ref="debtPaymentForm" label-position="left" label-width="180px">
                    <el-form-item label="Amount" prop="amount">
                        <el-input :disabled="!debt.balance" @click="" v-model.number="form.amount"
                                  @focus="selectContent"></el-input>
                        <small class="help-block hint">
                            Balance <small class="currency">{{ this.debt.balance - this.form.amount |
                            currency('XAF')}}</small>
                        </small>
                    </el-form-item>
                    <el-form-item label="Payment date" prop="date">
                        <el-date-picker v-model="form.date" format="dd MMM, yyyy" class="f-w"
                                        value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item prop="message" label="Message">
                        <el-input type="textarea" v-model="form.message"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close')">Cancel</el-button>
                <el-button type="primary" v-html="$t(`debt.button.pay_debt`)" @click.prevent="submitForm"
                           :loading="loading"></el-button>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
export default {
  name: 'DebtPayment',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    debt: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        date: null,
        amount: 0,
        message: ''
      },
      loading: false,
      rules: {
        amount: [
          {
            required: true,
            type: 'number',
            min: 1,
            message: 'Please, provide an amount less than or equal to the balance and greater than zero',
            trigger: 'blur'
          }
        ],
        date: [
          {
            required: true,
            message: 'Please, select a valid date.',
            trigger: 'blur'
          }
        ],
        message: [
          {
            required: true,
            message: 'Please, enter a message.',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    'form.amount' (value, old) {
      if (value > this.debt.balance) {
        this.$notify({
          type: 'warning',
          message: this.$t('debt.payment.amount_err'),
          title: this.$t('status.warning')
        })
        this.form.amount = this.debt.balance
      }
    }
  },
  methods: {
    selectContent (evt) {
      if ((parseInt(evt.target.value) === 0) && (this.debt.balance > 0)) {
        this.form.amount = this.debt.balance
      }
    },
    submitForm () {
      this.$refs.debtPaymentForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t('debt.confirm.pay'), () => {
            this.loading = true
            this.$debtService.payDebt(this.debt.id, this.form)
              .then(data => {
                this.afterSubmitForm(data.data)
              })
              .catch(this.afterSubmitFormError)
              .then(() => {
                this.loading = false
              })
          })
        }
      })
    },
    afterSubmitForm (data) {
      this.loading = false
      this.notify(this.$t('debt.message.paid'))
      this.$emit('paid', data)
      this.$emit('close')
    },
    afterSubmitFormError (error) {
      this.notify(error.response.data.message, this.$t('status.error'), 'error')
    },
    closed () {
      Object.keys(this.form).forEach((k, i) => {
        this.form[k] = null
      })
      this.loading = false
    },
    open () {
      this.form.amount = 0
      this.form.message = this.$t('message.debt_payment', { name: this.debt.employee.name })
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
