<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">
                    <!--<div class="col-md-4 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-currency-usd  "></div>
                            </div>
                            <div class="media-body">
                                <h4 class="p-t-10">{{ pageTitle || $t('report.name') }} </h4>
                                <p class="opacity-75">
                                </p>
                            </div>
                        </div>
                    </div>-->
                    <div class="col-md-12 text-center m-b-30 ml-auto">
                        <el-row class="text-md-left">
                            <router-link v-can="'report.supply'" style="margin: 8px;" class=" el-button el-button--primary el-button--medium" tag="el-button"
                                         type="primary" :to="{name: 'supply-report'}">
                                <i class="mdi mdi-chart-areaspline"></i> {{ $t('report.supply.name') }}
                            </router-link>
                            <router-link v-can="'report.supplier-payment'" style="margin: 8px;" class=" el-button el-button--primary el-button--medium" tag="el-button"
                                         type="primary" :to="{name: 'supplier-payment-report'}">
                                <i class="mdi mdi-chart-areaspline"></i> {{ $t('report.supplier_payment.name') }}
                            </router-link>
                            <router-link v-can="'report.payroll'" style="margin: 8px;" class=" el-button el-button--primary el-button--medium" tag="el-button"
                                         type="primary" :to="{name: 'payroll-report'}">
                                <i class="mdi mdi-chart-areaspline"></i> {{ $t('report.payroll.name') }}
                            </router-link>
                            <router-link v-can="'report.employee-debt'" style="margin: 8px;" class=" el-button el-button--primary el-button--medium" tag="el-button"
                                         type="primary" :to="{name: 'employee-debt-report'}">
                                <i class="mdi mdi-chart-areaspline"></i> {{ $t('report.employee_debt.name') }}
                            </router-link>
                            <router-link v-can="'report.deposit'" style="margin: 8px;" class=" el-button el-button--primary el-button--medium" tag="el-button"
                                         type="primary" :to="{name: 'deposit-report'}">
                                <i class="mdi mdi-chart-areaspline"></i> {{ $t('report.deposit.name') }}
                            </router-link>
                            <router-link v-can="'report.withdrawal'" style="margin: 8px;" class=" el-button el-button--primary el-button--medium" tag="el-button"
                                         type="primary" :to="{name: 'withdrawal-report'}">
                                <i class="mdi mdi-chart-areaspline"></i> {{ $t('report.withdrawal.name') }}
                            </router-link>
                            <router-link v-can="'report.inventory'" style="margin: 8px;" class=" el-button el-button--primary el-button--medium" tag="el-button"
                                         type="primary" :to="{name: 'inventory-report'}">
                                <i class="mdi mdi-chart-areaspline"></i> {{ $t('report.inventory.name') }}
                            </router-link>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle"/>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'Report',
  data () {
    return {
      pageTitle: ''
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    }
  },
  mounted () {
  }
}
</script>

<style>
    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 60px;
    }

    .el-aside {
        color: #333;
    }
</style>
