<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('salary.button.salaries') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="salaries.filter(data => !search || data.employee.name.toLowerCase().includes(search.toLowerCase()))"
                                         :pagination-props="{ pageSizes: [20, 30, 40, 100], pageSize: 20 }" center-align>
                                <span slot="empty" class="text-warning">No Salaries have been paid yet</span>
                                <el-table-column in label="S/N" type="index" fixed></el-table-column>
                                <el-table-column prop="employee.name" label="Employee" fixed></el-table-column>
                                <el-table-column prop="date" :formatter="$shortDate" label="Date"
                                                 sortable></el-table-column>
                                <el-table-column label="Paid" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.paid | currency('XAF')}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Total salary" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.total_salary | currency('XAF')}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Total Debt" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.debt | currency('XAF')}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Paid Debt" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ (scope.row.total_salary - scope.row.paid) | currency('XAF')}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At">
                                    <template slot="header" slot-scope="scope">
                                        <el-input v-model="search" size="mini" autofocus placeholder="Type to search"/>
                                    </template>
                                </el-table-column>
                                <!--<el-table-column label="Actions" min-width="100px">
                                    <template slot-scope="scope">
                                        <router-link :to="{name: 'debt', params: {id: scope.row.id}}" class="el-button el-button&#45;&#45;primary el-button&#45;&#45;mini">
                                            {{ $t('button.explore') }}
                                        </router-link>
                                        <button class="el-button el-button&#45;&#45;info el-button&#45;&#45;mini" @click.prevent="showEmployeeDetails(scope.row.id)">
                                            <i class="mdi mdi-details"></i>
                                        </button>
                                    </template>
                                </el-table-column>-->
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      salaries: [],
      search: ''
    }
  },
  methods: {},
  mounted () {
    this.$emit('parent-title', this.$t('salary.title.salary'))
    this.$salaryService.getSalaries()
      .then(data => {
        this.salaries = data
      })
  }
}
</script>

<style scoped>

</style>
