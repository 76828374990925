<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('account.subcategory.name') }}
                                </h5>
                            </el-col>
                            <el-col :span="12">
                                <el-button v-can="'account.create-subcategory'" size="medium" class="float-right" type="success" icon="el-icon-plus"
                                           @click="showSubcategoryDialog">
                                    {{ $t('account.subcategory.button.create') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="subcategories"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }">
                                <el-table-column in label="S/N" type="index" sortable fixed></el-table-column>
                                <el-table-column prop="name" label="Name" sortable fixed></el-table-column>
                                <el-table-column prop="amount" label="Amount" sortable fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.amount | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions">
                                    <template slot-scope="scope">
                                        <el-button v-can.disable="'account.update-subcategory'" icon="el-icon-edit" type="primary" size="mini"
                                                   @click.native="showEditDialog(scope.row.id)">
                                            {{ $t('button.edit') }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-edit-subcategory :subcategory="subcategory" :category="category" :visible="displaySubcategoryDialog"
                                 @close="closeSubcategoryDialog"
                                 @subcategory-created="subcategoryCreated"
                                 @subcategory-updated="subcategoryUpdated"></create-edit-subcategory>
    </div>
</template>

<script>
import CreateEditSubcategory from '../Modal/CreateEditSubcategory'

export default {
  name: 'AccountSubcategory',
  components: { CreateEditSubcategory },
  props: {
    category: {
      type: Object,
      require: true,
      default: () => ({})
    }
  },
  data () {
    return {
      subcategories: [],
      subcategory: {},
      displaySubcategoryDialog: false
    }
  },
  methods: {
    showSubcategoryDialog () {
      this.displaySubcategoryDialog = true
    },
    showEditDialog (id) {
      this.subcategory = this.subcategories.find(s => s.id === id)
      this.displaySubcategoryDialog = true
    },
    closeSubcategoryDialog () {
      this.displaySubcategoryDialog = false
      this.subcategory = {}
    },
    subcategoryCreated (data) {
      this.subcategories.splice(0, 0, data)
    },
    subcategoryUpdated (data) {
      const index = this.subcategories.indexOf(this.subcategories.find(s => s.id === data.id))
      this.subcategories.splice(index, 1, data)
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('account.subcategory.title.subcategory', { name: this.category.name }))
    this.$accountService.getSubcategories(this.$route.params.id)
      .then(data => (this.subcategories = data))
  }
}
</script>

<style scoped>

</style>
