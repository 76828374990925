<template>
    <div class="tab-pane fade active show" id="tab-reg" role="tabpanel"
         aria-labelledby="home-tab">
        <p class="opacity-75">
          <el-tabs tab-position="left">
            <el-tab-pane v-for="(message, index) in messages" :label="message.module.toUpperCase()" :key="message.id">
              <el-form :model="data" size="mini">
                <el-form-item label="Message">
                  <el-input type="textarea" v-model="messages[index].message"></el-input>
                </el-form-item>
                <el-form-item>
                  <span v-html="message.description"></span>
                </el-form-item>
                <el-form-item align="left">
                  <el-button :loading="loading" type="primary" @click="update(message.id)">Update</el-button>
                  <el-button :loading="loading" type="success" @click="update(message.id, true)">Restore Default</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </p>
    </div>
</template>

<script>
export default {
  name: 'DefaultMessages',
  data () {
    return {
      messages: [],
      data: {},
      loading: false
    }
  },
  methods: {
    module_name (module) {
      console.log(module)
      // return module.split('_').map(w => w.indexOf(0).toUpperCase() + w.substr(1)).join(' ')
    },
    update (id, useDefault = false) {
      this.loading = true
      const data = this.messages.find(m => m.id === id)
      data.use_default = useDefault
      this.$configService.setDefaultMessage(id, data)
        .then(data => {
          this.loading = false
          this.displayMessage(this.$t('status.success'))
        })
        .catch(this.globalErrHandle)
        .then(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.$configService.getDefaultMessages()
      .then(data => {
        this.messages = data
      })
  }
}
</script>

<style scoped>

</style>
