<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('employee.type.name') }}
                                </h5>
                            </el-col>
                            <el-col :span="12">
                                <el-button class="float-right" icon="el-icon-plus" type="success" size="mini" @click="showCreateDialog">
                                    {{ $t('employee.type.button.create') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="types"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }"
                                         center-align>
                                <el-table-column in label="S/N" type="index" sortable fixed></el-table-column>
                                <el-table-column prop="name" label="Name" sortable fixed></el-table-column>
                                <el-table-column prop="description" label="Description" sortable min-width="180"></el-table-column>
                                <el-table-column prop="salary" label="Salary" sortable>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.salary | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions" min-width="50px">
                                    <template slot-scope="scope">
                                        <el-button type="primary" size="mini"
                                                   @click.prevent="showEditDialog(scope.row.id)">
                                            <i class="mdi mdi-pencil-outline"></i> {{ $t('button.edit') }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-edit-employee-type :type="type" :visible="dialogVisible" @created="typeCreated" @updated="typeUpdated"
                                   @close="closeDialog"></create-edit-employee-type>
    </div>
</template>

<script>
import CreateEditEmployeeType from './Modals/CreateEditEmployeeType'

export default {
  name: 'Index',
  components: { CreateEditEmployeeType },
  data () {
    return {
      types: [],
      type: {},
      dialogVisible: false
    }
  },
  methods: {
    showEditDialog (id) {
      this.type = this.types.find(e => e.id === id)
      this.dialogVisible = true
    },
    showCreateDialog () {
      this.dialogVisible = true
    },
    closeDialog () {
      this.type = {}
      this.dialogVisible = false
    },
    typeCreated (data) {
      this.types.splice(0, 0, data)
    },
    typeUpdated (data) {
      this.types.splice(this.types.indexOf(this.types.find(e => e.id === data.id)), 1, data)
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('employee.type.name'))
    this.$employeeService.getEmployeeTypes()
      .then(data => {
        this.types = data
      })
  }
}
</script>

<style scoped>

</style>
