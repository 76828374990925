<template>
  <div>
    <main class="admin-main  ">
      <!--v-loading.fullscreen.lock="auth_status === 'loading'" -->
      <div class="container-fluid">
        <div class="row ">
          <div class="col-lg-5  bg-white">
            <div class="dropdown text-right absolute-right-dropdown">
              <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i
                  class="icon mdi  mdi-dots-vertical"></i> </a>

              <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                   style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-160px, 25px, 0px);">
                <button @click="changeServerAddress" class="dropdown-item" type="button">Change Server Address</button>
              </div>
            </div>
            <div class="row align-items-center m-h-100">
              <div class="mx-auto col-md-8">
                <div class="p-b-20 text-center">
                  <p>
                    <img src="@/assets/logo/colored-vertical.png" alt="slm-logo">
                  </p>
                  <!--                  <p class="admin-brand-content">
                    Salamanda
                  </p>-->
                </div>
                <h3 class="text-center p-b-20 fw-400">{{ $t('login.title') }}</h3>
                <form class="needs-validation" action="#">
                  <div class="form-row">
                    <div class="form-group floating-label col-md-12">
                      <label>Username</label>
                      <input @keyup.enter="focusPass()" v-model="data.username" type="email" required
                             class="form-control" :class="{'is-invalid': auth_error}"
                             :placeholder="$t('login.username')" ref="username">
                      <div v-if="auth_error" class="invalid-feedback">
                        {{ auth_message }}
                      </div>
                    </div>
                    <div class="form-group floating-label col-md-12">
                      <label>Password</label>
                      <input ref="password" v-model="data.password" type="password" @keyup.enter="login"
                             :placeholder="$t('login.password')"
                             required class="form-control ">
                    </div>
                  </div>

                  <button type="button" @click.prevent="login"
                          class="btn btn-primary btn-block btn-lg">
                                        <span v-if="loading" class="spinner-grow spinner-grow-lg" role="status"
                                              aria-hidden="true"></span>{{ $t('login.button') }}
                  </button>

                </form>
              </div>

            </div>
          </div>
          <div class="col-lg-7 d-none d-md-block bg-cover"
               :style="`background-image: url('${publicPath}assets/img/finance2.svg');`">

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      data: {
        username: '',
        password: ''
      },
      auth_message: '',
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    ...mapState({
      loading: state => state.Auth.status === 'loading',
      auth_error: state => state.Auth.status === 'error',
      name: state => state.Auth.user.name,
      last_login: state => state.Auth.user.last_login,
      auth_status: state => state.Auth.status,
      intended: state => state.Auth.intended
    })
  },
  watch: {
    auth_status (value) {
      if (value === 'error') {
        this.auth_message = this.$t('login.error.message')
      }
    }
  },
  methods: {
    ...mapActions('Base', ['setBaseURL']),
    ...mapActions('Auth', ['setIntended']),
    login () {
      this.$authService.login(Object.assign({}, this.data))
        .then(res => {
          this.setLanguage(res.user.lang || 'en')
          this.$notify({
            title: this.$t('status.success'),
            message: this.$t('login.welcome', [this.name, this.last_login]),
            type: 'success'
          })
          if (this.intended) {
            this.$router.push({
              name: this.intended
            })
          } else {
            this.$router.push({
              name: 'dashboard'
            })
          }
        })
        .catch(err => {
          console.log(err, 'Error is here...')
          if (err) {
            const status = err.response.status
            if (status === 500) {
              this.auth_message = this.$t('login.error.server')
            } else if (status === 401) {
              this.auth_message = err.response.data.error
            }
          }
        })
    },
    focusPass () {
      this.$refs.password.focus()
    },
    changeServerAddress () {
      this.setBaseURL(null)
      this.setIntended(null)
      this.$router.push({ name: 'base' })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.username.focus()
    })
    $('body').attr('class', 'jumbo-page')
  },
  destroyed () {
    $('body').removeClass('jumbo-page')
  }
}
</script>

<style scoped>
.absolute-right-dropdown {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
