<template>
    <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
               @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
        <slot name="title">
            <h3 class="text-center">{{ $t(`category.title.${edit ? 'update' : 'create'}`, {name: form.name}) }}</h3>
            <hr>
        </slot>
        <el-row>
            <el-col :span="12" :offset="6">
              <el-form ref="catForm" :model="form" :rules="rules" label-width="200px" size="large" status-icon>
                    <el-form-item label="Name" prop="name">
                      <el-input v-model="form.name" ref="input"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="Department" prop="department">
                      <el-select class="full-width" v-model="form.department" filterable
                                 placeholder="Select department">
                        <el-option v-for="dep in departments" :key="dep.id" :label="dep.name"
                                   :value="dep.id"></el-option>
                      </el-select>
                    </el-form-item>-->

              </el-form>
            </el-col>

        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close', false)">Cancel</el-button>
                <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
                    {{ $t(`category.button.${edit ? 'update' : 'create'}`) }}
                </el-button>
            </el-col>
        </el-row>

    </el-dialog>
</template>

<script>
export default {
  name: 'createCategory',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    category: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      departments: [],
      form: {
        name: ''
        // department: null
      },
      rules: {
        name: [
          { required: true, message: 'Please specify the category name', trigger: ['change', 'blur'] },
          { min: 3, message: 'Length should be at least 3', trigger: ['change', 'blur'] }
        ],
        category: [
          { required: true, message: 'Please enter a category description', trigger: ['change', 'blur'] }
        ]
      },
      loading: false
    }
  },
  computed: {
    submittable () {
      return !Object.keys(this.form).filter(k => (typeof this.form[k] !== 'boolean') && !this.form[k]).length
    },
    edit () {
      return this.category && this.category.id
    }
  },
  methods: {
    opened () {
      if (this.category && this.category.id) {
        this.form.name = this.category.name
        // this.form.department = parseInt(this.category.department_id)
      }
      this.$refs.input.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = ''
      })
      this.loading = false
    },
    submitForm () {
      this.$refs.catForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`category.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$categoryService.updateCategory(this.category.id, this.form)
                .then(data => {
                  this.notify(this.$t('category.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$categoryService.createCategory(this.form)
                .then(data => {
                  this.notify(this.$t('category.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
    // this.$departmentService.getDepartments()
    //   .then(departments => (this.departments = departments))
  }
}
</script>

<style scoped>

</style>
