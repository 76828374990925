export default class DebtService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getArchiveDebts () {
    return new Promise((resolve, reject) => {
      this.$http.get('debts/archive/all')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  restoreDebt (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`debts/archive/${id}/restore`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createDebt (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('debts/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  getDebts () {
    return new Promise((resolve, reject) => {
      this.$http.get('debts')
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  getEmployeeDebts (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`debts/employee/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  getDebtTypes () {
    return new Promise((resolve, reject) => {
      this.$http.get('debts/type')
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  getAllDebtTypes () {
    return new Promise((resolve, reject) => {
      this.$http.get('debts/type/all')
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  archiveDebt (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`debts/${id}/state`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  archiveDebtType (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`debts/type/${id}/archive`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  updateDebtType (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`debts/type/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          else resolve(data)
        })
        .catch(reject)
    })
  }

  createDebtType (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('debts/type/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  payDebt (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`debts/${id}/pay`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  getDebtPayments () {
    return new Promise((resolve, reject) => {
      this.$http.get('debts/payments')
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  getEmployeeArchivedDebts (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`debts/employee/${id}/archive`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data)
        })
        .catch(reject)
    })
  }

  attemptDeletion (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`debts/${id}/delete`)
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  getPaymentHistory (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`debts/payments/${id}/history`)
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }
}
