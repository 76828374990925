<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
              <el-row>
<!--                  <el-col>
                      <h5 class="m-b-0">
                          <i class="mdi mdi-timer"></i> {{ $t('session.name') }}
                      </h5>
                  </el-col>-->
                  <el-col align="right">
                      <pagination :total="total" @current-change="pageChanged"/>
                  </el-col>
              </el-row>

          </div>
          <div class="card-body">
            <el-card shadow="allway-shadow">
              <el-radio-group v-model.number="department" class="full-width" v-if="departments.length">
                <el-radio-button v-for="department in departments" :key="department.id" :label="department.id">{{
                    department.name
                  }}
                </el-radio-button>
                <div class="pull-right float-right" v-if="selected.length">
                  <b-button-group>
                    <b-button variant="info" @click="checkAll">Select All
                      <el-icon name="circle-plus-outline"/>
                    </b-button>
                    <b-button variant="warning" @click="unCheckAll">Unselect All
                      <el-icon name="remove-outline"/>
                    </b-button>
                    <b-button v-can="'session.print'" disabled variant="success" @click="printReportMultiple">Report
                      <el-icon name="printer"/>
                    </b-button>
                    <b-button v-can="'session.print'" variant="success" @click="generateSessionReport">Report
                      <el-icon name="document"/>
                    </b-button>
                    <b-button v-can="'session.print-service'" variant="primary" @click="printServiceMultiple">Service
                      <el-icon name="printer"/>
                    </b-button>
                  </b-button-group>
                </div>
              </el-radio-group>
              <span class="error" v-else>Departments Listing</span>
            </el-card>
            <br>
            <el-row :gutter="5">
              <el-card v-if="!sessions.length"><i>No available session for this department</i></el-card>
              <el-col :span="8" v-for="(session, idx) in sessions" :key="idx">
                <b-card title="" style="margin-top: 5px">
                    <span slot="header" class="session-title">
                        <i class="fa fa-dot-circle-o "
                           :class="[session.status ? 'session-open' : 'session-close']"></i>
                        &nbsp; {{ session.user.name | limit }}
                            <span class="pull-right" style="float: right">
                                  <el-checkbox :checked="!!selected.find(s => s.id === session.id)" v-model="sessions[idx].selected"
                                               :disabled="session.status"></el-checkbox>
                            </span>
                    </span>
                  <b-card-text>
                    <span>Opened By: {{ session.opener.name }}</span> <br>
                    <span v-if="session.closer">Closed By: {{ session.closer.name }}</span> <br>
                    <span v-if="session.closer">Closing Time: {{ session.closed_on | datetime }}</span>
                    <br>
                  </b-card-text>
                  <div slot="footer">
                    <b-button v-can.disable="'session.close'" size="sm" :disabled="!session.status" variant="danger"
                              @click="closeSession(session)">Close
                      <el-icon name="close"/>
                    </b-button>
                    <b-button v-can.disable="'session.print'" size="sm" disabled variant="success"
                              @click.prevent="printInventoryReport([session.id])">Report
                      <el-icon name="printer"/>
                    </b-button>
                    <b-button v-can.disable="'session.print'" size="sm" :disabled="session.status" variant="success"
                              @click.prevent="generateSessionReport([session.id])">Report
                      <el-icon name="document"/>
                    </b-button>
                    <b-button v-can.disable="'session.print-service'" size="sm" :disabled="session.status" variant="primary"
                              @click.prevent="printServiceReport([session.id])">Service
                      <el-icon name="dish"/>
                    </b-button>
                    {{ session.status ? 'Opened' : 'Closed' }}
                  </div>
                  <div slot="footer">
                    <small class="text-muted">Created At: {{ (session.created_at) | date }}</small>
                  </div>
                </b-card>
              </el-col>
              <el-col :span="24">
                  <pagination :total="total" @current-change="pageChanged" :current-page="currentPage"/>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '../_Partials/Pagination.vue'

export default {
  name: 'session-index',
  components: { Pagination },
  data () {
    return {
      departments: [],
      sessions: [],
      department: null,
      loading: false,
      showing: {},
      checked: [],
      perPage: 9,
      total: 0,
      currentPage: 1
    }
  },
  watch: {
    department () {
      this.resetPagination()
      this.fetchSessions()
    },
    currentPage (page) {
      this.fetchSessions()
    }
  },
  computed: {
    selected () {
      return this.sessions.filter(s => s.selected && !s.status)
    }
  },
  methods: {
    isChecked (id) {
      return this.sessions.find(s => s.id === id).selected
    },
    checkAll () {
      this.sessions.filter(s => !s.status && !s.selected).forEach(s => (s.selected = true))
    },
    unCheckAll () {
      this.sessions.filter(s => !s.status && s.selected).forEach(s => (s.selected = false))
    },
    printReportMultiple () {
      this.printInventoryReport(this.selected.map(s => s.id))
    },
    printServiceMultiple () {
      this.printServiceReport(this.selected.map(s => s.id))
    },
    fetchSessions () {
      this.$sessionService.getSessions(`${this.department}`, this.perPage, this.currentPage)
        .then(data => {
          this.sessions = this.addRequiredFields(data.data)
          this.total = data.total
        })
        .catch(err => {
          this.displayMessage(err.message, () => {
          }, 'error')
        })
    },
    pageChanged (page) {
      this.currentPage = page
    },
    resetPagination () {
      this.currentPage = 1
      this.total = 0
    },
    addRequiredFields (data) {
      return data.map(s => {
        s.selected = false
        return s
      })
    },
    getDepartments () {
      this.$departmentService.getDepartments()
        .then((data) => {
          this.departments = data
          if (this.departments.length && !this.department) {
            this.department = this.departments[0].id
          }
        })
    },
    closeSession (session) {
      if (session) {
        this.askQuestion(this.$t('session.confirm.close'), () => {
          this.$sessionService.closeUserSession(session.id)
            .then(data => {
              const index = this.sessions.indexOf(this.sessions.find(s => session.id === s.id))
              this.sessions.splice(index, 1, data)
              this.displayMessage(this.$t('session.message.closed'), () => {
                this.askQuestion(this.$t('session.confirm.reopen_new', [data.user.name]), () => {
                  this.$sessionService.createSession({ user: data.user_id, department: data.department_id })
                    .then(data2 => {
                      this.displayMessage(this.$t('session.message.created'), () => {
                        this.sessions.splice(0, 0, this.addRequiredFields([data2])[0])
                      })
                    })
                }, this.$t('title.question'), () => {
                  this.notify(this.$t('session.message.remember_to_open'), '', 'warning')
                })
              })
            })
        })
      }
    },
    printInventoryReport (data) {
      this.$printService.printSessions(data)
        .then(res => {})
        .catch(err => {
          err = err.response
          if (err && err.data && err.data.status === 'error') {
            this.displayMessage(err.data.message, () => {
            }, 'warning')
          }
        })
    },
    printServiceReport (data) {
      this.$printService.printServices(data)
        .then(res => {})
        .catch(err => {
          err = err.response
          if (err && err.data && err.data.status === 'error') {
            this.displayMessage(err.data.message, () => {
            }, 'warning')
          }
        })
    },
    generateSessionReport (data) {
      if (!data) {
        data = this.selected.map(s => s.id)
      }
      this.$sessionService.generateReport(data)
        .then(resp => {
          if (resp.url) {
            this.$electron.shell.openExternal(resp.url, {}, (err) => {
              if (err) {
                this.displayMessage(err, () => {
                }, 'error')
                return false
              }
              this.displayMessage(this.$t('report.message.downloaded'))
            })
          }
        })
        .catch(err => {
          err = err.response
          if (err && err.data && err.data.status === 'error') {
            this.displayMessage(err.data.message, () => {
            }, 'warning')
          }
        })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('session.name'))
    if (this.$route.query.department) {
      setTimeout(() => {
        this.department = this.$route.query.department
      }, 800)
    }
    this.getDepartments()
  }
}
</script>
<style scoped>
.session-open {
  color: #00a513;
}

.session-close {
  color: #ef1900;
}

.full-width {
  width: 100% !important;
}

.session-title {
  font-weight: bold;
  /*font-family: Niconne,monospace;*/
  font-size: 20px;
}
</style>
