<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">

                    <div class="col-md-4 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-currency-usd  "></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }}</h3>
                                <p class="opacity-75">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link :to="{name: 'debt'}" class="el-button el-button--primary el-button--medium"><i
                                    class="mdi mdi-format-list-bulleted"></i> {{ $t('employee.button.debt') }}
                            </router-link>
                            <router-link :to="{name: 'employee-payments'}" class="el-button el-button--default el-button--medium"><i
                                    class="mdi mdi-contactless-payment"></i> {{ $t('employee.button.payment') }}
                            </router-link>
                            <button @click.prevent="createEmployeeDebt" class="el-button el-button--success el-button--medium"><i
                                    class="mdi mdi-plus"></i> {{ $t('employee.button.create_debt') }}
                            </button>
                            <router-link :to="{name: 'employees'}" class="el-button el-button--info el-button--medium"><i
                                    class="mdi mdi-format-list-bulleted"></i> {{ $t('employee.button.all') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-debt :employee-id="employee.id" @close="closeDebtDialog" :display="debtDialog"
                     @debt-ok="recordCreated"></create-debt>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle" :employee="employee"/>
        </transition>
    </div>
</template>

<script>
import CreateDebt from './Modals/CreateDebt'

export default {
  name: 'Employee',
  components: { CreateDebt },
  data () {
    return {
      pageTitle: '',
      employee: {},
      debtDialog: false
    }
  },
  methods: {
    createEmployeeDebt () {
      this.debtDialog = true
    },
    closeDebtDialog (display) {
      this.debtDialog = display
    },
    updateTitle (title) {
      this.pageTitle = title
    },
    recordCreated (data) {
      this.$emit('new-debt', data)
    }
  },
  mounted () {
    this.$employeeService.findEmployee(this.$route.params.id)
      .then(data => {
        this.employee = data
      })
  }
}
</script>

<style scoped>

</style>
