export default class PrintService {
  constructor (axios, store, router) {
    this.$http = axios
    this.$store = store
    this.$router = router
  }

  printSessions (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('/sessions', { sessions: data })
        .then(resolve)
        .catch(reject)
    })
  }

  printServices (data) {
    return new Promise((resolve, reject) => {
      this.$http.get('/services', {
        params: { sessions: data }
      })
        .then(resolve)
        .catch(reject)
    })
  }
}
