<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">

                    <div class="col-md-4 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-currency-usd  "></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }} </h3>
                                <p class="opacity-75">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link v-can="'supplier.read'" class="el-button el-button--medium el-button--primary" type="primary"
                                         size="medium" :to="{name: 'suppliers'}"><i
                                    class="mdi mdi-format-list-bulleted"></i> {{ $t('supplier.button.all') }}
                            </router-link>
                            <router-link v-can="'supplier.create'" class="el-button el-button--medium el-button--success" type="primary"
                                         size="medium" :to="{name: 'create-supplier'}"><i
                                    class="mdi mdi-plus"></i> {{ $t('supplier.button.create') }}
                            </router-link>
                            <router-link v-can="'supplier.archives'" class="el-button el-button--medium el-button--warning" type="primary"
                                         size="medium" :to="{name: 'archive'}"><i
                                    class="mdi mdi-archive"></i> {{ $t('supplier.button.archive') }}
                            </router-link>
                            <el-button v-can="'supplier.payment'" icon="el-icon-s-finance" type="info" size="medium" @click="displayPayment=true">
                                Make Payment
                            </el-button>
                        </div>
                    </div>
                    <make-payment-modal :display="displayPayment" @updated="updatePaymentDisplay"
                                        @close="displayPayment = false"></make-payment-modal>

                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle"/>
        </transition>
    </div>
</template>

<script>
import MakePaymentModal from './History/MakePaymentModal'

export default {
  name: 'Supplier',
  components: { MakePaymentModal },
  data () {
    return {
      displayPayment: false,
      pageTitle: ''
    }
  },
  methods: {
    updatePaymentDisplay (status) {
      this.displayPayment = status
    },
    updateTitle (title) {
      this.pageTitle = title
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
