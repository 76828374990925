<template>
    <div class="tab-pane fade active show" id="tab-org" role="tabpanel"
         aria-labelledby="home-tab">

        <p class="opacity-75">
            <el-form label-position="left" :model="form" size="mini">
                <el-form-item label="Company Name">
                    <el-input disabled v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="Address">
                    <el-input v-model="form.address"></el-input>
                </el-form-item>
                <el-form-item label="Other Address">
                    <el-input v-model="form.address2"></el-input>
                </el-form-item>
                <el-form-item label="Phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="E-mail">
                    <el-input v-model="form.email"></el-input>
                </el-form-item>
                <el-form-item label="Website">
                    <el-input v-model="form.website"></el-input>
                </el-form-item>
                <el-form-item label="Salary Payment Date">
                    <el-date-picker class="f-w"
                                    v-model="form.salary_payment"
                                    type="date"
                                    placeholder="Pick a day"
                                    format="dd/MM"
                                    value-format="yyyy/MM/dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item align="right">
                    <el-button v-can.disable="'setting.organisation'" :loading="loading" type="primary" @click="update">
                      Update
                    </el-button>
                </el-form-item>
            </el-form>
        </p>
    </div>
</template>

<script>
export default {
  name: 'Organisation',
  data () {
    return {
      form: {
        name: '',
        address: '',
        address2: '',
        email: '',
        website: '',
        phone: '',
        salary_payment: ''
      },
      loading: false
    }
  },
  methods: {
    update () {
      this.$configService.setConfig(this.form, 'org')
        .then(data => {
          this.displayMessage(this.$t('status.success'))
        })
        .catch(this.globalErrHandle)
    }
  },
  mounted () {
    this.$configService.getConfig()
      .then(data => {
        this.form.name = data.company_name
        this.form.address = data.company_address
        this.form.address2 = data.company_other_address
        this.form.email = data.company_email
        this.form.website = data.company_website
        this.form.phone = data.company_phone
        this.form.salary_payment = data.salary_payment
      })
  }
}
</script>

<style scoped>

</style>
