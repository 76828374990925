<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('department.button.all') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables :data="departments"
                           :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                <el-table-column label="S/N" type="index" sortable></el-table-column>
                <el-table-column prop="name" :label="$t('label.name')" show-overflow-tooltip></el-table-column>
                <el-table-column :label="$t('department.label.warehouse')">
                  <template slot-scope="scope">
                    <el-select :value="scope.row.warehouse_id" size="mini"
                               @change="updateWarehouse($event, scope.row.id)">
                      <el-option v-can.disable="'department.warehouse'" v-for="wh in warehouses" :key="wh.id" :label="wh.name" :value="wh.id"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column align="center" :label="$t('label.status')">
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.state ? 'success' : 'danger'" effect="dark" size="mini">
                      {{ scope.row.state ? $t('label.active') : $t('label.inactive') }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="user.name" :label="$t('label.creator')"></el-table-column>
                <el-table-column prop="created_at" :formatter="$shortDate" :label="$t('label.created_at')"
                                 ></el-table-column>
                <el-table-column :label="$t('label.action')" min-width="100px">
                  <template slot-scope="scope">
                    <el-dropdown v-can.disable="'department.update'" @click="showEditDialog(scope.row.id)" split-button type="primary" size="small"
                                 trigger="click">
                      <i class="el-icon-edit"></i> {{ $t('button.edit') }}
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="showUsersDialog(scope.row.id)">
                          <i class="mdi mdi-account-group"></i> {{ $t('department.button.users') }}
                        </el-dropdown-item>
                        <el-dropdown-item v-can.disable="'department.archive'" @click.native="toggleState(scope.row.id)">
                          <i class="mdi mdi-archive"></i>
                          {{ scope.row.state ? $t('button.deactivate') : $t('button.activate') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-department :display="displayEditDialog" @close="closeEditDialog" @updated="departmentUpdate"
                     :department="department"></edit-department>
  </div>
</template>

<script>
import CreateEditDepartment from './Modal/CreateEditDepartment'

export default {
  name: 'Index',
  components: {
    'edit-department': CreateEditDepartment
  },
  data () {
    return {
      departments: [],
      warehouses: [],
      department: {},
      displayEditDialog: false
    }
  },
  methods: {
    updateWarehouse (whId, deptId) {
      this.$departmentService.changeWarehouse(deptId, whId)
        .then(res => {
          this.notify(this.$t('department.message.warehouse'))
          this.departments.find(d => d.id === deptId).warehouse_id = whId
        })
        .catch(this.globalErrHandle)
    },
    showEditDialog (id) {
      this.department = this.departments.find(d => d.id === id)
      this.displayEditDialog = true
    },
    closeEditDialog () {
      this.department = {}
      this.displayEditDialog = false
    },
    newDepartment (data) {
      this.departments.splice(0, 0, data)
    },
    departmentUpdate (data) {
      this.departments.splice(this.departments.indexOf(this.departments.find(d => d.id === data.id)), 1, data)
    },
    showUsersDialog (id) {},
    toggleState (id) {
      this.askQuestion(this.$t('department.confirm.archive'), () => {
        this.$departmentService.archiveDepartment(id)
          .then((data) => {
            this.notify(this.$t('department.message.archived'))
            this.departments.splice(this.departments.indexOf(this.departments.find(d => d.id === id)), 0, data)
          })
      })
    }
  },
  async mounted () {
    this.$emit('parent-title', this.$t('department.title.department'))
    this.$parent.$on('new-department', this.newDepartment)
    await this.$warehouseService.getWarehouses()
      .then(whs => (this.warehouses = whs))
    await this.$departmentService.getAllDepartments()
      .then(data => {
        this.departments = data
      })
  }
}
</script>

<style scoped>

</style>
