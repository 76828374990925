<template>
  <div>
    <div class="bg-dark m-b-30">
      <div class="container">
        <div class="row p-b-60 p-t-60">
          <div class="col-md-6 text-white p-b-30">
            <div class="media">
              <div class="avatar avatar mr-3">
                <div class="avatar-title bg-success rounded-circle mdi mdi-room-service"></div>
              </div>
              <div class="media-body">
                <h3 class="p-t-10">{{ pageTitle }}</h3>
                <p class="opacity-75"></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-center m-b-30 ml-auto">
<!--            <div class="text-md-right">
              <router-link :to="{name: 'services'}" class="el-button el-button&#45;&#45;primary">
                <i class="el-icon-s-order"></i> {{ $t('service.button.all') }}
              </router-link>
              <router-link :to="{name: 'create-service'}" class="el-button el-button&#45;&#45;success">
                <i class="el-icon-plus"></i> {{ $t('service.button.create') }}
              </router-link>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <router-view @parent-title="updateTitle"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Sale',
  data () {
    return {
      pageTitle: ''
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
