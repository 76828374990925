export default class ReceiptService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getReceipts () {
    return new Promise((resolve, reject) => {
      this.$http.get('sales-receipts')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  saveReceipt (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('sales-receipts/store', data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  deleteReceipt (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`sales-receipts/delete/${id}`, data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  changeStatus (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`sales-receipts/status/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
