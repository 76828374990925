<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <el-row>
              <el-col :span="12">
                <h5 class="m-b-0">
                  <i class="mdi mdi-security"></i> {{ $t('role.button.all') }}
                </h5>
              </el-col>
            </el-row>
          </div>
          <div class="card-body">
            <role-edit-modal @updated="roleUpdated" :role="role" @close="role = {}"
                             v-if="role.id"></role-edit-modal>
            <role-assign-permission-modal :role="assign" v-if="assign.id"
                                          @close="assign = {}"></role-assign-permission-modal>
            <div class="table-responsive">
              <data-tables :data="roles.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                           :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }"
                           center-align>
                <el-table-column prop="name" :label="$t('role.label.name')" fixed></el-table-column>
                <el-table-column show-overflow-tooltip prop="description" :label="$t('role.label.description')" fixed></el-table-column>
                <el-table-column prop="created_at" :formatter="$shortDate" :label="$t('label.created')"
                                 sortable></el-table-column>
                <el-table-column prop="updated_at" :formatter="$shortDate" :label="$t('label.last_updated')"
                                 sortable></el-table-column>
                <el-table-column label="Actions" min-width="120px">
                  <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" autofocus
                              :placeholder="$t('text.search_placeholder')"/>
                  </template>
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-tooltip :content="$t('role.button.assign_permission')">
                        <el-button v-can.disable="'role.assign-permission'" size="mini" type="primary" icon="el-icon-key" :loading="loading" @click="assignPermissions(scope.row.id, scope.$index)">
                          {{$t('button.assign')}}
                        </el-button>
                      </el-tooltip>
                      <el-tooltip :content="$t('role.button.edit_role')">
                      <el-button v-can.disable="'role.update'" type="info" size="mini" icon="el-icon-edit" @click="editRole(scope.row.id, scope.$index)">
                        {{ $t('button.edit')}}
                      </el-button>
                      </el-tooltip>
                      <el-tooltip :content="$t('role.button.delete_role')">
                        <el-button v-can.disable="'role.delete'" type="danger" size="mini" icon="el-icon-delete" @click="deleteRole(scope.row.id, scope.$index)">
                          {{ $t('button.delete') }}
                        </el-button>
                      </el-tooltip>
                    </el-button-group>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <role-assign-permission-modal :role="assign" v-if="assign.id" @close="assign = {}"></role-assign-permission-modal>
  </div>
</template>

<script>
import RoleEditModal from './Modals/Edit'
import RoleAssignPermissionModal from './Modals/AssignPermissions'

export default {
  name: 'roles-index',
  components: {
    RoleEditModal,
    RoleAssignPermissionModal
  },
  data () {
    return {
      roles: [],
      search: '',
      role: {},
      assign: {},
      loading: false
    }
  },
  methods: {
    editRole (id, role) {
      this.role = this.roles[role]
    },
    assignPermissions (id, index) {
      this.assign = this.roles[index]
    },
    deleteRole (id, index) {
      this.askQuestion(this.$t('role.confirm.delete'), () => {
        this.$roleService.deleteRole(id)
          .then(resp => {
            this.displayMessage(this.$t('role.message.deleted'), () => {
              this.roles.splice(index, 1)
              // this.$router.push({name: 'create-role'})
              // this.$router.push({name: 'roles'})
            })
          })
          .catch(this.globalErrHandle)
      })
    },
    roleUpdated (data, message) {
      let index = this.roles.find(role => role.id === data.id)
      if (index) {
        index = this.roles.indexOf(index)
        this.roles.splice(index, 1, data)
        this.role = {}
      }
    },
    loadRoles () {
      this.$roleService.getRoles()
        .then(data => {
          this.roles = data
        })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('role.title.role'))
    this.loadRoles()
  }
}
</script>

<style scoped>

</style>
