<template>
  <div>
  <el-dialog top="3vh" :visible.sync="visible" width="80%"
             :close-on-click-modal="true"
             :close-on-press-escape="true" @closed="closedHandler" center>
    <h3 slot="title">Assign Permission to Role: {{ role.name }}</h3>
    <el-row>
      <el-col :span="18" :offset="6">
        <el-transfer
            filterable
            :titles="['Permissions', 'Assigned Perms']"
            v-model="assigned"
            :data="unassigned">
        </el-transfer>
      </el-col>
    </el-row>

    <div slot="footer" class="dialog-footer">
      <el-button type="default" @click.prevent="visible = false">Cancel <i class="el-icon-close"></i></el-button>
      <el-button :loading="loading" type="primary" @click="saveChanges">Save Changes <i class="el-icon-circle-check-outline"></i></el-button>
    </div>
  </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'role-assign-permission-modal',
  props: ['role'],
  data () {
    return {
      visible: true,
      assigned: [],
      unassigned: [],
      loading: false
    }
  },
  methods: {
    closedHandler () {
      this.$emit('close')
    },
    getPermissions () {
      this.$permissionService.getPermissions()
        .then(res => {
          this.unassigned = res.map(p => ({ label: p.name, key: p.id, initials: p.name }))
        })
    },
    getRolePermissions () {
      this.$roleService.getRolePermissions(this.role.id)
      // this.$http.get(`roles/permissions/${this.role.id}`)
        .then(res => {
          this.assigned = res.map(p => p.id)
        })
    },
    saveChanges () {
      this.loading = true
      this.$roleService.assignPermissions(this.role.id, { permissions: this.assigned })
        .then(res => {
          this.$notify.success(this.$t('role.message.permission_assigned').toString())
          this.closedHandler()
          this.loading = false
        })
        .catch(this.globalErrHandle)
    }
  },
  mounted () {
    this.getRolePermissions()
    this.getPermissions()
  }
}
</script>
<style scoped>

</style>
