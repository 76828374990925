<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
             @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center">{{ $t('service.title.create') }}</h3>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form size="medium" ref="form">
          <el-form-item :label="$t('service.title.user')">
            <el-select ref="input" v-model="user" :placeholder="$t('text.click_to_select')" class="full-width">
              <el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" :offset="6">
        <el-button @click="$emit('close', false)">{{ $t('button.close') }}</el-button>
        <el-button :disabled="!user" :loading="loading" type="primary" @click="submitForm">
          {{ $t('service.button.create') }}
        </el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddServiceDialog',
  props: {
    display: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      users: [],
      user: null,
      loading: false
    }
  },
  methods: {
    async opened () {
      await this.fetchServiceProspects()
      this.$refs.input.focus()
    },
    closed () {
      this.user = null
      this.loading = false
    },
    submitForm () {
      this.askQuestion(this.$t('service.confirm.create'), () => {
        this.loading = true
        this.$serviceService.createService(this.user)
          .then(data => {
            this.notify(this.$t('service.message.created'), this.$t('status.success'))
            this.$emit('created', data)
            this.$emit('close')
          })
          .catch(this.afterSubmitError)
          .then(() => {
            this.loading = false
          })
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    },
    async fetchServiceProspects () {
      this.users = await this.$serviceService.getNonServices()
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
