<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('item.title.archive') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="items.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                                <el-table-column label="S/N" type="index" sortable fixed></el-table-column>
                                <el-table-column prop="name" label="Name" min-width="180" fixed></el-table-column>
                                <el-table-column label="Unit Price" min-width="100" sortable fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.unit_price | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="unit_type" label="Unit Type" sortable></el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions" min-width="100px">
                                    <template slot="header" slot-scope="scope">
                                        <el-input v-model="search" size="mini" autofocus placeholder="Type to search"/>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-button @click="beforeArchive(scope.row.id)" type="warning" size="mini"
                                            icon="mdi mdi-restore-clock">
                                            {{ $t('button.restore') }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Archive',
  data () {
    return {
      items: [],
      search: ''
    }
  },
  methods: {
    beforeArchive (id) {
      this.askQuestion(this.$t('item.confirm.restore'), () => {
        this.$itemService.restoreItem(id)
          .then(() => {
            this.notify(this.$t('item.message.restored'))
            this.items.splice(this.items.indexOf(this.items.find(d => d.id === id)), 1)
          })
      })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('item.title.archive'))
    this.$itemService.getArchivedItems()
      .then(data => {
        this.items = data
      })
  }
}
</script>

<style scoped>

</style>
