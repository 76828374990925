<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
             @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center" v-if="validData">
        {{ $t(`warehouse.title.update_price`, [warehouse.items.find(i => i.id === item).name]) }}
        ({{ warehouse.name }})
      </h3>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form :model="form" size="medium" :rules="rules" ref="form">
          <el-form-item :label="$t('warehouse.label.selling_price')" label-width="100" prop="price">
            <el-input @keyup.enter.native="submitForm" ref="input" v-model.number="form.price"></el-input>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" :offset="6">
        <el-button @click="$emit('close', false)">{{ $t('button.cancel') }}</el-button>
        <el-button :loading="loading" type="primary" @click="submitForm">
          {{ $t(`warehouse.button.update_price`) }}
        </el-button>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
export default {
  name: 'ModifySellingPrice',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    item: {
      type: Number,
      required: false
    },
    warehouse: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      form: {
        price: ''
      },
      rules: {
        price: [
          { type: 'number', min: 0, required: true, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: {
    validData () {
      return this.warehouse && this.item
    }
  },
  methods: {
    opened () {
      this.form.price = this.warehouse.items.find(i => i.id === this.item).pivot.selling_price
      this.$refs.input.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = null
      })
      this.loading = false
    },
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t('warehouse.confirm.update_price'), () => {
            this.loading = true
            this.$warehouseService.updateItemSellingPrice(this.warehouse.id, this.item, this.form)
              .then(data => {
                this.notify(this.$t('warehouse.message.updated_price'), this.$t('status.success'))
                this.$emit('updated')
                this.$emit('close')
              })
              .catch(this.afterSubmitError)
              .then(() => {
                this.loading = false
              })
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
