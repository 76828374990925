<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <el-card class="box-card text-center">
                    <div slot="header" class="clearfix">
                        <h3>{{ $t('report.payroll.name') }}</h3>
                    </div>
                    <el-form :inline="true" :model="form" class="demo-form-inline">
                        <el-form-item label="Debt Types">
                            <el-select collapse-tags multiple v-model="form.types" placeholder="All Debt Categories">
                                <el-option v-for="(type, ind) in types" :key="ind" :label="type.name"
                                           :value="type.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="" style="margin-top: 8px">
                            <el-radio-group v-model="form.dl" size="mini">
                                <el-radio-button @click.native.prevent="dl('PDF')" label="PDF"></el-radio-button>
                                <el-radio-button @click.native.prevent="dl('EXCEL')" label="EXCEL"></el-radio-button>
                            </el-radio-group>
                            <!--                            <el-checkbox v-model="form.pdf">PDF</el-checkbox>-->
                        </el-form-item>
                        <!--<el-form-item label="">
                            <el-checkbox v-model="form.excel">Excel</el-checkbox>
                        </el-form-item>-->
                        <el-form-item>
                            <el-button :loading="loading" type="primary" @click="onReportSubmit">Query</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
        </div>
        <br>
        <el-row>
            <el-card class="box-card">
                <el-table :data="employees" style="width: 100%;text-align: center;" :summary-method="getSummaries"
                          show-summary>
                    <span slot="empty" class="text-warning">No Data Available</span>
                    <el-table-column v-for="(a, b) of titles" :prop="a" :key="a" :label="a" align="center">
                        <template slot-scope="scope">
                            <span class="currency" v-if="Number.isInteger(scope.row[scope.column.property])">
                                {{ scope.row[scope.column.property] | currency }}
                            </span>
                            <span v-else>{{ scope.row[scope.column.property] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'Supplies',
  data () {
    return {
      form: {
        dl: false,
        types: []
      },
      titles: [],
      data: [],
      employees: [],
      loading: false,
      types: []
    }
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          // sums[index] = this.$t('title.totals')
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = this.formatPrice(sums[index])
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    onReportSubmit () {
      const query = Object.assign({}, this.form)
      if (!query.dl) delete query.dl
      this.loading = true
      this.$reportService.getCurrentPayroll(query)
        .then(data => {
          this.titles = data.data.splice(0, 1)[0]
          this.data = data.data
          const construct = []
          for (const row of this.data) {
            const r = {}
            for (const index in row) {
              if (row.hasOwnProperty(index)) {
                r[this.titles[index]] = row[index]
              }
            }
            construct.push(r)
          }
          this.employees = construct
          if (this.form.dl && data.url) {
            this.downloadFile(data.url)
          }
        })
        .catch(error => {
          this.displayMessage(error.message, () => {
          }, 'error')
        })
        .then(() => {
          this.loading = false
        })
    },
    downloadFile (url) {
    },
    dl (type) {
      if (this.form.dl.length) {
        if (this.form.dl === type) {
          this.form.dl = false
        } else {
          this.form.dl = type
        }
      } else {
        this.form.dl = type
      }
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('report.payroll.name'))
    this.$debtService.getDebtTypes()
      .then(data => (this.types = data.data))
  }
}
</script>

<style>
    .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
        -webkit-box-shadow: none; /*0 0 2px 2px #409EFF;*/
        box-shadow: none; /*0 0 2px 2px #409EFF*/;
    }
</style>
