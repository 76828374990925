<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12 text-center">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <h3>{{ $t('report.deposit.name') }}</h3>
                    </div>
                    <el-form :inline="true" :model="form" class="demo-form-inline">
                        <el-form-item label="Start">
                            <el-date-picker v-model="form.start_date" format="dd MMM, yyyy"
                                            value-format="yyyy-MM-dd"
                                            placeholder="Select a start date"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="End">
                            <el-date-picker v-model="form.end_date" format="dd MMM, yyyy"
                                            value-format="yyyy-MM-dd"
                                            placeholder="Select an end date"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="Account Subcategory">
                            <el-select collapse-tags multiple v-model="form.account_subcategory_id" placeholder="Choose supplier">
                                <el-option v-for="(sup, ind) in subcategories" :key="ind" :label="sup.name"
                                           :value="sup.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="">
                            <el-checkbox v-model="form.dl">Download</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button :loading="loading" type="primary" @click="onReportSubmit">Query</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
        </div>
        <br>
        <el-row>
            <el-card class="box-card">
                <el-table :data="data" style="width: 100%" show-summary :summary-method="getSummaries">
                    <span slot="empty" class="text-warning">No deposits have been received</span>
                    <el-table-column in label="S/N" type="index" fixed></el-table-column>
                    <el-table-column
                            prop="subcategory.name"
                            label="Account"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="amount"
                            label="Amount">
                        <template slot-scope="scope">
                            <span class="currency">{{ scope.row.amount | currency('XAF') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="message"
                            label="Message"
                            min-width="220">
                    </el-table-column>
                    <el-table-column fixed="right"
                            prop="date"
                            label="Date" :formatter="$shortDate">
                    </el-table-column>
                </el-table>
            </el-card>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'Supplies',
  data () {
    return {
      form: {
        start_date: null,
        end_date: null,
        account_subcategory_id: null,
        dl: false
      },
      subcategories: [],
      data: [],
      loading: false
    }
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          // sums[index] = 'Totals'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = this.formatPrice(sums[index])
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    onReportSubmit () {
      const query = Object.assign({}, this.form)
      if (!query.dl) delete query.dl
      this.loading = true
      this.$reportService.getDeposits(query)
        .then(data => {
          this.data = data.data
          if (this.form.dl && data.url) {
            this.downloadFile(data.url)
          }
        })
        .catch(error => {
          this.displayMessage(error.message, () => {
          }, 'error')
        })
        .then(() => {
          this.loading = false
        })
    },
    downloadFile (url) {
    },
    showProgress (received, total) {
      const percentage = (received * 100) / total
      console.log(percentage + '% | ' + received + ' bytes out of ' + total + ' bytes.')
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('report.deposit.name'))
    this.$accountService.getAllSubcategories()
      .then(data => (this.subcategories = data))
  }
}
</script>

<style scoped>

</style>
