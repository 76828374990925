<template>
  <div>
    <div class="bg-dark m-b-30">
      <div class="container">
        <div class="row p-b-60 p-t-60">
          <div class="col-md-4 text-white p-b-30">
            <div class="media">
              <div class="avatar avatar mr-3">
                <div class="avatar-title bg-success rounded-circle mdi mdi-shopping"></div>
              </div>
              <div class="media-body">
                <h3 class="p-t-10">{{ pageTitle }}</h3>
                <p class="opacity-75"></p>
              </div>
            </div>
          </div>
          <div class="col-md-8 text-center m-b-30 ml-auto">
            <div class="text-md-right">
              <router-link v-can="'session.read'" class="el-button el-button--medium el-button--primary" :to="{name: 'sessions'}">
                <i class="el-icon-s-order"></i> {{ $t('session.title.session') }}
              </router-link>
              <router-link v-can="'session.create'" class="el-button el-button--medium el-button--success" :to="{name: 'create-sessions'}">
                <i class="el-icon-s-order"></i> {{ $t('session.button.create') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <router-view @parent-title="updateTitle"/>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'Session',
  data () {
    return {
      pageTitle: '',
      displayCreateDialog: false
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    showCreateDialog () {
      this.displayCreateDialog = true
    },
    closeCreateDialog () {
      this.displayCreateDialog = false
    },
    itemCreated (data) {
      this.$emit('new-item', data)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
