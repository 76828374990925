<template>
  <div>
    <el-dialog title="License Activation" :visible.sync="licenseDialog" width="40%" center lock-scroll
               :show-close="true" :close-on-click-modal="false" :close-on-press-escape="false">
      <div>
        <el-tabs type="card" stretch v-model="lc.type">
          <el-alert v-if="status.message" :type="status.type || 'info'" effect="dark"
                    v-html="status.message"
                    show-icon closable class="mb-1"/>
          <el-tab-pane name="token" lazy :disabled.sync="loading">
            <span slot="label"><i class="el-icon-message"></i> Activation code</span>
            <el-input ref="l_token" autofocus rows="5" v-model="lc.activation_token" type="textarea"
                      placeholder="Paste activation code here"/>
            <div class="mt-2">
              <el-button :loading="loading" type="primary" @click="activate">Activate</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane name="account" lazy :disabled.sync="loading">
            <span slot="label"><i class="el-icon-user"></i> Salamanda Account</span>
            <el-form label-width="120px">
              <el-form-item label="Application secret">
                <el-input type="text" v-model.trim="lc.secret" readonly/>
              </el-form-item>
              <el-form-item label="Username">
                <el-input ref="l_username" type="text" v-model="lc.username"/>
              </el-form-item>
              <el-form-item label="Password">
                <el-input type="password" v-model="lc.password"/>
              </el-form-item>
              <el-form-item class="justify-content-end pull-right">
                <el-button :loading="loading" round type="primary" @click="activate">Sign In</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: 'Base',
  data () {
    return {
      dialogMessage: 'Please, enter address to your hosted server.',
      licenseDialog: false,
      lc: {
        secret: 'salamanda-v2',
        type: 'account',
        activation_token: '',
        username: 'test-server',
        password: 'Password!'
      },
      status: {
        type: '',
        message: null
      },
      loading: false
    }
  },
  watch: {
    'lc.type' (value) {
      setTimeout(() => {
        if (value === 'token') {
          this.$refs.l_token.focus()
        } else if (value === 'account') {
          this.$refs.l_username.focus()
        }
      }, 800)
    }
  },
  computed: {
    ...mapState({
      baseUrl: state => state.Base.BASE_URL
    })
  },
  methods: {
    ...mapActions('Base', [
      'setBaseURL'
    ]),
    showURLDialog () {
      this.$prompt(this.dialogMessage, 'SALAMANDA SERVER NOT SET!', {
        confirmButtonText: 'Verify',
        showClose: false,
        closeOnClickModal: false,
        showCancelButton: false,
        confirmButtonClass: 'el-button--primary',
        closeOnPressEscape: false,
        distinguishCancelAndClose: true,
        // inputPattern: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6}|[0-9]{1,3})([/\w .-]*)*\/?$/, // @todo verify url regex
        inputErrorMessage: 'Please, enter a valid URL',
        inputValue: '',
        center: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          done()
        }
      }).then(({ value }) => {
        this.$http.post('ping', {}, {
          baseURL: value
        })
          .then(res => res.data)
          .then(data => {
            if (data.data === 'pong') {
              this.setBaseURL(value)
              this.verifyLicense()
            } else {
              this.showURLDialog()
            }
          })
          .catch(() => {
            this.dialogMessage = 'Incorrect server address or server is unreachable. Please, contact the system administrator.'
            this.showURLDialog()
          })
      })
    },
    // @todo Refactor code to user licenseService
    async activate () {
      this.status.message = null
      this.loading = true
      const instance = axios.create({
        baseURL: 'http://localhost:8000/',
        timeout: 5000,
        headers: { Accept: 'application/json' }
      })
      await instance.get('sanctum/csrf-cookie')
      instance.post('api/v1/login', this.lc)
        .then(resp => resp.data)
        .then(data => {
          instance.get('api/v1/license', {
            headers: {
              Authorization: `Bearer ${data.token}`
            }
          })
            .then(resp => {
              this.status.message = `License found: ${resp.data.client.name} (expires: ${resp.data.end_date})`
              this.status.type = 'success'
              this.$authService.setLicense(resp.data, this.baseUrl)
                .then(data => {
                  this.$notify.success('License set successfully.')
                  this.loading = false
                  const loader = this.$loading({
                    text: 'Starting application...'
                  })
                  setTimeout(() => {
                    loader.close()
                    this.$router.push({ name: 'login' })
                  }, 2000)
                })
                .catch(err => {
                  this.status.message = err.response.data.message || 'An error occurred. Please, check your computer settings and try again.'
                  this.status.type = 'warning'
                  this.loading = false
                })
            })
            .catch((err) => {
              if (err.response.status === 502) {
                this.status.message = 'You do not have any active license at the moment. Please, contact support for assistance.'
                this.status.type = 'warning'
              } else {
                this.status.message = err.response.data.message || `Error occurred with status: ${err.response.status || err.response.request}`
                this.status.type = 'error'
              }
              this.loading = false
            })
        })
        .catch(err => {
          this.status.message = err.response.data.message || `Error occurred with status: ${err.response.status || err.response.request}`
          this.status.type = 'error'
          this.loading = false
        })
    },
    async verifyLicense () {
      return new Promise((resolve, reject) => {
        this.$authService.verifyLicense()
          .then(data => {
            const loader = this.$loading({
              text: 'Successful! Redirecting you to login resource...'
            })
            setTimeout(() => {
              loader.close()
              this.$router.push({ name: 'login' })
            }, 2000)
            // resolve(data)
          })
          .catch(err => {
            this.licenseDialog = true
            this.status.message = err.response.data.message || 'Invalid license'
            this.status.type = 'warning'
            reject(err)
          })
      })
    }
  },
  async mounted () {
    // if (!this.baseUrl || !this.baseUrl.length) {
    //   this.showURLDialog()
    // } else {
    //   await this.verifyLicense()
    // }
    this.$router.push({ name: 'login' })
  }
}
</script>

<style scoped>

</style>
