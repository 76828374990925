<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
             @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center">{{ $t(`warehouse.title.${edit ? 'update' : 'create'}`) }}</h3>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form :model="form" size="medium" :rules="rules" ref="form">
          <el-form-item :label="$t('warehouse.label.name')" label-width="100" prop="name">
            <el-input @keyup.enter.native="submitForm" ref="input" v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('warehouse.label.description')" label-width="100" prop="description">
            <el-input type="textarea" v-model="form.description"></el-input>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" :offset="6">
        <el-button @click="$emit('close', false)">{{ $t('button.cancel') }}</el-button>
        <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
          {{ $t(`warehouse.button.${edit ? 'update' : 'create'}`) }}
        </el-button>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
export default {
  name: 'createWarehouse',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    warehouse: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: '',
        description: ''
      },
      rules: {
        name: [
          { required: true, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: {
    submittable () {
      return !!this.form.name.length
    },
    edit () {
      return this.warehouse && this.warehouse.id
    }
  },
  methods: {
    opened () {
      if (this.warehouse && this.warehouse.id) {
        this.form.name = this.warehouse.name
        this.form.description = this.warehouse.description
      }
      this.$refs.input.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = ''
      })
      this.loading = false
    },
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.edit) {
            this.askQuestion(this.$t('warehouse.confirm.update'), () => {
              this.loading = true
              this.$warehouseService.updateWarehouse(this.warehouse.id, this.form)
                .then(data => {
                  this.notify(this.$t('warehouse.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            })
          } else {
            this.askQuestion(this.$t('warehouse.confirm.create'), () => {
              this.loading = true
              this.$warehouseService.createWarehouse(this.form)
                .then(data => {
                  this.notify(this.$t('warehouse.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            })
          }
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
