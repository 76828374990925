<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">

                    <div class="col-md-6 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-account-supervisor"></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }} </h3>
                                <p class="opacity-75">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link v-can="'customer.read'" :to="{name: 'customers'}" class="el-button el-button--primary el-button--medium"><i
                                    class="mdi mdi-format-list-bulleted"></i> {{ $t('customer.button.all') }}
                            </router-link>
                            <router-link v-can="'customer.create'" :to="{name: 'create-customer'}" class="el-button el-button--success el-button--medium"><i
                                    class="mdi mdi-plus"></i> {{ $t('customer.button.create') }}
                            </router-link>
                            <router-link v-can="'customer.archives'" :to="{name: 'archived-customers'}" class="el-button el-button--warning el-button--medium"><i
                                    class="mdi mdi-archive"></i> {{ $t('customer.button.archive') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle"/>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'Customers',
  data () {
    return {
      pageTitle: '' // this.$t('supply.title.purchase')
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    }
  }
}
</script>

<style scoped>

</style>
