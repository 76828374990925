<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-account-supervisor"></i> {{ $t('employee.title.archive') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="customers.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                                <el-table-column in label="S/N" type="index" sortable></el-table-column>
                                <el-table-column prop="name" label="Name"></el-table-column>
                                <el-table-column prop="address" label="Address"></el-table-column>
                                <el-table-column prop="user.name" label="Creator"></el-table-column>
                                <el-table-column prop="phone" label="Phone" :formatter="$formatPhone"></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                ></el-table-column>
                                <el-table-column label="Actions" min-width="100px">
                                    <template slot="header" slot-scope="scope">
                                        <el-input v-model="search" size="mini" autofocus
                                                placeholder="Type to search"/>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-popconfirm :title="$t('customer.confirm.restore')" :confirmButtonText="$t('button.restore')"
                                                       :cancelButtonText="$t('button.no')"
                                                       @onConfirm="restore(scope.$index, scope.row.id)">
                                            <button @click.prevent="" slot="reference" class="btn btn-sm btn-warning">
                                                <i class="mdi mdi-restore"></i> {{ $t('button.restore') }}
                                            </button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Archive',
  data () {
    return {
      customers: [],
      search: ''
    }
  },
  methods: {
    restore (index, id) {
      this.$customerService.restoreCustomer(id)
        .then(data => {
          this.customers.splice(this.customers.indexOf(this.customers.find(e => e.id === id)), 1)
          this.$notify({
            title: this.$t('status.success'),
            message: this.$t('customer.message.restored'),
            type: 'success'
          })
        })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('customer.title.archive'))
    this.$customerService.getArchivedCustomers()
      .then(data => {
        this.customers = data
      })
  }
}
</script>

<style scoped>

</style>
