<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('debt.type.name') }}
                                </h5>
                            </el-col>
                            <el-col :span="12" class="text-right">
                                <el-button @click.prevent="showDebtDialog" type="primary" icon="el-icon-plus">{{
                                    $t('debt.button.create_type') }}
                                </el-button>
                            </el-col>
                        </el-row>

                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="types"
                                         :pagination-props="{ pageSizes: [20, 30, 40, 100], pageSize: 20 }"
                                         center-align>
                                <el-table-column in label="S/N" type="index" fixed></el-table-column>
                                <el-table-column prop="name" label="Name"
                                                 min-width="120" fixed></el-table-column>
                                <el-table-column prop="type.can_pay" label="Payable">
                                    <template slot-scope="scope">
                                        <i v-if="scope.row.can_pay" class="el-icon-check active-text"></i>
                                        <i class="el-icon-close fain-text" v-else></i>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="type.affects_account" label="Deductible">
                                    <template slot-scope="scope">
                                        <i v-if="scope.row.affects_account" class="el-icon-check active-text"></i>
                                        <i class="el-icon-close fain-text" v-else></i>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="user.name" label="Creator"></el-table-column>
                                <el-table-column prop="created_at" label="Created At">
                                    <template slot-scope="scope">{{ shortDate(scope.row.created_at) }}</template>
                                </el-table-column>
                                <el-table-column label="Actions" min-width="190" fixed="right">
                                    <template slot-scope="scope">
                                        <el-button icon="el-icon-edit" type="success" size="mini"
                                                   @click.prevent="showDebtDialog(scope.row.id)">Edit
                                        </el-button>
                                        <el-popconfirm @onConfirm="archiveType(scope.row.id)" icon-color="red"
                                                       :confirmButtonText="$t('button.ok')"
                                                       :cancelButtonText="$t('button.cancel')"
                                                       :title="$t('debt.message.archive')">
                                            <el-button slot="reference" icon="el-icon-takeaway-box" type="warning"
                                                       size="mini">
                                                {{ $t('button.archive') }}
                                            </el-button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <edit-type :visible="visibleTypeDialog" :debt-type="type" @close="closeTypeDialog"
                   @new-type="newType"></edit-type>
    </div>
</template>

<script>
import EditType from './Modal/CreateUpdateType'

export default {
  name: 'DebtTypes',
  components: { EditType },
  data () {
    return {
      types: [],
      visibleTypeDialog: false,
      type: {}
    }
  },
  methods: {
    newType (data, update = false) {
      if (update) {
        this.types.splice(this.types.indexOf(this.types.find(t => t.id === data.id)), 1, data)
      } else this.types.splice(0, 0, data)
    },
    showDebtDialog (id = null) {
      if (id) {
        this.type = this.types.find(d => d.id === id)
      }
      this.visibleTypeDialog = true
    },
    closeTypeDialog () {
      this.visibleTypeDialog = false
      this.type = {}
    },
    archiveType (id) {
      this.$debtService.archiveDebtType(id)
        .then(() => {
          this.types.splice(this.types.indexOf(this.types.find(d => d.id === id)), 1)
          this.notify(this.$t('debt.type.archived'))
        })
        .catch(error => {
          this.notify(error.message, this.$t('status.error'), 'error')
        })
    }
  },
  mounted () {
    setTimeout(() => {
      this.$emit('parent-title', this.$t('debt.type.name'))
    }, 1000)
    this.$debtService.getDebtTypes()
      .then(data => {
        this.types = data.data
      })
  }
}
</script>

<style scoped>
    .fain-text {
        color: #8d97a5;
    }

    .active-text {
        color: #00cc99;
    }
</style>
