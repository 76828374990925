<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('variant.button.all') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables :data="variants"
                           :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                <el-table-column label="S/N" type="index" ></el-table-column>
                <el-table-column show-overflow-tooltip prop="name" :label="$t('variant.table.title.name')" ></el-table-column>
                <el-table-column prop="user.name" :label="$t('label.creator')" ></el-table-column>
                <el-table-column prop="created_at" :formatter="$shortDate" :label="$t('label.created_at')"
                                 sortable></el-table-column>
                <el-table-column label="Actions" min-width="100px">
                  <template slot-scope="scope">
                    <el-button v-can.disable="'variant.update'" @click="showEditDialog(scope.row.id)" split-button type="primary" size="small">
                      <i class="el-icon-edit"></i> {{ $t('button.edit') }}
                    </el-button>
                    <el-button v-can.disable="'variant.delete'" @click="variantDelete(scope.$index, scope.row.id)" :loading="loading" split-button type="danger" size="small">
                      <i class="el-icon-delete"></i> {{ $t('button.delete') }}
                    </el-button>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-variant :display="displayEditDialog" @close="closeEditDialog" @updated="variantUpdate"
                   @created="newVariant" :variant="variant"></edit-variant>
  </div>
</template>

<script>
import CreateVariant from './Modal/Create'
export default {
  name: 'Index',
  components: {
    'edit-variant': CreateVariant
  },
  data () {
    return {
      loading: false,
      variants: [],
      variant: {},
      displayEditDialog: false
    }
  },
  methods: {
    showEditDialog (id) {
      this.variant = this.variants.find(d => d.id === id)
      this.displayEditDialog = true
    },
    closeEditDialog () {
      this.displayEditDialog = false
    },
    newVariant (data) {
      this.variants.splice(0, 0, data)
    },
    variantUpdate (data) {
      this.variants.splice(this.variants.indexOf(this.variants.find(d => d.id === data.id)), 1, data)
    },
    variantDelete (index, id) {
      this.askQuestion(this.$t('variant.confirm.delete'), async () => {
        this.loading = true
        await this.$variantService.deleteVariant(id)
          .then(async data => {
            await this.$variantService.getVariantOptions(id)
              .then(async data => {
                if (data.length > 0) {
                  for (const option of data) {
                    await this.$variantService.deleteVariantOption(option.id)
                      .then(res => {})
                  }
                }
              })
            this.variants.splice(index, 1)
            this.loading = false
            this.notify(this.$t('variant.message.deleted'), this.$t('status.success'))
          })
      })
    },
    showUsersDialog (id) {}
  },
  async mounted () {
    this.$emit('parent-title', this.$t('variant.title.variant'))
    await this.$variantService.getVariants()
      .then(data => {
        this.variants = data
      })
  },
  created () {
    this.$parent.$on('new-variant', this.newVariant)
  }
}
</script>

<style scoped>

</style>
