<template>
  <div id="app">
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'salamanda-backoffice',
  computed: {
    ...mapState({
      loggedIn: state => state.Auth.logged_in
    })
  },
  created () {
    // this.$parent.$on('new-department', this.newDepartment)
  }
}
</script>

<style>

/* CSS */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.currency {
  font-size: 14px;
  color: #0011ff;
}

.f-w {
  width: 100%!important;
}

.f-24 {
  font-size: 23px !important;
}

.f-18 {
  font-size: 18px !important;
}

.el-message-box__header, .el-dialog__header {
  border-top: 5px solid #409EFF;
}

.highlight-primary {
  color: #6772e5;
}
.line-break::before{
  content: "\a";
  white-space: pre;
}
</style>
