import { render, staticRenderFns } from "./Salary.vue?vue&type=template&id=08ed5ad3&scoped=true"
import script from "./Salary.vue?vue&type=script&lang=js"
export * from "./Salary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08ed5ad3",
  null
  
)

export default component.exports