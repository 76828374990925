<template>
  <div>
    <div class="bg-dark m-b-30">
      <div class="container">
        <div class="row p-b-60 p-t-60">
          <div class="col-md-4 text-white p-b-30">
            <div class="media">
              <div class="avatar avatar mr-3">
                <div class="avatar-title bg-success rounded-circle mdi mdi-shopping"></div>
              </div>
              <div class="media-body">
                <h3 class="p-t-10">{{ pageTitle }}</h3>
                <p class="opacity-75"></p>
              </div>
            </div>
          </div>
          <div class="col-md-8 text-center m-b-30 ml-auto">
            <div class="text-md-right">
              <router-link v-can="'item.read'" class="el-button el-button--medium el-button--primary"
                           :to="{name: 'items'}">
                <i class="el-icon-s-order"></i> {{ $t('item.button.item') }}
              </router-link>
              <el-button v-can="'item.create'" @click="showCreateDialog" size="medium" icon="el-icon-plus"
                         type="success">
                {{ $t('item.button.create') }}
              </el-button>
              <!--<router-link class="el-button el-button--medium el-button--default" :to="{name: 'labels'}">
                <i class="mdi mdi-label"></i> {{ $t('item.button.labels') }}
              </router-link>
                <router-link v-can="'item.archives'" class="el-button el-button--medium el-button--warning"
                             :to="{name: 'items-archive'}" icon="el-icon-takeaway-box">
                    <i class="mdi mdi-archive"></i> {{ $t('button.archive') }}
                </router-link>
              <router-link class="el-button el-button--medium el-button--info" :to="{name: 'upload-items'}">
                <i class="mdi mdi-upload"></i> {{ $t('item.button.upload') }}
              </router-link>-->
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="mdi mdi-dots-vertical el-icon--right mdi-24px text-white"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <router-link :to="{name: 'upload-items'}" custom v-slot="{isActive}">
                      <el-dropdown-item icon="mdi mdi-upload" @click.native="$go('upload-items')" :disabled="isActive"
                                        divided>
                        {{ $t('item.button.upload') }}
                      </el-dropdown-item>
                    </router-link>
                    <router-link :to="{name: 'labels'}" custom v-slot="{isActive}">
                      <el-dropdown-item :disabled="isActive" icon="mdi mdi-label" @click.native="$go('labels')"
                                        divided>
                        {{ $t('item.button.labels') }}
                      </el-dropdown-item>
                    </router-link>
                    <router-link :to="{name: 'items-archive'}" custom v-slot="{isActive}">
                      <el-dropdown-item :disabled="isActive" icon="el-icon-takeaway-box"
                                        @click.native="$go('items-archive')" divided>
                        {{ $t('button.archive') }}
                      </el-dropdown-item>
                    </router-link>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-edit-item @close="closeCreateDialog" :display="displayCreateDialog"
                      @created="itemCreated"></create-edit-item>
    <transition name="slide-fade">
      <router-view @parent-title="updateTitle"/>
    </transition>
  </div>
</template>

<script>
import CreateEditItem from './Modal/CreateEditItem'

export default {
  name: 'Items',
  components: { CreateEditItem },
  data () {
    return {
      pageTitle: '',
      displayCreateDialog: false
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    showCreateDialog () {
      this.displayCreateDialog = true
    },
    closeCreateDialog () {
      this.displayCreateDialog = false
    },
    itemCreated (data) {
      this.$emit('new-item', data)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
