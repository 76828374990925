<template>
    <div class="container  pull-up">
        <div class="row">
            <div class="col-lg-12">

                <div class="card m-b-30 ">
                    <div class="card-header">
                        <h4 class="m-b-0">
                            Update Supplier [{{ form.name }}]
                            <hr>
                        </h4>
                        <p class="m-b-0 opacity-50">
                            Fields with
                            <req/>
                            are required
                        </p>
                    </div>
                    <div class="card-body ">
                        <el-form label-position="left" ref="form" :rules="rules" :model="form" label-width="120px">
                            <el-form-item label="Name" prop="name">
                                <el-input v-model="form.name" placeholder="Supplier full names"></el-input>
                            </el-form-item>
                            <el-form-item label="Address" prop="address">
                                <el-input v-model="form.address" placeholder="Address 1234 Main St"></el-input>
                            </el-form-item>
                            <el-form-item label="Phone Number" prop="phone">
                                <el-input v-model="form.phone" placeholder="Mobile number"></el-input>
                            </el-form-item>
                            <el-form-item class="text-center">
                                <div class="row">
                                    <div class="col-md-6"></div>
                                    <div class="col-md-6">
                                        <el-button type="primary" class="btn-block" @click.prevent="beforeUpdate">Update Supplier</el-button>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Edit',
  data () {
    return {
      form: {},
      rules: {
        name: [
          { required: true, message: 'Please input a valid supplier name', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Please input a valid address', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Please input phone number', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    beforeUpdate () {
      this.$confirm(this.$t('supplier.confirm.update'), this.$t('status.warning'), {
        confirmButtonText: this.$t('status.ok'),
        cancelButtonText: this.$t('status.cancel'),
        type: 'warning'
      }).then(() => {
        this.update()
      })
    },
    update () {
      this.$supplierService.update(this.form.id, Object.assign({}, this.form))
        .then(data => {
          this.$notify({
            title: this.$t('status.success'),
            message: this.$t('supplier.update.success'),
            type: 'success'
          })
          this.$router.push({ name: 'suppliers' })
        })
        .catch(console.log)
    }
  },
  mounted () {
    this.$supplierService.findById(this.$route.params.id)
      .then(data => {
        this.form = data
      })
  }
}
</script>

<style scoped>

</style>
