<template>
    <div class="container  pull-up">
        <div class="row">
            <div class="col-lg-12">

                <div class="card m-b-30 ">
                    <div class="card-header">
                        <p class="m-b-0 opacity-50">
                            Fields with
                            <req/>
                            are required
                        </p>
                        <hr>
                    </div>
                    <div class="card-body ">
                        <el-form size="medium" label-position="left" ref="form" :rules="rules" :model="form"
                                 label-width="150px" class="p-r-15">
                            <el-form-item label="Name" prop="name">
                                <el-input v-model="form.name" placeholder="Employee full names"></el-input>
                            </el-form-item>
                            <el-form-item label="Address" prop="address">
                                <el-input v-model="form.address" placeholder="Akwa 1234 Main St"></el-input>
                            </el-form-item>
                            <el-form-item label="ID Card #" prop="nic">
                                <el-input v-model="form.nic" placeholder="NIC"></el-input>
                            </el-form-item>
                            <el-form-item label="Phone Number" prop="phone">
                                <el-input v-model="form.phone" placeholder="Mobile number"></el-input>
                            </el-form-item>
                            <el-form-item label="Employee Category" prop="type_id">
                                <el-select v-model="form.type_id" filterable placeholder="Click to select" class="f-w">
                                    <el-option v-for="type in types" :key="type.id" :label="type.name" :value="type.id">
                                        <span style="float: left">{{ type.name }}</span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">{{ type.salary | currency('XAF') }}</span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Employment Date" prop="employment_date">
                                <el-date-picker class="f-w" v-model="form.employment_date" type="date"
                                                format="d MMM, yyyy"
                                                value-format="yyyy/MM/dd"
                                                placeholder="Pick a day" :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item class="text-center">
                                <div class="row">
                                    <div class="col-md-6"></div>
                                    <div class="col-md-6">
                                        <el-button :loading="loading" type="primary" class="btn-block" @click.prevent="create">
                                            Create Employee
                                        </el-button>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Create',
  data () {
    return {
      types: [],
      form: {
        name: '',
        nic: '',
        employment_date: '',
        address: '',
        email: '',
        phone: '',
        type_id: null
      },
      rules: {
        name: [
          { required: true, message: 'Please enter the employee name', trigger: 'blur' }
        ],
        address: [
          { required: false, message: 'Please enter the employee address', trigger: 'blur' }
        ],
        email: [
          { required: false, message: 'Please enter the employee email address', trigger: 'blur' }
        ],
        employment_date: [
          { required: true, message: 'Please select the employee date of employment', trigger: 'blur' }
        ],
        nic: [
          { required: true, message: 'Please National Identity Card Number of the employee', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Please enter the employee phone number', trigger: 'blur' }
        ],
        type_id: [
          { required: true, message: 'Please select the employee category', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: 'A week ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      loading: false
    }
  },
  methods: {
    create () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$employeeService.createEmployee(this.form)
            .then(response => {
              this.clearFields()
              this.displayMessage(`${response.employee.name} created`, () => {
                this.$router.push({ name: 'employees' })
              })
            })
            .catch(error => {
              this.displayMessage(`${error.message} created`, () => {}, 'error', 'Something went wrong')
            })
            .then(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    clearFields () {
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    this.$emit('parent-title', 'New Employees')
    this.$employeeService.getEmployeeTypes()
      .then(types => {
        this.types = types
      })
  }
}
</script>

<style scoped>

</style>
