<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-timer"></i> {{ $t('session.name') }}
            </h5>
          </div>
          <div class="card-body">
                <el-form :rules="rules" ref="form" :model="data">
                    <el-form-item prop="department" label="Department">
                        <el-select v-model="data.department" @change="data.user = ''" placeholder="Select Department"
                                   class="full-width">
                            <el-option
                                    v-for="department in departments"
                                    :key="department.id"
                                    :label="department.name"
                                    :value="department.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="user" label="User">
                        <el-select v-model="data.user" placeholder="Select User" class="full-width">
                            <el-option
                                    v-for="user in filterUsers"
                                    :key="user.id"
                                    :label="user.name"
                                    :value="user.id" :disabled="!user.active">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="card-footer">
                <button @click.prevent="createSession" class="btn btn-primary icon-btn" type="button"><i class="fa fa-fw fa-lg fa-check-circle"></i>Create
                    Session
                </button>&nbsp;&nbsp;&nbsp;<a class="btn btn-default icon-btn" href="#"><i
                    class="fa fa-fw fa-lg fa-times-circle"></i>Cancel</a>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'create-category',
  data () {
    return {
      loading: false,
      users: [],
      departments: [],
      data: {
        user: '',
        department: ''
      },
      rules: {
        user: [
          { required: true, trigger: ['blur', 'change'] }
        ],
        department: [
          { required: true, trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    filterUsers () {
      return this.data.department ? this.users.filter(u => parseInt(u.department_id) === this.data.department) : this.users
    }
  },
  methods: {
    async getUsersDepartments () {
      this.getUsers()
      this.getDepartments()
    },
    getUsers () {
      this.$sessionService.getSessionableUsers()
        .then((users) => {
          this.users = users.filter(u => !parseInt(u.is_admin))
        })
    },
    getDepartments () {
      this.$departmentService.getDepartments()
        .then((data) => {
          this.departments = data
        })
    },
    validateInputs () {
      return new Promise((resolve, reject) => {
        this.data.department && this.data.user ? resolve() : reject(new Error(!this.data.department ? 'Select a department' : 'Select a user'))
      })
    },
    createSession () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t('session.confirm.create'), () => {
            this.$sessionService.createSession(this.data)
              .then(data => {
                this.displayMessage(this.$t('session.message.created'), () => {
                  this.$router.push({ name: 'sessions', query: { department: this.data.department } })
                })
              })
              .catch(err => {
                const data = err.response.data
                if (data && data.status === 'error') {
                  this.displayMessage(this.$t('session.message.exist'), () => {}, 'error')
                } else {
                  this.displayMessage(err.message, () => {}, 'error')
                }
              })
          })
        }
      })
    },
    resetForm () {
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('session.title.create'))
    this.getUsersDepartments()
  }
}
</script>
<style>
    input[type="file"].el-upload__input {
        display: none;
    }

    .avatar-uploader .el-upload {
        position: inherit !important;
    }

    .full-width {
        width: 100%;
    }

    .validator {
        color: #ef7100;
        font-weight: bold;
    }

    .required {
        color: #ef1900;
        font-weight: bold;
    }

    .avatar-uploader .el-upload {
        position: fixed;
        overflow: hidden;
        width: 100px;
        height: 100px;
        display: block;
    }

    .avatar-uploader-icon:hover {
        opacity: .4;
        background-color: rgba(21, 21, 21, .5);
        color: #FFFFFF;
    }

    .avatar-uploader-icon {
        border: 2px dashed #d9d9d9;
        border-radius: 6px;
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 90px;
        text-align: center;
        cursor: pointer;
    }

    .avatar-uploader-icon:hover {
        opacity: 1;
    }

    [class^="el-icon-"] {
        display: inherit;
    }

</style>
