<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="visible" @open="open"
             @closed="closed" @close="$emit('close')" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center">{{
          $t(`user.title.${edit ? 'update' : 'create'}`, {name: user.name})
        }}</h3>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form :model="form" :rules="rules" label-width="200px" status-icon ref="userForm">
          <el-form-item label="Full Name" prop="name">
            <el-input ref="input" v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Username (login)" prop="username">
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="E-mail" prop="email">
            <el-input v-model="form.email" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item v-show="passwordRequired" label="Password" prop="password">
            <el-input type="password" v-model="form.password" auto-complete="off"
                      placeholder=""></el-input>
          </el-form-item>
          <el-form-item v-show="passwordRequired" label="Confirm Password" prop="password_confirmation">
            <el-input type="password" v-model="form.password_confirmation" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item v-show="passwordRequired" label="Assign a role" prop="role">
            <el-radio-group v-model="form.role" size="medium">
              <el-radio-button @click.native="registerRoleClicks" v-for="role in systemRoles" :key="role.id"
                               :label="role.id">{{ role.name }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Department" prop="department_id">
            <el-select class="full-width" v-model="form.department_id" filterable
                       placeholder="Select department" clearable>
              <el-option v-for="dep in departments" :key="dep.id" :label="dep.name"
                         :value="dep.id"></el-option>
            </el-select>
          </el-form-item>
          <el-row type="flex" justify="space-between">
            <el-col :span="12">
              <el-form-item label="Activate">
                <el-switch v-model="form.active"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>

    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" :offset="6">
        <el-button @click="$emit('close')">Cancel</el-button>
        <el-button :loading="loading" type="primary" @click="submitForm">
          {{ $t(`user.button.${edit ? 'update' : 'create'}`) }}
        </el-button>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
export default {
  name: 'CreateEditUser',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      departments: [],
      systemRoles: [],
      form: {
        name: this.user.name || '',
        username: this.user.username || null,
        email: this.user.email || '',
        department_id: this.user.department_id || null,
        password: '',
        password_confirmation: '',
        role: null,
        active: (this.user.active !== 0)
      },
      loading: false,
      passwordRequired: true,
      roleClicks: 0
    }
  },
  computed: {
    rules () {
      return {
        name: [
          { required: true, message: 'Please, enter a name', trigger: 'blur' }
        ],
        email: [
          { required: false, message: 'Please, enter an email address', trigger: 'blur' }
        ],
        username: [
          { required: true, message: 'Please, choose a username. e.g. sandra-kelly', trigger: 'blur' }
        ],
        password: [
          { required: this.passwordRequired, message: 'Please enter your password', trigger: 'blur' },
          { min: 6, message: 'Minimum length is 6 characters' },
          { validator: this.passwordRequired ? this.validatePassword : null, trigger: 'blur' }
        ],
        password_confirmation: [
          { required: this.passwordRequired, message: 'Please confirm the password', trigger: 'blur' },
          { validator: this.passwordRequired ? this.validatePasswordConfirmation : null, trigger: ['change', 'blur'] }
        ],
        department_id: [
          { required: false, type: 'number', message: 'Please select a department', trigger: ['change', 'blur'] }
        ]
      }
    },
    submittable () {
      return !Object.keys(this.form).filter(k => (typeof this.form[k] !== 'boolean') && !this.form[k]).length
    },
    edit () {
      return this.user && this.user.id
    }
  },
  watch: {
    'form.active' (value, prev) {
      if (typeof value === 'number') {
        this.form.active = this.form.active !== 0
      }
    }
  },
  methods: {
    open () {
      if (this.user && this.user.id) {
        this.passwordRequired = false
      }
    },
    opened () {
      if (this.user && this.user.id) {
        Object.keys(this.form).forEach((k) => {
          this.form[k] = this.user[k]
        })
      }
      this.$refs.input.focus()
    },
    closed () {
      this.form.name = ''
      this.form.username = ''
      this.form.email = ''
      this.form.department_id = null
      this.form.password = ''
      this.form.password_confirmation = ''
      this.form.role = null
      this.form.active = true

      this.loading = false
    },
    registerRoleClicks () {
      if (this.form.role === this.roleClicks) {
        this.$nextTick(() => {
          this.form.role = null
        })
      } else {
        this.roleClicks = this.form.role
      }
    },
    validatePassword (rule, value, callback) {
      if (value === '') {
        callback(new Error('Please input a password'))
      } else {
        if (this.form.password_confirmation !== '') {
          this.$refs.userForm.validateField('password_confirmation')
        }
        callback()
      }
    },
    validatePasswordConfirmation (rule, value, callback) {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.form.password) {
        callback(new Error('Two passwords do not match.'))
      } else {
        callback()
      }
    },
    submitForm () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`user.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$userService.updateUser(this.user.id, this.form)
                .then(data => {
                  this.notify(this.$t('user.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$userService.createUser(this.form)
                .then(data => {
                  this.notify(this.$t('user.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    },
    async fetchDepartments () {
      this.departments = await this.$departmentService.getDepartments()
    },
    async fetchSystemRoles () {
      this.systemRoles = await this.$roleService.getSystemRoles()
    }
  },
  mounted () {
    this.fetchDepartments()
    this.fetchSystemRoles()
  }
}
</script>

<style scoped>

</style>
