<template>
  <div class="tab-pane fade active show" id="tab-reg" role="tabpanel"
       aria-labelledby="home-tab">

    <h5 class="m-b-30 m-t-20">{{ $t('setting.tabs.sales_print_terminals') }}</h5>
    <el-row type="flex" class="row-bg" justify="space-around">
      <el-form inline class="demo-form-inline">
        <el-form-item label="Type">
          <el-select v-model="activeTerminal.type" placeholder="For every">
            <el-option v-for="type in types" :key="type.key" :label="type.value" :value="type.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Value">
          <el-select v-model="activeTerminal.value" placeholder="Select category value">
            <el-option v-for="type in selectedType" :key="type.id" :label="type.name" :value="type.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Terminal">
          <el-select v-model="activeTerminal.terminal" placeholder="Select a terminal">
            <el-option v-for="term in terminals" :key="term.id" :label="term.name" :value="term.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :disable="!addable">Add</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-divider/>
  </div>
</template>

<script>

export default {
  name: 'PriceLists',
  components: {},
  data () {
    return {
      types: [{ key: 'item', value: 'Item' }, { key: 'family', value: 'Family' }, { key: 'dept', value: 'Department' }],
      terminals: [{ id: 1, name: 'POS Kitchen', ip: '192.168.3.1', shared: '' }, {
        id: 2,
        name: 'POS Stock Controller',
        ip: '192.168.3.3',
        shared: 'slm-stock-pos-printer'
      }],
      items: [],
      families: [],
      departments: [],
      activeTerminal: {
        type: null,
        value: null,
        terminal: null
      }
    }
  },
  watch: {
    'activeTerminal.type' (value) {
      if (this.activeTerminal.value) this.activeTerminal.value = null
    }
  },
  computed: {
    addable () {
      return !Object.values(this.activeTerminal).some(value => value === null)
    },
    selectedType () {
      switch (this.activeTerminal.type) {
        case 'item':
          return this.items
        case 'family':
          return this.families
        case 'dept':
          return this.departments
        case 'cat':
          return this.categories
      }
    }
  },
  methods: {
    async fetchCategoryTypes () {
      this.families = await this.$categoryService.getCategories()
      this.items = await this.$itemService.getActiveItems()
      this.departments = await this.$departmentService.getDepartments()
    }
  },
  mounted () {
    this.fetchCategoryTypes()
  }
}
</script>

<style scoped>

</style>
