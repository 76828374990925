export default class AuthService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  login (data) {
    this.$store.dispatch('Auth/authRequest')
    return new Promise((resolve, reject) => {
      this.$http.post('auth/login', data)
        .then(res => {
          if (res.status === 200) {
            return res.data
          }
        })
        .then(async data => {
          await this.$store.dispatch('Auth/loggedIn', data)
          // @todo Get permissions
          resolve(data)
        })
        .catch(err => {
          this.$store.dispatch('Auth/authError')
          reject(err)
        })
    })
  }

  getUserPermissions () {
    return new Promise((resolve, reject) => {
      this.$http.get('roles/auth-user')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  setLicense (license, url) {
    return new Promise((resolve, reject) => {
      this.$http.post(`${url}/license/setup`, license)
        .then(async resp => {
          await this.$store.dispatch('Auth/setLicense', resp.data.data)
          resolve(resp)
        })
        .catch(reject)
    })
  }

  verifyLicense () {
    return new Promise((resolve, reject) => {
      this.$http.get('license/verify')
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
  }

  logout () {
    this.$store.dispatch('Auth/setIntended', this.$router.currentRoute.name)
    this.$store.dispatch('Auth/logout')
      .then(() => {
        this.$router.push({ name: 'login' })
      })
  }

  changePassword (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('auth/password', data)
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  changeLanguage (lang) {
    return new Promise((resolve, reject) => {
      this.$http.post('auth/language', { lang })
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }
}
