<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="false" :visible="display"
             :close-on-press-escape="false" :close-on-click-modal="false"
             @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h4 class="text-center">{{ $t(`employee.title.import`) }}</h4>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form size="medium" ref="form">
          <el-form-item :label="$t('employee.title.import')">
            <el-select ref="input" v-model="user" :placeholder="$t('text.click_to_select')" class="full-width">
              <el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id">
                <span style="float: left">{{ user.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">@{{ user.username }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" :offset="6">
        <el-button @click="$emit('close', false)">{{ $t('button.close') }}</el-button>
        <el-button :disabled="!this.user" :loading="loading" type="primary" @click="submitForm">
          {{ $t(`employee.button.import`) }}
        </el-button>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
export default {
  name: 'ImportUserAsEmployeeDialog',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    users: Array
  },
  data () {
    return {
      user: null,
      loading: false
    }
  },
  methods: {
    async opened () {
    },
    closed () {
      this.user = null
      this.loading = false
    },
    submitForm () {
      this.$emit('selected', this.users.find(u => u.id === this.user))
      this.$emit('close')
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
