<template>
  <div class="container  pull-up">
    <div class="row">
      <div class="col-lg-12">
        <div class="card m-b-30 ">
          <div class="card-header">
            <p class="m-b-0 opacity-50">
              Fields with
              <req/>
              are required
            </p>
            <hr>
          </div>
          <div class="card-body ">
            <el-form ref="form" :model="form" :rules="rules" size="large" status-icon>
              <el-form-item :label="$t('role.label.name')" prop="name">
                <el-input v-model="form.name" ref="input"></el-input>
              </el-form-item>
              <el-form-item :label="$t('role.label.description')" prop="description">
                <el-input type="textarea" v-model="form.description"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click.prevent="createRole" icon="mdi mdi-content-save" type="primary">
                  {{ $t('role.button.create') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Create',
  data () {
    return {
      form: {
        name: '',
        description: ''
      },
      rules: {
        name: [{ required: true }],
        description: [{ required: true }]
      }
    }
  },
  methods: {
    createRole () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t('role.confirm.create'), () => {
            this.$roleService.createRole(this.form)
              .then(resp => {
                this.displayMessage(this.$t('role.message.created'), () => {
                  this.$router.push({ name: 'roles' })
                })
              })
              .catch(this.globalErrHandle)
          })
        }
      })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('role.title.create'))
  }
}
</script>

<style scoped>

</style>
