<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-cash-plus"></i> {{ $t('payment_method.button.all') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables :data="payment_methods"
                           :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                <el-table-column label="S/N" type="index" sortable></el-table-column>
                <el-table-column prop="name" :label="$t('label.name')">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                    <el-tag v-if="scope.row.default" size="small">
                      {{ $t('label.default') }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column align="center" :label="$t('label.status')">
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.status ? 'success' : 'danger'" effect="dark" size="mini">
                      {{ scope.row.status ? $t('label.active') : $t('label.inactive') }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="user.name" :label="$t('label.creator')"></el-table-column>
                <el-table-column prop="created_at" :formatter="$shortDateTime" :label="$t('label.created_at')"
                                 ></el-table-column>
                <el-table-column prop="updated_at" :formatter="$shortDateTime" :label="$t('label.last_updated')"
                                 ></el-table-column>
                <el-table-column :label="$t('label.action')" min-width="100px">
                  <template slot-scope="scope">
                    <el-dropdown v-can.disable="'payment_method.update'" @click="showEditDialog(scope.row.id)" split-button type="primary" size="small"
                                 trigger="click">
                      <i class="el-icon-edit"></i> {{ $t('button.edit') }}
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-can.disable="'payment_method.default'" :disabled="!!scope.row.default"
                                                                          @click.native="changeDefault(scope.row.id)">
                        <i class="el-icon-medal-1"></i> {{ $t('payment_method.button.default') }}
                      </el-dropdown-item>
                        <el-dropdown-item v-can.disable="'payment-method.archive'" @click.native="toggleStatus(scope.row.id)">
                          <i class="mdi mdi-archive"></i>
                          {{ scope.row.status ? $t('button.deactivate') : $t('button.activate') }}
                        </el-dropdown-item>
                        <el-dropdown-item v-can.disable="'payment-method.archive'" @click.native="deleteMethod(scope.row.id)">
                          <i class="mdi mdi-trash-can"></i>
                          {{ $t('button.delete') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-edit-payment-method :display="displayEditDialog" @close="closeEditDialog" @updated="methodUpdated"
                     :payment_method="payment_method"></create-edit-payment-method>
  </div>
</template>

<script>
import CreateEditPaymentMethod from './Modal/CreateEditPaymentMethod'

export default {
  name: 'Index',
  components: { CreateEditPaymentMethod },
  data () {
    return {
      payment_methods: [],
      payment_method: {},
      displayEditDialog: false
    }
  },
  methods: {
    showEditDialog (id) {
      this.payment_method = this.payment_methods.find(m => m.id === id)
      this.displayEditDialog = true
    },
    closeEditDialog () {
      this.payment_method = {}
      this.displayEditDialog = false
    },
    newPaymentMethod (data) {
      this.payment_methods.push(data)
    },
    methodUpdated (data) {
      this.payment_methods.splice(this.payment_methods.indexOf(this.payment_method), 1, data)
    },
    toggleStatus (id) {
      this.askQuestion(this.$t('payment_method.confirm.update'), () => {
        this.$paymentMethodService.updateStatus(id)
          .then((data) => {
            this.notify(this.$t('payment_method.message.updated'))
            this.payment_methods.splice(this.payment_methods.indexOf(this.payment_methods.find(m => m.id === id)), 1, data)
          })
      })
    },
    changeDefault (id) {
      this.askQuestion(this.$t('payment_method.confirm.default'), () => {
        this.$paymentMethodService.setDefault(id)
          .then(_ => {
            this.notify(this.$t('payment_method.message.default'))
            this.fetchPaymentMethods()
          })
      })
    },
    deleteMethod (id) {
      this.askQuestion(this.$t('payment_method.confirm.delete'), () => {
        this.$paymentMethodService.deleteMethod(id)
          .then((data) => {
            this.notify(this.$t('payment_method.message.deleted'))
            this.payment_methods.splice(this.payment_methods.indexOf(this.payment_methods.find(m => m.id === id)), 1)
          })
      })
    },
    fetchPaymentMethods () {
      this.$paymentMethodService.getMethods().then(data => (this.payment_methods = data))
    }
  },
  async mounted () {
    this.$emit('parent-title', this.$t('payment_method.name'))
    this.$parent.$on('new-payment-method', this.newPaymentMethod)
    await this.fetchPaymentMethods()
  }
}
</script>

<style scoped>

</style>
