export default class WarehouseWarehouse {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getWarehouses () {
    return new Promise((resolve, reject) => {
      this.$http.get('warehouses')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getWarehouse (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`warehouses/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createWarehouse (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('warehouses/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateWarehouse (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`warehouses/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateItemSellingPrice (whId, itemId, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`warehouses/${whId}/update-price/${itemId}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  archiveWarehouse (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`warehouses/${id}/archive`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  changeDefault (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`warehouses/default/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getItems (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`warehouses/${id}/items-only`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  fetchWarehouseItems (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`warehouses/${id}/items`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  fetchWarehousesItem (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`warehouses/${id}/item`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getStockItem (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`warehouses/stock-item/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  adjustStockItem (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`warehouses/adjust-stock-item/${id}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  adjustWarehouseStock (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`warehouses/adjust-warehouse-stock/${id}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getStockHistory (query = {}) {
    return new Promise((resolve, reject) => {
      this.$http.get('warehouses/get-stock-history/', { params: query })
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getStockHistoryItems (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`warehouses/get-stock-history-items/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
