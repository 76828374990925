<template>
  <div class="container pull-up">
    <el-row :gutter="24" style="margin-bottom: 10px">
      <el-col :span="8">
        <el-card :body-style="{padding: '15px'}">
          <div style="">
            <strong class="card-header-title text-primary">Stock Levels</strong>
            <div class="bottom clearfix">
              <el-row :gutter="24">
                <el-col :span="12">
                  <span class="card-header-subtitle text-secondary">Quantity in stock</span>
                  <div class="card-header-value">{{ totalStock | currency }}</div>
                </el-col>
                <el-col :span="12">
                  <span class="card-header-subtitle text-secondary">Reorder Level</span>
                  <div class="card-header-value">{{ data.item.reorder_level }}</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card :body-style="{padding: '15px'}">
          <strong class="card-header-title text-primary">Sales</strong>
          <div class="bottom clearfix">
            <el-row :gutter="24">
              <el-col :span="12">
                <span class="card-header-subtitle text-secondary">Sales Price</span>
                <div class="card-header-value">{{ data.item.unit_price | currency(' XAF') }}</div>
              </el-col>
              <el-col :span="12">
                <span class="card-header-subtitle text-secondary">Last Sale</span>
                <div class="card-header-value">{{ data.last_sale | date }}</div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card :body-style="{padding: '15px'}">
          <div style="">
            <strong class="card-header-title text-primary">Purchases</strong>
            <div class="bottom clearfix">
              <el-row :gutter="24">
                <el-col :span="12">
                  <span class="card-header-subtitle text-secondary">Cost Price</span>
                  <div class="card-header-value">{{ data.item.purchase_price | currency(' XAF') }}</div>
                </el-col>
                <el-col :span="12">
                  <span class="card-header-subtitle text-secondary">Last Purchase</span>
                  <div class="card-header-value">{{ data.last_purchase | date }}</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <el-row :gutter="24">
              <el-col :span="6">
                <h5 class="m-b-0 float-left">
                  <i class="mdi mdi-road"></i> {{ $t('stock.title.activity') }}
                </h5>
              </el-col>
              <el-col :span="18">
                <div class="float-right">
                  <el-form inline v-model="filter">
                    <el-row :gutter="24">
                      <el-form-item>
                        <el-input max="5" v-model="filter.search"
                                  :placeholder="$t('text.search_placeholder')"></el-input>
                      </el-form-item>
                      <el-form-item label="From">
                        <el-date-picker v-model="filter.start_date" type="date"
                                        :placeholder="$t('text.pick_day')"></el-date-picker>
                      </el-form-item>
                      <el-form-item label="To">
                        <el-date-picker v-model="filter.end_date" type="date"
                                        :placeholder="$t('text.pick_day')"></el-date-picker>
                      </el-form-item>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables :data="filteredData"
                           :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                <el-table-column align="center" prop="date" :formatter="$shortDate"
                                 :label="$t('label.date')"></el-table-column>
                <el-table-column prop="type" :label="$t('stock.label.type')"></el-table-column>
                <el-table-column align="center" prop="reference" :label="$t('label.ref')"></el-table-column>
                <el-table-column show-overflow-tooltip prop="details"
                                 :label="$t('stock.label.detail')"></el-table-column>
                <el-table-column align="center" prop="in" :label="$t('stock.label.in')"></el-table-column>
                <el-table-column align="center" prop="out" :label="$t('stock.label.out')"></el-table-column>
                <el-table-column align="center" prop="cost_price" :label="$t('stock.label.cost_price')">
                  <template slot-scope="scope">{{ scope.row.cost_price }}</template>
                </el-table-column>
                <el-table-column align="center" prop="sales_price" :label="$t('stock.label.sales_price')">
                  <template slot-scope="scope">{{ scope.row.sales_price }}</template>
                </el-table-column>
                <el-table-column prop="user.username" :label="$t('label.user')">
                  <template slot-scope="scope">@{{ scope.row.user.username }}</template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <adjust-stock @close="displayStockAdjustmentDialog = false" :item="data.item" :total-stock="totalStock"
                  :display="displayStockAdjustmentDialog" @adjusted="initialise"></adjust-stock>
  </div>
</template>

<script>
import AdjustStock from './Modal/AdjustStock'

export default {
  name: 'StockItem',
  components: {
    AdjustStock
  },
  data () {
    return {
      data: {
        item: {},
        last_sale: null,
        last_purchase: null,
        history: []
      },
      filter: {
        start_date: null,
        end_date: null,
        search: ''
      },
      displayStockAdjustmentDialog: false
    }
  },
  computed: {
    totalStock () {
      return this.data.item.name ? this.data.item.warehouses.map(wh => wh.pivot.quantity).reduce((acc, next) => next + acc, 0) : 0
    },
    filteredData () {
      let data = this.data.history
      if (!this.filter.search && !this.filter.start_date && !this.filter.end_date) return this.data.history

      if (this.filter.start_date) {
        data = data.filter(row => this.$moment(row.created_at.substr(0, 10)).isSameOrAfter(this.filter.start_date))
      }
      if (this.filter.end_date) {
        data = data.filter(row => this.$moment(row.created_at.substr(0, 10)).isSameOrBefore(this.filter.end_date))
      }
      if (this.filter.search) {
        data = data.filter(row =>
          (row.reference && row.reference.toLowerCase().includes(this.filter.search.toLowerCase())) ||
            (row.details && row.details.toLowerCase().includes(this.filter.search.toLowerCase())))
      }
      return data
    }
  },
  methods: {
    fetchStockItem (id) {
      return new Promise((resolve, reject) => {
        this.$warehouseService.getStockItem(id)
          .then(resolve)
          .catch(reject)
      })
    },
    async initialise () {
      const id = this.$route.params.item_id
      this.data = await this.fetchStockItem(id)
    }
  },
  async mounted () {
    await this.initialise()
    this.$emit('parent-title', this.data.item.name)
    this.$emit('parent-subtitle', this.data.item.code)
  },
  created () {
    this.$parent.$on('display-stock-adjustment-dialog', () => {
      this.displayStockAdjustmentDialog = true
    })
  }
}
</script>

<style>
.card-header-title {
  font-weight: bolder;
}

.card-header-subtitle {
  text-transform: uppercase;
  /*color: #5e5e5e;*/
}

.card-header-value {
  font-weight: bold;
  font-size: 18px;
}
</style>
