<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{
                                    $t('employee.payment.title.history', {name: employee.name}) }}
                                </h5>
                            </el-col>
                            <el-row :span="12">
                                <el-tooltip effect="dark"
                                            content="This debt wouldn't leave a footprint on main account (e.g Initial migration of system)"
                                            placement="top">
                                    <el-button @click="debtCashin" type="default" size="medium" icon="el-icon-info"
                                               class="float-right">
                                        Unattended Debt-in
                                    </el-button>
                                </el-tooltip>
                            </el-row>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="payments"
                                         :pagination-props="{ pageSizes: [20, 30, 40, 100], pageSize: 20 }"
                                         center-align>
                                <el-table-column in label="S/N" type="index" fixed></el-table-column>
                                <el-table-column prop="amount" label="Amount" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.amount | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="message" label="Message" min-width="180" fixed></el-table-column>
                                <el-table-column prop="type" label="Type">
                                    <template slot-scope="scope">
                                        <span class="text-capitalize">{{ scope.row.type }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="date" :formatter="$shortDate" label="Date"
                                                 sortable></el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable>
                                    <template slot-scope="scope">
                                        {{ scope.row.user.name }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created"
                                                 sortable></el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <debt-cashin :employee-id="employee.id" :display="showDebtInDialog" @close="closeDebtCashin"></debt-cashin>
    </div>
</template>

<script>
import DebtCashin from './Modals/DebtCashin'

export default {
  name: 'Debt',
  components: { DebtCashin },
  props: {
    employee: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      payments: [],
      showDebtInDialog: false
    }
  },
  methods: {
    debtCashin () {
      this.showDebtInDialog = true
    },
    closeDebtCashin () {
      this.showDebtInDialog = false
    }
  },
  mounted () {
    setTimeout(() => {
      this.$emit('parent-title', this.$t('employee.title.payment', { name: this.employee.name }))
    }, 1000)
    this.$employeeService.getEmployeePayments(this.$route.params.id)
      .then(data => {
        this.payments = data.data
      })
  }
}
</script>

<style scoped>
    .fain-text {
        color: #8d97a5;
    }

    .active-text {
        color: #00cc99;
    }
</style>
