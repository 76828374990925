<template>
    <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
               @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
        <slot name="title">
            <h3 class="text-center">{{ $t(`department.title.${edit ? 'update' : 'create'}`) }}</h3>
            <hr>
        </slot>
        <el-row>
            <el-col :span="12" :offset="6">
                <el-form :model="form" size="medium" :rules="rules" ref="deptForm">
                    <el-form-item label="Department name" label-width="100" prop="name">
                        <el-input @keyup.enter.native="submitForm" ref="input" v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>

        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close', false)">Cancel</el-button>
                <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
                    {{ $t(`department.button.${edit ? 'update' : 'create'}`) }}
                </el-button>
            </el-col>
        </el-row>

    </el-dialog>
</template>

<script>
export default {
  name: 'CreateDepartment',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    department: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: this.department.name || ''
      },
      rules: {
        name: [
          { required: true, message: 'Please, enter the department name', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: {
    submittable () {
      return !!this.form.name.length
    },
    edit () {
      return this.department && this.department.id
    }
  },
  methods: {
    opened () {
      if (this.department && this.department.id) {
        this.form.name = this.department.name
      }
      this.$refs.input.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = ''
      })
      this.loading = false
    },
    submitForm () {
      this.$refs.deptForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`department.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$departmentService.updateDepartment(this.department.id, this.form)
                .then(data => {
                  this.notify(this.$t('department.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$departmentService.createDepartment(this.form)
                .then(data => {
                  this.notify(this.$t('department.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
