<template>
    <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
               @closed="closed" @close="$emit('close', false)" @open="opened" :destroy-on-close="true">
        <slot name="title">
            <el-row type="flex" justify="space-between">
                <el-col>
                    <div class="text-center h3" v-if="item">
                        `{{ item.reason | limit(25) }}` &bullet; {{ item.date | date }}
                    </div>
                </el-col>
                <el-col :span="10">
                    <el-input size="small" :placeholder="$t('text.search_placeholder')" v-model="search" autofocus clearable>
                        <span slot="append">
                            <span class="el-icon-search"/>
                        </span>
                    </el-input>
                </el-col>
            </el-row>

            <hr>
        </slot>
        <el-row style=" overflow-y: auto;">
            <el-col :span="24">
                <el-table :data="sortedByChange" max-height="790"
                             :pagination-props="{ pageSizes: [20, 30, 40, 100], pageSize: 20 }" center-align
                             class="adjust-wh-it">
                    <el-table-column label="S/N" type="index" sortable></el-table-column>
                    <el-table-column prop="item.name" :label="$t('label.name')"></el-table-column>
                    <el-table-column prop="qty" label="Quantity" align="center">
                        <template v-slot:default="scope">
                            <code v-if="scope.row.changed"
                                  :style="{color: (scope.row.from - scope.row.to) <= 0 ? 'green': '' }">
                                {{ scope.row.from | currency }} &longrightarrow; {{ scope.row.to | currency }}
                            </code>
                            <div v-else style="text-align: center;">-</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="difference" label="Diff" align="center">
                        <template v-slot:default="scope">
                            <code v-if="scope.row.changed"
                                  :style="{color: (scope.row.from - scope.row.to) <= 0 ? 'green': '' }">
                                {{ scope.row.from - scope.row.to | currency }}
                            </code>
                            <div v-else style="text-align: center;">-</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unit_price" label="Unit Price">
                        <template v-slot:default="scope">{{ scope.row.unit_price | currency }}</template>
                    </el-table-column>
                    <el-table-column prop="avr_purchase_price" label="Avr. Purchase">
                        <template v-slot:default="scope">{{ scope.row.avr_purchase_price | currency }}</template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="24" align="end" class="pr-5">
                <el-button type="primary" @click="$emit('close', false)">
                    {{ $t('button.close') }}
                </el-button>
            </el-col>
        </el-row>

    </el-dialog>
</template>

<script>
export default {
  name: 'StockAdjustmentItems',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      items: [],
      loading: false,
      search: ''
    }
  },
  watch: {
    'item' (value) {
      if (value && value.id) {
        this.fetchAdjustmentItems()
      }
    }
  },
  computed: {
    sortedByChange () {
      return this.items.filter(
        item => !this.search || item.item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.to.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          item.from.toString().toLowerCase().includes(this.search.toLowerCase()))
        .sort((a, b) => {
          return b.changed - a.changed
        })
    }
  },
  methods: {
    opened () {
    },
    closed () {
    },
    async fetchAdjustmentItems () {
      this.loading = true
      this.items = (await this.$warehouseService.getStockHistoryItems(this.item.id)).items
    },
    submitForm () {
      if (this.queue.length) {
        this.$prompt('', this.$t('warehouse.confirm.transfer'), {
          confirmButtonText: this.$t('button.continue'),
          cancelButtonText: this.$t('button.cancel'),
          inputPlaceholder: this.$t('warehouse.label.comment')
        }).then(({ value }) => {
          this.loading = true
          this.$transferService.executeTransfer({ data: this.queue, comment: value }).then(data => {
            this.notify(this.$t('warehouse.message.created'), this.$t('status.success'))
            this.$emit('created', data)
            this.clearTransferDialog()
            this.$emit('close')
          }).catch(this.afterSubmitError).then(() => {
            this.loading = false
          })
        }).catch((err) => {
          this.$notify.error(err.response.data)
        })
      }
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style>
[class^="el-icon-"] {
    display: inline-block;
}

.adjust-wh-it .el-table.el-table--fit {
    max-height: 78vh;
    overflow-y: auto;
}

.el-input-number__decrease {
    left: 10px;
}
</style>
