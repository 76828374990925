<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('account.category.name') }}
                                </h5>
                            </el-col>
                            <el-col :span="12">
                                <el-button v-can="'account.create-category'" size="medium" class="float-right" type="success" icon="el-icon-plus"
                                           @click="showCategoryDialog">
                                    {{ $t('account.category.button.create') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="categories"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }">
                                <el-table-column in label="S/N" type="index" sortable fixed></el-table-column>
                                <el-table-column prop="name" label="Name" sortable fixed></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions">
                                    <template slot-scope="scope">
                                        <el-dropdown v-can.disable="'account.read-subcategory'" size="mini" trigger="click" split-button type="primary"
                                                     @click="$router.push({name: 'subcategories', params: {id: scope.row.id}})">
                                            {{ $t('account.category.button.preview') }}
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-can.disable="'account.update-category'" icon="el-icon-edit"
                                                                  @click.native="showEditDialog(scope.row.id)">
                                                    {{ $t('button.edit') }}
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-edit-category :category="category" :visible="displayCategoryDialog" @close="closeCategoryDialog"
                              @category-created="categoryCreated"
                              @category-updated="categoryUpdated"></create-edit-category>
    </div>
</template>

<script>
import CreateEditCategory from './Modal/CreateEditCategory'

export default {
  name: 'Category',
  components: { CreateEditCategory },
  data () {
    return {
      categories: [],
      displayCategoryDialog: false,
      category: {}
    }
  },
  methods: {
    showCategoryDialog () {
      this.displayCategoryDialog = true
    },
    showEditDialog (id) {
      this.category = this.categories.find(c => c.id === id)
      this.displayCategoryDialog = true
    },
    closeCategoryDialog () {
      this.displayCategoryDialog = false
      this.category = {}
    },
    categoryCreated (data) {
      this.categories.splice(0, 0, data)
    },
    categoryUpdated (data) {
      const index = this.categories.indexOf(this.categories.find(c => c.id === data.id))
      this.categories.splice(index, 1, data)
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('account.category.title.category'))
    this.$accountService.getCategories()
      .then(data => (this.categories = data))
  }
}
</script>

<style scoped>

</style>
