export default class PaymentMethodService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getMethods () {
    return new Promise((resolve, reject) => {
      this.$http.get('payment_methods')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createMethod (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('payment_methods/store', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateMethod (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`payment_methods/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateStatus (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`payment_methods/${id}/status`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  setDefault (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`payment_methods/${id}/default`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteMethod (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`payment_methods/${id}/delete`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
