export default class CategoryService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getCategories () {
    return new Promise((resolve, reject) => {
      this.$http.get('categories/list')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createCategory (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('categories/store', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateCategory (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`categories/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  archiveCategory (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`categories/${id}/archive`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateTrackedStatus (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`categories/${id}/tracked`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
