<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('debt.name') }}
                                </h5>
                            </el-col>
                            <el-col :span="12" class="text-right" :tabindex="2">
                                {{ $t('title.filter') }}:
                                <el-dropdown @command="handleFilter">
                                    <span class="el-dropdown-link">
                                        {{ filterTitle }} <i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="*">{{ $t('title.show_all') }}</el-dropdown-item>
                                        <el-dropdown-item v-for="(type, ind) in filter.types" :command="type.name"
                                                          :key="ind">{{ type.name }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-col>
                        </el-row>

                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables
                                    :data="filterData"
                                    :pagination-props="{ pageSizes: [20, 30, 40, 100], pageSize: 20 }"
                                    center-align>
                                <el-table-column in label="S/N" type="index" fixed></el-table-column>
                                <el-table-column prop="date" :formatter="$shortDate" label="Date"
                                                 min-width="100" fixed></el-table-column>
                                <el-table-column prop="amount" label="Initial Amount" min-width="100" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.amount | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="employee.name" label="Employee"
                                                 min-width="120" fixed></el-table-column>
                                <el-table-column prop="balance" label="Balance" min-width="100" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.balance | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="type.name" min-width="100" label="Category"></el-table-column>
                                <el-table-column prop="type.can_pay" label="Payable" class-name="text-center">
                                    <template slot-scope="scope">
                                        <i v-if="scope.row.type.can_pay" class="el-icon-check active-text"></i>
                                        <i class="el-icon-close fain-text" v-else></i>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="type.affects_account" label="Deductible"
                                                 class-name="text-center">
                                    <template slot-scope="scope">
                                        <i v-if="scope.row.type.affects_account" class="el-icon-check active-text"></i>
                                        <i class="el-icon-close fain-text" v-else></i>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Actions" min-width="125" fixed="right">
                                    <template slot="header" slot-scope="scope">
                                        <el-input
                                                v-model="search" ref="searchInput"
                                                size="mini" autofocus
                                                placeholder="Type to search"/>
                                    </template>
                                    <template slot-scope="scope">
                                        <el-dropdown split-button type="primary" size="mini"
                                                     :class="{'disable-child': archivable(scope.row)}"
                                                     @click="!archivable(scope.row) ?showPaymentDialog(scope.row.id):false"
                                                     trigger="click">
                                            Payback
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item icon="el-icon-refresh-left"
                                                                  @click.native="showHistoryDialog(scope.row.id)">
                                                    {{ $t('button.history') }}
                                                </el-dropdown-item>
                                                <el-dropdown-item icon="el-icon-arrow-down"
                                                                  @click.native="showDetailsDialog(scope.row.id)">
                                                    More Details
                                                </el-dropdown-item>
                                                <el-dropdown-item :disabled="!archivable(scope.row)"
                                                                  icon="el-icon-takeaway-box"
                                                                  @click.native="addToArchive(scope.row.id)">
                                                    Archive
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                        <!--<el-popconfirm @onConfirm="addToArchive(scope.row.id)" icon-color="red"
                                                       :confirmButtonText="$t('button.understood')"
                                                       :cancelButtonText="$t('button.no')"
                                                       :title="$t('employee.message.archive')"
                                                       confirmButtonType="danger" cancel-button-type="success">
                                            <el-button slot="reference" icon="el-icon-takeaway-box" type="warning"
                                                       size="mini">
                                                Archive
                                            </el-button>
                                        </el-popconfirm>-->
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <debt-payment :debt="payment" :visible="displayPaymentDialog" @close="hidePaymentDialog"
                      @paid="actionDebtPaid"></debt-payment>
        <payment-history :debt="history" :visible="displayHistoryDialog" @close="hideHistoryDialog"></payment-history>
        <el-dialog title="More Information" :visible.sync="displayInfoDialog">
            <el-collapse v-if="debt.user">
                <el-collapse-item title="Description" name="1">
                    <div>{{ debt.description }}</div>
                </el-collapse-item>
                <el-collapse-item title="Employee" name="4">
                    <div>{{ debt.employee.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Creator" name="2">
                    <div>{{ debt.user.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Created At" name="3">
                    <div>{{ shortDate(debt.created_at) }}</div>
                </el-collapse-item>
            </el-collapse>
        </el-dialog>
    </div>
</template>

<script>
import DebtPayment from './Modal/DebtPayment'
import PaymentHistory from './Modal/PaymentHistory'

export default {
  name: 'Index',
  components: { PaymentHistory, DebtPayment },
  data () {
    return {
      debts: [],
      displayInfoDialog: false,
      debt: {},
      history: {},
      displayPaymentDialog: false,
      displayHistoryDialog: false,
      payment: {},
      search: '',
      filter: {
        category: '*',
        types: []
      }
    }
  },
  computed: {
    filterData () {
      return this.debts.filter(data => {
        if (this.filter.category === '*') {
          return !this.search || data.employee.name.toLowerCase().includes(this.search.toLowerCase())
        }
        return (!this.search && data.type.name === this.filter.category) || (data.employee.name.toLowerCase().includes(this.search.toLowerCase()) && data.type.name === this.filter.category)
      })
    },
    filterTitle () {
      return this.filter.category === '*' ? this.$t('title.show_all') : this.filter.category
    }
  },
  methods: {
    archivable (data) {
      return data.balance <= 0 || (!data.type.affects_account && !data.type.can_pay)
    },
    newDebt (data) {
      this.debts.splice(0, 0, data)
    },
    showDetailsDialog (id) {
      this.debt = this.debts.find(d => d.id === id)
      this.displayInfoDialog = true
    },
    showHistoryDialog (id) {
      this.history = this.debts.find(d => d.id === id)
      this.displayHistoryDialog = true
    },
    hideHistoryDialog () {
      this.displayHistoryDialog = false
    },
    showPaymentDialog (id) {
      this.payment = this.debts.find(d => d.id === id)
      this.displayPaymentDialog = true
    },
    hidePaymentDialog () {
      this.displayPaymentDialog = false
    },
    actionDebtPaid (data) {
      const index = this.debts.indexOf(this.debts.find(d => d.id === data.id))
      if (data.balance <= 0) {
        this.debts.splice(index, 1)
      } else {
        this.debts[index].balance = data.balance
      }
    },
    addToArchive (id) {
      this.askQuestion(this.$t('debt.confirm.archive'), () => {
        this.$debtService.archiveDebt(id)
          .then(() => {
            this.debts.splice(this.debts.indexOf(this.debts.find(d => d.id === id)), 1)
            this.notify(this.$t('employee.debt.archived'))
          })
          .catch(error => {
            this.notify(error.message, this.$t('status.error'), 'error')
          })
      })
    },
    handleFilter (cmd) {
      this.filter.category = cmd
    }
  },
  mounted () {
    setTimeout(() => {
      this.$refs.searchInput.focus()
      this.$emit('parent-title', this.$t('debt.title.debts'))
    }, 600)

    setTimeout(() => {
    }, 500)
    this.$debtService.getDebts()
      .then(data => {
        this.debts = data.data
      })
    this.$debtService.getAllDebtTypes()
      .then(data => (this.filter.types = data.data))
  },
  created () {
    this.$parent.$on('new-debt', this.newDebt)
  }
}
</script>

<style>
    .disable-child > div > button:first-child {
        z-index: 0;
        color: #FFF;
        background-color: #a0cfff;
        border-color: #a0cfff;
        cursor: not-allowed;
        background-image: none;
    }

    .disable-child > div > button:focus:first-child {
        /* outline: 1px dotted; */
        /* outline: 5px auto -webkit-focus-ring-color; */
        outline: none !important;
    }

    .fain-text {
        color: #8d97a5;
    }

    .active-text {
        color: #00cc99;
    }
</style>
