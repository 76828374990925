<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('supplier.button.supplies') }}
                                </h5>
                            </el-col>
                            <el-col :span="12">
                                <el-tooltip effect="dark"
                                            :content="$t('text.supplier_direct_debit')"
                                            placement="top">
                                    <el-button @click="cashIn" type="default" size="medium" class="float-right">
                                        <i class="mdi mdi-credit-card-refund"></i>
                                        {{ $t('supplier.button.direct_debit') }}
                                    </el-button>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive text-center">
                            <data-tables :data="history" class="text-center"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }">
                                <el-table-column in label="S/N" type="index"></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                                 sortable></el-table-column>
                                <el-table-column prop="total_cost" label="Total " sortable>
                                    <template slot-scope="scope">
                                        <span style="font-size: 15px; color: orange;">{{ formatPrice(scope.row.total_cost) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="paid" label=" Paid" sortable>
                                    <template slot-scope="scope">
                                        {{ formatPrice(scope.row.paid) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="owed" label=" Owed" sortable>
                                    <template slot-scope="scope">
                                        {{ formatPrice(scope.row.owed) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable></el-table-column>
                                <el-table-column prop="items_count" label="# Items" sortable></el-table-column>
                                <el-table-column label="Actions">
                                    <template slot-scope="scope">
                                        <button @click.prevent="showItemsDialogList(scope.$index)"
                                                class="btn btn-info btn-sm">
                                            <i class="mdi mdi-details"></i>
                                        </button>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <supplies-items-modal :items="showing" :visible="showItemsDialog"
                              @close="closeItemDialog"></supplies-items-modal>
        <supplier-cash-in v-if="showCashInDialog" @close="cashIn" @updated="refreshSupplierDebt"
                          :supplier-id="supplier_id"
                          :display="showCashInDialog"></supplier-cash-in>
    </div>
</template>

<script>
import SuppliesItemsModal from './SuppliesItemsModal'
import SupplierCashIn from './Dialog/SupplierCashIn'

export default {
  name: 'Supplies',
  components: { SupplierCashIn, SuppliesItemsModal },
  props: {
    supplier: Object
  },
  data () {
    return {
      showItemsDialog: false,
      history: [],
      showing: [],
      showCashInDialog: false,
      supplier_id: null
    }
  },
  methods: {
    cashIn () {
      this.showCashInDialog = !this.showCashInDialog
    },
    refreshSupplierDebt () {
      this.$emit('refresh-supplier')
    },
    showItemsDialogList (index) {
      this.showing = this.history[index].items
      this.showItemsDialog = true
    },
    closeItemDialog (display) {
      this.showing = []
      this.showItemsDialog = display
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$supplierService.getHistory(this.$route.params.id)
        .then(data => {
          this.history = data
        })
    })
  },
  beforeMount () {
    this.supplier_id = parseInt(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
