<template>
    <div class="tab-pane fade active show" id="tab-reg" role="tabpanel"
         aria-labelledby="home-tab">
        <p class="opacity-75">
            <el-form label-position="left" :model="form" size="mini">
                <el-form-item label="Registration No">
                    <el-input v-model="form.reg_no"></el-input>
                </el-form-item>
                <el-form-item align="right">
                    <el-button :loading="loading" type="primary" @click="update">Update</el-button>
                </el-form-item>
            </el-form>
        </p>
    </div>
</template>

<script>
export default {
  name: 'Registration',
  data () {
    return {
      form: {
        reg_no: ''
      },
      loading: false
    }
  },
  methods: {
    update () {
      this.loading = true
      this.$configService.setConfig(this.form, 'reg')
        .then(data => {
          this.displayMessage(this.$t('status.success'))
        })
        .catch(this.globalErrHandle)
        .then(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.$configService.getConfig()
      .then(data => {
        this.form.reg_no = data.company_reg_no
      })
  }
}
</script>

<style scoped>

</style>
