<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
             @closed="closed" @close="$emit('close', false)" @open="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center">{{ $t(`warehouse.title.transfer`) }}</h3>
      <hr>
    </slot>
    <el-row>
      <el-col>
        <table class="table table-hover ">
          <thead>
          <tr class="text-center">
            <th>#</th>
            <th>From</th>
            <th>Item</th>
            <th>To</th>
            <th>Qty</th>
            <th>Selling P.</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-center">
            <td>#</td>
            <td>
              <el-select disabled size="mini" v-model="data.from_warehouse" filterable
                         :placeholder="$t('warehouse.label.from_warehouse')" class="f-w" @change="toWarehouseChanged">
                <el-option v-for="wh in warehouses" :key="wh.id" :label="wh.name"
                           :value="wh.id"></el-option>
              </el-select>
            </td>
            <td>
              <el-select @change="itemChanged" size="mini" v-model="data.item" filterable
                         :placeholder="$t('supply.title.items')" class="f-w">
                <el-option :disabled="!item.pivot.quantity" v-for="item in items" :key="item.id" :label="item.name"
                           :value="item.id">
                  <template slot="default">{{ item.name }} ({{ item.pivot.quantity }})</template>
                </el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="data.to_warehouse" filterable
                         :placeholder="$t('warehouse.label.to_warehouse')" class="f-w">
                <el-option v-for="wh in towables" :key="wh.id" :label="wh.name" :value="wh.id">
                  <template>{{ wh.name }} ({{ wh.items.length ? wh.items[0].pivot.quantity : 0 }})</template>
                </el-option>
              </el-select>
            </td>
            <td>
              <el-input-number :min="0" :max="maximable - usedQuantity" v-model="data.quantity"
                               size="mini"></el-input-number>
            </td>
            <td class="currency">{{ currentSubtotal | currency }}</td>
            <td>
              <el-button :disabled="!addable" @click="queueTransfer" type="primary" icon="el-icon-plus"
                         size="mini"></el-button>
            </td>
          </tr>
          <tr v-for="(q, index) in queue" :key="index" class="text-center">
            <td>{{ index + 1 }}</td>
            <td>{{ warehouses.find(wh => wh.id === q.from_warehouse).name }}</td>
            <td>{{ items.find(i => i.id === q.item).name }}</td>
            <td>{{ warehouses.find(wh => wh.id === q.to_warehouse).name }}</td>
            <td>{{ q.quantity | currency }}</td>
            <td>{{ q.selling_price | currency }}</td>
            <td>
              <el-button @click="removeFromQueue(index)" type="danger" size="mini" icon="el-icon-delete"></el-button>
            </td>
          </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" :offset="6">
        <el-button @click="$emit('close', false)">{{ $t('button.cancel') }}</el-button>
        <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
          {{ $t(`warehouse.button.execute`) }}
        </el-button>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
export default {
  name: 'Transfer',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    item: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      data: {
        from_warehouse: null,
        item: null,
        to_warehouse: null,
        quantity: null,
        selling_price: null
      },
      items: [],
      warehouses: [],
      to_warehouses: [],
      queue: [],
      loading: false
    }
  },
  watch: {
    'data.from_warehouse' () {
      this.fetchWarehouseItems()
    }
  },
  computed: {
    submittable () {
      return !!this.queue.length
    },
    towables () {
      return this.to_warehouses.filter(whs => whs.id !== this.data.from_warehouse)
    },
    currentSubtotal () {
      if (this.data.to_warehouse && this.data.quantity) {
        const riteItems = this.to_warehouses.find(twh => twh.id === this.data.to_warehouse).items
        // eslint-disable-next-line no-return-assign
        return this.data.selling_price = riteItems.length ? (riteItems[0].pivot.selling_price) : (this.items.find(i => i.id === this.data.item).pivot.selling_price)
      }
      // eslint-disable-next-line no-return-assign
      return this.data.selling_price = 0.0
    },
    addable () {
      return this.data.from_warehouse && this.data.to_warehouse && this.data.quantity && this.data.item
    },
    maximable () {
      return this.data.item ? this.items.find(i => i.id === this.data.item).pivot.quantity : 0
    },
    usedQuantity () {
      if (this.data.item && this.data.from_warehouse && this.queue.length) {
        const items = this.queue.filter(q => (q.item === this.data.item && this.data.from_warehouse === q.from_warehouse))
        return items.length ? items.reduce((prev, curr) => prev + curr.quantity, 0) : 0
      }
      return 0
    }
  },
  methods: {
    opened () {
      if (this.item) {
        this.data.item = this.item
        this.itemChanged(this.item)
      }
    },
    closed () {
    },
    fetchWarehouseItems () {
      if (this.data.from_warehouse) {
        this.$warehouseService.getItems(this.data.from_warehouse)
          .then(whis => (this.items = whis))
      }
    },
    toWarehouseChanged () {
      if (this.data.from_warehouse === this.data.to_warehouse) {
        this.data.to_warehouse = null
      }
      this.data.item = null
      this.data.quantity = null
    },
    itemChanged (id) {
      if (id) {
        this.fetchToWarehouses(id)
        // Reorder maximum transfer
        const item = this.items.find(i => i.id === id)
        if (this.data.quantity > (item.pivot.quantity - this.usedQuantity)) {
          this.data.quantity = item.pivot.quantity - this.usedQuantity
        }
      }
    },
    fetchToWarehouses (id) {
      this.$warehouseService.fetchWarehousesItem(id)
        .then(whi => (this.to_warehouses = whi))
    },
    queueTransfer () {
      const data = Object.assign({}, this.data)
      const exists = this.queue.find(q => (q.from_warehouse === data.from_warehouse &&
          q.item === data.item && q.to_warehouse === data.to_warehouse))
      if (exists) {
        this.queue[this.queue.indexOf(exists)].quantity += data.quantity
      } else {
        this.queue.push(data)
      }
      this.data.quantity = null
      this.data.selling_price = 0
    },
    removeFromQueue (index) {
      this.queue.splice(index, 1)
    },
    submitForm () {
      if (this.queue.length) {
        this.$prompt('', this.$t('warehouse.confirm.transfer'), {
          confirmButtonText: this.$t('button.continue'),
          cancelButtonText: this.$t('button.cancel'),
          inputPlaceholder: this.$t('warehouse.label.comment')
        }).then(({ value }) => {
          this.loading = true
          this.$transferService.executeTransfer({ data: this.queue, comment: value })
            .then(data => {
              this.notify(this.$t('warehouse.message.created'), this.$t('status.success'))
              this.$emit('created', data)
              this.clearTransferDialog()
              this.$emit('close')
            })
            .catch(this.afterSubmitError)
            .then(() => {
              this.loading = false
            })
        }).catch((err) => {
          this.$notify.error(err.response.data)
        })
        /* this.askQuestion(this.$t('warehouse.confirm.transfer'), () => {
          this.loading = true
          this.$transferService.executeTransfer({data: this.queue})
            .then(data => {
              this.notify(this.$t('warehouse.message.created'), this.$t('status.success'))
              this.$emit('created', data)
              this.$emit('close')
            })
            .catch(this.afterSubmitError)
            .then(() => {
              this.loading = false
            })
        }) */
      }
    },
    clearTransferDialog () {
      this.queue = []
      this.data.from_warehouse = null
      this.data.item = null
      this.data.to_warehouse = null
      this.data.quantity = null
      this.data.selling_price = null
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
    this.$warehouseService.getWarehouses()
      .then(whs => (this.warehouses = whs))
      .then(_ => {
        if (this.$route.params.id) {
          setTimeout(() => {
            this.data.from_warehouse = parseInt(this.$route.params.id)
          }, 300)
        }
      })
  }
}
</script>

<style>
[class^="el-icon-"] {
  display: inline-block;
}

.el-input-number__decrease {
  left: 10px;
}
</style>
