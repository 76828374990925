<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('supplier.button.all') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="suppliers.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }">
                                <el-table-column in label="S/N" type="index"></el-table-column>
                                <el-table-column show-overflow-tooltip prop="name" label="Name"></el-table-column>
                                <el-table-column show-overflow-tooltip prop="address" label="Address"></el-table-column>
                                <el-table-column class-name="text-center" :formatter="$formatPhone" prop="phone"
                                                 label="Phone Number"></el-table-column>
                                <el-table-column prop="user.name" label="Creator"></el-table-column>
                                <el-table-column prop="amt_owed" label="Owing" sortable>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ formatPrice (scope.row.amt_owed) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                ></el-table-column>
                                <el-table-column label="Actions" min-width="150">
                                    <template slot="header" slot-scope="scope">
                                        <el-input v-model="search" size="mini" autofocus placeholder="Type to search"/>
                                    </template>
                                    <template slot-scope="scope">
                                        <router-link v-can.disable="'supplier.history'" tag="el-button" size="mini" :to="{name: 'supplier-history', params: {id: scope.row.id}}"
                                                     class="btn btn-info el-button--mini">
                                            <i class="mdi mdi-history"></i> {{ $t('supplier.button.history') }}
                                        </router-link>
                                        <el-dropdown trigger="click">
                                            <el-button type="primary" size="mini">
                                                Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                            </el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-can.disable="'supplier.update'" icon="mdi mdi-pencil" @click.native="edit(scope.row.id)">
                                                    Edit Supplier
                                                </el-dropdown-item>
                                                <el-dropdown-item v-can.disable="'supplier.archive'" icon="mdi mdi-archive"  @click.prevent="beforeArchive(scope.$index, scope.row.id)">
                                                    Archive Supplier
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                        <!--<button @click="edit(scope.row.id)" class="btn btn-sm btn-primary"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Edit Supplier">
                                            <i class="mdi mdi-pencil"></i>
                                        </button>
                                        <button @click.prevent="beforeArchive(scope.$index, scope.row.id)"
                                                class="btn btn-sm btn-warning" data-toggle="tooltip"
                                                data-placement="top"
                                                title="Archive Supplier">
                                            <i class="mdi mdi-archive"></i>
                                        </button>-->
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      suppliers: [],
      search: ''
    }
  },
  methods: {
    getSuppliers () {
      this.$supplierService.getSuppliers()
        .then(data => {
          this.suppliers = data
        })
    },
    beforeArchive (index, id) {
      this.$confirm(this.$t('supplier.confirm.archive'), this.$t('status.warning'), {
        confirmButtonText: this.$t('status.ok'),
        cancelButtonText: this.$t('status.cancel'),
        type: 'warning'
      }).then(() => {
        this.archive(index, id)
      })
    },
    archive (index, id) {
      this.$supplierService.archive(id)
        .then(data => {
          this.$notify({
            title: this.$t('status.success'),
            message: this.$t('supplier.archive.success', [this.suppliers[index].name]),
            type: 'success'
          })
          this.suppliers.splice(index, 1)
        })
    },
    edit (id) {
      this.$router.push({ name: 'edit-supplier', params: { id } })
    }
  },
  mounted () {
    this.getSuppliers()
    this.$emit('parent-title', this.$t('supplier.title.manage'))
  }
}
</script>

<style scoped>

</style>
