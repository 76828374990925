<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">
                    <div class="col-md-6 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-store-24-hour"></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }}</h3>
                                <p class="opacity-75"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link v-can="'warehouse.read'" class="el-button el-button--primary"
                                         :to="{name: 'warehouses'}">
                                <span class="el-icon-s-order"></span> {{ $t('warehouse.button.all') }}
                            </router-link>
                            <router-link v-can="'warehouse.history'" class="el-button el-button--info"
                                         :to="{name: 'transfer-history'}">
                                <span class="mdi mdi-history"></span> {{ $t('warehouse.button.history') }}
                            </router-link>
                            <el-button type="secondary" v-if="$route.name === 'stock-adjustment-history'"
                                       @click="openCreatePage" v-can="'warehouse.adjust_stock'">
                                <span class="el-icon-plus"></span> {{ $t('warehouse.button.create_adjustment') }}
                            </el-button>
                            <router-link v-else v-can="'warehouse.adjust_stock'" class="el-button el-button--success"
                                         :to="{name: 'stock-adjustment-history', params: {id: $route.params.id}}">
                                <span class="el-icon-receiving"></span> {{ $t('warehouse.button.adjust_stock') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="slide-fade">
            <router-view @parent-title="updateTitle"/>
        </transition>
        <el-dialog @closed="dialogClosed" :title="$t('supply.title.warehouse')" :visible.sync="showDialog" width="30%">
            <el-select @change="whChanged" style="width: 100%" collapse-tags v-model="warehouse"
                       :placeholder="$t('supply.title.warehouse')">
                <el-option v-for="(wh, ind) in warehouses" :key="ind" :label="wh.name" :value="wh.id"></el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showDialog = false">
                    {{ $t('button.cancel') }}
                </el-button>
                <el-button :disabled="!warehouse" type="primary" @click="whChanged">
                    {{ $t('button.confirm') }}
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'Warehouses',
  components: {},
  data () {
    return {
      pageTitle: '',
      showDialog: false,
      warehouse: null,
      warehouses: []
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    async whChanged () {
      await this.openCreatePage()
      this.dialogClosed()
    },
    openCreatePage () {
      return new Promise((resolve) => {
        if (this.$route.params.id) {
          this.$router.push({ name: 'warehouse-stock-adjustment', params: { id: this.$route.params.id } })
        } else if (this.warehouse) {
          this.$router.push({ name: 'warehouse-stock-adjustment', params: { id: this.warehouse } })
        } else {
          this.showDialog = true
        }
        resolve()
      })
    },
    dialogClosed () {
      this.warehouse = null
      this.showDialog = false
    }
  },
  mounted () {
    this.$warehouseService.getWarehouses().then(whs => (this.warehouses = whs))
  }
}
</script>

<style scoped>

</style>
