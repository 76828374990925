<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">
                    <div class="col-md-4 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-currency-usd  "></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }}</h3>
                                <p class="opacity-75">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link v-can="'debt.read'" class="el-button el-button--primary el-button--medium" tag="el-button" type="primary" :to="{name: 'debts'}">
                                <i class="mdi mdi-format-list-bulleted"></i> {{ $t('employee.button.debt') }}
                            </router-link>
                            <el-button v-can="'debt.payment'" class="btn btn-light" is="router-link" type="default" :to="{name: 'payments'}">
                                <i class="mdi mdi-contactless-payment"></i> {{ $t('employee.button.payment') }}
                            </el-button>
                            <el-button v-can="'debt.type'" class="btn btn-primary" is="router-link" type="info" :to="{name: 'debt-types'}">
                                <i class="mdi mdi-group"></i> {{ $t('debt.button.type') }}
                            </el-button>
                            <el-button v-can="'debt.create'" size="medium" type="success" @click.prevent="createEmployeeDebt">
                                <i class="mdi mdi-plus"></i> {{ $t('employee.button.create_debt') }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-debt @close="closeDebtDialog" :display="debtDialog" @debt-ok="recordCreated"></create-debt>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle"/>
        </transition>
    </div>
</template>

<script>
import CreateDebt from '../Employees/Employee/Modals/CreateDebt'
export default {
  name: 'Debts',
  components: { CreateDebt },
  data () {
    return {
      pageTitle: '',
      debtDialog: false
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    createEmployeeDebt () {
      this.debtDialog = true
    },
    closeDebtDialog (display) {
      this.debtDialog = display
    },
    recordCreated (data) {
      this.$emit('new-debt', data)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
