export default class VariantService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getVariants () {
    return new Promise((resolve, reject) => {
      this.$http.get('variants')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createVariant (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('variants/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateVariant (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`variants/update/${id}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteVariant (id) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`variants/delete/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getVariantOptions (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`variants/options/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getItemVariant (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`variants/item/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createVariantOption (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('variants/options/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateVariantOption (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`variants/options/update/${id}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteVariantOption (id) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`variants/options/delete/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createVariantAndOptions (data, itemId) {
    return new Promise((resolve, reject) => {
      this.$http.post(`variants/create/${itemId}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
