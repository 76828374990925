<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('employee.debt.history') }}
                                </h5>
                            </el-col>
                            <el-col :span="12">
                                <el-row :gutter="20" type="flex" justify="end">
                                    <el-col :span="6">
                                        <router-link :to="{name: 'employee-visualisation'}" tag="el-button"
                                                     class="float-right el-button el-button--info el-button--mini">
                                            <i class="el-icon-pie-chart"></i> {{ $t('employee.debt.button.visualise') }}
                                        </router-link>
                                    </el-col>
                                    <el-col :span="6">
                                        <router-link :to="{name: 'archived-debt'}" tag="el-button"
                                                     class="float-right el-button el-button--warning el-button--mini">
                                            <i class="el-icon-takeaway-box"></i> {{ $t('employee.debt.button.archived') }}
                                        </router-link>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="debts"
                                         :pagination-props="{ pageSizes: [20, 30, 40, 100], pageSize: 20 }"
                                         center-align>
                                <el-table-column in label="S/N" type="index" sortable fixed></el-table-column>
                                <el-table-column prop="date" :formatter="$shortDate" label="Date"
                                                 min-width="100" fixed></el-table-column>
                                <el-table-column prop="amount" label="Initial Amount" min-width="100" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.amount | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="balance" label="Balance" min-width="100" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.balance | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="type.name" min-width="100" label="Category"></el-table-column>
                                <el-table-column prop="type.can_pay" label="Payable" class-name="text-center">
                                    <template slot-scope="scope">
                                        <i v-if="scope.row.type.can_pay" class="el-icon-check active-text"></i>
                                        <i class="el-icon-close fain-text" v-else></i>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="type.affects_account" label="Deductible"
                                                 class-name="text-center">
                                    <template slot-scope="scope">
                                        <i v-if="scope.row.type.affects_account" class="el-icon-check active-text"></i>
                                        <i class="el-icon-close fain-text" v-else></i>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Date"
                                                 min-width="100"></el-table-column>
                                <el-table-column label="Actions" min-width="125" fixed="right">
                                    <template slot-scope="scope">
                                        <el-dropdown split-button type="primary" size="mini"
                                                     :class="{'disable-child': archivable(scope.row)}"
                                                     @click="!archivable(scope.row) ?showPaymentDialog(scope.row.id):false"
                                                     trigger="click">
                                            Payback
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item icon="el-icon-refresh-left"
                                                                  @click.native="showHistoryDialog(scope.row.id)">
                                                    {{ $t('button.history') }}
                                                </el-dropdown-item>
                                                <el-dropdown-item icon="el-icon-arrow-down"
                                                                  @click.native="showDeptDialog(scope.row.id)">
                                                    More Details
                                                </el-dropdown-item>
                                                <el-dropdown-item :disabled="!archivable(scope.row)"
                                                                  icon="el-icon-takeaway-box"
                                                                  @click.native="addToArchive(scope.row.id)">
                                                    Archive
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <debt-payment :debt="payment" :visible="displayPaymentDialog" @close="hidePaymentDialog"
                      @paid="actionDebtPaid"></debt-payment>
        <payment-history :debt="history" :visible="displayHistoryDialog" @close="hideHistoryDialog"></payment-history>
        <el-dialog title="More Information" :visible.sync="displayInfoDialog">
            <el-collapse v-if="debt.user">
                <el-collapse-item title="Description" name="1">
                    <div class="currency">{{ debt.description }}</div>
                </el-collapse-item>
                <el-collapse-item title="Creator" name="2">
                    <div class="currency">{{ debt.user.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Created At" name="3">
                    <div class="currency">{{ shortDate(debt.created_at) }}</div>
                </el-collapse-item>
                <el-collapse-item title="Last Updated At" name="4">
                    <div class="currency">{{ shortDate(debt.updated_at) }}</div>
                </el-collapse-item>
            </el-collapse>
        </el-dialog>
    </div>
</template>

<script>
import DebtPayment from '../../Debts/Modal/DebtPayment'
import PaymentHistory from '../../Debts/Modal/PaymentHistory'

export default {
  name: 'Debt',
  components: { PaymentHistory, DebtPayment },
  props: {
    employee: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      debts: [],
      displayInfoDialog: false,
      debt: {},
      displayPaymentDialog: false,
      payment: {},
      displayHistoryDialog: false,
      history: {}
    }
  },
  methods: {
    archivable (data) {
      return data.balance <= 0 || (!data.type.affects_account && !data.type.can_pay)
    },
    newDebt (data) {
      this.debts.splice(0, 0, data)
    },
    showPaymentDialog (id) {
      this.payment = this.debts.find(d => d.id === id)
      this.payment.employee = this.employee
      this.displayPaymentDialog = true
    },
    hidePaymentDialog () {
      this.displayPaymentDialog = false
    },
    showHistoryDialog (id) {
      this.history = this.debts.find(d => d.id === id)
      this.history.employee = this.employee
      this.displayHistoryDialog = true
    },
    hideHistoryDialog () {
      this.displayHistoryDialog = false
    },
    actionDebtPaid (data) {
      const index = this.debts.indexOf(this.debts.find(d => d.id === data.id))
      if (data.balance <= 0) {
        this.debts.splice(index, 1)
      } else {
        this.debts[index].balance = data.balance
      }
    },
    showDeptDialog (id) {
      this.debt = this.debts.find(d => d.id === id)
      this.displayInfoDialog = true
    },
    addToArchive (id) {
      this.askQuestion(this.$t('debt.confirm.archive'), () => {
        this.$debtService.archiveDebt(id)
          .then(() => {
            this.debts.splice(this.debts.indexOf(this.debts.find(d => d.id === id)), 1)
            this.notify(this.$t('employee.debt.archived'))
          })
          .catch(error => {
            this.notify(error.message, this.$t('status.error'), 'error')
          })
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.$emit('parent-title', this.$t('employee.title.debt', [this.employee.name]))
    }, 1000)
    this.$debtService.getEmployeeDebts(this.$route.params.id)
      .then(data => {
        this.debts = data.data
      })
  },
  created () {
    this.$parent.$on('new-debt', this.newDebt)
  }
}
</script>

<style>
    .disable-child > div > button:first-child {
        z-index: 0;
        color: #FFF;
        background-color: #a0cfff;
        border-color: #a0cfff;
        cursor: not-allowed;
        background-image: none;
    }

    .disable-child > div > button:focus:first-child {
        /* outline: 1px dotted; */
        /* outline: 5px auto -webkit-focus-ring-color; */
        outline: none !important;
    }

    .fain-text {
        color: #8d97a5;
    }

    .active-text {
        color: #00cc99;
    }
</style>
