<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">
                    <div class="col-md-6 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-office-building"></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }}</h3>
                                <p class="opacity-75"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link v-can="'department.read'" is="el-button" :to="{name: 'payment-methods'}" icon="el-icon-s-order" type="primary">
                                {{ $t('payment_method.button.all') }}
                            </router-link>
                            <el-button v-can="'department.create'" @click="showCreateDialog" icon="el-icon-plus"  type="success">
                                {{ $t('payment_method.button.create') }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-edit-payment-method @close="closeCreateDialog" :display="displayCreateDialog" @created="methodCreated"></create-edit-payment-method>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle"/>
        </transition>
    </div>
</template>

<script>
import CreateEditPaymentMethod from './Modal/CreateEditPaymentMethod'
export default {
  name: 'PaymentMethod',
  components: { CreateEditPaymentMethod },
  data () {
    return {
      pageTitle: '',
      displayCreateDialog: false
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    showCreateDialog () {
      this.displayCreateDialog = true
    },
    closeCreateDialog () {
      this.displayCreateDialog = false
    },
    methodCreated (data) {
      this.$emit('new-payment-method', data)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
