<template>
  <el-dialog :visible="display" @open="open" @closed="closed"
             @close="$emit('close', false)" @opened="opened"
             destroy-on-close append-to-body fullscreen>
    <template #title>
      <h3 class="text-center">{{ $t(`item.title.${edit ? 'update' : 'create'}`, { name: item.name }) }}</h3>
      <hr>
    </template>
    <el-row>
      <el-col :span="14" :offset="5">
        <el-form :model="form" size="small" :rules="rules" ref="itemForm">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item :label="$t('item.label.name')" prop="name">
                <el-input ref="inputName" v-model="form.name" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('item.label.category')" prop="category">
                <el-select class="full-width" v-model="form.category" filterable clearable
                           :placeholder="$t('item.placeholder.category')">
                  <el-option v-for="item in categories" :key="item.id" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('item.label.description')" prop="description">
                <el-input type="textarea" v-model="form.description" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="my-0">
              <el-checkbox style="margin-bottom: 20px;" v-model="form.sellable">{{
                  $t('item.placeholder.sellable')
                }}
              </el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item style="" :label="$t('item.label.sold_by.label')" prop="item_type">
                <el-radio-group v-model="form.sold_by" size="mini">
                  <el-radio :disabled="!form.sellable" label="each" border>{{
                      $t('item.label.sold_by.each')
                    }}
                  </el-radio>
                  <el-radio :disabled="!form.sellable" label="measure" border>{{
                      $t('item.label.sold_by.weight')
                    }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-show="false" :label="$t('item.label.selling_price')" label-width="100" prop="unit_price">
                <el-input v-model.number="form.unit_price" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item style="" :label="$t('item.label.item_type')" prop="item_type">
                <el-radio-group v-model="form.item_type" size="mini">
                  <el-radio label="product" border>{{ $t('item.label.type.product') }}</el-radio>
                  <el-radio label="service" border>{{ $t('item.label.type.service') }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item :label="$t('item.label.supplier')" prop="supplier">
                <el-select class="full-width" v-model="form.supplier" filterable clearable
                           :placeholder="$t('item.placeholder.supplier')">
                  <el-option v-for="sup in suppliers" :key="sup.id" :label="sup.name"
                             :value="sup.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('item.label.purchase_price')" label-width="100"
                            prop="purchase_price">
                <el-input v-model.number="form.purchase_price" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="Unit Type" label-width="100" prop="unit_type">
                <el-autocomplete :fetch-suggestions="fetchTypeSuggestions" @keyup.enter.native="submitForm"
                                 v-model="form.unit_type" class="full-width"></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Barcode" prop="barcode">
                <el-input v-model="form.barcode"
                          :placeholder="$t('item.placeholder.barcode')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item :label="$t('item.label.reorder_level')" prop="reorder_level">
                <el-input v-model="form.reorder_level" :placeholder="$t('item.placeholder.reorder_level')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('item.label.optimal_order')" prop="optimal_order">
                <el-input v-model="form.optimal_order" :placeholder="$t('item.placeholder.optimal_order')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-card class="my-3">
            <div slot="header">
              <span class="font-weight-bolder h5">{{ $t('item.label.inventory') }}</span>
            </div>
            <el-row align="center" justify="right">
              <el-col :span="12" class="mb-0">
                <label> {{ $t('item.label.track') }} </label>
              </el-col>
              <el-col :span="8"></el-col>
              <el-col :span="3" class="ml-3 mb-0">
                <el-switch v-model="form.track"></el-switch>
              </el-col>
            </el-row>
          </el-card>
          <!--    Start variant section     -->
          <el-card class="my-3">
            <div slot="header">
              <span class="font-weight-bolder h5">{{ $t('item.label.visual') }}</span>
            </div>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-row justify="space-between">
                  <el-col :span="12">
                    <el-radio v-model="form.visual_type" border label="colour" class="mb-5">Colour</el-radio>
                  </el-col>
                  <el-col :span="12" align="center">
                    <el-radio v-model="form.visual_type" border label="image" style="float: right; margin-right: 25px;">
                      {{ $t('item.label.image') }}
                    </el-radio>
                  </el-col>
                </el-row>
                <el-row justify="space-between">
                  <el-col :span="12">
                    <el-row justify="center" align="middle">
                      <el-col class="mr-1 mb-1" :key="index" v-for="(colour, index) in predefineColours" :span="4"
                              style="height: 50px; width: 50px; align-content: center;"
                              @click.native="form.visual_type === 'colour' ? setColour(colour.value) : null"
                              :style="{backgroundColor: form.visual_type === 'colour' ? colour.colour : '#fcfafa'}"
                              align="center" justify-self="center">
                        <span class="el-icon-check text-white" style="font-size: 24px;"
                              v-if="colour.value === form.colour"/>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="12">
                    <el-upload :disabled="form.visual_type !== 'image'" class="avatar-uploader" action=""
                               :show-file-list="false" :on-success="handleImageSuccess"
                               :before-upload="beforeImageUpload"
                               :on-remove="handleImageRemove"
                               style="float: right; margin-right: 25px;">
                      <img v-if="form.image" :src="form.image" class="custom-avatar" alt="">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-card>
          <!--    End visual section     -->
          <!--    Start variant section     -->
          <el-card class="mb-3">
            <div slot="header">
              <span class="font-weight-bolder h5"> {{ $t('variant.name') }} </span>
            </div>
            <div>
              <p style="color: grey;">{{ $t('variant.message.guide') }}</p>
              <el-button v-if="edit && item.variants.length" type="danger" icon="el-icon-delete"
                         @click.native="deleteAllVariants" plain>{{ $t('button.delete') }}
              </el-button>
              <el-button type="success" @click="toggleVariantDialog(true)" class="mt-2" plain>
                {{
                  item.variants && item.variants.length ? $t(`variant.button.edit_variants`) : $t(`variant.button.add_variants`)
                }}
                <i :class="`el-icon-${item.variants && item.variants.length? 'edit':'plus'}`"></i>
              </el-button> &nbsp;
              <i v-if="form.variants.length" class="text-primary">{{ $t('item.message.variant_modified') }} &nbsp;
                <el-button type="danger" size="mini" plain @click.prevent="form.variants = []">
                  {{ $t('item.button.remove') }}
                </el-button>
              </i>
              <div v-if="item.variants && item.variants.length">
                <el-divider/>
                <data-tables :data="item.variants"
                             :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                  <el-table-column label="S/N" type="index"></el-table-column>
                  <el-table-column show-overflow-tooltip prop="name" :label="$t('variant.table.title.variant')">
                  </el-table-column>
                  <el-table-column prop="unit_price" :label="$t('variant.table.title.unit_price')"
                                   :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <el-input
                        v-if="editedItemVariant.id === scope.row.id && editedItemVariant.module === 'unit_price'"
                        v-model="scope.row.unit_price" size="small"
                        @keyup.enter.native="editedItemVariant = {id: '', module: ''}"/>
                      <div v-else @click="updateEditedItemVariant(scope.row.id, 'unit_price')">
                        <span>{{ scope.row.unit_price }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="purchase_price" :label="$t('variant.table.title.purchase_price')"
                                   :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <el-input
                        v-if="editedItemVariant.id === scope.row.id && editedItemVariant.module === 'purchase_price'"
                        v-model="scope.row.purchase_price" size="small"
                        @keyup.enter.native="editedItemVariant = {id: '', module: ''}"/>
                      <div v-else @click="updateEditedItemVariant(scope.row.id, 'purchase_price')">
                        <span>{{ scope.row.purchase_price }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column show-overflow-tooltip prop="code"
                                   :label="$t('variant.table.title.code')"></el-table-column>
                  <el-table-column show-overflow-tooltip prop="barcode" :label="$t('variant.table.title.barcode')">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.barcode" size="small"
                                v-if="editedItemVariant.id === scope.row.id && editedItemVariant.module === 'barcode'"
                                @keyup.enter.native="editedItemVariant = {id: '', module: ''}"/>
                      <div v-else @click="updateEditedItemVariant(scope.row.id, 'barcode')">
                        <span>{{ scope.row.barcode }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Action" min-width="100px" align="center">
                    <template slot-scope="scope">
                      <el-button icon="el-icon-delete" v-can.disable="'variant.delete'"
                                 @click="itemVariantDelete(scope.$index, scope.row.id)" :loading="loading"
                                 type="danger" size="mini"></el-button>
                    </template>
                  </el-table-column>
                </data-tables>
              </div>
            </div>
          </el-card>
          <!--    End variant section     -->
          <!--    Start modifier section     -->
          <el-card v-if="modifiers.length > 0" style="margin-bottom: 10px;">
            <div slot="header">
              <span class="font-weight-bolder h5"> {{ $t('modifier.name') }} </span>
            </div>
            <div v-for="(modifier, index) in modifiers" :key="modifier.id">
              <el-row align="center" justify="right">
                <el-divider v-if="index > 0" class="my-3"/>
                <el-col :span="12" class="mb-0">
                  <label> {{ modifier.name }} </label>
                </el-col>
                <el-col :span="8"></el-col>
                <el-col :span="3" class="ml-3 mb-0">
                  <el-switch v-model="modifier.selected" @change="updateItemModifier($event, modifier)"></el-switch>
                </el-col>
              </el-row>
            </div>
          </el-card>
          <!--    End modifier section     -->
          <!--    Start tax section     -->
          <el-card v-if="taxes.length" style="margin-bottom: 10px;">
            <template #header>
              <span class="font-weight-bolder h5"> {{ $t('tax.name') }} </span>
            </template>
            <div v-for="(tax, index) in taxes" :key="tax.id">
              <el-row align="center" justify="space-between">
                <el-divider v-if="index > 0" class="my-3"/>
                <el-col :span="12" class="mb-0">
                  <label> {{ tax.name }} </label>
                </el-col>
                <el-col :span="8"></el-col>
                <el-col :span="3" class="ml-3 mb-0">
                  <el-switch v-model="tax.selected" @change="updateItemTax($event, tax)"></el-switch>
                </el-col>
              </el-row>
            </div>
          </el-card>
          <!--    End tax section     -->
          <!--    Start price list section     -->
          <el-table size="small" stripe :data="form.price_lists" style="width: 100%;"
                    header-cell-class-name="bg-secondary" class="mt-4">
            <el-table-column label-class-name="text-white text-center" :label="$t('item.label.price_list_prices')">
              <el-table-column :label="$t('price_list.label.description')"
                               prop="name" label-class-name="text-white" class-name="text-dark">
              </el-table-column>
              <el-table-column :label="$t('item.label.selling_price')"
                               prop="name" label-class-name="text-white" class-name="text-dark">
                <template slot-scope="scope">
                  <el-input @change="scope.row.id === 1 ? setSellingPrice($event) : (() => {})" size="small"
                            v-model.number="form.price_lists[scope.$index].value"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <!--    End price list section     -->
          <!--    Start department price section     -->
          <el-table size="small" stripe :data="form.departments" style="width: 100%"
                    header-cell-class-name="bg-secondary" class="mt-4">
            <el-table-column label-class-name="text-white text-center" :label="$t('item.label.department_prices')">
              <el-table-column :label="$t('warehouse.label.department')"
                               prop="name" label-class-name="text-white" class-name="text-dark">
                <template slot="header" slot-scope="scope">
                  {{ $t('warehouse.label.department') }} ({{ $t('warehouse.label.warehouse') }})
                </template>
                <template slot-scope="scope">{{ scope.row.name }} ({{ scope.row.wh }})</template>
              </el-table-column>
              <el-table-column :label="$t('item.label.selling_price')"
                               prop="name" label-class-name="text-white" class-name="text-dark">
                <template slot-scope="scope">
                  <el-input :disabled="!scope.row.wh" size="small"
                            v-model.number="form.departments[scope.$index].selling_price"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <!--    End department price section     -->
        </el-form>
      </el-col>
    </el-row>
    <div class="mb-5 pb-3"></div>
    <template #footer>
      <el-row class="dialog-footer">
        <el-col :span="12" :offset="6">
          <el-button @click="$emit('close', false)" type="danger" plain>{{ $t('button.cancel') }}</el-button>
          <el-button :disabled="!edited" :loading="loading" type="primary" @click="submitForm">
            {{ $t(`item.button.${edit ? 'update' : 'create'}`) }}
          </el-button>
        </el-col>
      </el-row>
    </template>
    <variant-dialog :display="variantDialog" @close="toggleVariantDialog" @created="newVariant"
                    @updated="updatedVariant" :item="item" :prev-variants="form.variants"></variant-dialog>
  </el-dialog>
</template>

<script>
import VariantDialog from '@/components/Items/Modal/VariantDialog.vue'

export default {
  name: 'CreateEditItem',
  components: { VariantDialog },
  props: {
    display: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      variantDialog: false,
      categories: [],
      suppliers: [],
      modifiers: [],
      taxes: [],
      itemModifiers: [],
      itemTaxes: [],
      editedItemVariant: {
        id: '',
        module: ''
      },
      snapshot: {},
      form: {
        name: '',
        description: '',
        unit_price: null,
        purchase_price: null,
        unit_type: null,
        item_type: 'product',
        visual_type: 'colour',
        sold_by: 'each',
        image: '',
        colour: '',
        barcode: '',
        sellable: true,
        reorder_level: null,
        optimal_order: null,
        category: null,
        supplier: null,
        price_lists: [],
        departments: [],
        modifiers: [],
        variants: [],
        taxes: []
      },
      itemVariants: [],
      predefineColours: [
        {
          value: '16729344',
          colour: '#ff4500'
        },
        {
          value: '11156336',
          colour: '#aba298'
        },
        {
          value: '16766720',
          colour: '#ffd700'
        },
        {
          value: '9437184',
          colour: '#90ee90'
        },
        {
          value: '13520127',
          colour: '#00ced1'
        },
        {
          value: '1973791',
          colour: '#1e90ff'
        },
        {
          value: '1973791',
          colour: '#c71585'
        },
        {
          value: '16729345',
          colour: '#FF4500AD'
        },
        {
          value: '16750464',
          colour: '#FF7800FF'
        },
        {
          value: '19371839',
          colour: '#00BABDFF'
        },
        {
          value: '1973791',
          colour: '#1F93FFBA'
        },
        {
          value: '13028405',
          colour: '#c7158577'
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: this.$t('validation.required'),
            trigger: 'change'
          }
        ],
        item_type: [
          {
            required: true,
            message: this.$t('validation.required'),
            trigger: 'change'
          }
        ],
        category: [{
          required: true,
          message: this.$t('validation.required'),
          trigger: 'change'
        }],
        unit_price: [
          {
            required: false,
            message: this.$t('validation.required'),
            trigger: 'change'
          },
          {
            type: 'number',
            message: this.$t('validation.required'),
            trigger: 'change'
          }
        ],
        unit_type: [
          {
            required: false,
            message: this.$t('validation.required'),
            trigger: 'change'
          }
        ],
        reorder_level: [
          {
            required: false,
            message: this.$t('validation.required'),
            trigger: 'change'
          }
        ],
        optimal_order: [
          {
            required: false,
            message: this.$t('validation.required'),
            trigger: 'change'
          }
        ]
      },
      loading: false,
      draggedIndex: null,
      unitTypes: []
    }
  },
  computed: {
    submittable () {
      return (this.form.name.length && this.form.unit_type && this.form.unit_price)
    },
    edit () {
      return this.item && this.item.id
    },
    edited () {
      return JSON.stringify(this.form) !== JSON.stringify(this.snapshot)
    }
  },
  watch: {
    'form.visual_type' (value) {
      console.log(value)
      if (value === 'image') {
        if (this.edit && this.item.colour) {
          this.form.colour = this.item.colour
        } else {
          this.form.colour = ''
        }
      } else {
        this.form.colour = this.predefineColours[0].value
      }
    }
    /* 'form.unit_price' (value) {
      if (!this.edit) {
        this.form.price_lists[0].value = value
      }
    } */
  },
  methods: {
    setColour (colour) {
      if (this.form.colour === colour) {
        this.form.colour = ''
      } else {
        this.form.colour = colour
      }
    },
    updateEditedItemVariant (itemVariantId, itemVariantModule) {
      this.editedItemVariant.id = itemVariantId
      this.editedItemVariant.module = itemVariantModule
    },
    updateItemVariants (data = null) {
      if (data !== null) {
        this.itemVariants = data
      } else {
        this.$itemService.getItemVariants(this.item.id)
          .then(data => {
            this.itemVariants = data
          })
      }
    },
    deleteAllVariants () {
      this.askQuestion(this.$t('variant.confirm.delete_all'), async () => {
        this.loading = true
        this.$itemService.deleteVariant(this.item.id)
          .then(data => {
            this.$emit('updated', data)
            this.loading = false
            this.notify(this.$t('variant.message.deleted'), this.$t('status.success'))
          })
      }, this.$t('variant.title.delete_all'), () => {
      }, 'error', { confirmButtonClass: 'el-button--danger' })
    },
    itemVariantDelete (index, id) {
      this.askQuestion(this.$t('variant.confirm.delete'), async () => {
        this.loading = true
        this.$itemService.deleteItemVariant(id)
          .then(data => {
            this.itemVariants.splice(index, 1)
            this.loading = false
            this.notify(this.$t('variant.message.deleted'), this.$t('status.success'))
          })
      })
    },
    setSellingPrice (value) {
      this.form.unit_price = parseInt(value)
    },
    handleImageRemove () {
      this.form.image = ''
    },
    encodeImageFileAsURL (file, loc) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.form.image = reader.result
        return false
      }
      reader.readAsDataURL(file)
    },
    beforeImageUpload (image) {
      this.encodeImageFileAsURL(image)
    },
    handleImageSuccess () {
    },
    fetchTypeSuggestions (queryString, cb) {
      cb(queryString
        ? (this.unitTypes.filter(t => {
            return t.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          }))
        : this.unitTypes)
    },
    open () {
      this.fetchData()
    },
    opened () {
      if (this.edit) {
        /* Object.keys(this.form).forEach((k) => {
          if (k === 'category') {
            this.form[k] = this.item.category_id
          } else if (k === 'supplier') {
            this.form[k] = this.item.supplier_id
          } else {
            this.form[k] = this.item[k]
          }
        }) */
        this.form.name = this.item.name
        this.form.description = this.item.description
        this.form.unit_price = this.item.unit_price
        this.form.purchase_price = this.item.purchase_price
        this.form.unit_type = this.item.unit_type
        this.form.item_type = this.item.item_type
        this.form.visual_type = this.item.visual_type === 'colour' ? 'colour' : 'image'
        this.form.sold_by = this.item.sold_by !== undefined ? this.item.sold_by : 'each'
        this.form.image = this.item.image
        this.form.colour = this.item.colour
        this.form.barcode = this.item.barcode
        this.form.sellable = this.item.sellable !== undefined ? this.item.sellable : true
        this.form.reorder_level = this.item.reorder_level
        this.form.optimal_order = this.item.optimal_order
        this.form.category = this.item.category_id
        this.form.supplier = this.item.supplier_id
        this.form.price_lists = this.item.price_lists
        this.form.departments = this.item.departments
        this.form.modifiers = this.item.modifiers
        this.form.variants = [] // this.item.variants
        this.form.taxes = this.item.taxes
      } else {
        this.colour = this.predefineColours[0].value
      }
      this.snapshot = Object.assign({}, this.form)
      this.$refs.inputName.focus()
    },
    clearFields () {
      this.$refs.itemForm.resetFields()
    },
    closed () {
      this.clearFields()
      this.form.name = ''
      this.form.description = ''
      this.form.unit_price = null
      this.form.purchase_price = null
      this.form.unit_type = null
      this.form.item_type = 'product'
      this.form.sellable = true
      this.form.sold_by = 'each'
      this.form.visual_type = 'colour'
      this.form.reorder_level = null
      this.form.category = null
      this.form.barcode = ''
      this.form.image = ''
      this.form.supplier = null
      this.form.modifiers = []
      this.form.variants = []
      this.form.taxes = []
      this.loading = false
    },
    toggleVariantDialog (value) {
      if (value === undefined) value = false
      this.variantDialog = value
    },
    newVariant (data) {
      if (data && data.length) {
        this.form.variants = data
      } else {
        this.form.variants = []
      }
    },
    updatedVariant (data) {
      if (data && data.length) {
        this.form.variants = data
      } else {
        this.form.variants = []
      }
    },
    updateItemModifier (value, modifier) {
      if (value) {
        this.$nextTick(_ => (this.form.modifiers.push(modifier)))
      } else {
        this.$nextTick(_ => {
          const index = this.form.modifiers.indexOf(this.form.modifiers.find(m => m.id === modifier.id))
          this.form.modifiers.splice(index, 1)
        })
      }
    },
    async updateItemTax (value, tax) {
      if (value) {
        this.$nextTick(_ => (this.form.taxes.push(tax)))
      } else {
        this.$nextTick(_ => {
          const index = this.form.taxes.indexOf(this.form.taxes.find(m => m.id === tax.id))
          this.form.taxes.splice(index, 1)
        })
      }
      /* const tax = this.taxes.find(m => m.id === data.id)
      tax.selected = value
      if (value) {
        const itemTax = {
          item_id: this.item.id,
          tax_id: data.id
        }
        await this.$itemService.createItemTax(itemTax)
          .then(res => {
          })
      } else {
        const itemTax = this.itemTaxes.find(im => im.tax_id === data.id && im.item_id === this.item.id)
        await this.$itemService.deleteItemTax(itemTax.id)
          .then(res => {
          })
      } */
    },
    submitForm () {
      this.$refs.itemForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`item.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$itemService.updateItem(this.item.id, this.form)
                .then(data => {
                  this.notify(this.$t('item.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$itemService.createItem(this.form)
                .then(data => {
                  this.notify(this.$t('item.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
            // create new item variants
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    },
    fetchSuppliers () {
      return new Promise((resolve, reject) => {
        this.$supplierService.getSuppliers()
          .then(resolve)
          .catch(reject)
      })
    },
    async fetchData () {
      this.$categoryService.getCategories()
        .then(data => {
          this.categories = data
        })
      this.suppliers = await this.fetchSuppliers()
      setTimeout(() => {
        this.$configService.getPriceLists()
          .then(data => {
            this.form.price_lists = data.map(pl => {
              let value = null
              if (this.edit) {
                const ipl = this.item.price_lists.find(ipl => ipl.id === pl.id)
                if (ipl && ipl.id) {
                  value = ipl.pivot.price
                }
              }
              return {
                id: pl.id,
                name: pl.name,
                value
              }
            })
          })
        if (this.edit) {
          this.$departmentService.getDepartmentsWithWarehouseItem(this.item.id)
            .then(data => {
              this.form.departments = data.map(d => {
                return {
                  id: d.id,
                  wh: d.warehouse ? d.warehouse.name : null,
                  name: d.name,
                  selling_price: d.warehouse && d.warehouse.items.length ? d.warehouse.items[0].pivot.selling_price : null
                }
              })
            })
        } else {
          this.$departmentService.getDepartmentsWithWarehouseItem()
            .then(data => (this.form.departments = data.map(d => ({
              id: d.id,
              wh: d.warehouse.name,
              name: d.name,
              selling_price: null
            }))))
        }
      }, 200)
      this.$itemService.getItemsUnitTypes()
        .then(data => (this.unitTypes = data))
      /* await this.$itemService.getItemModifiers()
        .then(data => {
          this.itemModifiers = data
        }) */
      await this.$modifierService.getModifiers()
        .then(data => {
          this.modifiers = data.map(m => ({
            ...m,
            selected: this.item.modifiers ? !!this.item.modifiers.find(mm => m.id === mm.id) : false
          }))
        })
      // await this.$itemService.getItemTaxes()
      //   .then(data => {
      //     this.itemTaxes = data
      //   })
      await this.$taxService.getTaxes()
        .then(data => {
          this.taxes = data.map(t => ({
            ...t,
            selected: this.item.taxes ? !!this.item.taxes.find(tt => t.id === tt.id) : false
          }))
          /* for (const tax of data) {
            if (this.item && this.item.id) {
              const record = this.itemTaxes.find(im => im.tax_id === tax.id && im.item_id === this.item.id)
              tax.selected = !!record
            } else {
              tax.selected = false
            }
          }
          this.taxes = data */
        })
      await this.updateItemVariants()
    }
  },
  mounted () {
  }
}
</script>

<style>
.el-dialog__footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: white;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 99999999999999999;
}

input[type="file"].el-upload__input {
  display: none;
}

.avatar-uploader .el-upload {
  position: inherit !important;
}

.avatar-uploader .el-upload {
  position: fixed;
  overflow: hidden;
  width: 100px;
  height: 100px;
  display: block;
}

.avatar-uploader-icon:hover {
  opacity: .4;
  background-color: rgba(21, 21, 21, .5);
  color: #FFFFFF;
}

.avatar-uploader-icon {
  border: 2px dashed #d9d9d9;
  border-radius: 6px;
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 90px;
  text-align: center;
  cursor: pointer;
}

.avatar-uploader-icon:hover {
  opacity: 1;
}

[class^="el-icon-"] {
  display: inherit;
}

.custom-avatar {
  width: 100px;
  height: 100px;
  display: block;
  border: 2px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
