export default class ItemService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getItems () {
    return new Promise((resolve, reject) => {
      this.$http.get('items')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getActiveItems () {
    return new Promise((resolve, reject) => {
      this.$http.get('items/active')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getItemsUnitTypes () {
    return new Promise((resolve, reject) => {
      this.$http.get('items/suggestions/unit-types')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  massInsert (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('items/mass-insert', data)
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
  }

  getArchivedItems () {
    return new Promise((resolve, reject) => {
      this.$http.get('items/archive/all')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createItem (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('items/create', data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateItem (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`items/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  archiveItem (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`items/${id}/archive`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  restoreItem (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`items/archive/${id}/restore`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getItemModifiers () {
    return new Promise((resolve, reject) => {
      this.$http.get('item_modifiers')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createItemModifier (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('item_modifiers/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteVariant (itemId) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`items/variants/delete/${itemId}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteItemModifier (id) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`item_modifiers/delete/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getAllItemVariants () {
    return new Promise((resolve, reject) => {
      this.$http.get('item_variants')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getItemVariants (itemId) {
    return new Promise((resolve, reject) => {
      this.$http.get(`item_variants/${itemId}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createItemVariant (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('item_variants/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateItemVariant (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`item_variants/update/${id}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteItemVariant (id) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`item_variants/delete/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getItemTaxes () {
    return new Promise((resolve, reject) => {
      this.$http.get('item_taxes')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createItemTax (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('item_taxes/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteItemTax (id) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`item_taxes/delete/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getSearchedItems (query) {
    return new Promise((resolve, reject) => {
      this.$http.post('items/search', query)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getCategoryItems (categoryId) {
    return new Promise((resolve, reject) => {
      this.$http.get(`items/labels/categories/${categoryId}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getSupplierItems (supplierId) {
    return new Promise((resolve, reject) => {
      this.$http.get(`items/labels/suppliers/${supplierId}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
