<template>
    <el-pagination
            :background="background"
            :layout="layout"
            :total="total" :hide-on-single-page="hideOnSinglePage" :page-size="pageSize"
            :page-sizes="pageSizes" :current-page="currentPage" :small="small"
            @size-change="sizeChange" @current-change="currentChange"
            @prev-click="prevClick" @next-click="nextClick">
    </el-pagination>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
      required: false
    },
    hideOnSinglePage: {
      type: Boolean,
      default: true
    },
    layout: {
      type: String,
      default: 'prev, pager, next'
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default: () => ([10, 20, 30, 40, 50, 100])
    },
    currentPage: {
      type: Number,
      default: 1
    },
    small: {
      type: Boolean,
      default: false
    },
    background: {
      type: Boolean,
      default: true
    },
    fetch: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    sizeChange () {
      this.$emit('size-chang')
    },
    currentChange (page) {
      this.$emit('current-change', page)
    },
    prevClick () {
      this.$emit('prev-click')
    },
    nextClick () {
      this.$emit('next-click')
    }
  }
}
</script>

<style scoped>

</style>
