<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('supply.button.supplies') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables cell-class-name="text-center" :header-row-style="{textAlign: 'text-center'}"
                  :data="supplies.filter(data => !search || data.supplier.name.toLowerCase().includes(search.toLowerCase()))"
                  :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }">
                <el-table-column prop="code" :label="$t('label.ref')"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="supplier.name" :label="$t('supply.label.supplier')"></el-table-column>
                <el-table-column align="center" prop="paid" :label="$t('supply.title.total')" sortable>
                  <template slot-scope="scope">
                    <span class="currency">{{ formatPrice(scope.row.total_cost) }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="paid" :label="$t('supply.label.paid')" sortable>
                  <template slot-scope="scope">
                    <span class="currency">{{ formatPrice(scope.row.paid) }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="owed" :label="$t('supply.label.owed')" sortable>
                  <template slot-scope="scope">
                    <span class="currency">{{ formatPrice(scope.row.owed) }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="user.name" :label="$t('label.creator')"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="date" :formatter="$shortDate" :label="$t('label.date')">
                </el-table-column>
                <el-table-column align="center" prop="items_count" :label="$t('supply.label.item_count')" sortable></el-table-column>
                <el-table-column :label="$t('label.action')" min-width="100px">
                  <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" autofocus :placeholder="$t('text.search_placeholder')"/>
                  </template>
                  <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click.prevent="showItemsDialog(scope.row.id)">
                      <i class="mdi mdi-details"></i>
                    </el-button>
                    <el-button type="info" size="mini" icon="el-icon-printer"></el-button>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <supplies-items-modal :visible="itemsDialog" :items="items" @close="closeItemsDialog"></supplies-items-modal>
  </div>
</template>

<script>
import SuppliesItemsModal from '../Supplier/History/SuppliesItemsModal'

export default {
  name: 'Supplies',
  components: { SuppliesItemsModal },
  data () {
    return {
      supplies: [],
      items: [],
      itemsDialog: false,
      search: ''
    }
  },
  methods: {
    showItemsDialog (id) {
      this.items = this.supplies.find(s => s.id === id).items
      this.itemsDialog = true
    },
    closeItemsDialog (display) {
      this.itemsDialog = display
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('supply.title.purchase'))
    this.$supplyService.getSupplies()
      .then(data => {
        this.supplies = data
      })
  }
}
</script>

<style scoped>

</style>
