<template>
    <el-dialog
            :title="$t('employee.modal.details.title', [employee.name])"
            :visible="visible"
            width="55%" @close="$emit('close', false)"
            center>
        <div v-if="employee.type">
                <ul class="list-group m-t-10">
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">Name:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ employee.name }}  </span>
                        </span>
                    </li>
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">NIC:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ employee.nic }}  </span>
                        </span>
                    </li>
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">Email:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ employee.email }}  </span>
                        </span>
                    </li>
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">Address:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ employee.address }}  </span>
                        </span>
                    </li>
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">Category:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ employee.type.name }}  </span>
                        </span>
                    </li>
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">Salary:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ employee.type.salary | currency('XAF') }}  </span>
                        </span>
                    </li>
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">Employment Date:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ shortDate(employee.employment_date) }}  </span>
                        </span>
                    </li>
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">Created on:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ shortDate(employee.created_at) }}  </span>
                        </span>
                    </li>
                    <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                        <span>
                            <span class="d-block">Creator:</span>
                        </span>
                        <span>
                            <span class="text-success d-block"> {{ employee.user.name }}  </span>
                        </span>
                    </li>
                </ul>
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="$emit('close', false)">Close</el-button>
            </span>
    </el-dialog>
</template>

<script>
export default {
  name: 'EmployeeDetails',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    employee: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
