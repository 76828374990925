<template>
<div>Permissions</div>
</template>

<script>
export default {
  name: 'Permissions'
}
</script>

<style scoped>

</style>
