<template>
  <div class="container  pull-up">
    <div class="row">
      <div class="col-lg-12">
        <div class="card m-b-30 ">
          <div class="card-header">
            <p class="m-b-0 opacity-50">
              Fields with
              <req/>
              are required
            </p>
            <hr>
          </div>
          <div class="card-body ">
            <el-form ref="form" :model="form" :rules="rules" label-width="200px" size="large" status-icon>
              <el-form-item :label="$t('service.label.name')" prop="name">
                <el-input v-model="form.name" ref="input"></el-input>
              </el-form-item>
              <el-form-item :label="$t('service.label.department')" prop="description">
                <el-select v-model="form.department" :placeholder="$t('text.click_to_select')"
                           class="full-width">
                  <el-option
                      v-for="department in departments"
                      :key="department.id"
                      :label="department.name"
                      :value="department.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('service.label.description')" prop="description">
                <el-input type="textarea" v-model="form.description" :placeholder="$t('service.label.description')"></el-input>
              </el-form-item>
            </el-form>
            <div class="card-footer">
              <el-button @click.prevent="createService" icon="mdi mdi-content-save" type="success">
                {{ $t('service.button.create') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Create',
  data () {
    return {
      departments: [],
      form: {
        name: '',
        department: null,
        description: ''
      },
      rules: {
        name: [{ required: true }],
        department: [{ required: true }],
        description: [{ required: false }]
      }
    }
  },
  methods: {
    createService () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t('service.confirm.create'), () => {
            this.$serviceService.createService(this.form)
              .then(resp => {
                this.displayMessage(this.$t('service.message.created'), () => {
                  this.$router.push({ name: 'services' })
                })
              })
              .catch(this.globalErrHandle)
          })
        }
      })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('service.title.create'))
    this.$departmentService.getDepartments()
      .then(data => (this.departments = data))
  }
}
</script>

<style scoped>

</style>
