export default class UserService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getUsers () {
    return new Promise((resolve, reject) => {
      this.$http.get('users')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getCashiers () {
    return new Promise((resolve, reject) => {
      this.$http.get('users/cashiers')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createUser (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('users/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateUser (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`users/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  changeStatus (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`users/${id}/status`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  resetPassword (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`users/${id}/password-reset`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
