<template>
    <div>
        <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display" @open="fetchSuppliers"
                   @closed="closed" @close="$emit('close', false)" :show-close="false">
            <slot name="title">
                <h3 class="text-center">Add supplier payment <span v-if="supplierSelected">[{{ formatPrice(supplier.amt_owed) }}]</span>
                </h3>
            </slot>
            <el-row>
                <el-col :span="12" :offset="6">
                    <el-form :model="form" :rules="rules">
                        <el-form-item label="Choose supplier" label-width="100" prop="supplier">
                            <el-select v-model="form.supplier" placeholder="Please select a supplier"
                                       style="width:100%">
                                <el-option v-for="sup in suppliers" :label="sup.name" :key="sup.id"
                                           :value="sup.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Payment amount" label-width="100" prop="amount">
                            <el-input :disabled="!supplierSelected || !owing" @focus="selectContent"
                                      v-model.number="form.amount" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="Payment date" label-width="100" prop="date">
                            <el-date-picker v-model="form.date" format="dd MMM, yyyy" class="f-w"
                                            value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="Message" label-width="100" prop="message">
                            <el-input type="textarea" v-model="form.message"></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-row slot="footer" class="dialog-footer">
                <el-col :span="12" :offset="6">
                    <el-button @click="$emit('close', false)">Cancel</el-button>
                    <el-button :loading="loading" :disabled="!submittable" type="primary" @click="makePayment">Confirm
                    </el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'MakePaymentModal',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    supplierId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      form: {
        supplier: this.supplierId,
        amount: 0,
        date: null,
        message: ''
      },
      supplier: {},
      suppliers: [],
      loading: false,
      rules: {
        supplier: [
          { required: true, message: 'Please, select a supplier', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: 'Please, enter a valid amount', trigger: 'blur' }
        ],
        date: [
          { required: true, message: 'Please, select a date', trigger: 'blur' }
        ],
        message: [
          { required: true, message: 'Please, enter a message', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    'form.amount' (value, old) {
      if (this.form.supplier && this.suppliers.length) {
        const sup = this.suppliers.find(s => s.id === this.form.supplier)
        if (sup && (this.form.amount > (sup.amt_owed + 24))) {
          this.$notify({
            type: 'warning',
            message: this.$t('supplier.modal.payment.amount'),
            title: this.$t('status.warning')
          })
          this.form.amount = sup.amt_owed
        }
      }
    },
    'form.supplier' (id) {
      this.supplier = this.suppliers.find(s => s.id === id)
      this.form.amount = 0
    }
  },
  computed: {
    supplierSelected () {
      return this.form.supplier > 0
    },
    owing () {
      if (this.form.supplier && this.suppliers.length) {
        return this.suppliers.find(s => s.id === this.form.supplier).amt_owed > 0
      }
      return false
    },
    submittable () {
      return this.supplierSelected && this.owing && this.form.amount > 0
    }
  },
  methods: {
    selectContent (evt) {
      if ((parseInt(evt.target.value) === 0) && (this.form.supplier > 0)) {
        this.form.amount = this.suppliers.find(s => s.id === this.form.supplier).amt_owed
      }
    },
    fetchSuppliers () {
      this.$supplierService.getSuppliers()
        .then(data => {
          this.suppliers = data
          if (this.supplierId) {
            this.supplier = this.suppliers.find(s => s.id === this.supplierId)
          }
        })
    },
    makePayment () {
      const supplier = this.suppliers.find(s => s.id === this.form.supplier) || {}
      this.askQuestion(this.$t('supplier.confirm.make_payment', { supplier: supplier.name }), () => {
        const data = Object.assign(this.form)
        data.supplier_id = data.supplier
        this.loading = true
        this.$supplierService.makeSupplierPayment(data)
          .then(res => {
            this.displayMessage(this.$t('supplier.modal.payment.paid', {
              amount: data.amount,
              supplier: supplier.name
            }), () => {
              this.$emit('updated', res)
              this.$emit('close', false)
            })
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              this.displayMessage(error.response.data.message, () => {
              }, 'warning')
            }
          })
          .then(() => {
            this.loading = false
          })
      })
    },
    closed () {
      this.form.amount = 0
      this.form.supplier = null
      this.form.date = null
      this.form.message = ''
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
