<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">
                    <div class="col-md-6 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-currency-usd  "></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }} </h3>
                                <p class="opacity-75"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 text-white p-b-30 ml-auto">
                        <div class="rounded text-white bg-white-translucent">
                            <div class="p-all-15">
                                <div class="row">
                                    <div v-if="category.id" class="col-md-12 my-2 m-md-0 text-center">
                                        <div class="text-overline opacity-75">
                                            {{ $t('account.category.title.sum', {name: category.name}) }}
                                        </div>
                                        <transition name="slide-fades">
                                            <h3 v-if="animation" class="m-0 text-success">
                                                {{ formatPrice(category.total) }}
                                            </h3>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <router-view v-if="category.id" :category="category" @parent-title="updateTitle"
                         @refresh-balance="refreshBalance"/>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'AccountCategory',
  components: {},
  data () {
    return {
      pageTitle: '',
      category: {},
      animation: false
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    refreshBalance () {
      this.animateAmount()
      this.$accountService.getAccount()
        .then(data => {
          this.account = data
        })
    },
    animateAmount () {
      this.animation = false
      setTimeout(() => {
        this.animation = true
      }, 1000)
    }
  },
  mounted () {
    this.refreshBalance()
    this.$accountService.getCategorySummary(this.$route.params.id)
      .then(data => (this.category = data))
  }
}
</script>

<style>
    .slide-fades-enter-active {
        transition: all .3s ease;
    }

    .slide-fades-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fades-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
