<template>
  <el-dialog :visible="display" @opened="openedHandler"
             :close-on-click-modal="false" :show-close="false"
             :close-on-press-escape="false" @closed="closedHandler" center>
    <template slot="title">
      <h5 class="text-dark">{{ $t('stock.title.adjust_stock') }}</h5>
      <span class="text-primary">{{ $t('stock.title.adjust_stock_description') }}</span>
      <hr>
    </template>
    <el-row>
      <el-col :span="20" :offset="2">
        <el-form ref="form" :model="data" :rules="rules" size="mini" label-width="150px">
          <el-form-item :label="$t('stock.label.item')">
            <strong class="pl-2">{{ item.name }} ({{ item.code }})</strong>
          </el-form-item>
          <el-form-item :label="$t('label.date')" prop="date">
            <el-date-picker class="full-width f-w" type="date" v-model="data.date"></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('stock.label.type')" prop="type">
            <el-radio-group v-model="data.type" size="small">
              <el-radio border label="inc">{{ $t('stock.label.increase_quantity') }}</el-radio>
              <el-radio border label="dec">{{ $t('stock.label.decrease_quantity') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="add" :label="$t('stock.label.cost_price')" prop="cost_price">
            <el-input v-model.number="data.cost_price"></el-input>
          </el-form-item>
          <hr>
          <el-form-item :label="$t('stock.label.warehouse')" prop="warehouse">
            <el-select filterable allow-create class="f-w" v-model="data.warehouse">
              <el-option :key="index" v-for="(wh, index) in warehouses" :value="wh.id" :label="wh.name"
                         :disabled="!add && !wh.items.length">
                <template slot="default">{{ wh.name }} ({{
                    wh.items.length ? wh.items[0].pivot.quantity : 0
                  }})
                </template>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="add ? $t('stock.label.increase_by') : $t('stock.label.decrease_by')" prop="quantity">
            <el-input :disabled="!adjustable" v-model.number="data.quantity"></el-input>
          </el-form-item>
          <el-form-item :label="$t('stock.label.total_stock')" prop="total_stock">
            <el-input disabled :value="totalStock"></el-input>
          </el-form-item>
          <el-form-item>
            <strong class="">
              {{ $t('stock.label.new_quantity_in_stock') }}:
              {{ add ? (data.quantity + totalStock) : (totalStock - data.quantity) }}</strong>
          </el-form-item>
          <el-form-item :label="$t('stock.label.reason')" prop="reason">
            <el-select filterable allow-create class="f-w" v-model="data.reason">
              <el-option :key="index" :label="reason.value" v-for="(reason, index) in reasons"
                         :value="reason.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
          <el-button :disabled="!adjustable" type="primary" :loading="loading" @click.prevent="saveStockAdjustment">
            {{ $t('button.save') }}
          </el-button>
          <el-button @click.prevent="$emit('close')">{{ $t('button.cancel') }}</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'AdjustStock',
  props: {
    display: { type: Boolean },
    item: { type: Object, required: true },
    totalStock: { type: Number, required: false }
  },
  data () {
    return {
      loading: false,
      data: {
        cost_price: null,
        type: 'inc',
        date: null,
        quantity: null,
        reason: '',
        warehouse: null
      },
      reasons: [
        { key: 1, value: this.$t('stock.reasons.take_adjustment') },
        { key: 2, value: this.$t('stock.reasons.written_off') },
        { key: 3, value: this.$t('stock.reasons.damaged') },
        { key: 4, value: this.$t('stock.reasons.complement') }
      ],
      warehouses: [],
      show: false,
      rules: {
        reason: [{ required: true }],
        quantity: [{ required: true, type: 'number', min: 1 }],
        date: [{ required: true }],
        warehouse: [{ required: true }]
      }
    }
  },
  computed: {
    add () {
      return this.data.type.startsWith('inc')
    },
    adjustable () {
      return this.add || (!this.add && this.totalStock > 0)
    }
  },
  watch: {
    'data.quantity' (value) {
      if (value < 0) this.data.quantity = 0
      if (!this.add && value > this.totalStock) {
        this.data.quantity = this.totalStock
      }
      if (!this.add && this.data.warehouse) {
        const items = this.warehouses.find(wh => wh.id === this.data.warehouse).items
        if (items.length && value > items[0].pivot.quantity) {
          this.data.quantity = items[0].pivot.quantity
        }
      }
    },
    'data.type' (value) {
      if (!this.add && this.data.quantity > this.totalStock) {
        this.data.quantity = this.totalStock
      }
      if (this.data.warehouse && !this.add) {
        const items = this.warehouses.find(wh => wh.id === this.data.warehouse).items
        if (!items.length || !items[0].pivot.quantity) {
          this.data.warehouse = null
        }
        if (items.length && this.data.quantity && items[0].pivot.quantity < this.data.quantity) {
          this.data.quantity = items[0].pivot.quantity
        }
      }
    }
  },
  methods: {
    saveStockAdjustment () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t('stock.confirm.adjust'), () => {
            this.loading = true
            this.$warehouseService.adjustStockItem(this.item.id, this.data)
              .then(data => {
                this.notify(this.$t('stock.message.adjusted'), this.$t('status.success'))
                this.$emit('adjusted')
                this.$emit('close')
              })
              .catch(this.globalErrHandle)
              .then(() => {
                this.loading = false
              })
          })
        }
      })
    },
    openedHandler () {
      this.data.cost_price = this.item.unit_price
      this.$warehouseService.fetchWarehousesItem(this.item.id)
        .then(whs => {
          this.warehouses = whs
        })
    },
    closedHandler () {
      this.data.quantity = null
      this.data.warehouse = null
      this.data.reason = null
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style>
.el-input, .el-radio-group, .el-input__prefix {
  padding-left: .5rem !important;
}
</style>
