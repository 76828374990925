<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-store-24-hour"></i> {{ $t('warehouse.title.stock') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables :data="getItems"
                           :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                <el-table-column label="S/N" type="index"></el-table-column>
                <el-table-column show-overflow-tooltip prop="name" :label="$t('label.name')"></el-table-column>
                <el-table-column show-overflow-tooltip prop="category.name" :label="$t('item.label.category')"></el-table-column>
                <el-table-column align="center" prop="pivot.selling_price" :label="$t('warehouse.label.selling_price')">
                  <template slot-scope="scope">{{ scope.row.pivot.selling_price | currency }}</template>
                </el-table-column>
                <el-table-column align="center" prop="purchase_price" :label="$t('warehouse.label.purchase')">
                  <template slot-scope="scope">{{ scope.row.purchase_price | currency }}</template>
                </el-table-column>
                <el-table-column align="center" prop="pivot.quantity" :label="$t('warehouse.label.quantity')">
                  <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.pivot.quantity <= scope.row.reorder_level" class="item" effect="dark"
                                :content="$t('warehouse.message.level')">
                      <router-link v-can.disable="'item.stock'" :to="{name: 'stock-item', params: {item_id: scope.row.id}}"><i class="el-icon-warning text-warning"></i></router-link>
                    </el-tooltip>
                    {{ scope.row.pivot.quantity | currency }}
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="reorder_level"
                                 :label="$t('warehouse.label.reorder_level')"></el-table-column>
                <el-table-column align="center" label="Actions">
                  <template slot="header" slot-scope="scope">
                    <el-input ref="searchField" v-model="search" size="mini" placeholder="Type to search"/>
                  </template>
                  <template slot-scope="scope">
                    <el-dropdown v-can.event="'warehouse.transfer'" @click="openTransferDialog(scope.row.id)"
                                 split-button type="primary" size="mini" trigger="click">
                      <i class="mdi mdi-swap-horizontal el-icon--left"></i>{{ $t('warehouse.button.transfer') }}
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="openSellingPriceDialog(scope.row.id)" icon="el-icon-edit">
                          {{ $t('warehouse.button.update_price') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modify-selling-price :display="displaySellingPriceDialog"  @close="closeSellingPriceDialog" :item="item"
                          :warehouse="warehouse" @updated="updateList"></modify-selling-price>
    <transfer :item="item" :display="displayTransferDialog" @close="closeTransferDialog" @created="updateList"></transfer>
  </div>
</template>

<script>
import Transfer from './Modal/Transfer'
import ModifySellingPrice from './Modal/ModifySellingPrice'
export default {
  name: 'Manage',
  components: { ModifySellingPrice, Transfer },
  data () {
    return {
      warehouse: {},
      displayTransferDialog: false,
      displaySellingPriceDialog: false,
      item: null,
      search: ''
    }
  },
  computed: {
    getItems () {
      return this.warehouse.items
        ? this.warehouse.items.filter(item => !this.search || item.name.toLowerCase().includes(this.search.toLowerCase()) || item.code.toLowerCase().includes(this.search.toLowerCase()))
        : []
    }
  },
  methods: {
    closeTransferDialog () {
      this.displayTransferDialog = false
      this.item = null
    },
    openTransferDialog (item) {
      this.item = item
      this.displayTransferDialog = true
    },
    closeSellingPriceDialog () {
      this.displaySellingPriceDialog = false
      this.item = null
    },
    openSellingPriceDialog (item) {
      this.item = item
      this.displaySellingPriceDialog = true
    },
    sellingPriceUpdated (data) {
      this.warehouse.items.find(i => i.id === data.id).pivot.selling_price = data.price
    },
    async fetchItems (wh) {
      return new Promise((resolve, reject) => {
        this.$warehouseService.fetchWarehouseItems(wh)
          .then(data => {
            this.warehouse = data
            resolve(data)
          })
      })
    },
    updateList () {
      return new Promise((resolve, reject) => {
        this.fetchItems(this.$route.params.id)
          .then(resolve)
          .catch(reject)
      })
    }
  },
  async mounted () {
    await this.updateList()
    this.$emit('parent-title', this.$t('warehouse.title.manage', [this.warehouse.name]))
    this.$nextTick(this.$refs.searchField.focus)
  },
  created () {
    // this.$parent.$on('new-warehouse', this.newWarehouse)
  }
}
</script>

<style scoped>

</style>
