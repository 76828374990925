<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('item.title.labels') }}
                        </h5>
                    </div>
                    <div>
                      <div class="card-body">
                        <el-dialog :title="activeModule === 'category' ? $t('item.button.add_by_category') : $t('item.button.add_by_supplier')" :visible.sync="showDialog" width="50%" append-to-body
                                   :close-on-click-modal="false" :close-on-press-escape="false">
                          <el-row class="mr-1" :gutter="17">
                            <el-input v-model="dialogSearch" :placeholder="$t('item.label.search')" suffix-icon="mdi mdi-magnify"></el-input>
                          </el-row>
                          <el-row>
                            <el-card style="margin-top: 3px; overflow-y: auto; max-height: 250px;">
                              <div v-for="(obj, index) in data.filter(data => !dialogSearch || data.name.toLowerCase().includes(dialogSearch.toLowerCase()))" :key="index" class="text item">
                                <el-row :gutter="5">
                                  <el-checkbox v-model="obj.selected">{{ obj.name }}</el-checkbox>
                                </el-row>
                              </div>
                            </el-card>
                          </el-row>
                          <template #footer>
                            <el-button @click="showDialog = false">{{ $t(`button.close`) }}</el-button>
                            <el-button :disabled="data.length === 0" type="primary" :loading="loading" @click.prevent="saveAdded">
                              {{ $t(`button.add`) }}
                            </el-button>
                          </template>
                        </el-dialog>
                        <el-form ref="labForm" :model="form" :rules="rules" label-width="200px" size="large" status-icon>
                          <el-row :gutter="20" justify="center">
                            <el-col :span="10">
                              <el-form-item :label="$t('item.label.label_type')" prop="label_type">
                                <el-select v-model="form.label_type" :placeholder="$t('item.label.label_type')" collapse-tags clearable>
                                  <el-option
                                    v-for="item in types"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                  </el-option>
                                </el-select>
                              </el-form-item>
                            </el-col>
                            <el-col :span="10">
                              <el-form-item :label="$t('item.label.department')" prop="department">
                                <el-select v-model="form.department" :placeholder="$t('item.label.department')" collapse-tags clearable>
                                  <el-option
                                    v-for="item in departments"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                  </el-option>
                                </el-select>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="20" justify="center">
                            <el-col>
                              <el-form-item :label="$t('item.label.barcode_source_field')" prop="barcode_source_field">
                                <el-select v-model="form.barcode_source_field" :placeholder="$t('item.label.barcode_source_field')" collapse-tags clearable>
                                  <el-option
                                    v-for="item in barsources"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                  </el-option>
                                </el-select>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-form>
                        <el-row class="mt-4" :gutter="10" justify="center">
                          <el-col :span="2"></el-col>
                          <el-col :span="12">
                            <el-checkbox v-model="form.print_name">{{ $t('item.label.print_name') }}</el-checkbox>
                            <el-checkbox v-model="form.print_price">{{ $t('item.label.print_price') }}</el-checkbox>
                          </el-col>
                        </el-row>
                        <el-divider class="mt-10 mb-10 mr-5"></el-divider>
                      </div>
                      <div class="card-body">
                        <el-row :gutter="20" justify="center">
                          <el-col :span="12">
                            <h3>{{ $t('item.name') }}</h3>
                          </el-col>
                          <el-col :span="6" align="right">
                            <el-button @click="showAddByDialog('category')" type="text" size="medium">{{ $t('item.button.add_by_category') }}</el-button>
                          </el-col>
                          <el-col :span="6" align="right">
                            <el-button @click="showAddByDialog('supplier')" type="text" size="medium">{{ $t('item.button.add_by_supplier') }}</el-button>
                          </el-col>
                        </el-row>
                        <el-row>
                          <div class="search-container" style="display: flex; justify-content: flex-end;">
                            <el-col :span="5">
                              <el-autocomplete
                                v-model="search"
                                :fetch-suggestions="querySearch"
                                :placeholder="$t('item.label.search_item')"
                                suffix-icon="mdi mdi-magnify"
                                @select="addItem">
                                <template slot-scope="{ item }">
                                  <div class="value">{{ item.name }}</div>
                                </template>
                              </el-autocomplete>
                            </el-col>
                          </div>
                          <div class="table-responsive">
                            <data-tables :data="addedItems"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                              <el-table-column show-overflow-tooltip prop="name" :label="$t('item.label.item')" fixed></el-table-column>
                              <el-table-column show-overflow-tooltip prop="quantity" :label="$t('item.label.quantity')">
                                <template slot-scope="scope">
                                  <el-input-number v-model="scope.row.quantity" :min="1"></el-input-number>
                                </template>
                              </el-table-column>
                              <el-table-column label="" min-width="100px" align="center">
                                <template slot-scope="scope">
                                  <el-button icon="el-icon-delete" @click="removeItem(scope.$index, scope.row.id)"
                                             type="danger" size="small"></el-button>
                                </template>
                              </el-table-column>
                            </data-tables>
                          </div>
                        </el-row>
                        <el-row>
                          <div id="labels" ref="labels">
                            <div v-for="label in labels" :key="label.id" class="bordered-div" style="width: 350px;">
                              <div v-if="form.print_price" class="ml-2" style="font-size: 13px;">{{ label.price | currency(' XAF') }}</div>
                              <div v-if="form.print_price" class="ml-2" style="font-size: 13px;">{{ label.name }}</div>
                              <barcode :value="label.text" :options="{ displayValue: form.print_name, width: 3, height: 25, fontSize: 12 }"></barcode>
                            </div>
                          </div>
                          <div id="labels-sheet" ref="labelsSheet">
                            <img :src="labelsSheet" />
                          </div>
                          <div v-if="submittable && addedItems.length > 0" id="label">
                            <h5>$10.00</h5>
                            <barcode value="text" :options="{ displayValue: true }" ref="barcode"></barcode>
                          </div>
                          <el-col align="right">
                            <el-button :disabled="!submittable || addedItems.length === 0" :loading="loading" type="primary" @click="submitForm">
                              {{ $t('item.button.create_labels') }}
                            </el-button>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode'
export default {
  name: 'Labels',
  components: { Barcode: VueBarcode },
  data () {
    return {
      form: {
        label_type: '',
        department: '',
        barcode_source_field: '',
        print_name: true,
        print_price: true
      },
      types: [
        { label: 'Dymo - 3/4" x 2-1/2"', value: '19mm x 64mm' },
        { label: 'Dymo - 1" x 2-1/8"', value: '25mm x 54mm' },
        { label: 'Dymo - 1-1/8" x 3-1/2"', value: '28mm x 89mm' },
        { label: 'Zebra - 1-1/4" x 1"', value: '32mm x 25mm' },
        { label: 'Zebra - 2" x 1"', value: '51mm x 25mm' }
      ],
      barsources: [
        { label: this.$t('item.label.sku'), value: 'code' },
        { label: this.$t('item.label.barcode'), value: 'barcode' },
        { label: this.$t('item.label.none'), value: 'none' }
      ],
      rules: {
        label_type: [
          { required: true, message: 'Please select the label type', trigger: ['change', 'blur'] }
        ],
        department: [
          { required: true, message: 'Please select the department', trigger: ['change', 'blur'] }
        ],
        barcode_source_field: [
          { required: true, message: 'Please select the barcode source field', trigger: ['change', 'blur'] }
        ]
      },
      loading: false,
      showDialog: false,
      activeModule: '',
      labelsSheet: '',
      labels: [],
      addedItems: [],
      selectedCategories: [],
      selectedSuppliers: [],
      items: [],
      departments: [],
      data: [],
      categories: [],
      suppliers: [],
      search: '',
      dialogSearch: ''
    }
  },
  computed: {
    submittable () {
      return !Object.keys(this.form).filter(k => (typeof this.form[k] !== 'boolean') && !this.form[k]).length
    }
  },
  methods: {
    async querySearch (queryString, cb) {
      if (queryString && queryString.length >= 3) {
        const payload = { name: queryString.toLowerCase() }
        await this.$itemService.getSearchedItems(payload)
          .then(data => {
            this.items = data
            cb(data)
          })
      }
    },
    showAddByDialog (module) {
      for (const item of this.categories) {
        item.selected = false
      }
      for (const supplier of this.suppliers) {
        supplier.selected = false
      }
      this.activeModule = module
      this.data = module === 'category' ? this.categories : module === 'supplier' ? this.suppliers : ''
      this.showDialog = true
    },
    addItem (item) {
      item.quantity = item.optimal_order ? item.optimal_order : item.reorder_level ? item.reorder_level : 10
      this.addedItems.push(item)
      this.addedItems = [...new Set(this.addedItems.map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
    },
    async updateAddedItems () {
      let items = []
      if (this.selectedCategories.length > 0) {
        for (const category of this.selectedCategories) {
          await this.$itemService.getCategoryItems(category.id)
            .then(data => {
              items = [...data]
              items = [...new Set(items.map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
            })
        }
      }
      if (this.selectedSuppliers.length > 0) {
        for (const supplier of this.selectedSuppliers) {
          await this.$itemService.getSupplierItems(supplier.id)
            .then(data => {
              items = [...data]
              items = [...new Set(items.map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
            })
        }
      }
      for (const item of items) {
        item.quantity = item.optimal_order ? item.optimal_order : item.reorder_level ? item.reorder_level : 10
      }
      this.addedItems = items
    },
    saveAdded () {
      if (this.activeModule === 'category') {
        this.selectedCategories = this.categories.filter(category => category.selected === true)
      }
      if (this.activeModule === 'supplier') {
        this.selectedSuppliers = this.suppliers.filter(supplier => supplier.selected === true)
      }
      this.updateAddedItems()
    },
    removeItem (index, id) {
      this.addedItems.splice(index, 1)
    },
    submitForm () {
      this.$refs.labForm.validate(async (valid) => {
        let department
        let price
        if (valid) {
          this.labels = []
          for (const item of this.addedItems) {
            price = item.unit_price
            const text = this.form.barcode_source_field === 'code' ? item.code : this.form.barcode_source_field === 'barcode' ? item.barcode : ''
            department = this.departments.find(department => department.id === this.form.department)
            if (text !== null && text !== '') {
              this.labels.push({
                name: item.name,
                price: price,
                text: text,
                department: department
              })
            }
          }
          if (this.labels.length > 0) {
            try {
              const labelsDivBlock = this.$refs.labels
              await this.$html2canvas(labelsDivBlock).then(async canvas => {
                this.labelsSheet = canvas.toDataURL('base64')
              })
              await this.$htmlToPaper('labels-sheet')
            } catch (e) {
              console.log(e)
              for (const item of this.addedItems) {
                price = item.unit_price
                const barcodeText = this.form.barcode_source_field === 'code' ? item.code : this.form.barcode_source_field === 'barcode' ? item.barcode : ''
                department = this.departments.find(department => department.id === this.form.department)
                console.log(price, barcodeText, department, this.form.label_type)
                // ensure barcode is not '' or null, use print-name and print-price
                if (barcodeText !== null && barcodeText !== '') {
                  await this.$htmlToPaper('label')
                  // const canvas = this.$refs.barcode.$el.querySelector('canvas')
                  // const imgData = canvas.toDataURL('image/png')
                  // console.log(imgData)
                }
              }
            }
          }
        }
      })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('item.title.labels'))
    this.$categoryService.getCategories()
      .then(data => {
        for (const category of data) {
          category.selected = false
        }
        this.categories = data
      })
    this.$supplierService.getSuppliers()
      .then(data => {
        for (const supplier of data) {
          supplier.selected = false
        }
        this.suppliers = data
      })
    this.$departmentService.getDepartments()
      .then(data => {
        this.departments = data
      })
  }
}
</script>

<style scoped>
.bordered-div {
  border: 1px solid black; /* Adjust border style, width, and color as needed */
  padding: 5px; /* Add padding if desired */
}
</style>
