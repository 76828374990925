export default class SupplyService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getSupplies () {
    return new Promise((resolve, reject) => {
      this.$http.get('supplies')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createSupply (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('supplies/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
