<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('category.button.all') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="categories"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                                <el-table-column label="S/N" type="index" ></el-table-column>
                                <el-table-column show-overflow-tooltip prop="name" :label="$t('category.label.name')" ></el-table-column>
                              <el-table-column align="center" :label="$t('category.label.tracked')">
                                <template slot-scope="scope">
                                  <el-switch @change="updateTrackedStatus(scope.row.id)" :value="scope.row.tracked"></el-switch>
                                </template>
                              </el-table-column>
                                <el-table-column prop="department" :label="$t('category.label.department')">
                                  <!--@todo Add departments which can access a category (dialog pop-up with checkable deps)-->
                                </el-table-column>
                                <el-table-column prop="user.name" :label="$t('label.creator')" ></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" :label="$t('label.created_at')"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions" min-width="100px">
                                    <template slot-scope="scope">
                                        <el-button v-can.disable="'category.update'" @click="showEditDialog(scope.row.id)" split-button type="primary" size="small">
                                            <i class="el-icon-edit"></i> {{ $t('button.edit') }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <edit-category :display="displayEditDialog" @close="closeEditDialog" @updated="categoryUpdate"
                       @created="newCategory" :category="category"></edit-category>
    </div>
</template>

<script>
import CreateEditCategory from './Modal/CreateEditCategory'
export default {
  name: 'Index',
  components: {
    'edit-category': CreateEditCategory
  },
  data () {
    return {
      categories: [],
      category: {},
      displayEditDialog: false
    }
  },
  methods: {
    showEditDialog (id) {
      this.category = this.categories.find(d => d.id === id)
      this.displayEditDialog = true
    },
    closeEditDialog () {
      this.department = {}
      this.displayEditDialog = false
    },
    newCategory (data) {
      this.categories.splice(0, 0, data)
    },
    categoryUpdate (data) {
      this.categories.splice(this.categories.indexOf(this.categories.find(d => d.id === data.id)), 1, data)
    },
    showUsersDialog (id) {},
    updateTrackedStatus (id) {
      this.$categoryService.updateTrackedStatus(id)
        .then(data => {
          this.categories.find(c => c.id === id).tracked = data.tracked
        })
    },
    beforeArchive (id) {
      this.askQuestion(this.$t('category.confirm.archive'), () => {
        this.$categoryService.archiveCategory(id)
          .then(() => {
            this.notify(this.$t('category.message.archived'))
            this.categories.splice(this.categories.indexOf(this.categories.find(d => d.id === id)), 1)
          })
      })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('category.title.category'))
    this.$categoryService.getCategories()
      .then(data => {
        this.categories = data
      })
  },
  created () {
    this.$parent.$on('new-category', this.newCategory)
  }
}
</script>

<style scoped>

</style>
