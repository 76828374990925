export default class PermissionService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getPermissions () {
    return new Promise((resolve, reject) => {
      this.$http.get('permissions')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  listPermissions () {
    return new Promise((resolve, reject) => {
      this.$http.get('permissions/list')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
