<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12 text-center">
                <el-collapse v-model="expanded">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <h5 class="ml-2">Query <i class="mdi mdi-database-search"></i></h5>
                        </template>
                        <el-form label-position="top" inline :model="query" class="demo-form-inline">
                            <el-form-item label="Date">
                                <el-date-picker v-model="query.date" format="dd MMM, yyyy"
                                                value-format="yyyy-MM-dd"
                                                placeholder="Select a date"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="Warehouses">
                                <el-select collapse-tags multiple v-model="query.warehouses"
                                           placeholder="Filter warehouse">
                                    <el-option v-for="(wh, ind) in warehouses" :key="ind" :label="wh.name"
                                               :value="wh.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Contains Items">
                                <el-select filterable collapse-tags multiple v-model="query.items" placeholder="Filter items">
                                    <el-option v-for="(item, ind) in items" :key="ind" :label="item.name"
                                               :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Users">
                                <el-select collapse-tags multiple v-model="query.users" placeholder="Filter user">
                                    <el-option v-for="(user, ind) in users" :key="ind" :label="user.name"
                                               :value="user.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="mt-4 pt-3">
                                <el-button :loading="loading" type="primary" @click="executeQuery">
                                    Query <i class="mdi mdi-database-search"></i></el-button>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                    <div class="card-body">
                        <el-row slot="tool" type="flex" justify="space-between">
                            <el-col :span="8">
                                <div class="card-header">
                                    <h5 class="m-b-0">
                                        <i class="mdi mdi-icon-receiving"></i>
                                        {{ $t('warehouse.title.adjust_stock') }}
                                    </h5>
                                </div>
                            </el-col>
                            <el-col :span="10">
                                <el-input size="small" :placeholder="$t('text.search_placeholder')"
                                          v-model="query.search" clearable>
                                            <span slot="append">
                                                <span class="el-icon-search"/>
                                            </span>
                                </el-input>
                            </el-col>
                        </el-row>
                        <el-table :data="filterRecords" center-align style="width: 100%">
                            <el-table-column align="left" prop="reason"
                                             :label="$t('stock.label.reason')" fixed width="220">
                            </el-table-column>
                            <el-table-column prop="date" :label="$t('label.date')" width="100" :formatter="$shortDate"/>
                            <el-table-column prop="summary.total_purchase_price" label="C.P" width="100">
                                <template v-slot:default="scope">
                                    {{ scope.row.summary.total_purchase_price | roundup | currency }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="summary.total_sales" label="Sales" width="100">
                                <template v-slot:default="scope">
                                    {{ scope.row.summary.total_sales | roundup | currency }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="summary.total_profit" label="Profit" width="100">
                                <template v-slot:default="scope">
                                    {{ scope.row.summary.total_profit | roundup | currency }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="warehouse.name" :label="$t('warehouse.label.warehouse') | limit(6)"
                                             width="160" show-overflow-toltip>
                                <template v-slot:label="scope"></template>
                                <template v-slot:default="scope">
                                    {{ scope.row.warehouse ? scope.row.warehouse.name : '-'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="user.name" :label="$t('label.user')"
                                             width="120" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="created_at" :label="$t('label.created')"
                                             :formatter="$shortDateTime" width="120" show-overflow-tooltip/>
                            <el-table-column align="right" prop="actions" label="Actions" min-width="150" fixed="right">
                                <template v-slot:default="scope">
                                    <el-dropdown split-button type="primary"
                                                 @click="showAdjustmentDialog(scope.row.id)">
                                        {{ $t('button.preview') }}
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item>
                                                {{ $t('button.print') }} <span class="el-icon-printer"></span>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <stock-adjustment-items :display="showDialog" @close="closeAdjustmentDialog" :item="activeAdjustment"/>
    </div>
</template>

<script>
import StockAdjustmentItems from './Modal/StockAdjustmentItems.vue'

export default {
  name: 'StockAdjustmentHistory',
  components: { StockAdjustmentItems },
  data () {
    return {
      expanded: ['1'],
      showDialog: false,
      activeAdjustment: null,
      query: {
        warehouses: [],
        items: [],
        users: [],
        date: null,
        search: ''
      },
      history: [],
      users: [],
      items: [],
      warehouses: [],
      loading: false
    }
  },
  computed: {
    filterRecords () {
      return this.history.filter(
        item => !this.query.search || item.reason.toLowerCase().includes(this.query.search.toLowerCase()) ||
          item.date.toLowerCase().includes(this.query.search.toLowerCase()))
    }
  },
  watch: {},
  methods: {
    executeQuery () {
      this.$warehouseService.getStockHistory(this.query).then(data => {
        this.history = data
      })
    },
    async showAdjustmentDialog (id) {
      this.activeAdjustment = this.history.find(adj => adj.id === id)
      this.showDialog = true
    },
    closeAdjustmentDialog () {
      this.activeAdjustment = null
      this.showDialog = false
    }
  },
  mounted () {
    this.executeQuery()
    this.$warehouseService.getWarehouses().then(whs => (this.warehouses = whs))
    this.$userService.getUsers().then(users => (this.users = users))
    this.$itemService.getItems().then(items => (this.items = items))
    this.$emit('parent-title', 'Filter stock adjustment history')
  },
  created () {
    // this.$parent.$on('new-warehouse', this.newWarehouse)
  }
}
</script>

<style>
.el-form-item > .el-form-item__label {
    padding: 0;
    margin: 0;
}
</style>
