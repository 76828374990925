<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('account.withdraw.name') }}
                                </h5>
                            </el-col>
                            <el-col :span="12">
                                <el-button v-can.disable="'withdrawal.create'" size="medium" class="float-right" type="success" icon="el-icon-plus"
                                           @click="showWithdrawalDialog">
                                    {{ $t('account.withdraw.button.create') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="withdrawals"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }">
                                <el-table-column in label="S/N" type="index" sortable fixed></el-table-column>
                                <el-table-column prop="amount" label="Amount" sortable fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ formatPrice (scope.row.amount) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="date" :formatter="$shortDate" label="Date"
                                                 sortable></el-table-column>
                                <el-table-column prop="message" label="Message" sortable></el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions">
                                    <template slot-scope="scope">
                                        <el-button type="info" size="mini" icon="el-icon-arrow-down"
                                                   @click="showDetailsDialog(scope.row.id)">
                                            {{ $t('button.detail') }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-withdrawal :visible="displayWithdrawalDialog" @close="closeWithdrawalDialog"
                           @withdrawal-created="withdrawalCreated"></create-withdrawal>
        <el-dialog title="More Information" :visible.sync="displayInfoDialog">
            <el-collapse v-if="withdrawal.id">
                <el-collapse-item title="Category" name="-1">
                    <div class="currency">{{ withdrawal.subcategory.category.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Subcategory" name="0">
                    <div class="currency">{{ withdrawal.subcategory.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Message" name="1">
                    <div class="currency">{{ withdrawal.message }}</div>
                </el-collapse-item>
                <el-collapse-item title="Creator" name="2">
                    <div class="currency">{{ withdrawal.user.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Created At" name="3">
                    <div class="currency">{{ shortDate(withdrawal.created_at) }}</div>
                </el-collapse-item>
            </el-collapse>
        </el-dialog>
    </div>
</template>

<script>
import CreateWithdrawal from './Modal/CreateWithdrawal'

export default {
  name: 'Withdrawal',
  components: { CreateWithdrawal },
  data () {
    return {
      withdrawals: [],
      displayInfoDialog: false,
      withdrawal: {},
      displayWithdrawalDialog: false
    }
  },
  methods: {
    showDetailsDialog (id) {
      this.withdrawal = this.withdrawals.find(d => d.id === id)
      this.displayInfoDialog = true
    },
    showWithdrawalDialog () {
      this.displayWithdrawalDialog = true
    },
    closeWithdrawalDialog () {
      this.displayWithdrawalDialog = false
    },
    withdrawalCreated (data) {
      this.withdrawals.splice(0, 0, data)
      this.$emit('refresh-balance')
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('account.withdraw.title.withdrawal'))
    this.$accountService.getWithdrawals()
      .then(data => (this.withdrawals = data))
  }
}
</script>

<style scoped>

</style>
