import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth, guest, log } from '../middleware/index'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

/* const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
  }
] */

const routes = [
  /* {
    path: '/',
    redirect: '/login'
  }, */
  {
    path: '/',
    name: 'base',
    component: require('@/components/Onboard/Base').default,
    meta: {
      middleware: [log]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: require('@/components/Auth/Login').default,
    meta: {
      middleware: [guest, log]
    }
  },
  {
    path: '/dashboard',
    component: require('@/components/Layout/Dashboard').default,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: require('@/components/Dashboard/Home').default,
        meta: {
          middleware: [auth, log]
        }
      },
      {
        path: 'settings',
        component: require('@/components/Settings/Settings').default,
        children: [
          {
            path: 'config',
            component: require('@/components/Settings/Config').default,
            children: [
              {
                path: 'config_logo',
                name: 'config_logo',
                component: require('@/components/Settings/Tabs/Logo').default,
                meta: {
                  middleware: [auth, log]
                }
              },
              {
                path: '',
                name: 'config_org',
                component: require('@/components/Settings/Tabs/Organisation').default,
                meta: {
                  middleware: [auth, log]
                }
              },
              {
                path: 'config_reg',
                name: 'config_reg',
                component: require('@/components/Settings/Tabs/Registration').default,
                meta: {
                  middleware: [auth, log]
                }
              },
              {
                path: 'price_lists',
                name: 'price_lists',
                component: require('@/components/Settings/Tabs/PriceLists').default,
                meta: {
                  middleware: [auth, log]
                }
              },
              {
                path: 'default_messages',
                name: 'default_messages',
                component: require('@/components/Settings/Tabs/DefaultMessages').default,
                meta: {
                  middleware: [auth, log]
                }
              },
              {
                path: 'sales_receipts',
                name: 'receipts',
                component: require('@/components/Settings/Tabs/SalesReceipts').default,
                meta: {
                  middleware: [auth, log]
                }
              },
              {
                path: 'sales_print_terminals',
                name: 'print_terminals',
                component: require('@/components/Settings/Tabs/SalesPrintTerminals').default,
                meta: {
                  middleware: [auth, log]
                }
              },
              {
                path: 'pos_settings',
                name: 'pos_settings',
                component: require('@/components/Settings/Tabs/POSDefaults').default,
                meta: {
                  middleware: [auth, log]
                }
              }
            ]
          }
        ]
      },
      {
        path: '/suppliers',
        component: require('@/components/Supplier/Supplier').default,
        children: [
          {
            path: '',
            name: 'suppliers',
            component: require('@/components/Supplier/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create',
            name: 'create-supplier',
            component: require('@/components/Supplier/Create').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'edit/:id',
            name: 'edit-supplier',
            component: require('@/components/Supplier/Edit').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'archive',
            name: 'archive',
            component: require('@/components/Supplier/Archive').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/customers',
        component: require('@/components/Customers/Customers').default,
        children: [
          {
            path: '',
            name: 'customers',
            component: require('@/components/Customers/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create',
            name: 'create-customer',
            component: require('@/components/Customers/Create').default,
            meta: {
              middleware: [auth, log]
            }
          },
          // {
          //   path: 'edit/:id',
          //   name: 'edit-customer',
          //   component: require('@/components/Customers/Edit').default,
          //   meta: {
          //     middleware: [auth, log]
          //   }
          // },
          {
            path: 'archive',
            name: 'archived-customers',
            component: require('@/components/Customers/Archive').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/roles',
        component: require('@/components/Roles/Roles').default,
        children: [
          {
            path: '',
            name: 'roles',
            component: require('@/components/Roles/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create-role',
            name: 'create-role',
            component: require('@/components/Roles/Create').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'permissions',
            name: 'permissions',
            component: require('@/components/Roles/Permissions').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/supplier/history/:id',
        component: require('@/components/Supplier/History').default,
        children: [
          {
            path: '',
            name: 'supplier-history',
            component: require('@/components/Supplier/History/Supplies').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'payments',
            name: 'supplier-payments',
            component: require('@/components/Supplier/History/Payments').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/supplies',
        component: require('@/components/Purchase/Purchase').default,
        children: [
          {
            path: '',
            name: 'supplies',
            component: require('@/components/Purchase/Supplies').default,
            meta: {
              middleware: [auth, log]
            }
          }, {
            path: 'create-supply',
            name: 'add-supply',
            component: require('@/components/Purchase/AddSupply').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/sales',
        component: require('@/components/Sale/Sale').default,
        children: [
          {
            path: '',
            name: 'sales',
            component: require('@/components/Sale/Index').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/account',
        component: require('@/components/Account/Account').default,
        children: [
          {
            path: '',
            name: 'deposit',
            component: require('@/components/Account/Deposit').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'withdrawal',
            name: 'withdrawal',
            component: require('@/components/Account/Withdrawal').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'category',
            name: 'category',
            component: require('@/components/Account/Category').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/account/category/:id',
        component: require('@/components/Account/Category/Category').default,
        children: [
          {
            path: '',
            name: 'subcategories',
            component: require('@/components/Account/Category/Subcategory').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/employees',
        component: require('@/components/Employees/Employees').default,
        children: [
          {
            path: '',
            name: 'employees',
            component: require('@/components/Employees/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create-employee',
            name: 'add-employee',
            component: require('@/components/Employees/Create').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'archived-employee',
            name: 'archived-employee',
            component: require('@/components/Employees/Archive').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'employee-types',
            name: 'employee-types',
            component: require('@/components/Employees/Type').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/users',
        component: require('@/components/Users/Users').default,
        children: [
          {
            path: '',
            name: 'users',
            component: require('@/components/Users/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create-user',
            name: 'add-user',
            component: require('@/components/Users/Create').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/employees/:id',
        component: require('@/components/Employees/Employee/Employee').default,
        children: [
          {
            path: '',
            name: 'debt',
            component: require('@/components/Employees/Employee/Debt').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'archived-debt',
            name: 'archived-debt',
            component: require('@/components/Employees/Employee/ArchivedDebt').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'employee-payments',
            name: 'employee-payments',
            component: require('@/components/Employees/Employee/Payments').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'visualise',
            name: 'employee-visualisation',
            component: require('@/components/Employees/Employee/Visualise').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/debts',
        component: require('@/components/Debts/Debts').default,
        children: [
          {
            path: '',
            name: 'debts',
            component: require('@/components/Debts/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'payments',
            name: 'payments',
            component: require('@/components/Debts/Payments').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'debt-types',
            name: 'debt-types',
            component: require('@/components/Debts/DebtTypes').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/departments',
        component: require('@/components/Departments/Departments').default,
        children: [
          {
            path: '',
            name: 'departments',
            component: require('@/components/Departments/Index').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/payment-methods',
        component: require('@/components/PaymentMethod/PaymentMethod').default,
        children: [
          {
            path: '',
            name: 'payment-methods',
            component: require('@/components/PaymentMethod/Index').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/items',
        component: require('@/components/Items/Items').default,
        children: [
          {
            path: '',
            name: 'items',
            component: require('@/components/Items/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'items-archive',
            name: 'items-archive',
            component: require('@/components/Items/Archive').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'upload-items',
            name: 'upload-items',
            component: require('@/components/Items/Upload').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/sessions',
        component: require('@/components/Session/Session').default,
        children: [
          {
            path: '',
            name: 'sessions',
            component: require('@/components/Session/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: '/create-session',
            name: 'create-sessions',
            component: require('@/components/Session/Create').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/services',
        component: require('@/components/Service/Service').default,
        children: [
          {
            path: '',
            name: 'services',
            component: require('@/components/Service/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: '/create-service',
            name: 'create-service',
            component: require('@/components/Service/Create').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/categories',
        component: require('@/components/Category/Category').default,
        children: [
          {
            path: '',
            name: 'categories',
            component: require('@/components/Category/Index').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/warehouses',
        component: require('@/components/Warehouses/Warehouses').default,
        children: [
          {
            path: '',
            name: 'warehouses',
            component: require('@/components/Warehouses/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'manage/:id',
            name: 'manage-warehouse',
            component: require('@/components/Warehouses/Manage').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'manage/:id/adjust_stock',
            name: 'warehouse-stock-adjustment',
            component: require('@/components/Warehouses/AdjustWarehouseStock').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'manage/:id/stock_adjustment_history',
            name: 'stock-adjustment-history',
            component: require('@/components/Warehouses/StockAdjustmentHistory').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'manage/:id/stock_adjustment_history_items',
            name: 'stock-adjustment-history-items',
            component: require('@/components/Warehouses/StockAdjustmentHistory').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'transfer-history',
            name: 'transfer-history',
            component: require('@/components/Warehouses/History').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/stock-item/:item_id',
        component: require('@/components/Stock/Stock').default,
        children: [
          {
            path: '',
            name: 'stock-item',
            component: require('@/components/Stock/StockItem').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/salaries',
        component: require('@/components/Salary/Salary').default,
        children: [
          {
            path: '',
            name: 'salaries',
            component: require('@/components/Salary/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'pay',
            name: 'pay-salary',
            component: require('@/components/Salary/Pay').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/discounts',
        component: require('@/components/Discounts/Discount').default,
        children: [
          {
            path: '',
            name: 'discounts',
            component: require('@/components/Discounts/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create',
            name: 'create-discount',
            component: require('@/components/Discounts/Modal/CreateEditDiscount').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/modifiers',
        component: require('@/components/Modifiers/Modifier').default,
        children: [
          {
            path: '',
            name: 'modifiers',
            component: require('@/components/Modifiers/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create',
            name: 'create-modifier',
            component: require('@/components/Modifiers/Modal/Create').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/variants',
        component: require('@/components/Variants/Variant').default,
        children: [
          {
            path: '',
            name: 'variants',
            component: require('@/components/Variants/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create',
            name: 'create-variant',
            component: require('@/components/Variants/Modal/Create').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/taxes',
        component: require('@/components/Taxes/Tax').default,
        children: [
          {
            path: '',
            name: 'taxes',
            component: require('@/components/Taxes/Index').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'create',
            name: 'create-tax',
            component: require('@/components/Taxes/Modal/CreateEditTax').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/labels',
        component: require('@/components/Items/Items.vue').default,
        children: [
          {
            path: '',
            name: 'labels',
            component: require('@/components/Items/Labels.vue').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      },
      {
        path: '/reports',
        component: require('@/components/Report/Report').default,
        name: 'reports',
        children: [
          {
            path: 'supply-report',
            name: 'supply-report',
            component: require('@/components/Report/SuppliesReport').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'supplier-payment-report',
            name: 'supplier-payment-report',
            component: require('@/components/Report/SupplierPaymentReport').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'payroll-report',
            name: 'payroll-report',
            component: require('@/components/Report/PayrollReport').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'employee-debt-report',
            name: 'employee-debt-report',
            component: require('@/components/Report/EmployeeDebtReport').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'deposit-report',
            name: 'deposit-report',
            component: require('@/components/Report/DepositReport').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'withdrawal-report',
            name: 'withdrawal-report',
            component: require('@/components/Report/WithdrawalReport').default,
            meta: {
              middleware: [auth, log]
            }
          },
          {
            path: 'inventory-report',
            name: 'inventory-report',
            component: require('@/components/Report/InventoryReport').default,
            meta: {
              middleware: [auth, log]
            }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
