<template>
    <el-dialog ref="debtTypeForm"
               :visible="visible" @closed="closed"
               lock-scroll :close-on-click-modal="false" @open="open" :show-close="false" fullscreen>
        <template slot="title">
            <h3 class="text-center">Add New Deposit</h3>
            <hr>
        </template>
        <el-row>
            <el-col :span="12" :offset="6">
                <el-form :model="form" size="medium" :rules="rules" ref="depositForm" label-position="left"
                         label-width="180px">
                    <el-form-item label="Amount" prop="amount">
                        <el-input v-model.number="form.amount"></el-input>
                    </el-form-item>
                    <el-form-item label="Category" prop="category_id">
                        <el-select placeholder="Click to choose" class="f-w" v-model="form.category_id">
                            <el-option v-for="(category, index) in categories" :key="index" :label="category.name"
                                       :value="category.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Subcategory" prop="subcategory_id">
                        <el-select placeholder="Click to choose" class="f-w" v-model="form.subcategory_id">
                            <el-option v-for="(sub, index) in subcategories" :key="index" :label="sub.name"
                                       :value="sub.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Date" prop="date">
                        <el-date-picker v-model="form.date" class="f-w" format="ddd MMM, yyyy"
                                        value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="Message" prop="message">
                        <el-input type="textarea" v-model="form.message"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close')">Cancel</el-button>
                <el-button :loading="loading" type="success" size="medium" @click="submitForm">
                    {{ $t('account.deposit.button.create') }}
                </el-button>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
export default {
  name: 'CreateDeposit',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    deposit: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        subcategory_id: null,
        date: null,
        category_id: null,
        amount: 0,
        message: ''
      },
      categories: [],
      loading: false,
      rules: {
        date: [
          { required: true, message: 'Please, select the date of deposit', trigger: 'blur' }
        ],
        amount: [
          { required: true, min: 1, type: 'number', message: 'Please, enter a valid amount', trigger: 'blur' }
        ],
        message: [
          {
            required: true,
            message: 'Please, add a message for record keeping-sake',
            trigger: 'blur'
          }
        ],
        subcategory_id: [
          {
            required: true,
            message: 'Please, select an account subcategory',
            trigger: 'blur'
          }
        ],
        category_id: [
          {
            required: true,
            message: 'Please, select an account category',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    'form.category_id' (value) {
      this.form.subcategory_id = null
    }
  },
  computed: {
    subcategories () {
      if (this.form.category_id) {
        return this.categories.find(c => c.id === this.form.category_id).subcategories
      }
      return []
    }
  },
  methods: {
    submitForm () {
      this.$refs.depositForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t('account.deposit.confirm.create', {
            amount: this.formatPrice(this.form.amount),
            subcat: this.subcategories.find(s => s.id === this.form.subcategory_id).name
          }), () => {
            this.loading = true
            this.$accountService.createDeposit(this.form)
              .then(data => {
                this.$emit('deposit-created', data)
                this.$emit('close')
              })
              .catch(error => {
                if (error.response) {
                  if (error.response.status === 403) {
                    this.displayMessage(error.response.data.message, () => {
                    }, 'error')
                  } else {
                    this.displayMessage(error.message, () => {
                    }, 'error')
                  }
                }
              })
              .then(() => {
                this.loading = false
              })
          })
        }
      })
    },
    open () {
      this.$accountService.getCategories()
        .then(data => (this.categories = data))
    },
    closed () {
      this.$refs.depositForm.resetFields()
      // Object.keys(this.form).forEach((k) => (this.form[k] = null))
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
