<template>
    <div class="container pull-up">
        <el-card :body-style="{padding: '0 15px'}" style="margin-bottom: 15px">
            <el-row :gutter="24" style="margin-bottom: 10px; padding-top: 10px">
                <el-col :span="12">
                    <el-form size="small" label-position="left" label-width="120px" :model="form" :rules="rules"
                             ref="adjustmentForm">
                        <el-form-item label="Adjustment Date" prop="date">
                            <el-date-picker v-model="form.date" type="date" autofocus
                                            :placeholder="$t('text.pick_day')"
                                            value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="Reason" prop="reason">
                            <el-input v-model="form.reason"></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </el-card>
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-body">
                        <el-row type="flex" justify="space-between">
                            <el-col :span="5">
                                <b-button :disabled="loading" @click="fetchItems" variant="outline-primary">
                                    Load All <span v-if="loading" class="el-icon-loading"></span>
                                    <span v-else class="el-icon-refresh"></span>
                                </b-button>
                                <b-button v-if="warehouse.items" @click="clearData"
                                          variant="outline-info">Clear All
                                </b-button>
                            </el-col>
                            <el-col :span="10">
                                <el-input size="small" :placeholder="$t('text.search_placeholder')"
                                          v-model="filter.search" clearable>
                                            <span slot="append">
                                                <span class="el-icon-search"/>
                                            </span>
                                </el-input>
                            </el-col>
                        </el-row>
                        <el-table :data="filteredData" center-align max-height="500" :layout="buildLayout" class="adjust-wh-tb">

                            <el-table-column prop="code" label="Code" width="100"
                                             show-overflow-tooltip fixed></el-table-column>
                            <el-table-column prop="name" :label="$t('label.name')" fixed></el-table-column>
                            <el-table-column prop="category.name"
                                             :label="$t('item.label.category')"></el-table-column>
                            <el-table-column align="right" show-overflow-tooltip prop="cost_price"
                                             :label="$t('warehouse.label.selling_price')">
                                <template v-slot:default="scope">
                                    {{ scope.row.pivot.selling_price | currency(' XAF') }}
                                </template>
                            </el-table-column>
                            <el-table-column align="right" show-overflow-tooltip prop="cost_price"
                                             :label="$t('warehouse.label.purchase')">
                                <template v-slot:default="scope">
                                    {{ scope.row.purchase_price | currency(' XAF') }}
                                </template>
                            </el-table-column>
                            <el-table-column align="center" prop="pivot.quantity"
                                             label="Qty on Hand">
                                <template v-slot:default="scope">
                                    {{ scope.row.pivot.quantity | currency }}
                                </template>
                            </el-table-column>
                            <el-table-column align="center" prop="new_qty"
                                             label="New Qty on Hand">
                                <template v-slot="scope">
                                    <el-input-number size="mini" v-model="scope.row.new_qty" :min="0">
                                    </el-input-number>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-row class="justify-end mt-3">
                            <el-col align="end">
                                <el-button type="primary" @click="saveChanges" :disabled="!contentModified">Save
                                    Changes
                                </el-button>
                                <el-button type="primary" plain @click="$router.go(-1)">Cancel</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'AdjustWarehouseStock',
  components: {},
  data () {
    return {
      form: {
        reason: '',
        date: null
      },
      loading: false,
      warehouse: {},
      filter: {
        search: ''
      },
      rules: {
        reason: [{ required: true, message: 'Please, provide a reason for this change', trigger: 'blur' }],
        date: [{ required: true, message: 'Please pick a date', trigger: 'blur' }]
      }
    }
  },
  computed: {
    filteredData () {
      return this.warehouse.items
        ? this.warehouse.items.filter(
          item => !this.filter.search || item.name.toLowerCase().includes(this.filter.search.toLowerCase()) ||
            item.code.toLowerCase().includes(this.filter.search.toLowerCase()))
        : []
    },
    buildLayout () {
      return this.warehouse.items ? 'tool, pagination, table, pagination' : 'tool, table'
    },
    contentModified () {
      return this.warehouse.items && this.warehouse.items.some(item => item.pivot.quantity !== item.new_qty)
    }
  },
  methods: {
    fetchItems () {
      this.anyChanges(() => {
        this.loading = true
        const whId = this.$route.params.id
        this.$warehouseService.fetchWarehouseItems(whId).then(data => {
          data.items = data.items.map(item => {
            // add new_qty field
            item.new_qty = item.pivot.quantity
            return item
          })
          this.warehouse = data
        }).then(() => (this.loading = false))
      })
    },
    async anyChanges (cb = () => {
    }) {
      if (this.contentModified) {
        this.askQuestion('Some changes were made to this page. Are you to discard them?', cb, 'Unsaved changes!')
      } else {
        cb()
      }
    },
    clearData () {
      this.anyChanges(() => (this.warehouse = {}))
    },
    saveChanges () {
      this.$refs.adjustmentForm.validate((valid) => {
        if (valid) {
          const items = this.warehouse.items.filter(item => item.pivot.quantity !== item.new_qty)
            .map((item, index, items) => {
              if (!index) {
                return `<ol><li>${item.name}: <code>${item.pivot.quantity} &longrightarrow; ${item.new_qty}</code></li>`
              } else if (index === items.length - 1) {
                return `<li>${item.name}: <code>${item.pivot.quantity} &longrightarrow; ${item.new_qty}</code></li></ol>`
              } else {
                return `<li>${item.name}: <code>${item.pivot.quantity} &longrightarrow; ${item.new_qty}</code></li>`
              }
            })
          this.askQuestion(items.join(''), () => {
            this.$warehouseService.adjustWarehouseStock(this.warehouse.id, { ...this.form, items: this.warehouse.items })
              .then(data => {
                console.log(data)
                this.displayMessage('Changes saved successfully')
              })
          }, 'Save stock adjustment levels?')
        } else {
        }
      })
    }
  },
  async mounted () {
    this.warehouse = await this.$warehouseService.getWarehouse(this.$route.params.id)
    this.$emit('parent-title', `Adjust stock in ${this.warehouse.name}`)
  },
  created () {
  }
}
</script>

<style>
.adjust-wh-tb .el-table.el-table--fit {
    max-height: 45vh;
    overflow-y: auto;
}
</style>
