<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('discount.button.all') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables :data="discounts"
                           :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                <el-table-column label="S/N" type="index" ></el-table-column>
                <el-table-column show-overflow-tooltip prop="name" :label="$t('discount.table.title.name')" ></el-table-column>
                <el-table-column show-overflow-tooltip prop="value" :label="$t('discount.table.title.value')" ></el-table-column>
                <el-table-column show-overflow-tooltip prop="type" :label="$t('discount.table.title.type')" ></el-table-column>
                <el-table-column prop="user.name" :label="$t('label.creator')" ></el-table-column>
                <el-table-column prop="created_at" :formatter="$shortDate" :label="$t('label.created_at')"
                                 sortable></el-table-column>
                <el-table-column label="Actions" min-width="100px">
                  <template slot-scope="scope">
                    <el-button v-can.disable="'discount.update'" @click="showEditDialog(scope.row.id)" split-button type="primary" size="small">
                      <i class="el-icon-edit"></i> {{ $t('button.edit') }}
                    </el-button>
                    <el-button v-can.disable="'discount.delete'" @click="discountDelete(scope.$index, scope.row.id)" :loading="loading" split-button type="danger" size="small">
                      <i class="el-icon-delete"></i> {{ $t('button.delete') }}
                    </el-button>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-discount :display="displayEditDialog" @close="closeEditDialog" @updated="discountUpdate"
                   @created="newDiscount" :discount="discount"></edit-discount>
  </div>
</template>

<script>
import CreateEditDiscount from './Modal/CreateEditDiscount'
export default {
  name: 'Index',
  components: {
    'edit-discount': CreateEditDiscount
  },
  data () {
    return {
      loading: false,
      discounts: [],
      discount: {},
      displayEditDialog: false
    }
  },
  methods: {
    showEditDialog (id) {
      this.discount = this.discounts.find(d => d.id === id)
      this.displayEditDialog = true
    },
    closeEditDialog () {
      this.displayEditDialog = false
    },
    newDiscount (data) {
      this.discounts.splice(0, 0, data)
    },
    discountUpdate (data) {
      this.discounts.splice(this.discounts.indexOf(this.discounts.find(d => d.id === data.id)), 1, data)
    },
    discountDelete (index, id) {
      this.askQuestion(this.$t('discount.confirm.delete'), async () => {
        this.loading = true
        await this.$discountService.deleteDiscount(id)
          .then(data => {
            this.discounts.splice(index, 1)
            this.loading = false
            this.notify(this.$t('discount.message.deleted'), this.$t('status.success'))
          })
      })
    },
    showUsersDialog (id) {}
  },
  async mounted () {
    this.$emit('parent-title', this.$t('discount.title.discount'))
    await this.$discountService.getDiscounts()
      .then(data => {
        this.discounts = data
      })
  },
  created () {
    this.$parent.$on('new-discount', this.newDiscount)
  }
}
</script>

<style scoped>

</style>
