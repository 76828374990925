<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-room-service"></i> {{ $t('service.button.all') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables :data="services"
                           :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                <el-table-column label="S/N" type="index" sortable></el-table-column>
                <el-table-column show-overflow-tooltip prop="name" :label="$t('service.label.name')"></el-table-column>
                <el-table-column align="center" :label="$t('service.label.department')">
                  <template slot-scope="scope">
                    <el-select :value="scope.row.department_id" size="mini"
                               @change="updateDepartment($event, scope.row.id)">
                      <el-option v-can.disable="'service.department'" v-for="dpt in departments" :key="dpt.id"
                                 :label="dpt.name" :value="dpt.id"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column align="center" :label="$t('label.status')">
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.active ? 'success' : 'danger'" effect="dark" size="small">
                      {{ scope.row.active ? $t('service.label.active') : $t('service.label.inactive') }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" :formatter="$shortDate"
                                 :label="$t('label.created_at')"></el-table-column>
                <el-table-column :label="$t('label.actions')">
                  <template slot-scope="scope">
                    <el-button v-can.disable="'service.archive'" type="danger" size="small"
                               @click="removeService(scope.row.id)">
                      {{ $t(`service.button.remove`) }}
                    </el-button>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {},
  data () {
    return {
      services: [],
      departments: []
    }
  },
  methods: {
    updateDepartment (deptId, serviceId) {
      this.$serviceService.updateDepartment(serviceId, deptId)
        .then(data => {
          this.services.find(s => s.id === serviceId).department_id = deptId
          this.notify(this.$t('service.message.department_updated'))
        })
    },
    removeService (id) {
      this.askQuestion(this.$t('service.confirm.archive'), () => {
        this.$serviceService.removeService(id)
          .then((data) => {
            this.notify(this.$t('service.message.archived'))
            this.services.splice(this.services.indexOf(this.services.find(d => d.id === id)), 1)
          })
      })
    },
    updateServices (data) {
      this.services.splice(0, 0, data)
    }
  },
  async mounted () {
    this.$emit('parent-title', this.$t('service.name'))
    this.$parent.$on('new-service', this.updateServices)
    await this.$departmentService.getDepartments()
      .then(data => (this.departments = data))
    await this.$serviceService.getServices()
      .then(data => (this.services = data))
  },
  created () {
  }
}
</script>

<style scoped>

</style>
