<template>
    <el-dialog ref="debtTypeForm"
               :visible="visible" @closed="closed"
               lock-scroll :close-on-click-modal="false" @open="open" :show-close="false">
        <template slot="title">
            {{ dynamicTitle }}
            <hr>
        </template>
        <el-form :model="form" :rules="rules" ref="debtTypeForm" label-position="left" label-width="180px">
            <el-form-item label="Category Name" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="Can Affect Income (CAI)" prop="affects_account">
                <el-switch :active-value="1" :inactive-value="0" v-model="form.affects_account"
                active-text="Debts would affects main account" inactive-text="Debt would NOT affect main account"></el-switch>
            </el-form-item>
            <el-form-item label="Payable" prop="can_pay">
                <el-switch :active-value="1" :inactive-value="0" v-model="form.can_pay"
                active-text="Debts can be deducted from salary" inactive-text="Debts CANNOT be paid"></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="$emit('close')">Cancel</el-button>
            <el-popconfirm title="Are you sure about this?" :cancel-button-text="$t('button.cancel')"
                           :confirm-button-text="$t('button.confirm')" @confirm="submitForm">
                <el-button :loading="loading" slot="reference" type="primary" v-html="$t(`debt.button.${debtType ? 'update' : 'create'}_type`)"></el-button>
            </el-popconfirm>
        </div>
    </el-dialog>
</template>

<script>
export default {
  name: 'EditType',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    debtType: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      form: {
        name: this.debtType.name || '',
        can_pay: this.debtType.can_pay || null,
        affects_account: this.debtType.affects_account || null
      },
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Please, provide a name', trigger: 'blur' }
        ],
        affects_account: [
          {
            required: true,
            message: 'Please, specify where this debt type will be reduced from main account',
            trigger: 'blur'
          }
        ],
        can_pay: [
          {
            required: true,
            message: 'Please, specify whether debts of this category are payable by the debtor',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    dynamicTitle () {
      return this.debtType && this.debtType.id ? `EDIT CATEGORY: ${this.debtType.name.toUpperCase()}` : 'NEW DEBT CATEGORY'
    }
  },
  methods: {
    submitForm () {
      this.$refs.debtTypeForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.debtType) {
            this.$debtService.updateDebtType(this.debtType.id, this.form)
              .then(data => {
                this.afterSubmitForm(data.data)
              })
              .catch(this.afterSubmitFormError)
          } else {
            this.$debtService.createDebtType(this.form)
              .then(data => {
                this.afterSubmitForm(data.data)
              })
              .catch(this.afterSubmitFormError)
          }
        }
      })
    },
    afterSubmitForm (data) {
      this.notify(this.$t('debt.message.create_type'))
      this.$emit('close')
      this.$emit('new-type', data, !!this.debtType)
      this.loading = false
    },
    afterSubmitFormError (error) {
      this.notify(error.response.data.message, this.$t('status.error'), 'error')
    },
    closed () {
      // this.$refs.debtTypeForm.resetFields()
      Object.keys(this.form).forEach((k, i) => {
        this.form[k] = null
      })
      this.loading = false
    },
    open () {
      if (this.debtType) {
        Object.keys(this.form).forEach((k, i) => {
          this.form[k] = this.debtType[k]
        })
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
