<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12 text-center">
          <el-collapse v-model="expanded">
            <el-collapse-item name="1">
              <template slot="title">
                <h5 class="ml-2">Query <i class="mdi mdi-database-search"></i></h5>
              </template>
              <el-form label-position="top" inline :model="query" class="demo-form-inline">
                <el-form-item label="Start Date">
                  <el-date-picker v-model="query.start_date" format="dd MMM, yyyy"
                                  value-format="yyyy-MM-dd"
                                  placeholder="Select a start date"></el-date-picker>
                </el-form-item>
                <el-form-item label="End Date">
                  <el-date-picker v-model="query.end_date" format="dd MMM, yyyy"
                                  value-format="yyyy-MM-dd"
                                  placeholder="Select an end date"></el-date-picker>
                </el-form-item>
                <el-form-item label="From Warehouse">
                  <el-select collapse-tags multiple v-model="query.from_warehouse" placeholder="Filter warehouse">
                    <el-option v-for="(wh, ind) in warehouses" :key="ind" :label="wh.name"
                               :value="wh.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="To Warehouse">
                  <el-select collapse-tags multiple v-model="query.to_warehouse" placeholder="Filter warehouse">
                    <el-option v-for="(wh, ind) in toWarehouses" :key="ind" :label="wh.name"
                               :value="wh.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Item">
                  <el-select collapse-tags multiple v-model="query.item" placeholder="Filter item">
                    <el-option v-for="(item, ind) in items" :key="ind" :label="item.name"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="User">
                  <el-select collapse-tags multiple v-model="query.user" placeholder="Filter user">
                    <el-option v-for="(user, ind) in users" :key="ind" :label="user.name"
                               :value="user.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="mt-4 pt-3">
                  <el-checkbox v-model="query.dl">Download</el-checkbox>
                </el-form-item>
                <el-form-item class="mt-4 pt-3">
                  <el-button :loading="loading" type="primary" @click="executeQuery">
                    Query <i class="mdi mdi-database-search"></i></el-button>
                </el-form-item>
              </el-form>
            </el-collapse-item>
          </el-collapse>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30" style="border-top-left-radius: 0; border-top-right-radius: 0;">
          <div class="card-header">
            <h5 class="m-b-0">
              <i class="mdi mdi-store-24-hour"></i> {{ $t('warehouse.title.history') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover ">
                <thead>
                <tr>
                  <th>S.N</th>
                  <th>{{ $t('warehouse.label.transfer.from') }}</th>
                  <th>{{ $t('warehouse.label.transfer.item') }}</th>
                  <th>{{ $t('warehouse.label.transfer.quantity') }}</th>
                  <th>{{ $t('warehouse.label.transfer.to') }}</th>
                  <th>{{ $t('warehouse.label.transfer.comment') }}</th>
                  <th>{{ $t('warehouse.label.transfer.user') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(transfer, date) in transfers">
                  <tr>
                    <td colspan="6" align="center">{{ date | date(false) }}</td>
                  </tr>
                  <tr v-for="(row, index) in transfer">
                    <td>{{ index + 1 }}</td>
                    <td>{{ row.from ? row.from.name : $t('warehouse.label.transfer.supplier') }}</td>
                    <td>{{ row.item.name }}</td>
                    <td>{{ row.quantity }}</td>
                    <td>{{ row.to.name }}</td>
                    <td>{{ row.comment }}</td>
                    <td>{{ row.user.name }}</td>
                  </tr>
                </template>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransferHistory',
  data () {
    return {
      expanded: ['1'],
      transfers: {},
      query: {
        start_date: null,
        end_date: null,
        user: [],
        from_warehouse: [],
        to_warehouse: [],
        item: []
      },
      users: [],
      warehouses: [],
      items: [],
      loading: false
    }
  },
  computed: {
    toWarehouses () {
      return this.warehouses.filter(wh => {
        return !this.query.from_warehouse.find(whId => whId === wh.id)
      })
    }
  },
  watch: {
    'query.from_warehouse' (value) {
      if (value.length && this.query.to_warehouse.find(whId => whId === value[value.length - 1])) {
        this.query.to_warehouse = []
      }
    }
  },
  methods: {
    executeQuery () {
      this.$transferService.fetchTransfers(this.query)
        .then(data => {
          this.transfers = data
        })
    }
  },
  mounted () {
    // this.executeQuery()
    this.$warehouseService.getWarehouses()
      .then(whs => (this.warehouses = whs))
    this.$itemService.getItems()
      .then(items => (this.items = items))
    this.$userService.getUsers()
      .then(users => (this.users = users))
    this.$emit('parent-title', this.$t('warehouse.title.history'))
  },
  created () {
    // this.$parent.$on('new-warehouse', this.newWarehouse)
  }
}
</script>

<style>
 .el-form-item > .el-form-item__label {
   padding: 0;
   margin: 0;
 }
</style>
