const state = {
  BASE_URL: null
}

const mutations = {
  SET_BASE_URL: (state, payload) => {
    state.BASE_URL = payload
  }
}

const actions = {
  async logout ({ commit }) {
    await commit('LOGGED_OUT')
    return new Promise((resolve, reject) => {
      resolve()
    })
  },
  setBaseURL ({ commit }, payload) {
    commit('SET_BASE_URL', payload)
  }
}

const getters = {
  getBaseURL (state) {
    return state.BASE_URL
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
