<template>
  <div>
    <div class="bg-dark m-b-30">
      <div class="container">
        <div class="row p-b-60 p-t-60">

          <div class="col-md-6 text-white p-b-30">
            <div class="media">
              <div class="avatar avatar mr-3">
                <div class="avatar-title bg-success rounded-circle mdi mdi-currency-usd  "></div>
              </div>
              <div class="media-body">
                <h3 class="p-t-10">{{ pageTitle }} </h3>
                <p class="opacity-75">
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-center m-b-30 ml-auto">
            <div class="text-md-right">
              <router-link v-can="'employee.read'" :to="{name: 'employees'}"
                           class="el-button el-button--primary el-button--medium"><i
                  class="mdi mdi-format-list-bulleted"></i> {{ $t('employee.button.all') }}
              </router-link>
              <router-link v-can="'employee.create'" :to="{name: 'add-employee'}"
                           class="el-button el-button--success el-button--medium"><i
                  class="mdi mdi-plus"></i> {{ $t('employee.button.create') }}
              </router-link>
              <router-link v-can="'employee.archives'" :to="{name: 'archived-employee'}"
                           class="el-button el-button--warning el-button--medium"><i
                  class="mdi mdi-archive"></i> {{ $t('employee.button.archive') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="slide-fade">
      <router-view @parent-title="updateTitle"/>
    </transition>
  </div>
</template>

<script>
import ImportUserAsEmployeeDialog from './Modals/ImportUserAsEmployeeDialog'

export default {
  name: 'Employee',
  components: { ImportUserAsEmployeeDialog },
  data () {
    return {
      pageTitle: ''
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    }
  }
}
</script>

<style scoped>

</style>
