<template>
  <div>
    <div class="bg-dark m-b-30">
      <div class="container">
        <div class="row p-b-60 p-t-60">
          <div class="col-md-6 text-white p-b-30">
            <div class="media">
              <div class="avatar avatar mr-3">
                <div class="avatar-title bg-success rounded-circle mdi mdi-percent"></div>
              </div>
              <div class="media-body">
                <h3 class="p-t-10">{{ pageTitle }}</h3>
                <p class="opacity-75"></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-center m-b-30 ml-auto">
            <div class="text-md-right">
              <router-link v-can="'discount.read'" is="el-button" :to="{name: 'discounts'}" icon="el-icon-s-order" type="primary">
                {{ $t('discount.button.all') }}
              </router-link>
              <el-button v-can="'discount.create'" @click="showCreateDialog" icon="el-icon-plus"  type="success">
                {{ $t('discount.button.create') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-discount @close="closeCreateDialog" :display="displayCreateDialog" @created="discountCreated"></create-discount>
    <transition name="slide-fade">
      <router-view @parent-title="updateTitle"/>
    </transition>
  </div>
</template>

<script>
import CreateDiscount from './Modal/CreateEditDiscount'
export default {
  name: 'Discount',
  components: { CreateDiscount },
  data () {
    return {
      pageTitle: '',
      displayCreateDialog: false
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    showCreateDialog () {
      this.displayCreateDialog = true
    },
    closeCreateDialog () {
      this.displayCreateDialog = false
    },
    discountCreated (data) {
      this.$emit('new-discount', data)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
