const state = {
  logged_in: false,
  user: {},
  token: null,
  expires_in: null,
  token_type: '',
  permissions: [],
  roles: [],
  status: '',
  intended: '',
  lang: '',
  license: {}
}

const mutations = {
  LOGGED_IN: (state, payload) => {
    state.token = payload.token
    state.user = payload.user
    state.expires_in = payload.expires_in
    state.token_type = payload.token_type
    state.logged_in = true
    state.lang = payload.user.lang || 'en'
  },
  LOGGED_OUT: (state) => {
    return new Promise((resolve) => {
      state.token = ''
      state.user = {}
      state.expires_in = null
      state.token_type = null
      state.logged_in = false
      state.status = ''
      resolve()
    })
  },
  AUTH_REQUEST: (state) => {
    state.status = 'loading'
  },
  SET_LOCALE: (state, payload) => {
    state.lang = payload
  },
  AUTH_SUCCESS: (state, token) => {
    state.status = 'success'
  },
  AUTH_ERROR: (state) => {
    state.status = 'error'
  },
  INTENDED_URL: (state, url) => {
    state.intended = url
  },
  SET_PERMISSIONS: (state, data) => {
    state.roles = data.map(r => r.slug)
    state.permissions = data.map(r => r.permissions.map(p => p.slug)).reduce((a, b) => a.concat(b), [])
  },
  LICENSE: (state, license) => {
    state.license = license
  }
}

const actions = {
  async loggedIn ({ commit, dispatch, state }, payload) {
    commit('LOGGED_IN', payload)
    await dispatch('fetchPermissions')
  },
  fetchPermissions ({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$authService.getUserPermissions()
        .then(data => {
          commit('SET_PERMISSIONS', data)
          resolve()
        })
        .catch(reject)
    })
  },
  fetchLicense ({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$authService.verifyLicense()
        .then(async data => {
          resolve(await commit('LICENSE', data))
        })
        .catch(reject)
    })
  },
  async clearLicense ({ commit }) {
    return new Promise((resolve, reject) => (resolve(commit('LICENSE', {}))))
  },
  async logout ({ commit }) {
    return new Promise((resolve, reject) => {
      resolve(commit('LOGGED_OUT'))
    })
  },
  setIntended ({ commit }, payload) {
    commit('INTENDED_URL', payload)
  },
  changeLocale ({ commit }, payload) {
    commit('SET_LOCALE', payload)
  },
  authRequest ({ commit }) {
    commit('AUTH_REQUEST')
  },
  authError ({ commit }) {
    commit('AUTH_ERROR')
  },
  authSuccess ({ commit }) {
    commit('AUTH_SUCCESS')
  },
  setLicense ({ commit }, data) {
    commit('LICENSE', data)
  }
}

const getters = {
  getToken (state) {
    return state.token
  },
  getUser (state) {
    return state.user
  },
  getPermissions (state) {
    return state.permissions
  },
  getRoles (state) {
    return state.roles
  },
  getLocale (state) {
    return state.lang
  },
  loggedIn (state) {
    return state.logged_in
  },
  isAdmin (state) {
    return state.user.is_admin
  },
  getLicense (state) {
    return state.license
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
