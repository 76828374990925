<template>
  <div>
    <div class="bg-dark m-b-30">
      <div class="container">
        <div class="row p-b-60 p-t-60">
          <div class="col-md-6 text-white p-b-30">
            <div class="media">
              <div class="avatar avatar mr-3">
                <div class="avatar-title bg-success rounded-circle mdi mdi-car-tire-alert"></div>
              </div>
              <div class="media-body">
                <h3 class="p-t-10">{{ pageTitle }}</h3>
                <p class="opacity-75">{{ pageSubtitle }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-center m-b-30 ml-auto">
            <div class="text-md-right">
              <el-button class="el-button el-button--primary" @click.prevent="$router.back()">
                <span class="el-icon-arrow-left"></span> {{ $t('button.back') }}
              </el-button>
              <el-button v-can.disable="'stock.adjust'" class="el-button el-button--success"
                         @click.prevent="$emit('display-stock-adjustment-dialog')">
                <span class="el-icon-edit-outline"></span> {{ $t('stock.button.adjust_stock') }}
              </el-button>
              <router-link v-can.disable="'warehouse.read'" class="el-button el-button--info" :to="{name: 'warehouses'}">
                <span class="el-icon-s-order"></span> {{ $t('warehouse.button.all') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <router-view @parent-title="updateTitle"
                   @parent-subtitle="updateSubtitle"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Stock',
  data () {
    return {
      pageTitle: '',
      pageSubtitle: ''
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    updateSubtitle (subtitle) {
      this.pageSubtitle = subtitle
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
