<template>
    <el-dialog
            :title="$t('supplier.modal.items.title')"
            :visible="visible"
            width="55%" @close="$emit('close', false)"
            center>
        <div>
            <data-tables :data="items" class="text-center">
                <el-table-column in label="S/N" type="index"></el-table-column>
                <el-table-column prop="item.name" label="Item name"></el-table-column>
                <el-table-column label="Unit price">
                    <template slot-scope="scope">
                        {{ formatPrice(scope.row.unit_price) }}
                    </template>
                </el-table-column>
                <el-table-column prop="quantity" label="Qty"></el-table-column>
                <el-table-column prop="total_cost" label="Total Cost">
                    <template slot-scope="scope">
                        {{ formatPrice(scope.row.total_cost) }}
                    </template>
                </el-table-column>
                <el-table-column label="Current price">
                    <template slot-scope="scope">
                        {{ formatPrice(scope.row.item.unit_price) }}
                    </template>
                </el-table-column>
            </data-tables>
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="$emit('close', false)">Close</el-button>
            </span>
    </el-dialog>
</template>

<script>
export default {
  name: 'SuppliesItemsModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
