<template>
  <div>
    <div class="row m-all-5 mt-3 mb-3">
      <div class="col-lg-5">
        <el-card class="box-card full-height">
          <div class="row">
            <div class="col-lg-2">
              <i class="mdi mdi-television float-left text-primary" style="font-size: 45px"></i>
            </div>
            <div class="col">
              <div class="row">
                <span class="float-left text-primary" style="font-size: 26px"> {{ license.client }} </span>
              </div>
              <div class="row">
                <span class="float-left text-black-50" style="font-size: 12px"> SALES DASHBOARD </span>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col">
        <el-card :body-style="{ padding: '0px 20px' }">
            <el-form label-position="top" inline>
              <el-row>
                  <el-col :span="8" class="mb-2">
                      <el-form-item>
                          <span slot="label" class="text-primary font-weight-bold ml-2">YEAR</span>
                          <el-select
                                  v-model="year"
                                  multiple
                                  collapse-tags
                                  placeholder="Select Year">
                              <el-option
                                      v-for="item in selectOptions.year"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value">
                              </el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="8" class="mb-2">
                      <el-form-item>
                          <span slot="label" class="text-primary font-weight-bold ml-2">MONTH</span>
                          <el-select
                                  v-model="month"
                                  multiple
                                  collapse-tags
                                  placeholder="Select Month">
                              <el-option
                                      v-for="item in selectOptions.month"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value">
                              </el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="8" class="mb-2">
                      <el-form-item>
                          <span slot="label" class="text-primary font-weight-bold ml-2">PAYMENT METHOD</span>
                          <el-select
                                  v-model="payment_method"
                                  multiple
                                  collapse-tags
                                  placeholder="Select Payment Method">
                              <el-option
                                      v-for="item in selectOptions.payment_method"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value">
                              </el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
              </el-row>
            </el-form>
        </el-card>
      </div>
    </div>

    <div class="row m-all-5">
      <div class="col">
        <el-card class="box-card full-height">
          <div slot="default">
            <div class="row">
              <div class="col ml-3">
                <div class="row">
                  <span class="text-black-50"> TOTAL SALES </span>
                </div>
                <div class="row">
                  <span class="text-primary" style="font-size: 35px">$12,763</span>
                </div>
              </div>
              <div class="col">
                <i class="mdi mdi-cash-multiple float-right text-primary" style="font-size: 50px"></i>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col">
        <el-card class="box-card full-height">
          <div slot="default">
            <div class="row">
              <div class="col ml-3">
                <div class="row">
                  <span class="text-black-50"> TOTAL PROFIT </span>
                </div>
                <div class="row">
                  <span class="text-primary" style="font-size: 35px">$2,531</span>
                </div>
              </div>
              <div class="col">
                <i class="mdi mdi-finance float-right text-primary" style="font-size: 50px"></i>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col">
        <el-card class="box-card full-height">
          <div slot="default">
            <div class="row">
              <div class="col ml-3">
                <div class="row">
                  <span class="text-black-50"> PROFIT % </span>
                </div>
                <div class="row">
                  <span class="text-primary" style="font-size: 35px">25%</span>
                </div>
              </div>
              <div class="col">
                <i class="mdi mdi-percent float-right text-primary" style="font-size: 50px"></i>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <div class="row m-all-5 mt-3">
      <div class="col-lg-5">
        <el-card class="box-card full-height">
          <div slot="header" class="clearfix">
            <i class="mdi mdi-calendar float-left text-primary"></i>
            <span class="m-l-5 text-primary"> MONTHLY </span>
          </div>
          <div>
            <bar-chart :chart-data="barChartData1"/>
          </div>
        </el-card>
      </div>
      <div class="col-lg-4">
        <el-card class="box-card full-height">
          <div slot="header" class="clearfix">
            <i class="mdi mdi-food float-left text-primary"></i>
            <span class="m-l-5 text-primary"> PRODUCT </span>
          </div>
          <div>
            <bar-chart :chart-data="barChartData2.data" :chart-options="barChartData2.options"/>
          </div>
        </el-card>
      </div>
      <div class="col-lg-3">
        <el-card class="box-card full-height">
          <div slot="header" class="clearfix">
            <i class="mdi mdi-point-of-sale float-left text-primary"></i>
            <span class="m-l-5 text-primary"> SALES TYPE </span>
          </div>
          <div class="mt-5">
            <pie-chart :chart-data="pieChartData1"/>
          </div>
        </el-card>
      </div>
    </div>

    <div class="row m-all-5 mt-3">
      <div class="col-lg-9">
        <el-card class="box-card full-height">
          <div slot="header" class="clearfix">
            <i class="mdi mdi-calendar-week float-left text-primary"></i>
            <span class="m-l-5 text-primary"> DAILY </span>
          </div>
          <div>
            <line-chart :chart-data="lineChartData"/>
          </div>
        </el-card>
      </div>
      <div class="col-lg-3">
        <el-card class="box-card full-height">
          <div slot="header" class="clearfix">
            <i class="mdi mdi-cash float-left text-primary"></i>
            <span class="m-l-5 text-primary"> PAYMENT MODE </span>
          </div>
          <div class="mt-5">
            <pie-chart :chart-data="pieChartData2"/>
          </div>
        </el-card>
      </div>
    </div>

    <div class="container py-3 ml-1">
        <div class="row">
            <div class="col-lg-6  m-b-30 ">
                <div class="card m-b-30 full-height">
                    <img :src="`${publicPath}assets/img/patterns/header.png`" class="rounded-top" width="100%" alt="header">
                    <div class="card-body  bg-gray-900 rounded-bottom ">
                        <div class="pull-up-sm">
                            <div class="avatar avatar-lg">
                                <div class="avatar-title rounded-circle mdi mdi-heart bg-primary"></div>
                            </div>
                        </div>
                        <h3 class="text-white pt-4 fw-300">
                            <span class="js-greeting">Welcome to SALAMANDA ERP</span>
                        </h3>
                        <p class="opacity-75 text-white">
                            Good Afternoon, {{ $store.getters['Auth/getUser'].name }} ({{ $store.getters['Auth/getUser'].username }}).
                        </p>
                        <div class="btn-group-justified">
                            <router-link v-can="'report.read'" :to="{name: 'reports'}" class="btn m-b-15 ml-2 mr-2 btn-success">
                                <i class="mdi mdi-chart-bell-curve"></i> View Reports
                            </router-link>
                            <router-link v-can="'debt.read'" :to="{name: 'debts'}" href="#!" class="btn m-b-15 ml-2 mr-2 btn-outline-success">
                                <i class="mdi mdi-cash-register"></i> Debts
                            </router-link>
                            <router-link v-can="'employee.read'" :to="{name: 'employees'}" href="#!" class="btn m-b-15 ml-2 mr-2 btn-success">
                                <i class="mdi mdi-account-group"></i> Employees
                            </router-link>
                            <router-link v-can="'supplier.read'" :to="{name: 'suppliers'}" href="#!" class="btn m-b-15 ml-2 mr-2 btn-outline-success">
                                <i class="mdi mdi-account-network"></i> Suppliers
                            </router-link>
                            <router-link v-can="'session.read'" :to="{name: 'sessions'}" href="#!" class="btn m-b-15 ml-2 mr-2 btn-success">
                                <i class="mdi mdi-timer"></i> Sessions
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import BarChart from '../_Partials/Charts/BarChart'
import PieChart from '../_Partials/Charts/PieChart.vue'
import DoughnutChart from '../_Partials/Charts/DoughnutChart'
import LineChart from '../_Partials/Charts/LineChart'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    BarChart, PieChart, DoughnutChart, LineChart
  },
  data () {
    return {
      barChartData1: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            indexAxis: 'x',
            label: 'Month',
            backgroundColor: '#f87979',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          }
        ]
      },
      barChartData2: {
        data: {
          labels: [
            'Product1',
            'Product2',
            'Product3',
            'Product4',
            'Product5',
            'Product6',
            'Product7',
            'Product8',
            'Product9',
            'Product10'
          ],
          datasets: [
            {
              indexAxis: 'y',
              label: 'Product',
              backgroundColor: '#f87979',
              data: [40000, 20000, 12000, 3900, 1000, 4500, 3900, 8000, 40200, 2800, 1200, 1100]
            }
          ]
        },
        options: {
          /* Legende ausblenden */
          legend: {
            display: false
          },
          /* responsive */
          responsive: true,

          /* tooltips - hover labels */
          tooltips: {
            enabled: false
          },

          /* Layout */
          layout: {
            padding: {
              right: 30
            }
          },

          /* Label auf Balken – Werte hinter balken */
          plugins: {
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: '#fff',
              font: {
                weight: 'bold',
                size: 14
              },
              formatter: function (value, context) {
                return value + '%'
              }
            }
          },
          /* Plugins ende */

          /* Animation */
          animation: {
            duration: 2000
          },
          /* Animation Ende */

          /* Achsen Einstellungen */
          scales: {
            /* x Achse
            xAxes: [{
              display: false,
              gridLines: {
                display: false
              },

              ticks: {
                beginAtZero: true,
                max: 90
              }

            }], */
            /* x-axes ende */

            /* Y Achse
            yAxes: [{
              display: true,
              gridLines: {
                display: false
              },

              ticks: {
                fontColor: '#fff',
                fontStyle: 'normal',
                fontSize: 13,
                padding: -170
              },
              categoryPercentage: 1.0,
              barPercentage: 0.85

            }] */
            /* y-axes ende */
          }
          /* Scales Ende */
        }
      },
      pieChartData1: {
        labels: ['Direct Sales', 'Online', 'Wholesaler'],
        datasets: [
          {
            backgroundColor: ['#424770', 'red', 'orange'],
            data: [40, 20, 80]
          }
        ]
      },
      pieChartData2: {
        labels: ['Cash', 'Online'],
        datasets: [
          {
            backgroundColor: ['#424770', 'red'],
            data: [80, 20]
          }
        ]
      },
      doughnutChartData: {
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [40, 20, 80, 10]
          }
        ]
      },
      lineChartData: {
        labels: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ],
        datasets: [
          {
            fill: true,
            label: 'Day',
            backgroundColor: '#f87979',
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },
      selectOptions: {
        year: [{
          value: '2022',
          label: '2022'
        }, {
          value: '2023',
          label: '2023'
        }],
        month: [{
          value: 'January',
          label: 'January'
        }, {
          value: 'February',
          label: 'February'
        }, {
          value: 'March',
          label: 'March'
        }, {
          value: 'April',
          label: 'April'
        }, {
          value: 'May',
          label: 'May'
        }, {
          value: 'June',
          label: 'June'
        }, {
          value: 'July',
          label: 'July'
        }, {
          value: 'August',
          label: 'August'
        }, {
          value: 'September',
          label: 'September'
        }, {
          value: 'October',
          label: 'October'
        }, {
          value: 'November',
          label: 'November'
        }, {
          value: 'December',
          label: 'December'
        }],
        payment_method: [{
          value: 'Mobile Money',
          label: 'Mobile Money'
        }, {
          value: 'Bank Transfer',
          label: 'Bank Transfer'
        }]
      },
      year: [],
      month: [],
      payment_method: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.Auth.user,
      license: (state) => state.Auth.license
    })
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped>
</style>
