<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
             @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center">{{ $t(`tax.title.${edit ? 'update' : 'create'}`, { name: form.name }) }}</h3>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form ref="taxForm" label-position="top" :model="form" :rules="rules" label-width="200px" size="large">
          <el-row Lgutter="24">
            <el-col :span="16">
              <el-form-item :label="$t('tax.form.label.name')" prop="name">
                <el-input v-model="form.name" ref="inputName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('tax.form.label.rate')" prop="rate">
                <el-input-number ref="rateField" v-model="form.rate" controls-position="right"
                                 :min="0" :max="100" @focus="!form.rate ? $refs.rateField.select() : null">
                </el-input-number>
                <!--<el-input v-model="form.rate" suffix-icon="mdi mdi-percent">
                </el-input>-->
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item :label="$t('tax.form.label.type')" prop="type">
            <el-select v-model="form.type" collapse-tags class="full-width">
              <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('tax.form.label.option')" prop="option">
            <el-select v-model="form.option" collapse-tags class="full-width" clearable>
              <el-option v-for="option in options" :key="option.value" :label="option.label" :value="option.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <el-row>
        <el-col :span="12" :offset="6">
          <el-button @click="$emit('close', false)">Cancel</el-button>
          <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm"
                     :icon="`el-icon-${edit ? 'edit' : 'plus'}`">
            {{ $t(`tax.button.${edit ? 'update' : 'add'}`) }}
          </el-button>
        </el-col>
      </el-row>
    </template>

  </el-dialog>
</template>

<script>
export default {
  name: 'createTax',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    tax: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: '',
        rate: '',
        type: '',
        option: null
      },
      types: [
        {
          label: this.$t('tax.form.type.additive'),
          value: 'additive'
        },
        {
          label: this.$t('tax.form.type.inclusive'),
          value: 'inclusive'
        }
      ],
      options: [
        {
          label: this.$t('tax.form.option.new_items'),
          value: 'new'
        },
        {
          label: this.$t('tax.form.option.existing_items'),
          value: 'existing'
        },
        {
          label: this.$t('tax.form.option.both'),
          value: 'new_existing'
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: 'Please specify the tax name',
            trigger: ['change']
          },
          {
            min: 3,
            message: 'Length should be at least 3',
            trigger: ['change']
          }
        ],
        rate: [
          {
            required: true,
            message: 'Please enter the tax rate',
            trigger: ['change']
          }
        ],
        type: [
          {
            required: true,
            message: 'Please select the tax type',
            trigger: ['change']
          }
        ]
      },
      loading: false
    }
  },
  computed: {
    submittable () {
      return !Object.keys(this.form).filter(k => (typeof this.form[k] !== 'boolean') && !this.form[k]).length
    },
    edit () {
      return this.tax && this.tax.id
    }
  },
  methods: {
    opened () {
      if (this.edit) {
        this.form.name = this.tax.name
        this.form.rate = this.tax.rate
        this.form.type = this.tax.type
        this.form.option = this.tax.option
      }
      this.$refs.inputName.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = ''
      })
      this.loading = false
    },
    submitForm () {
      this.$refs.taxForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`tax.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$taxService.updateTax(this.tax.id, this.form)
                .then(data => {
                  this.notify(this.$t('tax.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$taxService.createTax(this.form)
                .then(data => {
                  this.notify(this.$t('tax.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
