<template>
    <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="visible" @open="open"
               @closed="closed" @close="$emit('close')" @opened="opened" :destroy-on-close="true">
        <slot name="title">
            <h3 class="text-center">{{ $t(`employee.type.title.${edit ? 'update' : 'create'}`, {name: type.name})
                }}</h3>
            <hr>
        </slot>
        <el-row>
            <el-col :span="12" :offset="6">
                <el-form :model="form" size="medium" :rules="rules" ref="typeForm">
                    <el-form-item label="Name" label-width="100" prop="name">
                        <el-input ref="input" v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Salary" label-width="100" prop="salary">
                        <el-input v-model.number="form.salary" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Has Dynamic Salary" label-width="100" prop="dynamic_salary">
                        <el-checkbox border v-model="form.dynamic_salary" label="Salary would be calculated?"
                                     class="f-w"></el-checkbox>
                    </el-form-item>
                    <el-form-item label="Description" label-width="100" prop="description">
                        <el-input type="textarea" v-model="form.description" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>

        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close')">Cancel</el-button>
                <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
                    {{ $t(`employee.type.button.${edit ? 'update' : 'create'}`) }}
                </el-button>
            </el-col>
        </el-row>

    </el-dialog>
</template>

<script>
export default {
  name: 'CreateEditEmployeeType',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    type: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: this.type.name || '',
        salary: this.type.salary || null,
        description: this.type.description || '',
        dynamic_salary: this.type.dynamic_salary || false
      },
      rules: {
        name: [
          { required: true, message: 'Please, enter a name', trigger: 'blur' }
        ],
        salary: [
          { required: true, message: 'Please, enter a salary amount', trigger: 'blur' },
          { type: 'number', message: 'Please, enter a valid amount', trigger: 'blur' }
        ],
        description: [
          { required: true, message: 'Please, enter simplistic description', trigger: 'blur' }
        ],
        dynamic_salary: [
          {
            required: true,
            type: 'boolean',
            message: 'Please say whether the salary will be specified manually',
            trigger: 'blur'
          }
        ]
      },
      loading: false
    }
  },
  computed: {
    submittable () {
      return !Object.keys(this.form).filter(k => (!this.form[k] && k !== 'dynamic_salary')).length
    },
    edit () {
      return this.type && this.type.id
    }
  },
  methods: {
    open () {
    },
    opened () {
      if (this.type && this.type.id) {
        Object.keys(this.form).forEach((k) => {
          this.form[k] = k === 'dynamic_salary' ? !!this.type[k] : this.type[k]
        })
      }
      this.$refs.input.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = null
      })
      this.loading = false
    },
    submitForm () {
      this.$refs.typeForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`employee.type.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$employeeService.updateEmployeeType(this.type.id, this.form)
                .then(data => {
                  this.notify(this.$t('employee.type.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              this.$employeeService.createEmployeeType(this.form)
                .then(data => {
                  this.notify(this.$t('employee.type.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
