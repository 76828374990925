<template>
  <div class="container  pull-up">
    <div class="row">
      <div class="col-lg-12">

        <div class="card m-b-30 ">
          <div class="card-header">
            <p class="m-b-0 opacity-50">
              Fields with
              <req/>
              are required
            </p>
            <hr>
          </div>
          <div class="card-body ">
            <el-form size="medium" label-position="left" ref="form" :rules="rules" :model="form"
                     label-width="150px" class="p-r-15">
              <el-form-item :label="$t('customer.label.name')" prop="name">
                <el-input v-model="form.name" placeholder=""></el-input>
              </el-form-item>
              <el-form-item :label="$t('customer.label.address')" prop="address">
                <el-input v-model="form.address" placeholder=""></el-input>
              </el-form-item>
              <el-form-item :label="$t('customer.label.nic')" prop="nic">
                <el-input v-model="form.nic" placeholder=""></el-input>
              </el-form-item>
              <el-form-item :label="$t('customer.label.phone')" prop="phone">
                <el-input v-model="form.phone" placeholder=""></el-input>
              </el-form-item>
              <el-form-item :label="$t('customer.label.email')" prop="email">
                <el-input v-model="form.email" placeholder=""></el-input>
              </el-form-item>
              <el-form-item :label="$t('customer.label.price_list')" prop="price_list">
                <el-select clearable v-model="form.price_list" filterable :placeholder="$t('text.click_to_select')" class="f-w"
                           default-first-option>
                  <el-option v-for="pl in price_lists" :key="pl.id" :label="pl.name" :value="pl.id">
                    <span>{{ pl.name }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="text-center">
                <div class="row">
                  <div class="col"></div>
                  <div class="col"></div>
                  <div class="col">
                    <el-button icon="mdi mdi-content-save" :loading="loading" type="primary" class="btn-block" @click.prevent="create">
                      {{ $t('customer.button.create') }}
                    </el-button>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Create',
  data () {
    return {
      price_lists: [],
      form: {
        name: '',
        nic: '',
        address: '',
        email: '',
        phone: '',
        price_list: null
      },
      rules: {
        name: [
          { required: true, trigger: 'blur' }
        ],
        address: [
          { required: false, trigger: 'blur' }
        ],
        email: [
          { required: false, trigger: 'blur' }
        ],
        nic: [
          { required: false, trigger: 'blur' }
        ],
        phone: [
          { required: false, trigger: 'blur' }
        ],
        price_list: [
          { required: false, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  methods: {
    create () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$customerService.createCustomer(this.form)
            .then(response => {
              this.displayMessage(this.$t('customer.message.created'), () => {
                this.$router.push({ name: 'customers' })
              })
            })
            .catch(error => {
              this.displayMessage(error.message, () => {
              }, 'error', 'Something went wrong')
            })
            .then(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    clearFields () {
      this.$refs.form.resetFields()
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('customer.title.create'))
    this.$configService.getPriceLists()
      .then(data => {
        this.price_lists = data
        this.form.price_list = data[0].id
      })
  }
}
</script>

<style scoped>

</style>
