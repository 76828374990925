<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('debt.name') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="debts.filter(data => !search || data.message.toLowerCase().includes(search.toLowerCase()))"
                                         :pagination-props="{ pageSizes: [20, 30, 40, 100], pageSize: 20 }"
                                         center-align>
                                <el-table-column in label="S/N" type="index" fixed></el-table-column>
                                <el-table-column prop="amount" label="Amount" fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ scope.row.amount | currency('XAF') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="message" label="Message" min-width="180" fixed></el-table-column>
                                <el-table-column prop="type" label="Type">
                                    <template slot-scope="scope">
                                        <span class="text-capitalize">{{ scope.row.type }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="date" :formatter="$shortDate" label="Date"
                                                 sortable></el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable>
                                    <template slot-scope="scope">
                                        {{ scope.row.user.name }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created">
                                    <template slot="header" slot-scope="scope">
                                        <el-input v-model="search" size="mini" autofocus
                                                placeholder="Type to search"/>
                                    </template>
                                </el-table-column>
                                <!--<el-table-column label="Actions" min-width="100px">
                                    <template slot-scope="scope">
                                        <el-button icon="el-icon-arrow-down" type="success" size="mini"
                                                   class="btn btn-sm btn-info"
                                                   @click.prevent="showDeptDialog(scope.row.id)">
                                        </el-button>
                                    </template>
                                </el-table-column>-->
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="More Information" :visible.sync="displayInfoDialog">
            <el-collapse v-if="debt.user">
                <el-collapse-item title="Description" name="1">
                    <div>{{ debt.description }}</div>
                </el-collapse-item>
                <el-collapse-item title="Employee" name="4">
                    <div>{{ debt.employee.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Creator" name="2">
                    <div>{{ debt.user.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Created At" name="3">
                    <div>{{ shortDate(debt.created_at) }}</div>
                </el-collapse-item>
            </el-collapse>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'Payments',
  data () {
    return {
      debts: [],
      displayInfoDialog: false,
      debt: {},
      search: ''
    }
  },
  methods: {
    showDeptDialog (id) {
      this.debt = this.debts.find(d => d.id === id)
      this.displayInfoDialog = true
    }
  },
  mounted () {
    setTimeout(() => {
      this.$emit('parent-title', this.$t('debt.title.payments'))
    }, 1000)
    this.$debtService.getDebtPayments()
      .then(data => {
        this.debts = data.data
      })
  },
  created () {
  }
}
</script>

<style scoped>
    .fain-text {
        color: #8d97a5;
    }

    .active-text {
        color: #00cc99;
    }
</style>
