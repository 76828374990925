<template>
  <el-dialog top="3vh" :visible.sync="visible" width="80%"
             :close-on-click-modal="true"
             :close-on-press-escape="true" @closed="closedHandler" center>
    <h3 slot="title">Assign Role to <i class="text-primary">{{ user.name }}</i></h3>
    <el-row :gutter="24">
      <el-col></el-col>
      <el-col :span="20" :offset="2">
        <el-transfer
            filterable
            :titles="['Unassigned', 'User Roles']"
            v-model="assigned"
            :data="unassigned">
        </el-transfer>
      </el-col>
      <el-col></el-col>
    </el-row>

    <div slot="footer" class="dialog-footer">
      <el-button type="default" @click="visible = false">Cancel</el-button>
      <el-button :loading="loading" type="primary" @click="saveChanges">Save changes</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'user-assign-role-modal',
  props: ['user'],
  data () {
    return {
      visible: true,
      users: [],
      unassigned: [],
      assigned: [],
      loading: false
    }
  },
  methods: {
    closedHandler () {
      this.$emit('close')
    },
    getRoles () {
      this.$roleService.getRoles()
        .then(res => {
          this.roles = res
          this.unassigned = res.map(r => ({ label: r.name, key: r.id, initials: r.name }))
        })
    },
    getUserRoles () {
      this.$roleService.getUserRole(this.user.id)
        .then(res => {
          this.assigned = res.map(r => r.id)
        })
    },
    saveChanges () {
      this.loading = true
      this.$roleService.assignUserRole(this.user.id, { roles: this.assigned.filter(i => !!i) })
        .then(res => {
          this.$notify.success(this.$t('user.message.assign_role').toString())
          this.closedHandler()
          this.loading = false
          this.visible = false
        })
        .catch(this.globalErrHandle)
    }
  },
  mounted () {
    this.getUserRoles()
    this.getRoles()
  }
}
</script>
<style scoped>
</style>
