<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30 ">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            Pay-in employee salary form
                        </h5>
                        <p class="m-b-0 opacity-50">
                            Fields marked with
                            <req/>
                            are required
                        </p>
                        <hr>
                    </div>
                    <div class="card-body ">
                        <el-form :model="form" size="small" :rules="rules" ref="payForm" label-width="150px"
                                 label-position="left" class="">
                            <el-form-item label="Employee" prop="employee_id">
                                <el-select v-model="form.employee_id" placeholder="Click to select an employee"
                                           class="f-w">
                                    <el-option v-for="(emp, index) in employees" :key="index" :label="emp.name"
                                               :value="emp.id">
                                        <span style="float: left">{{ emp.name }}</span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">{{ emp.type.name }}</span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-row :gutter="12">
                                <el-col :span="12">
                                    <el-form-item label="Total Salary" v-if="!dynamicSalary">
                                        <el-input disabled v-model="meta.total"></el-input>
                                    </el-form-item>
                                    <el-form-item label="" v-else>
                                        <small slot="label">Total Salary (dynamic-salary)</small>
                                        <el-input v-model="form.amount"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Total Debt">
                                        <el-input disabled v-model="meta.debts"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="Payable Salary">
                                        <el-input disabled v-model="meta.payable"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="Pay Debts" prop="pay_debts">
                                <el-switch v-model="form.pay_debts"></el-switch>
                            </el-form-item>
                            <el-form-item label="Payment Date" prop="date">
                                <el-date-picker type="date" placeholder="Pick a date" v-model="form.date"
                                                class="f-w" format="dd MMM, yyyy"
                                                value-format="yyyy-MM-dd"></el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="card-footer">
                        <el-col>
                            <button @click.prevent="submitForm" type="submit" class="btn btn-success btn-lg btn-block"
                                    :disabled="loading">
                                <i v-if="loading" class="el-icon-loading"></i>
                                Pay Salary
                            </button>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Pay',
  data () {
    return {
      form: {
        employee_id: null,
        amount: '',
        date: '',
        pay_debts: true
      },
      meta: {
        total: 0,
        debts: 0,
        payable: 0,
        employee_type: {}
      },
      rules: {
        employee_id: [
          { required: true, message: 'Please, start by selecting an employee', trigger: 'blur' }
        ],
        amount: [
          { required: true, type: 'number', message: 'You cannot pay a zero salary', trigger: 'change' }
        ],
        date: [
          { required: true, message: 'Please select a date by clicking', trigger: 'change' }
        ]
      },
      employees: [],
      debts: [],
      loading: false
    }
  },
  watch: {
    'form.employee_id' (value, prev) {
      this.initialiseObjects(value)
    },
    'form.pay_debts' (value) {
      if (this.form.employee_id) this.initialiseObjects(this.form.employee_id)
    },
    'form.date' (value) {
      this.form.date = require('moment')(value).endOf('month').format('YYYY-MM-DD')
    },
    'form.amount' (value) {
      if (this.form.pay_debts) {
        this.meta.payable = value - this.totalDebt
      }
    }
  },
  computed: {
    payableSalary () {
      if (this.form.pay_debts) {
        return this.totalSalary - this.totalDebt
      }
      return this.totalSalary
    },
    totalSalary () {
      if (this.form.employee_id) {
        return this.employees.find(e => e.id === this.form.employee_id).type.salary
      }
      return 0
    },
    totalDebt () {
      return this.debts.reduce((acc, cur) => acc + cur.balance, 0)
    },
    dynamicSalary () {
      if (this.form.employee_id) {
        return !!this.employees.find(e => e.id === this.form.employee_id).type.dynamic_salary
      }
      return false
    },
    emp () {
      if (this.form.employee_id) {
        return this.employees.find(e => e.id === this.form.employee_id)
      }
      return {}
    }
  },
  methods: {
    initialiseObjects (empId) {
      this.meta.total = this.formatPrice(this.totalSalary)
      this.form.amount = this.totalSalary
      this.$debtService.getEmployeeDebts(empId)
        .then(data => {
          this.debts = data.data.filter(d => d.type.can_pay)
          this.meta.debts = this.formatPrice(this.totalDebt)
          this.meta.payable = this.formatPrice(this.payableSalary)
        })
    },
    submitForm () {
      this.$refs.payForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t('salary.confirm.pay',
            { amount: this.formatPrice(this.form.amount), debt: this.formatPrice(this.totalDebt) }), () => {
            this.loading = true
            this.$salaryService.createSalary(this.form)
              .then(data => {
                this.displayMessage(this.$t('salary.message.created', {
                  amount: this.formatPrice(this.form.amount),
                  name: this.emp.name
                }), () => {
                  this.$refs.payForm.resetFields()
                })
              })
              .catch(async (err) => {
                if (err.response) {
                  if (err.response.status === 403) {
                    await this.displayMessage(err.response.data.message, () => {
                    }, 'error', this.$t('status.error'))
                  }
                }
              })
              .then(() => {
                this.loading = false
              })
          })
        } else {
          return false
        }
      })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('salary.title.pay'))
    this.$employeeService.getEmployees()
      .then(data => (this.employees = data))
  }
}
</script>

<style>
    .el-input.is-disabled .el-input__inner {
        color: #606266;
    }
</style>
