<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">
                    <div class="col-md-6 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-room-service"></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }}</h3>
                                <p class="opacity-75"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link v-can="'service.read'" :to="{name: 'services'}" class="el-button el-button--primary">
                                <i class="el-icon-s-order"></i> {{ $t('service.button.all') }}
                            </router-link>
                            <el-button v-can="'service.create'" @click="showCreateDialog" class="el-button el-button--success">
                                <i class="el-icon-plus"></i> {{ $t('service.button.create') }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <add-service-dialog :display="displayCreateDialog" @close="closeCreateDialog" @created="created"/>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle"/>
        </transition>
    </div>
</template>

<script>
import AddServiceDialog from './Modals/AddServiceDialog'
export default {
  name: 'Services',
  components: { AddServiceDialog },
  data () {
    return {
      pageTitle: '',
      displayCreateDialog: false
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    showCreateDialog () {
      this.displayCreateDialog = true
    },
    closeCreateDialog () {
      this.displayCreateDialog = false
    },
    created (data) {
      this.$emit('new-service', data)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
