<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <el-row>
                            <el-col :span="12">
                                <h5 class="m-b-0">
                                    <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('account.deposit.name') }}
                                </h5>
                            </el-col>
                            <el-col :span="12">
                                <el-button v-can.disable="'deposit.create'" size="medium" class="float-right" type="success" icon="el-icon-plus"
                                @click="showDepositDialog">
                                    {{ $t('account.deposit.button.create') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="deposits"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }">
                                <el-table-column in label="S/N" type="index" sortable fixed></el-table-column>
                                <el-table-column prop="amount" label="Amount" sortable fixed>
                                    <template slot-scope="scope">
                                        <span class="currency">{{ formatPrice (scope.row.amount) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="date" :formatter="$shortDate" label="Date"
                                                 sortable></el-table-column>
                                <el-table-column prop="message" label="Message" sortable></el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions">
                                    <template slot-scope="scope">
                                        <el-button type="info" size="mini" icon="el-icon-arrow-down"
                                                   @click="showDetailsDialog(scope.row.id)">
                                            {{ $t('button.detail') }}
                                        </el-button>
                                        <!--<router-link tag="el-button" size="mini" :to="{name: 'supplier-history', params: {id: scope.row.id}}"
                                                     class="btn btn-info el-button&#45;&#45;mini">
                                            <i class="mdi mdi-history"></i> {{ $t('supplier.button.history') }}
                                        </router-link>-->
                                        <!--<el-dropdown trigger="click">
                                            <el-button type="primary" size="mini">
                                                Actions <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                                            </el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item icon="mdi mdi-pencil" @click.native="edit(scope.row.id)">
                                                    Edit Supplier
                                                </el-dropdown-item>
                                                <el-dropdown-item icon="mdi mdi-archive"  @click.prevent="beforeArchive(scope.$index, scope.row.id)">
                                                    Archive Supplier
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>-->
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-deposit :visible="displayDepositDialog" @close="closeDepositDialog" @deposit-created="depositCreated"></create-deposit>
        <el-dialog title="More Information" :visible.sync="displayInfoDialog">
            <el-collapse v-if="deposit.id">
                <el-collapse-item title="Category" name="-1">
                    <div class="currency">{{ deposit.subcategory.category.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Subcategory" name="0">
                    <div class="currency">{{ deposit.subcategory.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Message" name="1">
                    <div class="currency">{{ deposit.message }}</div>
                </el-collapse-item>
                <el-collapse-item title="Creator" name="2">
                    <div class="currency">{{ deposit.user.name }}</div>
                </el-collapse-item>
                <el-collapse-item title="Created At" name="3">
                    <div class="currency">{{ shortDate(deposit.created_at) }}</div>
                </el-collapse-item>
            </el-collapse>
        </el-dialog>
    </div>
</template>

<script>
import CreateDeposit from './Modal/CreateDeposit'
export default {
  name: 'Deposit',
  components: { CreateDeposit },
  data () {
    return {
      deposits: [],
      displayInfoDialog: false,
      deposit: {},
      displayDepositDialog: false
    }
  },
  methods: {
    showDetailsDialog (id) {
      this.deposit = this.deposits.find(d => d.id === id)
      this.displayInfoDialog = true
    },
    showDepositDialog () {
      this.displayDepositDialog = true
    },
    closeDepositDialog () {
      this.displayDepositDialog = false
    },
    depositCreated (data) {
      this.deposits.splice(0, 0, data)
      this.$emit('refresh-balance')
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('account.deposit.title.deposit'))
    this.$accountService.getDeposits()
      .then(data => (this.deposits = data))
  }
}
</script>

<style scoped>

</style>
