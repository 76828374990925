// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
import { Vue, store, router, App, i18n } from './packages/modules'
import moment from 'moment'
import ACL from './packages/acl'

Vue.config.productionTip = false
Vue.use(ACL, { store })

Vue.component('req', {
  template: '<span style="color: red; font-size: 15px">*</span>'
})
Vue.mixin({
  data: () => ({
    notifyPromise: Promise.resolve(),
    windowLoader: false, // @todo Create global fullscreen loader directive.
    publicPath: process.env.BASE_URL
  }),
  methods: {
    $go (name, cb = () => {}, cbErr = () => {}) {
      if (this.$route.name !== name) {
        this.$router.push({ name }).then(cb).catch(cbErr)
      }
    },
    notify (msg, title = 'Success', type = 'success', dur = 3000) {
      this.notifyPromise.then(this.$nextTick).then(() => {
        this.$notify({
          title: title,
          message: msg,
          duration: dur,
          type
        })
      })
    },
    formatPrice (value, currency = 'XAF') {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').concat(` ${currency}`)
    },
    shortDate (value) {
      return moment(value).format('MMM Do YY')
    },
    screenLoader (...args) {},
    displayMessage (message, cbOK = () => {
    }, type = 'success', title = '', options = {}) {
      this.$confirm(message, (title || this.$t('status.message')), {
        confirmButtonText: this.$t('button.close'),
        showCancelButton: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonClass: `el-button--${type === 'error' ? 'danger' : 'primary'}`,
        dangerouslyUseHTMLString: true,
        type: type,
        ...options
      }).then(cbOK)
    },
    askQuestion (question, cbOK = () => {
    }, title = 'Question', cbCancel = () => {
    }, type = 'question', config = {}) {
      if (question) {
        const h = this.$createElement
        this.$confirm(question, title, {
          confirmButtonText: this.$t('button.continue'),
          cancelButtonText: this.$t('button.cancel'),
          closeOnPressEscape: false,
          closeOnClickModal: false,
          iconClass: type === 'question' ? 'el-icon-question' : null,
          type: type,
          dangerouslyUseHTMLString: true,
          distinguishCancelAndClose: true,
          beforeClose: (action, instance, done) => {
            done()
          },
          ...config
        }).then((res) => {
          cbOK(res)
        }).catch((err) => {
          cbCancel(err)
        })
      }
    },
    setLanguage (lang) {
      this.$i18n.locale = lang
      store.dispatch('Auth/changeLocale', lang)
      this.$authService.changeLanguage(lang)
    },
    async globalErrHandle (err, cb = () => {}, type = 'error', title = this.$t('status.error')) {
      if (err.response) {
        if (err.response.status === 403) {
          await this.displayMessage(err.response.data.message, cb, type, title)
        } else {
          await this.displayMessage(err.response.data || err.response, cb, type, title)
        }
      }
    },
    $currency (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    openUrlInBrowser (url) {
      this.$electron.shell.openExternal(url, {}, (err) => {
        if (err) {
          this.displayMessage(err, () => {
          }, 'error')
          return false
        }
        this.displayMessage(this.$t('report.message.downloaded'))
      })
    }
  },
  filters: {
    currency (value, currency = '') {
      if (!value) {
        value = (value / 1).toFixed(0).replace('.', ',')
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').concat(`${currency}`)
    },
    date (value, short = true, format = 'MMM Do YY') {
      return short ? moment(value, 'YYYY-MM-DD hh:mm:ss').format(format) : moment(value).format('MMMM Do YYYY')
    },
    datetime (value, short = false) {
      return short ? moment(value).format('MMM Do YY, h:mm:ss a') : moment(value).format('MMMM Do YYYY, h:mm:ss a')
    },
    limit (value = '', len = 8, dots = true) {
      return value.toString().substr(0, len) + ((value.length > len) && dots ? '...' : '')
    },
    roundup (value, dp = 2) {
      if (!isNaN(value)) {
        value = parseFloat(value)
      } else if (!value || value.isNaN || isNaN(value)) {
        value = 0.0
      }
      return value.toFixed(dp)
    }
  }
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
