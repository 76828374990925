<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">
                    <div class="col-md-6 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-currency-usd  "></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }} </h3>
                                <p class="opacity-75"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 text-white p-b-30 ml-auto">
                        <div class="rounded text-white bg-white-translucent">
                            <div class="p-all-15">
                                <div class="row">
                                    <div class="col-md-12 my-2 m-md-0 text-center">
                                        <div class="text-overline opacity-75">{{ $t('account.title.amount') }}</div>
                                        <transition name="slide-fades">
                                            <h3 v-if="animation" class="m-0 text-success">
                                                {{ formatPrice(account.amount) }}
                                            </h3>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div class="col-md-5 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link :to="{name: 'supplier-history'}" class="btn btn-success btn-sm"><i
                                    class="mdi mdi-contactless-payment"></i> {{ $t('supplier.button.supplies') }}
                            </router-link>
                            <router-link :to="{name: 'supplier-payments'}" class="btn btn-primary btn-sm"><i
                                    class="mdi mdi-subtitles"></i> {{ $t('supplier.button.payment') }}
                            </router-link>
                            <router-link :to="{name: 'suppliers'}" class="btn btn-warning btn-sm"><i
                                    class="mdi mdi-format-list-bulleted"></i> {{ $t('supplier.button.all') }}
                            </router-link>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle" @refresh-balance="refreshBalance"/>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'Account',
  components: {},
  data () {
    return {
      pageTitle: '',
      account: {},
      animation: false
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    },
    refreshBalance () {
      this.animateAmount()
      this.$accountService.getAccount()
        .then(data => {
          this.account = data
        })
    },
    animateAmount () {
      this.animation = false
      setTimeout(() => {
        this.animation = true
      }, 1000)
    }
  },
  mounted () {
    this.refreshBalance()
  }
}
</script>

<style>
    .slide-fades-enter-active {
        transition: all .3s ease;
    }

    .slide-fades-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fades-enter, .slide-fades-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
