<template>
    <div>
        <div class="bg-dark m-b-30">
            <div class="container">
                <div class="row p-b-60 p-t-60">

                    <div class="col-md-6 text-white p-b-30">
                        <div class="media">
                            <div class="avatar avatar mr-3">
                                <div class="avatar-title bg-success rounded-circle mdi mdi-account-network"></div>
                            </div>
                            <div class="media-body">
                                <h3 class="p-t-10">{{ pageTitle }} </h3>
                                <p class="opacity-75">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center m-b-30 ml-auto">
                        <div class="text-md-right">
                            <router-link v-can="'supply.read'" :to="{name: 'supplies'}" class="btn btn-primary btn-lg"><i
                                    class="mdi mdi-format-list-bulleted"></i> {{ $t('supply.button.supplies') }}
                            </router-link>
                            <router-link v-can="'supply.create'" :to="{name: 'add-supply'}" class="btn btn-success btn-lg"><i
                                    class="mdi mdi-plus"></i> {{ $t('supply.button.create') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <router-view @parent-title="updateTitle"/>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'Purchase',
  data () {
    return {
      pageTitle: '' // this.$t('supply.title.purchase')
    }
  },
  methods: {
    updateTitle (title) {
      this.pageTitle = title
    }
  }
}
</script>

<style scoped>

</style>
