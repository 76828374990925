export default class ModifierService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getModifiers () {
    return new Promise((resolve, reject) => {
      this.$http.get('modifiers')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createModifier (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('modifiers/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateModifier (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`modifiers/update/${id}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteModifier (id) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`modifiers/delete/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getModifierOptions (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`modifiers/options/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createModifierOption (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('modifiers/options/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateModifierOption (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`modifiers/options/update/${id}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteModifierOption (id) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`modifiers/options/delete/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
