<template>
  <div>
    <Sidebar/>
    <main class="admin-main">
      <!--site header begins-->
      <header class="admin-header">

        <a @click.prevent="toggleSidebar" href="#" class="sidebar-toggle" data-toggleclass="sidebar-open"
           data-target="body"> </a>

        <nav class=" mr-auto my-auto">
          <ul class="nav align-items-center">
            <li class="nav-item" style="padding-left: 30px;">
              <el-dropdown trigger="click" placement="bottom" size="mini" @command="changeLang">
                <span class="el-dropdown-link">
                    <i class="mdi mdi-24px mdi-translate el-icon--left"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="lang">
                  <el-dropdown-item command="en" :class="{'active-lang': $i18n.locale === 'en'}">
                    <img :src="`${publicPath}assets/img/lang/en.png`" alt="En"> En
                  </el-dropdown-item>
                  <el-dropdown-item command="fr" :class="{'active-lang': $i18n.locale === 'fr'}">
                    <img :src="`${publicPath}assets/img/lang/fr.png`" alt="Fr"> Fr
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>

          </ul>
        </nav>
        <nav class="ml-auto mr-auto">
          <ul class="nav align-items-center">
            <li class="nav-item"
                :class="{'text-primary': elc === 1, 'text-info': elc === 0, 'text-danger': elc === -1}">
              <strong>Licensed to: {{ license.client }}
                <i v-if="elc === 0">(expires in less than 60 days)</i>
                <i v-else>(until: {{ license.end_date | date(true) }})</i>
              </strong>
            </li>
          </ul>
        </nav>
        <nav class=" ml-auto">
          <ul class="nav align-items-center">
            <!--<li class="nav-item">
                  <a href="#" class="btn btn-dark" data-toggle="modal" data-target="#demoModal">
                  </a>
              </li>-->
            <li class="nav-item dropdown ">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <div class="avatar avatar-sm avatar-online">
                  <span class="avatar-title rounded-circle bg-dark">{{
                      user && user.name ? user.name.charAt(0).toUpperCase() : ''
                    }}</span>
                </div>
              </a>
              <div class="dropdown-menu  dropdown-menu-right">
                <a @click.prevent="meta.passwordDialog = true" class="dropdown-item" href="#">
                  <i class="el-icon el-icon-scissors"></i> {{ $t('link.pw_reset') }}
                </a>
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" :to="{name: 'config_org'}">
                  <i class="el-icon el-icon-set-up"></i> {{ $t('setting.name') }}
                </router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click.prevent="$authService.logout()"
                   style="background: #f56868; color: #FFF;">
                  <i class="el-icon el-icon-switch-button"></i> {{ $t('link.logout') }} ({{ user.username }})
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </header>
      <el-dialog title=""
                 :visible.sync="meta.passwordDialog"
                 width="50%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
                 :destroy-on-close="true"
                 center>
        <div>
          <div slot="title">
            <h4 class="text-center">Update Password</h4>
            <hr>
          </div>
          <el-form size="mini" ref="passForm" label-position="top" label-width="100px" :model="pass"
                   :rules="meta.passRules">
            <el-form-item label="Current Password" prop="old_password">
              <el-input v-model="pass.old_password"></el-input>
            </el-form-item>
            <el-form-item label="New Password" prop="password">
              <el-input v-model="pass.password"></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password" prop="password_confirmation">
              <el-input v-model="pass.password_confirmation"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
                    <el-button @click="meta.passwordDialog = false">Cancel</el-button>
                    <el-button :loading="meta.loading" type="primary" @click="updatePassword" icon="el-icon-lock">Proceed</el-button>
                  </span>
      </el-dialog>
      <!--<el-page-header @back="goBack" content=""></el-page-header>-->
      <transition tag="section" class="admin-content" name="bounce">
        <router-view/>
      </transition>
    </main>
  </div>
</template>

<script>
import Sidebar from '../_Partials/Sidebar'
import { mapState } from 'vuex'

// @todo Refactor password dialog to independent component.
export default {
  name: 'Dashboard',
  components: { Sidebar },
  data () {
    return {
      pass: {
        old_password: '',
        password: '',
        password_confirmation: ''
      },
      meta: {
        loading: false,
        passRules: {
          password: [{ required: true, trigger: 'blur', message: 'Please, this field is required' }],
          old_password: [{ required: true, trigger: 'blur', message: 'Please, this field is required' }],
          password_confirmation: [{ required: true, trigger: 'blur', message: 'Please, this field is required' }]
        },
        passwordDialog: false,
        languageDialog: false
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.Auth.user,
      license: (state) => state.Auth.license
    }),
    evaluateLicenseColor () {
      const diff = this.$moment(this.license.end_date).diff(this.$moment(new Date()), 'days')
      if (diff > 90) {
        return 1
      } else if (diff > 60) return 0
      return -1
    },
    elc () {
      return this.evaluateLicenseColor
    }
  },
  watch: {
    async 'license' (license) {
      console.log(license, 'license value')
    }
  },
  methods: {
    updatePassword () {
      this.$refs.passForm.validate((valid) => {
        if (valid) {
          this.$authService.changePassword(this.pass)
            .then(data => {
              this.displayMessage('Password Updated', () => {
                this.$authService.logout()
              })
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status === 403) {
                  this.displayMessage(error.response.data.message, () => {
                  }, 'error')
                  return false
                } else if (error.response.status !== 422) {
                  this.notify(error.message, 'Error', 'error')
                  return false
                }
              }
            })
        }
      })
    },
    goBack () {
    },
    changeLang (lang) {
      this.setLanguage(lang)
    },
    toggleSidebar () {
      window.$('body.sidebar-pinned').addClass('sidebar-open')
    }
  },
  mounted () {
    if (!Object.keys(this.license).length) {
      this.$store.dispatch('Auth/fetchLicense')
        .catch(() => {
          this.$router.push({ name: 'base' })
        })
    }
  }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.7);
  }
}

.el-dropdown-menu__item.active-lang {
  background-color: #424770;
  color: #ecf5ff;
}
</style>
