<template>
  <div>
    <el-dialog :visible="display"  @opened="openedHandler"
               :close-on-click-modal="false" :show-close="false"
               :close-on-press-escape="false" @closed="closedHandler" center>
      <template slot="title">
        <h5 class="text-dark">{{$t('price_list.title.create')}}</h5>
        <hr>
      </template>
      <el-form ref="form" :model="data" :rules="rules" label-width="200px" size="mini" status-icon>
        <el-form-item :label="$t('price_list.label.name')" prop="name">
          <el-input ref="name" v-model="data.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('price_list.label.description')" prop="description">
          <el-input type="textarea" v-model="data.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" :loading="loading" @click.prevent="createPriceList">
            {{ $t('price_list.button.create') }}
          </el-button>
          <el-button @click.prevent="$emit('close')">{{ $t('button.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddPriceList',
  props: {
    display: { type: Boolean },
    position: { type: Number, default: 100 }
  },
  data () {
    return {
      loading: false,
      data: {
        name: '',
        description: '',
        position: 100
      },
      show: false,
      rules: {
        name: [{ required: true }]
      }
    }
  },
  methods: {
    createPriceList () {
      this.askQuestion(this.$t('price_list.confirm.create'), () => {
        this.$configService.addPriceList(this.data)
          .then(data => {
            this.notify(this.$t('price_list.message.created'), this.$t('status.success'))
            this.$emit('created', data)
            this.$emit('close')
          })
          .catch(this.afterSubmitError)
          .then(() => {
            this.loading = false
          })
      })
    },
    openedHandler () {
      this.data.position = this.position
      console.log(this.position)
      this.$refs.name.focus()
    },
    closedHandler () {
      this.data.name = ''
      this.data.description = ''
      this.position = 100
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
