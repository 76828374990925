export default class ReportService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getSupplierPayments (query) {
    return new Promise((resolve, reject) => {
      this.$http.get('reports/supplier/payment', { params: query })
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  getSupplies (query) {
    return new Promise((resolve, reject) => {
      this.$http.get('reports/supplies', { params: query })
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  getCurrentPayroll (query) {
    return new Promise((resolve, reject) => {
      this.$http.get('reports/expected-expenditure', { params: query })
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  getEmployeeDebts (query) {
    return new Promise((resolve, reject) => {
      this.$http.get('reports/employee-debts', { params: query })
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  getDeposits (query) {
    return new Promise((resolve, reject) => {
      this.$http.get('reports/deposits', { params: query })
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  getWithdrawals (query) {
    return new Promise((resolve, reject) => {
      this.$http.get('reports/withdrawals', { params: query })
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }

  queryInventory (query) {
    return new Promise((resolve, reject) => {
      this.$http.get('reports/inventories', { params: query })
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .catch(reject)
    })
  }
}
