<template>
  <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display"
             @closed="closed" @close="$emit('close', false)" @opened="opened" :destroy-on-close="true">
    <slot name="title">
      <h3 class="text-center">{{ $t(`variant.title.${edit ? 'update' : 'create'}`, {name: form.name}) }}</h3>
      <hr>
    </slot>
    <el-row>
      <el-col :span="12" :offset="6">
        <el-form ref="varForm" :model="form" :rules="rules" label-width="100px" size="large" status-icon>
          <el-form-item label="Name" prop="name">
            <el-input v-model="form.name" ref="input"></el-input>
          </el-form-item>
          <el-form-item label="Item" prop="item">
            <el-select v-model="form.item_id" placeholder="Item" collapse-tags clearable>
              <el-option
                v-for="item in items"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml-2">
            <el-card class="box-card">
              <div v-for="(option, index) in form.options" :key="index" class="text item">
                <el-row :gutter="5" draggable="true">
                  <el-col :span="20">
                    <el-input v-model="option.name" :placeholder="$t('variant.label.option_name')"></el-input>
                  </el-col>
                  <el-col :span="3" class="ml-3" align="end">
                    <el-button type="danger" @click="removeOption(index)" icon="el-icon-delete"></el-button>
                  </el-col>
                </el-row>
              </div>
              <el-button type="success" @click="addOption" class="mt-3 ml-2" plain>
                {{ $t(`variant.button.add_option`) }}
                <i class="el-icon-plus"></i>
              </el-button>
            </el-card>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="12" :offset="6">
        <el-button @click="$emit('close', false)">Cancel</el-button>
        <el-button :disabled="!submittable" :loading="loading" type="primary" @click="submitForm">
          {{ $t(`variant.button.${edit ? 'update' : 'create'}`) }}
        </el-button>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
export default {
  name: 'createVariant',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    variant: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      items: [],
      form: {
        name: '',
        item_id: '',
        options: []
      },
      rules: {
        name: [
          { required: true, message: 'Please specify the variant name', trigger: ['change', 'blur'] },
          { min: 3, message: 'Length should be at least 3', trigger: ['change', 'blur'] }
        ],
        item: [
          { required: true, message: 'Please select the item', trigger: ['change', 'blur'] }
        ]
      },
      loading: false
    }
  },
  computed: {
    submittable () {
      return !Object.keys(this.form).filter(k => (typeof this.form[k] !== 'boolean') && !this.form[k]).length
    },
    edit () {
      return this.variant && this.variant.id
    }
  },
  methods: {
    async opened () {
      if (this.variant && this.variant.id) {
        this.form.name = this.variant.name
        await this.$variantService.getVariantOptions(this.variant.id)
          .then(data => {
            if (data.length > 0) {
              const options = []
              for (const option of data) {
                options.push({
                  id: option.id,
                  name: option.name,
                  variant_id: option.variant_id
                })
              }
              this.form.options = options
            }
          })
      } else {
        const options = [{ name: '' }]
        this.form.options = options
      }
      this.$refs.input.focus()
    },
    closed () {
      Object.keys(this.form).forEach((k) => {
        this.form[k] = ''
      })
      this.loading = false
    },
    addOption () {
      this.form.options.push({
        name: ''
      })
    },
    removeOption (index) {
      this.form.options.splice(index, 1)
    },
    submitForm () {
      this.$refs.varForm.validate((valid) => {
        if (valid) {
          this.askQuestion(this.$t(`variant.confirm.${this.edit ? 'update' : 'create'}`), () => {
            this.loading = true
            if (this.edit) {
              this.$variantService.updateVariant(this.variant.id, this.form)
                .then(async data => {
                  if (this.form.options.length > 0) {
                    for (const option of this.form.options) {
                      if (option.name !== '') {
                        option.variant_id = data.id
                        if (Object.prototype.hasOwnProperty.call(option, 'id')) {
                          await this.$variantService.updateVariantOption(option.id, option)
                            .then(res => {})
                        } else {
                          await this.$variantService.createVariantOption(option)
                            .then(res => {})
                        }
                      }
                    }
                  }
                  this.notify(this.$t('variant.message.updated'), this.$t('status.success'))
                  this.$emit('updated', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            } else {
              const variant = [{ name: this.form.name, options: this.form.options }]
              this.$variantService.createVariantAndOptions(variant, this.form.item_id)
                .then(async data => {
                  this.loading = false
                  this.notify(this.$t('variant.message.created'), this.$t('status.success'))
                  this.$emit('created', data)
                  this.$emit('close')
                })
                .catch(this.afterSubmitError)
                .then(() => {
                  this.loading = false
                })
            }
          })
        }
      })
    },
    afterSubmitError (error) {
      if (error.response) {
        this.notify(error.response.data.message, error.response.statusText || this.$t('status.error'), 'error')
      } else if (error.request) {
        this.notify(error.request.message, this.$t('status.error'), 'error')
      } else {
        this.notify(error.message, this.$t('status.error'), 'error')
      }
    }
  },
  mounted () {
    this.$itemService.getItems()
      .then(data => {
        this.items = data
      })
  }
}
</script>

<style scoped>

</style>
