<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <el-row>
              <el-col :span="12">
                <h5 class="m-b-0">
                  <i class="mdi mdi-account-supervisor"></i> {{ $t('customer.button.all') }}
                </h5>
              </el-col>
            </el-row>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables
                  :data="customers.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                  :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }"
                  center-align>
                <el-table-column in label="S/N" type="index" sortable></el-table-column>
                <el-table-column show-overflow-tooltip prop="name" label="Name"></el-table-column>
                <el-table-column label="PL">
                  <template slot-scope="scope">
                    <el-select :clearable="$can('customer.price-list')" :value="scope.row.price_list_id" size="mini" @change="updatePriceList(scope.row.id, $event)">
                      <el-option v-can.disable="'customer.price-list'" v-for="pl in price_lists" :key="pl.id" :label="pl.name" :value="pl.id"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="address" :label="$t('customer.label.address')"></el-table-column>
                <el-table-column show-overflow-tooltip prop="user.name" :label="$t('customer.label.user')">

                </el-table-column>
                <el-table-column align="center" :label="$t('customer.label.buy_on_credit')">
                  <template slot-scope="scope">
                    <el-switch @change="updateCreditCustomer(scope.row.id)" :value="scope.row.buy_on_credit"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" :label="$t('customer.label.phone')" :formatter="$formatPhone"></el-table-column>
                <el-table-column prop="created_at" :formatter="$slimDate" :label="$t('label.date')"></el-table-column>
                <el-table-column :label="$t('title.actions')" min-width="70px">
                  <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" autofocus
                              :placeholder="$t('text.search_placeholder')" ref="searchInput"/>
                  </template>
                  <template slot-scope="scope">
                    <el-button v-can.disable="'customer.archive'" @click.prevent="archiveCustomer(scope.row.id)" type="primary" size="mini">
                      {{ $t('button.archive') }}
                    </el-button>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      price_lists: [],
      customers: [],
      customer: {},
      detailsDialog: false,
      search: ''
    }
  },
  methods: {
    updatePriceList (customerId, plId) {
      this.$customerService.updatePriceList(customerId, plId)
        .then(res => {
          this.customers.find(c => c.id === customerId).price_list_id = plId
          this.notify(this.$t('customer.message.price_list_updated'))
        })
        .catch(this.globalErrHandle)
    },
    archiveCustomer (id) {
      this.askQuestion(this.$t('customer.confirm.archive'), () => {
        this.$customerService.archiveCustomer(id)
          .then(() => {
            this.notify(this.$t('customer.message.archived'))
            this.customers.splice(this.customers.indexOf(this.customers.find(c => c.id === id)), 1)
          })
      })
    },
    updateCreditCustomer (id) {
      this.$customerService.updateCreditCustomer(id)
        .then(data => {
          this.customers.find(c => c.id === id).buy_on_credit = data.buy_on_credit
        })
    }
  },
  async mounted () {
    this.$emit('parent-title', this.$t('customer.title.customer'))
    setTimeout(() => {
      this.$refs.searchInput.focus()
    }, 500)
    await this.$configService.getPriceLists()
      .then(data => {
        this.price_lists = data
      })
    await this.$customerService.getCustomers()
      .then(data => {
        this.customers = data
      })
  }
}
</script>

<style scoped>

</style>
