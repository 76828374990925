<template>
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('employee.title.archive') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="employees.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }" center-align>
                                <el-table-column in label="S/N" type="index" sortable></el-table-column>
                                <el-table-column prop="name" label="Name" sortable></el-table-column>
                                <el-table-column prop="type.name" label="Category" sortable></el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable></el-table-column>
                                <el-table-column prop="phone" label="Phone" sortable></el-table-column>
                                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions" min-width="100px">
                                    <template slot="header" slot-scope="scope">
                                        <el-input v-model="search" size="mini" autofocus
                                                placeholder="Type to search"/>
                                    </template>
                                    <template slot-scope="scope">
                                        <button class="btn btn-sm btn-info" @click.prevent="showEmployeeDetails(scope.row.id)">
                                            <i class="mdi mdi-details"></i>
                                        </button>
                                        <el-popconfirm title="Are you sure to restore this?" confirmButtonText='Restore'
                                                       cancelButtonText='No, Thanks'
                                                       @onConfirm="restore(scope.$index, scope.row.id)">
                                            <button @click.prevent="" slot="reference" class="btn btn-sm btn-warning">
                                                <i class="mdi mdi-restore"></i> Restore
                                            </button>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <employee-details :employee="employee" :visible="detailsDialog" @close="closeDetailsDialog"></employee-details>
    </div>
</template>

<script>
import EmployeeDetails from './Modals/EmployeeDetails'
export default {
  name: 'Index',
  components: { EmployeeDetails },
  data () {
    return {
      employees: [],
      employee: {},
      detailsDialog: false,
      search: ''
    }
  },
  methods: {
    showEmployeeDetails (id) {
      this.employee = this.employees.find(e => e.id === id)
      this.detailsDialog = true
    },
    closeDetailsDialog (display) {
      this.employee = {}
      this.detailsDialog = display
    },
    restore (index, id) {
      this.$employeeService.restoreEmployee(id)
        .then(data => {
          this.employees.splice(this.employees.indexOf(this.employees.find(e => e.id === id)), 1)
          this.$notify({
            title: this.$t('status.success'),
            message: this.$t('employee.message.restored'),
            type: 'success'
          })
        })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('employee.title.archive'))
    this.$employeeService.getArchiveEmployees()
      .then(data => {
        this.employees = data
      })
  }
}
</script>

<style scoped>

</style>
