export default class RoleService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getRoles () {
    return new Promise((resolve, reject) => {
      this.$http.get('roles')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getSystemRoles () {
    return new Promise((resolve, reject) => {
      this.$http.get('roles/system')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getPermissions () {
    return new Promise((resolve, reject) => {
      this.$http.get('permissions')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getRolePermissions (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`roles/permissions/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createRole (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('roles/create', data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteRole (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`roles/delete/${id}`, data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateRole (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`roles/update/${id}`, data)
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
  }

  assignPermission (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`roles/permissions/assign//${id}`, data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
    })
  }

  assignPermissions (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`roles/permissions/assign/${id}`, data)
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
  }

  getUserRole (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.get(`roles/user/${id}`, data)
        .then(res => res.data)
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
  }

  assignUserRole (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`roles/user/assign/${id}`, data)
        .then(res => res.data)
        .then(res => res.data)
        .then(resolve)
        .catch(reject)
    })
  }
}
