export default class SaleService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getServices () {
    return new Promise((resolve, reject) => {
      this.$http.get('services')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getNonServices () {
    return new Promise((resolve, reject) => {
      this.$http.get('services/prospects')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateDepartment (serviceId, deptId) {
    return new Promise((resolve, reject) => {
      this.$http.post(`services/${serviceId}/departments/${deptId}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createService (userId) {
    return new Promise((resolve, reject) => {
      this.$http.post(`services/store/${userId}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateService (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`services/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  removeService (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`services/status/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
