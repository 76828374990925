<template>
  <el-dialog :title="$t('variant.title.create_all')" :visible="display" width="50%" append-to-body
             :close-on-click-modal="false" :close-on-press-escape="false" @open="open" @close="safeCloseVariant"
             @closed="closed">
    <div v-for="(variant, index) in variants" :key="index" class="text item">
      <el-row :gutter="5">
        <el-col :span="6">
          <el-input :ref="`variant-name-${index}`" v-model="variants[index].name"
                    :placeholder="$t('variant.label.variant_name')"></el-input>
        </el-col>
        <el-col :span="12">
          <el-input :ref="`input-value-${index}`" v-model="variants[index].inputValue"
                    :placeholder="$t('variant.message.options_guide')"
                    @keyup.enter.native="handleInputConfirm(index)">
          </el-input>
          <el-tag v-for="(optionName, idx) in variant.options"
                  :key="idx" @close="removeOption(index, idx)" size="mini"
                  class="mt-1 ml-2" :disable-transitions="false" closable>
            {{ optionName }}
          </el-tag>
        </el-col>
        <el-col :span="4" class="" align="end">
          <el-button-group>
            <transition name="el-zoom-in-center">
              <el-button type="success" size="medium" v-if="variants[index].inputValue.length"
                         @click="handleInputConfirm(index)" icon="el-icon-check"></el-button>
            </transition>
            <el-button type="danger" size="medium" @click="removeVariant(index)" icon="el-icon-delete"></el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <el-button type="success" @click="addVariant" class="mt-3 ml-2" plain>
      {{ $t(`variant.title.create`) }}
      <i class="el-icon-plus"></i>
    </el-button>
    <template #footer>
      <el-row class="dialog-footer">
        <el-col :span="12" :offset="6">
          <el-button @click.prevent="safeCloseVariant">{{ $t(`button.cancel`) }}</el-button>
          <el-button :disabled="!variants.length" type="primary" :loading="loading" @click.prevent="saveVariant">
            {{ $t(`button.save`) }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'VariantDialog',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: false,
      default: () => ({})
    },
    prevVariants: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data () {
    return {
      variants: [],
      snapshot: [],
      loading: false
    }
  },
  computed: {
    edit () {
      return this.item && this.item.id
    },
    defaultVariant: () => ([{
      name: '',
      inputValue: '',
      options: []
    }]),
    safeToExit () {
      return Object.entries(this.variants).toString() === Object.entries(this.snapshot).toString()
    }
  },
  methods: {
    async open () {
      if (this.prevVariants && this.prevVariants.length > 0) {
        this.variants = this.prevVariants
      } else {
        if (this.edit) {
          const variants = await this.$variantService.getItemVariant(this.item.id)
          if (variants && variants.length > 0) {
            this.variants = variants.map(v => ({
              id: v.id,
              name: v.name,
              options: v.options.map(vo => vo.name),
              inputValue: ''
            }))
          } else {
            this.variants = this.defaultVariant
          }
        } else {
          this.variants = this.defaultVariant
        }
      }
      this.snapshot = Object.assign({}, this.variants)
      if (this.variants.length) {
        this.$nextTick(_ => {
          this.$refs['variant-name-0'][0].$refs.input.focus()
        })
      }
    },
    safeCloseVariant () {
      if (this.safeToExit) {
        this.$emit('close')
      } else {
        this.askQuestion('You have unsaved changes. Do you want to continue?',
          () => (this.$emit('close')), 'Save changes')
      }
    },
    closed () {
      this.variants = []
    },
    removeOption (vIndex, oIndex) {
      this.variants[vIndex].options.splice(oIndex, 1)
    },
    handleInputConfirm (index) {
      const inputValue = this.variants[index].inputValue
      if (inputValue) {
        this.variants[index].options.push(inputValue)
        this.variants[index].inputValue = ''
      }
    },
    addVariant () {
      if (this.variants.length < 3) {
        this.variants.push({
          name: '',
          item_id: this.item.id,
          options: [],
          inputValue: ''
        })
      } else {
        this.notify(this.$t('variant.message.exceeded'), this.$t('variant.label.exceeded'), 'error')
      }
    },
    removeVariant (index) {
      this.variants.splice(index, 1)
    },
    saveVariant () {
      if (this.variants.length) {
        if (this.variants[this.variants.length - 1].inputValue.length > 0) {
          this.notify('Please, confirm the last option and continue', 'Verify', 'warning')
          this.$refs[`input-value-${this.variants.length - 1}`][0].$refs.input.focus()
          return
        }
        const data = this.variants.filter(v => {
          return v.name & v.options.length > 0
        })
        this.$emit('created', data.length ? data : [])
        this.variants = this.snapshot
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>
