export default class DiscountService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getDiscounts () {
    return new Promise((resolve, reject) => {
      this.$http.get('discounts')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createDiscount (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('discounts/create', data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateDiscount (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`discounts/update/${id}`, data)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  deleteDiscount (id) {
    return new Promise((resolve, reject) => {
      this.$http.delete(`discounts/delete/${id}`)
        .then(res => res.data)
        .then(data => {
          if (data.status === 'error') reject(new Error(data.message))
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
