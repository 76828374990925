<template>
    <el-dialog label-width="120px" label-position="top" :fullscreen="true" :visible="display" @open="fetchTypes"
               @closed="closed" @close="$emit('close', false)" :show-close="false">
        <slot name="title">
            <h3 class="text-center">Add employee debt </h3>
            <hr>
        </slot>
        <el-row>
            <el-col :span="12" :offset="6">
                <el-form :model="form" size="medium" :rules="rules" ref="debtForm">
                    <el-form-item label="Select Employee" label-width="100" prop="employee_id">
                        <el-select v-model="form.employee_id" placeholder="Please select an employee" style="width:100%">
                            <el-option v-for="emp in employees" :label="emp.name" :key="emp.id" :value="emp.id">
                                <span style="float: left">{{ emp.name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ emp.type.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Select Debt Category" label-width="100" prop="type_id">
                        <el-select v-model="form.type_id" placeholder="Please select a debt category" class="f-w">
                            <el-option v-for="type in types" :label="type.name" :key="type.id" :value="type.id">
                                <span style="float: left">{{ type.name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px;">
                            <el-tooltip :enterable="false" class="item" effect="dark" :content="$t(`employee.debt.affects_account[${type.affects_account}]`)"
                                        placement="left">
                            <i class="el-icon-scissors p-r-15"
                               :class="[type.affects_account ? 'active-text' : 'faint-text']"></i>
                            </el-tooltip>
                            <el-tooltip :enterable="false" class="item" effect="dark" :content="$t(`employee.debt.payable[${type.can_pay}]`)"
                                        placement="left">
                            <i class="el-icon-s-finance p-l-15"
                               :class="[type.can_pay ? 'active-text' : 'faint-text']"></i>
                            </el-tooltip>
                        </span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Debt amount" label-width="100" prop="amount">
                        <el-input v-model.number="form.amount" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Date" label-width="100" prop="date">
                        <el-date-picker class="f-w" v-model="form.date" type="date" placeholder="Pick a day"
                                        format="dd MMM, yyyy" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="Description" prop="description" label-width="100">
                        <el-input type="textarea" v-model="form.description"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-row slot="footer" class="dialog-footer">
            <el-col :span="12" :offset="6">
                <el-button @click="$emit('close', false)">Cancel</el-button>
                <el-button :disabled="canConfirm" :loading="loading" type="primary" @click="saveDept">Confirm</el-button>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
export default {
  name: 'DebtCashin',
  props: {
    display: {
      type: Boolean,
      required: true
    },
    employeeId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      form: {
        type_id: null,
        amount: 0,
        employee_id: this.employeeId,
        date: null,
        description: ''
      },
      rules: {
        type_id: [{ required: true, message: 'Please, select a debt category', trigger: 'blur' }],
        amount: [{ required: true, message: 'Please, the debt amount', trigger: 'blur' }],
        employee_id: [{ required: true, message: 'Please, select an employee', trigger: 'blur' }],
        date: [{ required: true, message: 'Please, select the date of the debt', trigger: 'blur' }],
        description: [{ required: true, message: 'Please, add a description for this debt', trigger: 'blur' }]
      },
      types: [],
      employees: [],
      loading: false
    }
  },
  watch: {},
  computed: {
    canConfirm () {
      return Object.values(this.form).some(it => (!it || it === 0))
    }
  },
  methods: {
    saveDept () {
      this.askQuestion(this.$t('debt.confirm.create', { name: this.employees.find(e => e.id === this.form.employee_id).name }), () => {
        this.$refs.debtForm.validate((valid) => {
          if (valid) {
            this.loading = true
            this.$employeeService.cashInDebt(this.employeeId, this.form)
              .then(data => {
                this.notify(this.$t('employee.debt.success'), this.$t('status.success'))
                this.$emit('debt-ok', data.data)
                this.$refs.debtForm.resetFields()
                this.$emit('close', false)
              })
              .then(() => {
                this.loading = false
              })
          }
        })
      })
    },
    fetchTypes () {
      if (this.employeeId) {
        this.form.employee_id = this.employeeId
        this.$employeeService.findEmployee(this.employeeId)
          .then(data => {
            this.employees = [data]
          })
      } else {
        this.$employeeService.getEmployees()
          .then(data => {
            this.employees = data
          })
      }
      this.$debtService.getDebtTypes()
        .then(data => {
          this.types = data.data
        })
    },
    closed () {
      this.$refs.debtForm.resetFields()
      this.loading = false
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
    .fain-text {
        color: #8d97a5;
    }

    .active-text {
        color: #00cc99;
    }
</style>
