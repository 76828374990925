<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-body">
            <div class="row" style="">
              <div style="visibility:hidden; opacity:0" id="dropzone">
                <div id="textnode">Drop files to add data.</div>
              </div>
              <div class="el-upload-draggable" @click="initiateFileSelection">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                <input ref="upload" @change="uploadFiles($event.target.files[0])" type="file" name="file"
                       class="el-upload__input">
              </div>
            </div>
            <div>
              <el-dialog fullscreen :title="`Visualize ${itemsCount} Items`" :visible.sync="dialogVisible">
                <el-row style="padding-bottom: 15px;float: right;">
                  <el-button type="default" @click="dialogVisible = false">Cancel</el-button>
                  <el-button :loading="loading" type="success" @click="confirmInsert">Confirm</el-button>
                </el-row>

                <!--                <el-row>-->
                <table class="table table-bordered">
                  <thead>
                  <tr scope="col">
                    <th>Code</th>
                    <th>Name</th>
                    <th>Unit Price</th>
                    <th>Category</th>
                    <th>Purchase Price</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody v-for="(value, key) in content.rows">
                  <tr v-for="(field, ind) in value" :key="ind">
                    <td>{{ field.code }}</td>
                    <td>{{ field.name }}</td>
                    <td>{{ field.selling_price }}</td>
                    <td>{{ key }}</td>
                    <td>{{ field.purchase_price }}</td>
                    <td>
                      <el-button @click="handleRemoveItem(ind, key)" type="danger" icon="el-icon-delete"
                                 size="mini" round>
                        Remove
                      </el-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!--                </el-row>-->
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">Cancel</el-button>
                    <el-button :loading="loading" type="success" @click="confirmInsert">Confirm</el-button>
                </span>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
// import { remote } from 'electron'

/* let path = require('path')

let download = require('electron-file-downloader')
remote.ipcMain.on('download-btn', (e, args) => {
  download(remote.getCurrentWindow(), args.url)
    .then(dl => console.log(dl.getSavePath()))
    .catch(console.error)
}) */

export default {
  name: 'upload-source',
  data () {
    return {
      content: {},
      itemsCount: 0,
      loading: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dialogVisible: false,
      form: {},
      formLabelWidth: '120px'
    }
  },
  methods: {
    handleRemoveItem (index, parent) {
      this.content.rows[parent].splice(index, 1)
    },
    initiateFileSelection () {
      window.$(this.$refs.upload).click()
    },
    closeInsertDialog () {
      this.dialogVisible = false
      this.content = {}
    },
    downloadTemplate (evt) {
      /* let url = `${path.join(__static, 'downloadable/source-template.xlsx')}`
      console.log(url)
      download.download(remote.getCurrentWindow(), url, {filename: `infinity-finance-source-template-${(new Date()).getTime()}.xlsx`})
        .then(res => console.log(res)) */
    },
    uploadFiles (file) {
      console.log(file)
      readXlsxFile(file).then((rows) => {
        // `rows` is an array of rows
        const dep = rows[0][0]
        // each row being an array of cells.
        rows.splice(0, 1)
        console.log(dep)
        const data = {
          department: dep,
          rows: {}
        }
        let ind = ''
        for (let i = 0; i < rows.length; i++) {
          if (rows[i][1] && rows[i][2]) {
            data.rows[ind].push({
              code: rows[i][0],
              name: rows[i][1],
              selling_price: rows[i][2],
              purchase_price: rows[i][3],
              description: rows[i][4],
              reorder_level: rows[i][5]
            })
          } else {
            ind = rows[i][0]
            data.rows[ind] = []
          }
        }
        console.log('final data', data)
        this.itemsCount = 0
        for (const rec in data.rows) {
          if (data.rows.hasOwnProperty(rec)) {
            this.itemsCount += data.rows[rec].length
          }
        }
        // data.reduce((acc, element) => console.log(acc + element.length), 0)
        this.content = data
        this.dialogVisible = true
      }).catch(err => {
        console.log(err)
        if (err) {
          this.err('Error occurred while loading file! Please ensure that file is a valid Excel Document')
        }
      })

      /* let reader = new FileReader()
      reader.onload = function (f) {
        let content = f.target.result
        this.content = content
      }
      reader.readAsDataURL(file) */
    },
    handleNodeClick (...data) {
      console.log(data)
    },
    confirmInsert () {
      const data = Object.assign({}, this.content)
      this.loading = true
      this.$itemService.massInsert(data)
        .then(response => {
          console.log(response)
          this.loading = false
          if (response.status === 'success') {
            this.closeInsertDialog()
            const mssg = `${response.created} were created. ${response.exists ? response.exists : 0} exists and ${response.exists_count > 1 ? 'were' : 'was'} ignored`
            this.$notify.success(mssg)
            this.$router.push({ name: 'items' })
          } else {
            this.$notify.error(response.error)
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response) {
            this.$notify.error(err.response)
          } else if (err.request) {
            this.$notify.error(err.request)
          } else {
            this.$notify.error(err.message)
          }
          return false
        })
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('item.title.upload'))
    const self = this
    window.addEventListener('dragenter', function (e) {
      document.querySelector('#dropzone').style.visibility = ''
      document.querySelector('#dropzone').style.opacity = 1
      document.querySelector('#textnode').style.fontSize = '48px'
    })

    window.addEventListener('dragleave', function (e) {
      e.preventDefault()

      document.querySelector('#dropzone').style.visibility = 'hidden'
      document.querySelector('#dropzone').style.opacity = 0
      document.querySelector('#textnode').style.fontSize = '42px'
    })

    window.addEventListener('dragover', function (e) {
      e.preventDefault()
      document.querySelector('#dropzone').style.visibility = ''
      document.querySelector('#dropzone').style.opacity = 1
      document.querySelector('#textnode').style.fontSize = '48px'
    })

    window.addEventListener('drop', function (e) {
      e.preventDefault()
      document.querySelector('#dropzone').style.visibility = 'hidden'
      document.querySelector('#dropzone').style.opacity = 0
      document.querySelector('#textnode').style.fontSize = '42px'

      const files = e.dataTransfer.files
      self.uploadFiles(files[0])
    })
  }
}
</script>

<style scoped>
.el-dialog__body {
  padding: 0 20px !important;
  max-height: 120px !important;
}

.el-download-template {
  position: absolute;
  bottom: 82px;
  right: 50px;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 98%;
  height: 679px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

.el-upload-draggable {
  background-color: #fff;
  border: 3px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100vw;
  height: 70vh;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.el-upload-draggable .el-icon-upload {
  font-size: 67px;
  color: #c0c4cc;
  margin: 340px 0 16px;
  line-height: 80px;
}

.el-upload-draggable .el-upload__text em {
  color: #409eff;
  font-style: normal;
}

.el-upload-draggable .el-upload__input {
  display: none;
}

</style>
