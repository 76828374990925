<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <el-row>
              <el-col :span="12">
                <h5 class="m-b-0">
                  <i class="mdi mdi-account-group-outline"></i> {{ $t('user.button.user') }}
                </h5>
              </el-col>
            </el-row>
          </div>
          <div class="card-body">
            <user-assign-role-modal :user="assign" v-if="assign.id"
                                    @close="assign = {}"></user-assign-role-modal>
            <div class="table-responsive">
              <data-tables
                  :data="users.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                  :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }"
                  center-align>
                <el-table-column label="Photo" align="center" min-width="50" fixed>
                  <template slot-scope="scope">
                    <el-avatar size="medium" :src="scope.row.photo"></el-avatar>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="name" label="Name" fixed>
                  <template slot-scope="scope">{{ scope.row.name }}
                    <el-tag size="mini" v-if="scope.row.is_admin" type="primary">SU</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="username" label="Username" fixed></el-table-column>
                <el-table-column prop="department" label="Department">
                  <template slot-scope="scope">
                    {{ scope.row.department ? scope.row.department.name : 'None' }}
                  </template>
                </el-table-column>
                <el-table-column label="Status">
                  <template slot-scope="scope">
                    <el-checkbox v-can.disable="'user.archive'" style="padding-bottom: 25px!important;" :disabled="scope.row.is_admin"
                                 :label="$t(`status.${scope.row.active? 'active':'inactive'}`)"
                                 border @change="updateStatus($event, scope.row.id)"
                                 size="mini" :value="!!scope.row.active"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                 sortable></el-table-column>
                <el-table-column label="Actions" min-width="120px">
                  <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" autofocus
                              placeholder="Type to search"/>
                  </template>
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-tooltip :content="$t('button.edit')" placement="top">
                        <el-button v-can.disable="'user.update'" size="mini" type="success" icon="el-icon-edit"
                                   @click="editUserDialog(scope.row.id)">
                        </el-button>
                      </el-tooltip>
                      <el-tooltip :content="$t('user.button.reset')" placement="top">
                        <el-button v-can.disable="'user.reset-password'" type="warning" size="mini" :disabled="scope.row.is_admin" icon="el-icon-key"
                                   @click="resetPassword(scope.row.id)">
                        </el-button>
                      </el-tooltip>
                      <el-tooltip :content="$t('user.button.assign')" placement="top">
                        <el-button v-can.disable="'role.assign'" type="primary" size="mini" icon="el-icon-lock"
                                   @click="assignRole(scope.row.id, scope.$index)">
                        </el-button>
                      </el-tooltip>
                    </el-button-group>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-edit-user :user="user" :visible="displayDialog" @created="userCreated" @updated="userUpdated"
                      @close="closeUserDialog"></create-edit-user>
  </div>
</template>

<script>
import CreateEditUser from './Modals/CreateEditUser'
import UserAssignRoleModal from './Modals/AssignRole'

export default {
  name: 'Index',
  components: {
    CreateEditUser,
    UserAssignRoleModal
  },
  data () {
    return {
      users: [],
      user: {},
      displayDialog: false,
      search: '',
      assign: {}
    }
  },
  methods: {
    assignRole (id, index) {
      console.log(id, index)
      console.log(this.users[index])
      this.assign = this.users[index]
    },
    resetPassword (id) {
      this.$prompt(this.$t('user.confirm.reset'), this.$t('user.title.reset', { name: this.users.find(u => u.id === id).username }), {
        confirmButtonText: this.$t('button.proceed'),
        cancelButtonText: this.$t('button.cancel'),
        inputPattern: /[\w]{6,}/,
        inputErrorMessage: this.$t('user.validation.password')
      }).then(({ value }) => {
        this.$userService.resetPassword(id, { password: value })
          .then(data => {
            this.displayMessage(this.$t('user.message.reset'))
          })
          .catch(error => {
            this.displayMessage(error.response && error.response.data ? error.response.data.message : error.message,
              () => {
              }, 'error')
          })
      })
    },
    updateStatus (value, id) {
      this.askQuestion(this.$t('user.confirm.status'), () => {
        this.$userService.changeStatus(id)
          .then(data => {
            const index = this.users.indexOf(this.users.find(u => u.id === id))
            this.users[index].active = data.active
          })
          .catch(error => {
            this.displayMessage(error.response && error.response.data ? error.response.data.message : error.message,
              () => {
              }, 'error')
          })
      })
    },
    editUserDialog (id) {
      this.user = this.users.find(e => e.id === id)
      this.displayDialog = true
    },
    closeUserDialog () {
      this.user = {}
      this.displayDialog = false
    },
    userCreated (data) {
      this.users.splice(0, 0, data)
    },
    userUpdated (data) {
      const index = this.users.indexOf(this.users.find(u => u.id === data.id))
      this.users.splice(index, 1, data)
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('user.title.user'))
    this.$userService.getUsers()
      .then(data => {
        this.users = data
      })
  },
  created () {
    this.$parent.$on('user-created', this.userCreated)
  }
}
</script>

<style scoped>

</style>
