<template>
  <div class="container pull-up">
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-30">
          <div class="card-header">
            <el-row>
              <el-col :span="12">
                <h5 class="m-b-0">
                  <i class="mdi mdi-checkbox-intermediate"></i> {{ $t('employee.button.all') }}
                </h5>
              </el-col>
              <el-col :span="12">
                <router-link v-can.disable="'employee.type'" :to="{name: 'employee-types'}"
                             class="el-button el-button--primary el-button--medium float-right mr-3">
                  {{ $t('employee.type.button.type') }}
                </router-link>
              </el-col>
            </el-row>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <data-tables
                  :data="employees.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                  :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }"
                  center-align>
                <el-table-column in label="S/N" type="index" sortable fixed></el-table-column>
                <el-table-column prop="name" label="Name" sortable fixed>
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                    <i class="highlight-primary line-break" v-if="scope.row.bound_user_id">
                      @{{ scope.row.bound_user.username}}
                    </i>
                  </template>
                </el-table-column>
                <el-table-column prop="type.name" label="Category" sortable></el-table-column>
                <el-table-column prop="user.name" label="Creator" sortable></el-table-column>
                <el-table-column prop="phone" label="Phone" sortable></el-table-column>
                <el-table-column prop="created_at" :formatter="$shortDate" label="Created At"
                                 sortable></el-table-column>
                <el-table-column label="Actions" min-width="100px">
                  <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" autofocus
                              placeholder="Type to search" ref="searchInput"/>
                  </template>
                  <template slot-scope="scope">
                    <router-link v-can.disable="'debt.read'" :to="{name: 'debt', params: {id: scope.row.id}}"
                                 class="el-button el-button--primary el-button--mini">
                      <i class="mdi mdi-eye"></i> {{ $t('button.view') }}
                    </router-link>
                    <button disabled class="el-button el-button--info el-button--mini"
                            @click.prevent="showEmployeeDetails(scope.row.id)">
                      <i class="mdi mdi-pencil"></i> {{ $t('button.edit') }}
                    </button>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </div>
      </div>
    </div>
    <employee-details :employee="employee" :visible="detailsDialog" @close="closeDetailsDialog"></employee-details>
  </div>
</template>

<script>
import EmployeeDetails from './Modals/EmployeeDetails'

export default {
  name: 'Index',
  components: { EmployeeDetails },
  data () {
    return {
      employees: [],
      employee: {},
      detailsDialog: false,
      search: ''
    }
  },
  methods: {
    showEmployeeDetails (id) {
      this.employee = this.employees.find(e => e.id === id)
      this.detailsDialog = true
    },
    closeDetailsDialog (display) {
      this.employee = {}
      this.detailsDialog = display
    }
  },
  mounted () {
    this.$emit('parent-title', this.$t('employee.title.employee'))
    setTimeout(() => {
      this.$refs.searchInput.focus()
    }, 500)
    this.$employeeService.getEmployees()
      .then(data => {
        this.employees = data
      })
  }
}
</script>

<style scoped>

</style>
