export default class SupplierService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getSuppliers () {
    return new Promise((resolve, reject) => {
      this.$http.get('suppliers')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  create (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('suppliers/create', data)
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .then(reject)
    })
  }

  update (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`suppliers/update/${id}`, data)
        .then(res => res.data)
        .then(data => {
          resolve(data)
        })
        .then(reject)
    })
  }

  getArchive () {
    return new Promise((resolve, reject) => {
      this.$http.get('suppliers/archive/all')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  archive (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`suppliers/${id}/archive`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  findById (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`suppliers/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  cashIn (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`suppliers/${id}/cash-in`, data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getHistory (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`suppliers/${id}/history`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getSupplierPayment (id) {
    return new Promise((resolve, reject) => {
      this.$http.get(`suppliers/payments/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  makeSupplierPayment (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('supplier_payments/create', data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }
}
