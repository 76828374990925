export default class CustomerService {
  constructor (http, store, router) {
    this.$http = http
    this.$store = store
    this.$router = router
  }

  getCustomers () {
    return new Promise((resolve, reject) => {
      this.$http.get('customers')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  getArchivedCustomers () {
    return new Promise((resolve, reject) => {
      this.$http.get('customers/archives')
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  createCustomer (data) {
    return new Promise((resolve, reject) => {
      this.$http.post('customers/create', data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateCustomer (id, data) {
    return new Promise((resolve, reject) => {
      this.$http.post(`customers/${id}/update`, data)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updatePriceList (customerId, plId) {
    return new Promise((resolve, reject) => {
      this.$http.post(`customers/price-list/${customerId}/${plId}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  archiveCustomer (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`customers/archive/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  restoreCustomer (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`customers/archive/${id}/restore`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(reject)
    })
  }

  updateCreditCustomer (id) {
    return new Promise((resolve, reject) => {
      this.$http.post(`customers/update-credit-status/${id}`)
        .then(res => res.data)
        .then(data => {
          resolve(data.data)
        })
    })
  }
}
