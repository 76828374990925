<template>
  <div class="tab-pane fade active show" id="tab-reg" role="tabpanel"
       aria-labelledby="home-tab">
    <strong class="p-b-2 mb-5 h5">{{ $t('pos.name') }}</strong>
    <el-tabs tab-position="left" style="min-height: 200px;">
      <el-tab-pane v-for="(dep, idx) in departments" :key="dep.id" :label="dep.name">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-switch
                v-model="dep.settings.print_order_on_save"
                :active-text="$t('pos.label.department.print_order_on_save')">
            </el-switch>
          </el-col>
          <el-col>
            <el-switch
                v-model="dep.settings.print_order_on_update"
                :active-text="$t('pos.label.department.print_order_on_update')">
            </el-switch>
          </el-col>
          <el-col>
            <el-switch
                v-model="dep.settings.print_invoice_on_save"
                :active-text="$t('pos.label.department.print_invoice_on_save')">
            </el-switch>
          </el-col>
          <el-col :span="6" :offset="18">
            <el-button :loading="loading" type="primary" @click="updateSettings(dep.id)">Save</el-button>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

export default {
  name: 'POSDefaults',
  data () {
    return {
      departments: [],
      loading: false
    }
  },
  methods: {
    getDepartments () {
      this.$departmentService.getDepartments()
        .then(data => {
          this.departments = data
        })
    },
    updateSettings (id) {
      this.loading = true
      this.$departmentService.updateDepartmentSettings(id, this.departments.find(d => d.id === id).settings)
        .then(data => {
          this.notify(this.$t('pos.message.updated'))
          // this.getDepartments()
        })
        .catch(this.globalErrHandle)
        .then(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.getDepartments()
  }
}
</script>

<style>
.el-col {
  margin-bottom: 24px;
}
.el-col:last-child {
  margin-bottom: 0;
}
</style>
