<template>
    <!--<div>-->
    <div class="container pull-up">
        <div class="row">
            <div class="col-md-12">
                <div class="card m-b-30">
                    <div class="card-header">
                        <h5 class="m-b-0">
                            <i class="mdi mdi-checkbox-intermediate"></i> Archived Suppliers
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <data-tables :data="archives"
                                         :pagination-props="{ pageSizes: [10, 15, 30, 40, 100], pageSize: 10 }">
                                <el-table-column in label="S/N" type="index" sortable></el-table-column>
                                <el-table-column prop="name" label="Name" sortable></el-table-column>
                                <el-table-column class-name="text-center" :formatter="$formatPhone" prop="phone"
                                                 label="Phone Number" sortable></el-table-column>
                                <el-table-column prop="user.name" label="Creator" sortable></el-table-column>
                                <el-table-column prop="updated_at" :formatter="$shortDate" label="Archived Date"
                                                 sortable></el-table-column>
                                <el-table-column label="Actions" min-width="100px">
                                    <template slot-scope="scope">
                                        <button  class="btn btn-sm btn-warning" @click="beforeRestore(scope.$index, scope.row.id)">
                                            <i class="mdi mdi-restore"></i> Restore
                                        </button>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!---->
</template>

<script>
export default {
  name: 'Archive',
  data () {
    return {
      archives: []
    }
  },
  methods: {
    getArchive () {
      this.$supplierService.getArchive()
        .then(data => {
          this.archives = data
        })
    },
    beforeRestore (index, id) {
      this.$confirm(this.$t('supplier.confirm.restore'), this.$t('status.warning'), {
        confirmButtonText: this.$t('status.ok'),
        cancelButtonText: this.$t('status.cancel'),
        type: 'warning'
      }).then(() => {
        this.restore(index, id)
      })
    },
    restore (index, id) {
      this.$supplierService.archive(id)
        .then(data => {
          this.archives.splice(index, 1)
          this.$notify({
            title: this.$t('status.success'),
            message: this.$t('supplier.restore.success'),
            type: 'success'
          })
        })
    }
  },
  mounted () {
    this.getArchive()
    this.$emit('parent-title', this.$t('supplier.title.archive'))
  }
}
</script>

<style scoped>

</style>
