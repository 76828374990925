const ACL = {
  install (Vue, options) {
    options = { ...options }
    const store = options.store

    const them = function (string) {
      return string.split('|').map(function (o) {
        return o.split('&')
      }).reduce((a, b) => a.concat(b))
    }
    const resolveCan = function (perms) {
      const permissions = store.getters['Auth/getPermissions']
      return them(perms).filter(x => !permissions.includes(x)).length === 0
    }
    const resolveCannot = function (perms) {
      const permissions = store.getters['Auth/getPermissions']
      return them(perms).filter(x => !permissions.includes(x)).length > 0
    }
    Vue.directive('can', {
      inserted (el, binding, vnode, oldVnode) {
        if (!resolveCan(binding.value) && !store.getters['Auth/isAdmin']) {
          if (binding.modifiers.disable) {
            if (window.$(el).hasClass('el-button')) {
              window.$(el).addClass('is-disabled').attr('disabled', true).css({ pointerEvents: 'none' })
            } else if (window.$(el).hasClass('el-select-dropdown__item')) {
              window.$(el).addClass('is-disabled').css({ pointerEvents: 'none' })
            } else {
              window.$(el).attr('disabled', true).addClass('is-disabled').css({ pointerEvents: 'none' })
            }
          } else if (binding.modifiers.event) {
            // Disable event only
            if (window.$(el).hasClass('el-dropdown')) {
              window.$(el).find('button:first').addClass('is-disabled').attr('disabled', true).css({ pointerEvents: 'none' })
            }
          } else {
            window.$(el).remove()
          }
        }
      }
    })
    Vue.directive('cannot', {
      inserted (el, binding, vnode, oldVnode) {
        if (!resolveCannot(binding.value)) {
          if (binding.modifiers.disable) {
            if (window.$(el).hasClass('el-button')) {
              window.$(el).addClass('is-disabled').attr('disabled', true)
            } else {
              window.$(el).attr('disabled', true)
            }
          } else {
            window.$(el).remove()
          }
        }
      }
    })
    Vue.prototype.$can = function (permissions) {
      return resolveCan(permissions) || store.getters['Auth/isAdmin']
    }
    Vue.prototype.$cannot = function (permissions) {
      return resolveCannot(permissions)
    }
  }
}

export default ACL
